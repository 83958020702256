import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LitigationTypesRepo from '../../data/repositories/LitigationTypesRepo';
import litigationTypesStore from 'data/stores/LitigationTypesStore';

class LitigationTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitLitigationType = async (litigationType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLitigationType(litigationType);
    }

    return this.createLitigationType(litigationType);
  };

  createLitigationType = async (litigationType: any) => {
    const { create } = LitigationTypesRepo;

    const [response] = await to(create(litigationType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту радног спора.'
        });
      }, 1000);
      litigationTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateLitigationType = async (litigationType: any) => {
    const { update } = LitigationTypesRepo;

    const [response] = await to(update(litigationType.id, litigationType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту радног спора.'
        });
      }, 1000);
      litigationTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const litigationTypesListingStore = new LitigationTypesListingStore(
  LitigationTypesRepo
);
export default LitigationTypesListingStore;
