import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LegalFormTypesRepo from '../../data/repositories/LegalFormTypesRepo';
import { legalFormTypesStore } from '../../data/stores/LegalFormTypesStore';

class LegalFormTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitLegalFormType = async (legalFormType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLegalFormType(legalFormType);
    }

    return this.createLegalFormType(legalFormType);
  };

  createLegalFormType = async (legalFormType: any) => {
    const { create } = LegalFormTypesRepo;

    const [response] = await to(create(legalFormType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали правну форму.'
        });
      }, 1000);
      legalFormTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateLegalFormType = async (legalFormType: any) => {
    const { update } = LegalFormTypesRepo;

    const [response] = await to(update(legalFormType.id, legalFormType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали форму.'
        });
      }, 1000);
      legalFormTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const legalFormTypesListingStore = new LegalFormTypesListingStore(
  LegalFormTypesRepo
);
export default LegalFormTypesListingStore;
