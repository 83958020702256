import { Component } from 'react';
import { observable, action } from 'mobx';
import { RouteComponentProps } from 'react-router';
import { questionDetailsStore } from './QuestionDetailsStore';
import { Col, Modal, Row } from 'react-bootstrap';
import { Box } from '@material-ui/core';
import { Button, Paper } from '@mui/material';
import { pickDefaultValues } from '../../lib/form';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { observer } from 'mobx-react';
import * as permission from '../../permission-consts';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CustomMaterialUiSelect from '../../components/CustomMaterialUiSelect';
import WorkersRepo from 'data/repositories/WorkersRepo';
import SearchResultsComponent from 'components/SearchResultsComponent';
import PermissionGuard from 'hoc/PermissionGuard';
import { authStore } from '../../data/stores/AuthStore';
import questionsStore from 'data/stores/QuestionsStore';

type TParams = { id: string };

const DEFAULT_VALUES = {
  id: '',
  full_name: '',
  title: '',
  content: '',
  email: '',
  number: '',
  status: '',
  statusLiteral: '',
  answer: '',
  worker: null,
  working_on_user_id: null
};

@observer
class QuestionDetailsPage extends Component<RouteComponentProps<TParams>> {
  @observable open: boolean = false;

  @action
  setOpen = (bool: boolean) => {
    this.open = bool;
  };

  @action
  handleClose = () => {
    this.setOpen(false);
  };

  @action
  handleOpen = () => {
    this.setOpen(true);
  };

  handleDelete = (openItemId: any) => {
    questionDetailsStore.deleteQuestion(openItemId);
    this.handleClose();
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;

    questionDetailsStore.fetchOne(params.id);
  }

  onSubmit = (data: any) => {
    questionDetailsStore.updateQuestion(data);
  };

  getInitialValues = () => {
    const { openItem } = questionDetailsStore;

    if (openItem && openItem.id) {
      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      let statusLiteral;
      switch (openItem.status) {
        case 'no-answer':
          statusLiteral = 'неодговорено';
          break;
        case 'send-answer':
          statusLiteral = 'одговор послат кориснику';
          break;
        case 'control':
          statusLiteral = 'послато на контролу';
          break;
        case 'new':
          statusLiteral = 'неодговорено';
          break;
        case 'bad-answer':
          statusLiteral = 'питање враћено са ревизије, одговор није добар';
          break;
        case 'good-answer':
          statusLiteral = 'питање враћено са ревизије, одговор је добар';
          break;
      }

      return {
        ...pickedValues,
        statusLiteral: statusLiteral
      };
    }

    return DEFAULT_VALUES;
  };

  render() {
    const { openItem } = questionDetailsStore;
    const { authUser } = authStore;
    // console.log(authUser);

    if (!openItem) {
      return null;
    }

    return (
      <SearchResultsComponent type="active">
        <Box className="account-div" style={{ padding: '10px' }}>
          <Paper style={{ padding: '30px 0' }}>
            <Box
              style={{
                height: 'auto',
                minHeight: '60px',
                width: '100%',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px'
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: '16px',
                  color: '#888'
                }}
              >
                Питања корисника
              </h3>
              <Button
                onClick={() => {
                  window.location = '/dashboard/questions' as any;
                }}
                variant="contained"
                style={{
                  backgroundColor: '#fb8c00',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px'
                }}
              >
                <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                НАЗАД
              </Button>
            </Box>
            <Box style={{ padding: 30 }}>
              <Formik
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                render={({
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  setFieldTouched
                }) => (
                  <Form noValidate className="form-horizontal">
                    <Row>
                      <Col md={4}>
                        <Field
                          disabled={true}
                          component={TextField}
                          name="full_name"
                          type="text"
                          label="Име"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />

                        <Field
                          component={TextField}
                          name="title"
                          type="text"
                          label="Наслов"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />

                        <Field
                          disabled={true}
                          component={TextField}
                          name="email"
                          type="text"
                          label="Електронска пошта"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />

                        <Field
                          disabled={true}
                          component={TextField}
                          name="statusLiteral"
                          type="text"
                          label="Статус питања"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />

                        <Field
                          component={TextField}
                          name="number"
                          type="text"
                          label="Број питања"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />

                        <CustomMaterialUiSelect
                          setFieldValue={setFieldValue}
                          value={values.working_on_user_id}
                          fullValue={values.worker}
                          showFields={['firstname', 'lastname']}
                          repo={WorkersRepo}
                          display="name"
                          name="working_on_user_id"
                          fullValueName="worker"
                          valueAttr="id"
                          label="Обрађивач*"
                          // queryParams={{ search: 'role_id:4' }}
                        />

                        <Field
                          component={TextField}
                          multiline
                          name="content"
                          type="text"
                          label="Питање"
                          variant="standard"
                          inputProps={{
                            style: { fontSize: 16, lineHeight: 2 }
                          }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={4}></Col>
                      <Col md={4}>
                        <Field
                          component={TextField}
                          multiline
                          name="answer"
                          type="text"
                          label="Одговор"
                          variant="standard"
                          inputProps={{
                            style: { fontSize: 16, lineHeight: 2 }
                          }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>

                    <Row
                      style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 10,
                        margin: '0 auto',
                        padding: '15px 0',
                        width: 'calc(100% - 20px)',
                        backgroundColor: '#EEEEEE',
                        borderTop: '1px solid #ddd'
                      }}
                    >
                      <Col md={4}>
                        {authUser.roles[0].name !== 'Руководиоци' ? (
                          <>
                            <Button
                              variant="text"
                              style={{
                                fontSize: 14,
                                color: 'black',
                                visibility: openItem ? 'visible' : 'hidden'
                              }}
                              onClick={() => this.handleOpen()}
                            >
                              <CloseIcon
                                style={{ fontSize: 20, color: 'black' }}
                              />
                              ОБРИШИ
                            </Button>

                            <Button
                              onClick={() => {
                                // expenseTypesListingStore.showAddButton();
                                console.log('Submit Form');
                              }}
                              type="submit"
                              variant="text"
                              style={{ fontSize: 14, color: 'black' }}
                            >
                              <CheckIcon
                                style={{ fontSize: 20, color: 'black' }}
                              />
                              САЧУВАЈ
                            </Button>
                          </>
                        ) : null}
                      </Col>

                      <Col md={8}>
                        {openItem.status !== 'control' &&
                        openItem.status !== 'good-answer' &&
                        openItem.status !== 'send-answer' ? (
                          <PermissionGuard
                            permissionsWithAccess={
                              permission.sendAnswerToControl
                            }
                          >
                            <Button
                              onClick={() => {
                                setTimeout(() => {
                                  questionDetailsStore.sendAnswerToControl(
                                    openItem
                                  );
                                }, 1000);
                              }}
                              type="submit"
                              variant="text"
                              style={{ fontSize: 14, color: 'black' }}
                            >
                              <CheckIcon
                                style={{ fontSize: 20, color: 'black' }}
                              />
                              ПОШАЉИ ОДГОВОР НА КОНТРОЛУ
                            </Button>
                          </PermissionGuard>
                        ) : null}
                        {/* {openItem.answer && openItem.status === 'bad-answer' ? (
                        <Button
                          onClick={() => {
                            // expenseTypesListingStore.showAddButton();
                            questionDetailsStore.setBadAnswerStatus(openItem);
                            console.log('Submit Form');
                          }}
                          variant="text"
                          style={{ fontSize: 14, color: 'black' }}
                        >
                          <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                          ОДГОВОР НИЈЕ ДОБАР
                        </Button>
                      ) : null} */}

                        {openItem.answer && openItem.status === 'control' ? (
                          <>
                            <PermissionGuard
                              permissionsWithAccess={permission.answerGoodBad}
                            >
                              <Button
                                onClick={() => {
                                  // questionDetailsStore.setGoodAnswerStatus(
                                  //   openItem
                                  // );
                                  questionDetailsStore.sendAnswerToUser(
                                    openItem
                                  );
                                }}
                                variant="text"
                                type="submit"
                                style={{ fontSize: 14, color: 'black' }}
                              >
                                <CheckIcon
                                  style={{ fontSize: 20, color: 'black' }}
                                />
                                ОДГОВОР ЈЕ ДОБАР (ПОШАЉИ ОДГОВОР КОРИСНИКУ)
                              </Button>

                              <Button
                                onClick={() => {
                                  questionDetailsStore.setBadAnswerStatus(
                                    openItem
                                  );
                                }}
                                variant="text"
                                style={{ fontSize: 14, color: 'black' }}
                              >
                                <CloseIcon
                                  style={{ fontSize: 20, color: 'black' }}
                                />
                                ОДГОВОР НИЈЕ ДОБАР
                              </Button>
                            </PermissionGuard>
                          </>
                        ) : null}
                        {/* <PermissionGuard
                          permissionsWithAccess={permission.sendAnswerToUser}
                        >
                          <Button
                            onClick={() => {
                              questionDetailsStore.sendAnswerToUser(openItem);
                            }}
                            variant="text"
                            style={{ fontSize: 14, color: 'black' }}
                          >
                            <CheckIcon
                              style={{ fontSize: 20, color: 'black' }}
                            />
                            ПОШАЉИ ОДГОВОР КОРИСНИКУ{'  '}
                            {openItem.status === 'send-answer' ? (
                              <strong> (ПОСЛАТ)</strong>
                            ) : null}
                          </Button>
                        </PermissionGuard> */}
                      </Col>
                    </Row>
                  </Form>
                )}
              />
            </Box>
          </Paper>
        </Box>
        {/* Delete Dialog */}
        <Dialog
          open={this.open}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id={`alert-dialog-title-${openItem.id}`}>
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Да ли сте сигурни да желите да трајно обришете питање? Трајно
              обрисана питања је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()}>Не, одустани</Button>
            <Button
              onClick={() => {
                this.handleDelete(openItem.id);
              }}
              autoFocus
            >
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog>
      </SearchResultsComponent>
    );
  }
}

export default QuestionDetailsPage;
