import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { litigatorStatusesStore } from '../../data/stores/LitigatorStatusesStore';
import LitigatorStatusesRepo from '../../data/repositories/LitigatorStatusesRepo';

class LitigatorStatusesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitLitigatorStatus = async (litigatorStatus: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLitigatorStatus(litigatorStatus);
    }

    return this.createLitigatorStatus(litigatorStatus);
  };

  createLitigatorStatus = async (litigatorStatus: any) => {
    const { create } = LitigatorStatusesRepo;

    const [response] = await to(create(litigatorStatus));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title:
            'Успешно сте креирали врсту начина избора миритеља односно арбитра.'
        });
      }, 1000);
      litigatorStatusesStore.fetch();
      this.closeModalForm();
    }
  };

  updateLitigatorStatus = async (litigatorStatus: any) => {
    const { update } = LitigatorStatusesRepo;

    const [response] = await to(update(litigatorStatus.id, litigatorStatus));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title:
            'Успешно сте ажурирали врсту начина избора миритеља односно арбитра.'
        });
      }, 1000);
      litigatorStatusesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const litigatorStatusesListingStore = new LitigatorStatusesListingStore(
  LitigatorStatusesRepo
);
export default LitigatorStatusesListingStore;
