import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import ConclusionsRepo from '../../data/repositories/ConclusionsRepo';
import conclusionsStore from '../../data/stores/ConclusionsStore';

class ConclusionsListStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitConclusion = async (conclusion: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateConclusion(conclusion);
    }

    return this.createConclusion(conclusion);
  };

  createConclusion = async (conclusion: any) => {
    const { create } = ConclusionsRepo;

    const [response] = await to(create(conclusion));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту закључка.'
        });
      }, 1000);
      conclusionsStore.fetch();
      this.closeModalForm();
    }
  };

  updateConclusion = async (conclusion: any) => {
    const { update } = ConclusionsRepo;

    const [response] = await to(update(conclusion.id, conclusion));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту закључка.'
        });
      }, 1000);
      conclusionsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const conclusionsListStore = new ConclusionsListStore(ConclusionsRepo);
export default ConclusionsListStore;
