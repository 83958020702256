import { Component } from 'react';
import { observer } from 'mobx-react';
// import { observable, action } from 'mobx';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import AddLitigationModal from './components/AddLitigationModal';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import { litigationsArchiveStore } from './LitigationsArchiveStore';
import LitigatorFullNameCellWithAvatar from 'components/TableCells/LitigatorFullNameCellWithAvatar';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import { RowInfo } from 'react-table';
import './style.css';
import DateCell from 'components/TableCells/DateCell';
import SearchIcon from '@mui/icons-material/Search';
import LitigationNumberCell from 'components/TableCells/LitigationNumberCell';
import SearchResultsComponent from 'components/SearchResultsComponent';
import { pageStore } from '../../data/stores/PageStore';
import { searchStore } from '../../data/stores/SearchStore';

@observer
class LitigationsArchivePage extends Component {
  // componentDidMount() {
  //   litigationsStore.fetchAll();
  // }

  showAddLitigationModal = () => {
    litigationsArchiveStore.hideAddButton();
    litigationsArchiveStore.openModalForm();
  };

  hideAddLitigationModal = () => {
    litigationsArchiveStore.showAddButton();
    litigationsArchiveStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: {
        cursor: 'pointer',
        color:
          row &&
            row.original &&
            row.original.consent &&
            row.original.consent.name
            ? '#3F51B5'
            : ''
      },
      onClick: () => {
        if (row && row.original) {
          // page detail
          window.location.href = '/dashboard/litigations/' + row.original.id;
          // modal detail
          pageStore.setNavTitle(
            pageStore.navTitle + ' ' + row.original.litigation_number
          );
          //litigationsArchiveStore.openModalForm(row.original);
        }
      }
    };
  };

  itemAction = (litigation: any) => {
    return (
      <div className="td-actions text-center">
        {/* <PermissionGuard permissionsWithAccess={permission.accidentDetails}> */}
        <Button
          style={{ color: 'black' }}
          onClick={() => {
            // litigationsListingStore.openModalForm(litigation);
          }}
          startIcon={<SearchIcon />}
        >
          ПРИКАЗ
        </Button>
        {/* </PermissionGuard> */}
      </div>
    );
  };

  render() {
    // const { addButtonVisible } = litigationsArchiveStore;

    const columns = [
      // {
      //   Header: '#',
      //   id: 'id',
      //   accessor: 'id',
      //   sortable: false,
      //   width: 50
      // },
      {
        Header: 'Датум',
        id: 'document_date_of_input',
        accessor: 'document_date_of_input',
        Cell: DateCell(),
        sortable: true,
        filterable: false,
        width: 100
      },
      {
        Header: 'Бр. предмета',
        accessor: 'litigation_number',
        Cell: LitigationNumberCell,
        sortable: false,
        filterable: true,
        width: 120
      },
      {
        Header: 'Прва страна',
        id: 'name_first_party',
        accessor: 'name_first_party',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Друга страна',
        accessor: 'name_second_party',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Предмет спора',
        id: 'litigation_type.litigation_name',
        accessor: 'litigation_type.litigation_name',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Фаза',
        accessor: 'phase.name',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Миритељ и арбитар',
        id: 'litigator.litigator_firstname',
        accessor: 'litigator',
        Cell: LitigatorFullNameCellWithAvatar,
        sortable: false,
        filterable: true
      },
      {
        Header: 'Обрађивач',
        id: 'worker.firstname',
        accessor: 'worker',
        Cell: WorkerFullNameCellWithAvatar,
        sortable: false,
        filterable: true
      },
      {
        Header: 'Опције',
        accessor: 'actions',
        filterable: false,
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="archived">
        <div style={{ padding: '20px' }}>
          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={litigationsStore.fetchArchivedLitigations}
              meta={litigationsStore.meta}
              data={litigationsStore.attachCustomColumn(
                'actions',
                this.itemAction
              )}
              getTdProps={this.getTdProps}
              loading={litigationsStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddLitigationModal
              showModal={litigationsArchiveStore.showModal}
              closeModalForm={this.hideAddLitigationModal}
              openItem={litigationsArchiveStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default LitigationsArchivePage;
