import TableStore from './TableStore';
import { observable, action } from 'mobx';
import ConsentsRepo from '../repositories/ConsentsRepo';
import to from '../../lib/awaitTo';

class ConsentsStore extends TableStore<any> {
  @observable consents: any;

  @action
  setConsents = (data: any) => {
    this.consents = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(ConsentsRepo.getAllConsents({}));
    if (res) {
      this.setConsents(res.data);
    }
  };
}

export const consentsStore = new ConsentsStore(ConsentsRepo);
export default consentsStore;
