import { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import { string, object, number } from 'yup';
import { workersListingStore } from '../WorkersListingStore';
import './style.css';
import { Editor } from 'react-draft-wysiwyg';
import Thumb from 'components/Thumb';
import { workerAvatarsStore } from '../../../data/stores/WorkerAvatarsStore';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

interface AddWorkerModalProps {
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
}

const DEFAULT_VALUES = {
  id: '',
  role: null,
  role_id: null,
  firstname: '',
  lastname: '',
  phone: '',
  private_phone: '',
  email: '',
  private_email: '',
  position: '',
  professional_qualifications: '',
  additional_info: EditorState.createEmpty()
};

const workerValidationSchema = object().shape({
  role_id: number().required(),
  firstname: string()
    .required()
    .max(255),
  lastname: string()
    .required()
    .max(255),
  phone: string()
    .required()
    .max(255),
  email: string()
    .required()
    .max(255),
  position: string()
    .required()
    .max(255),
  professional_qualifications: string()
    .required()
    .max(10000)
});

@observer
class AddWorkerModal extends Component<AddWorkerModalProps> {
  componentWillUnmount() {
    workerAvatarsStore.clearWorkerAvatar();
  }

  onUpload = (data: any, openItem?: any) => {
    workerAvatarsStore.setFile(data);
    openItem
      ? workerAvatarsStore.updateWorkerAvatar(data, openItem.id)
      : workerAvatarsStore.createWorkerAvatar(data);
  };

  onSubmit = (data: any) => {
    const { openItem } = this.props;

    if (openItem && openItem.image_link === null) {
      data.image_link =
        process.env.REACT_APP_STORAGE_BASE_URL +
        '/' +
        workerAvatarsStore.imageLink;
    }

    if (openItem === undefined) {
      data.image_link =
        process.env.REACT_APP_STORAGE_BASE_URL +
        '/' +
        workerAvatarsStore.imageLink;
    }

    workersListingStore.createWorker(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;

    if (openItem && openItem.id) {
      if (openItem.image_link && openItem.image_link !== '') {
        workerAvatarsStore.setImageLink(openItem.image_link);
      }

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      const blocksFromHtml = htmlToDraft(openItem.additional_info);
      const { contentBlocks, entityMap } = blocksFromHtml;

      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      return {
        ...pickedValues,
        additional_info: editorState,
        role_id: openItem.role[0].id
        // role: openI
      };
    }

    return DEFAULT_VALUES;
  };

  render() {
    const { showModal, closeModalForm, openItem } = this.props;
    const { imageLink, file, setImageLink } = workerAvatarsStore;
    const { roles } = JSON.parse(localStorage.getItem('allSelectOptions')!);

    if (imageLink) {
      setImageLink(imageLink);
    }

    return (
      <div style={{ position: 'relative' }}>
        <Modal show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Додавање новог корисника
            <Button
              onClick={() => {
                workersListingStore.showAddButton();
                workerAvatarsStore.clearWorkerAvatar();
                closeModalForm();
              }}
              variant="contained"
              style={{
                backgroundColor: '#fb8c00',
                fontSize: 14,
                fontWeight: 400,
                color: 'white',
                width: '205px',
                height: '36px',
                borderRadius: '4px'
              }}
            >
              <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
              НАЗАД
            </Button>
          </Modal.Header>
          <Formik
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            validationSchema={workerValidationSchema}
            render={({
              values,
              isValid,
              isSubmitting,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body>
                  <Col md={3}>
                    {/* <DropzoneArea
                      initialFiles={[this.imageLink]}
                      dropzoneText={''}
                      showPreviewsInDropzone={true}
                      filesLimit={1}
                      onChange={(file: any) => setFieldValue('file', file)}
                    /> */}
                    <div
                      style={{
                        border: '2px dashed #ccc',
                        height: '300px',
                        overflow: 'hidden',
                        maxWidth: '330px'
                      }}
                    >
                      <input
                        id="file"
                        name="file"
                        type="file"
                        style={{
                          position: 'absolute',
                          height: '100%',
                          opacity: 0
                        }}
                        onChange={(event: any) => {
                          openItem && openItem.id
                            ? this.onUpload(
                                event.currentTarget.files[0],
                                openItem
                              )
                            : this.onUpload(event.currentTarget.files[0]);
                          //setFieldValue('file', event.currentTarget.files[0]);
                        }}
                        className="form-control"
                      />
                      {imageLink && !file ? (
                        <img
                          style={{
                            width: '100%',
                            maxWidth: '330px',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                          alt="alt_image"
                          src={imageLink}
                        />
                      ) : null}
                      <Thumb file={file} />
                    </div>
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col md={12}>
                        <CustomMaterialUiSelect
                          // submitted={isValid}
                          setFieldValue={setFieldValue}
                          value={values.role_id}
                          fullValue={values.role}
                          //repo={RolesRepo}
                          data={roles}
                          display="name"
                          name="role_id"
                          fullValueName="role"
                          valueAttr="id"
                          label="Привилегије*"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="firstname"
                          type="text"
                          label="Име*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="lastname"
                          type="text"
                          label="Презиме*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="phone"
                          type="text"
                          label="Телефон*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="private_phone"
                          type="text"
                          label="Приватни телефон"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="email"
                          type="email"
                          label="Електронска пошта*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextField}
                          name="private_email"
                          type="email"
                          label="Приватна електронска пошта"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="position"
                          type="text"
                          label="Радно место*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          component={TextField}
                          name="professional_qualifications"
                          type="text"
                          label="Звање*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="form-group">
                          <Editor
                            editorState={values.additional_info}
                            wrapperClassName="textEditorWrap"
                            toolbarClassName="textEditorToolbar"
                            editorClassName="textEditorInput"
                            onEditorStateChange={(option: any) => {
                              return setFieldValue('additional_info', option);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* Add more fields */}
                  </Col>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                    ОБРИШИ
                  </Button>
                  <Button
                    onClick={() => {
                      workersListingStore.showAddButton();
                    }}
                    type="submit"
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                    САЧУВАЈ
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          />
        </Modal>
      </div>
    );
  }
}

export default AddWorkerModal;
