import React from 'react';
import { CellInfo } from 'react-table';

const LegalFormCell = ({ value }: CellInfo) => {
  if (!value) return null;
  return (
    <span>
      {value.name} ({value.abbreviation})
    </span>
  );
};

export default LegalFormCell;
