import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'strike_types';

const getAllStrikeTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const StrikeTypesRepo = new BaseRepo(type);

export default {
  ...StrikeTypesRepo,
  getAllStrikeTypes
};
