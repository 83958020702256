import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'strikes';

// const parseRequest = ({
//   name,
//   description,
//   toEmail,
//   fromEmail,
//   country
// }: any) => ({
//   name,
//   description,
//   toEmail,
//   fromEmail,
//   countryId: country.id
// });

const getAllStrikes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

// const fetchLitigationIdByLitigationNumber = (litigationNumber: string) => {
//   return axios.get(
//     `get-by-litigation-number?litigation_number=${litigationNumber}`
//   );
// };

const getAllStrikesWithoutPagination = () => {
  return axios.get('get-all-strikes-without-pagination');
};

const StrikesRepo = new BaseRepo(type);

export default {
  ...StrikesRepo,
  getAllStrikes,
  getAllStrikesWithoutPagination
  //   fetchLitigationIdByLitigationNumber
};
