import React, { Component } from 'react';
import Loading from 'react-top-loading-bar';
import { observer } from 'mobx-react';

import { loadingBarStore } from '../../data/stores/LoadingBarStore';
import { PRIMARY_COLOR } from 'lib/constants';

@observer
class LoadingBar extends Component {
  setRef = (ref: any) => {
    loadingBarStore.setLoader(ref);
  };

  render() {
    return (
      <div
        style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 10000 }}
      >
        <Loading
          progress={loadingBarStore.progress}
          height={3}
          color={PRIMARY_COLOR}
          onRef={this.setRef}
        />
      </div>
    );
  }
}

export default LoadingBar;
