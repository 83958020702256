import { observer } from 'mobx-react';
import { Component } from 'react';
import { observable, action } from 'mobx';
import DataTable from '../../../components/DataTable';
import { officeDocumentFileUploadsStore } from '../../../data/stores/OfficeDocumentFileUploadsStore';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import WorkerFullNameCellWithAvatar from '../../../components/TableCells/WorkerFullNameCellWithAvatar';

interface OfficeDocumentFileUploadsDataTableProps {
  litigationId?: any;
  officeDocumentId?: any;
}

@observer
class OfficeDocumentFileUploadsDataTable extends Component<
  OfficeDocumentFileUploadsDataTableProps
> {
  @observable open: boolean = false;

  @observable currentOfficeDocumentFileUpload: any = null;

  componentWillUnmount() {
    this.clearState();
  }

  @action
  clearState = () => {
    this.currentOfficeDocumentFileUpload = null;
    this.open = false;
  };

  @action
  setCurrentOfficeDocumentFileUpload = (officeDocumentFileUpload: any) => {
    this.currentOfficeDocumentFileUpload = officeDocumentFileUpload;
  };

  itemAction = (officeDocumentFileUpload: any) => (
    <div
      className="td-actions text-right"
      style={{ display: 'flex', zIndex: 9999 }}
    >
      <div>
        <Button
          style={{ display: 'block' }}
          onClick={() => {
            window.open(officeDocumentFileUpload.link, '_blank');
          }}
        >
          <PrintIcon style={{ fontSize: 24, marginRight: 3 }} />
          Погледај
        </Button>
        <Button
          style={{ display: 'block' }}
          onClick={() => {
            this.setCurrentOfficeDocumentFileUpload(officeDocumentFileUpload);
            this.handleOpen();
          }}
        >
          <DeleteIcon style={{ fontSize: 24, marginRight: 3 }} />
          Обриши
        </Button>
      </div>
    </div>
  );

  @action
  setOpen = (bool: boolean) => {
    this.open = bool;
  };

  @action
  handleClose = () => {
    this.setOpen(false);
  };

  @action
  handleOpen = () => {
    this.setOpen(true);
  };

  render() {
    const { litigationId, officeDocumentId } = this.props;
    if (officeDocumentId !== undefined) {
      officeDocumentFileUploadsStore.setCurrentOfficeDocumentId(
        officeDocumentId
      );
    }

    const columns = [
      // {
      //   Header: '#',
      //   id: 'id',
      //   accessor: 'id',
      //   sortable: false,
      //   filterable: false,
      //   width: 50
      // },
      {
        Header: 'Врста документа',
        id: 'name',
        accessor: 'name',
        sortable: false,
        filterable: false
      },
      // {
      //   Header: 'Снимио/ла',
      //   accessor: 'uploadedByUser',
      //   cell: WorkerFullNameCellWithAvatar,
      //   sortable: false,
      //   filterable: false,
      //   width: 80
      // },
      {
        Header: 'Датум',
        id: 'created_at',
        accessor: 'created_at',
        sortable: false,
        filterable: false,
        width: 80
      },
      {
        Header: 'Време',
        id: 'time',
        accessor: 'time',
        sortable: false,
        filterable: false,
        width: 80
      },
      {
        Header: 'Тип',
        id: 'type',
        accessor: 'type',
        sortable: false,
        filterable: false,
        width: 80
      },
      // {
      //   Header: 'Датум',
      //   id: 'name',
      //   accessor: (template: any) => template,
      //   Cell: LinkCell,
      //   sortable: false
      // },
      {
        Header: 'Опције',
        accessor: 'actions',
        sortable: false,
        filterable: false,
        width: 160
      }
    ];

    return (
      <>
        <DataTable
          enableCaching={false}
          // getTdProps={this.getTdProps}
          minRows={5}
          meta={officeDocumentFileUploadsStore.meta}
          columns={columns}
          fetchData={officeDocumentFileUploadsStore.fetchByOfficeDocumentId}
          data={officeDocumentFileUploadsStore.attachCustomColumn(
            'actions',
            this.itemAction
          )}
          loading={officeDocumentFileUploadsStore.loading}
          defaultSort={[{ id: 'createdAt', desc: true }]}
        />
        <Dialog
          open={this.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Да ли сте сигурни да желите да трајно обришете '
              {this.currentOfficeDocumentFileUpload &&
                this.currentOfficeDocumentFileUpload.name}
              '? Трајно обрисане податке је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Не, одустани</Button>
            <Button
              onClick={() => {
                officeDocumentFileUploadsStore.delete(
                  this.currentOfficeDocumentFileUpload.id
                );
                this.handleClose();
              }}
              autoFocus
            >
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default OfficeDocumentFileUploadsDataTable;
