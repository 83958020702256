import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { RowInfo } from 'react-table';
import { RouteComponentProps } from 'react-router';
import questionsStore from '../../data/stores/QuestionsStore';
import DateCell from '../../components/TableCells/DateCell';
import StatusCell from '../../components/TableCells/StatusCell';
import { Question } from '../../data/types';
import { navigate } from '../../lib/routerHelper';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import SearchResultsComponent from 'components/SearchResultsComponent';

const columns = [
  {
    Header: '#',
    id: 'id',
    accessor: 'id',
    sortable: false,
    filterable: false,
    width: 50
  },
  {
    Header: 'Датум',
    accessor: 'createdAt',
    sortable: true,
    filterable: false,
    Cell: DateCell(),
    width: 100
  },
  {
    Header: 'Датум завођења',
    accessor: 'createdAtTime',
    sortable: false,
    filterable: false,
    width: 180
  },
  {
    Header: 'Бр. питања',
    accessor: 'number',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Име корисника',
    accessor: 'fullName',
    sortable: false,
    filterable: false,
    width: 180
  },
  {
    Header: 'Наслов',
    accessor: 'title',
    sortable: false,
    filterable: false,
    width: 200
  },
  {
    Header: 'Статус питања',
    accessor: 'status',
    sortable: false,
    filterable: false,
    Cell: StatusCell
  },
  {
    Header: 'Електронска пошта',
    id: 'email',
    accessor: 'email',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Обрађивач',
    id: 'worker.firstname',
    accessor: 'worker',
    Cell: WorkerFullNameCellWithAvatar,
    sortable: false,
    filterable: false,
    width: 160
  }
];

@observer
class QuestionsListingPage extends Component<RouteComponentProps> {
  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          this.openQuestionDetailsPage(row.original);
        }
      }
    };
  };

  openQuestionDetailsPage(question: Question) {
    const { history } = this.props;
    if (!question) return;

    navigate('dashboard.questions.details', { history }, { id: question.id });
  }

  render() {
    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <DataTable
            enableCaching={false}
            getTdProps={this.getTdProps}
            columns={columns}
            fetchData={questionsStore.fetch}
            data={questionsStore.data}
            loading={questionsStore.loading}
            defaultSort={[{ id: 'createdAt', desc: true }]}
          />
        </div>
      </SearchResultsComponent>
    );
  }
}

export default QuestionsListingPage;
