import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Component } from 'react';
import BaseRepo from 'data/repositories/BaseRepo';
import to from 'lib/awaitTo';
import { observable, action } from 'mobx';
import './style.css';
import { observer } from 'mobx-react';

interface ServerSelectAutocompleteProps {
  label: string;
  id: string;
  data?: any[];
  repo: BaseRepo;
  property?: string;
  saveProperty?: string;
  value: any;
  properties?: any;
  style?: any;
  setFieldValue?: any;
  fieldValue?: any;
  disabled?: boolean;
  fullValue?: any;
  fullValueName?: string;
}

@observer
class ServerSelectAutocomplete extends Component<
  ServerSelectAutocompleteProps
> {
  @observable value: any = '';

  @observable data: any = [];

  @observable saveProperty: string | undefined = undefined;

  @action
  setData = (data: any) => {
    this.data = data;
  };

  @action
  setValue = (value: any) => {
    this.value = value;
  };

  @action
  setSaveProperty = (value: any | undefined) => {
    this.saveProperty = value;
  };

  async componentDidMount() {
    const {
      repo,
      property,
      value,
      data,
      disabled,
      properties,
      saveProperty
    } = this.props;
    this.setValue(value);

    if (saveProperty) {
      this.setSaveProperty(saveProperty);
    }

    if (!data) {
      const [response] = await to(repo.getAll({}));

      if (response) {
        if (property !== undefined && properties === undefined) {
          let responseArr = response.data.map((element: any) => {
            return element[property] !== null ? element[property] : null;
          });

          this.setData(responseArr.filter((element: string) => element));
        }
        if (
          property === undefined &&
          properties !== undefined &&
          properties.length >= 2
        ) {
          let responseArr = response.data.map((element: any) => {
            return element[properties[1]] !== null
              ? element[properties[0]] + ' - ' + element[properties[1]]
              : null;
          });

          this.setData(responseArr.filter((element: string) => element));
        }
      }
    } else {
      if (property !== undefined) {
        let dataArr = data.map((element: any) => {
          return element[property] !== null ? element[property] : null;
        });

        this.setData(dataArr.filter((element: string | null) => element));
      } else {
        let dataArr = data.map((element: any) => {
          return element[properties[1]] !== null
            ? element[properties[0]] + ' - ' + element[properties[1]]
            : null;
        });

        this.setData(dataArr.filter((element: string | null) => element));
      }
    }

    function onlyUnique(value: any, index: any, self: any) {
      return self.indexOf(value) === index;
    }

    let uniqueArr = this.data.filter(onlyUnique);
    this.setData(uniqueArr);
  }

  render() {
    const {
      id,
      label,
      setFieldValue,
      fieldValue,
      disabled,
      style,
      fullValueName
    } = this.props;
    return (
      <Autocomplete
        disabled={disabled}
        freeSolo
        style={style}
        id={id}
        value={this.value}
        onChange={(e: any, value: any) => {
          this.setValue(value);
          setFieldValue(fieldValue, this.value);
          // if (fullValueName !== undefined) {
          //   setFieldValue(fullValueName, 1);
          // }
        }}
        options={this.data.map((option: any) => option)}
        renderInput={params => (
          <TextField
            style={{}}
            {...params}
            variant="standard"
            onChange={e => {
              this.setValue(e.currentTarget.value);
              setFieldValue(fieldValue, this.value);
            }}
            label={label}
            InputLabelProps={{
              style: {
                fontSize: 16,
                position: 'relative',
                paddingBottom: '5px',
                top: '2px',
                marginBottom: '0px'
              }
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: {
                fontSize: 16,
                position: 'relative',
                paddingBottom: '5px',
                marginTop: '5px'
              }
            }}
          />
        )}
      />
    );
  }
}

export default ServerSelectAutocomplete;
