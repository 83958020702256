import React from 'react';
import moment from 'moment';
import { CellInfo } from 'react-table';

import { DATE_FORMAT } from '../../lib/constants';

const DateFromTimestampCell = (format = DATE_FORMAT) => ({
  value
}: CellInfo) => {
  if (!value) {
    return null;
  }

  let date = value;
  if (typeof value !== 'string' && value.date) {
    date = value.date;
  }

  return <div>{moment.unix(date / 1000).format(format)}</div>;
};

export default DateFromTimestampCell;
