import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'phases';

const getAllPhases = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const PhasesRepo = new BaseRepo(type);

export default {
  ...PhasesRepo,
  getAllPhases
};
