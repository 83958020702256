import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import WorkerAvatarsRepo from '../repositories/WorkerAvatarsRepo';
import { WorkerAvatar } from 'data/types';
import WorkersRepo from 'data/repositories/WorkersRepo';
import { workersStore } from '../stores/WorkersStore';

class WorkerAvatarsStore extends TableStore<any> {
  @observable workerAvatar: WorkerAvatar | null = null;

  @observable imageLink: any = null;

  @observable file: any = undefined;

  @action
  setImageLink = (imageLink: any) => {
    this.imageLink = imageLink;
  };

  @action
  setFile = (file: any) => {
    this.file = file;
  };

  @action
  setWorkerAvatar = (workerAvatar: WorkerAvatar | null) => {
    this.workerAvatar = workerAvatar;
  };

  @action
  clearWorkerAvatar = () => {
    this.setFile(undefined);
    this.setWorkerAvatar(null);
    this.setImageLink(null);
  };

  createWorkerAvatar = async (workerAvatar: WorkerAvatar) => {
    const [res] = await to(WorkerAvatarsRepo.uploadWorkerAvatar(workerAvatar));
    if (res) {
      this.setImageLink(res.data);
      this.setWorkerAvatar(res.data);
    }
  };

  updateWorkerAvatar = async (workerAvatar: WorkerAvatar, workerId: any) => {
    const [res] = await to(WorkerAvatarsRepo.uploadWorkerAvatar(workerAvatar));
    if (res) {
      const [response] = await to(
        WorkersRepo.update(workerId, {
          image_link: process.env.REACT_APP_STORAGE_BASE_URL + res.data
        })
      );
      if (response) {
        workersStore.fetch();
      }
      this.setImageLink(res.data);
      this.setWorkerAvatar(res.data);
    }
  };
}

export const workerAvatarsStore = new WorkerAvatarsStore(WorkerAvatarsRepo);
export default workerAvatarsStore;
