import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';
import { forEach } from 'lodash';

const type = 'fee_types';

const parseRequest = (data: any) => {
  let request = {} as any;

  forEach(data, (field: any, key: any) => {
    request[key] = field;
  });

  return request;
};

const getAllFeeTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const FeeTypesRepo = new BaseRepo(type, {
  create: parseRequest,
  update: parseRequest
});

export default {
  ...FeeTypesRepo,
  getAllFeeTypes
};
