import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import GasExpenseTypesRepo from '../repositories/GasExpenseTypesRepo';

class GasExpenseTypesStore extends TableStore<any> {
  @observable gasExpenseTypes: any;

  @action
  setGasExpenseTypes = (data: any) => {
    this.gasExpenseTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(GasExpenseTypesRepo.getAllGasExpenseTypes({}));
    if (res) {
      this.setGasExpenseTypes(res.data);
    }
  };
}

export const gasExpenseTypesStore = new GasExpenseTypesStore(
  GasExpenseTypesRepo
);
export default gasExpenseTypesStore;
