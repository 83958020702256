import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import { Query } from '../../lib/queryBuilder';
import LitigationsRepo from 'data/repositories/LitigationsRepo';
import axios from 'axios';
import { Toast } from 'lib/dialogs';
import litigationsListingStore from 'pages/LitigationsListingPage/LitigationsListingStore';
import SearchRepo from 'data/repositories/SearchRepo';
import searchStore from './SearchStore';

class LitigationsStore extends TableStore<any> {
  @observable litigations: any;
  @observable openItem: any;
  @observable currentLitigationNumber: any = null;
  @observable message: string | null;

  @action
  setLitigations = (data: any) => {
    this.litigations = data;
  };

  @action
  setOpenItem = (litigation: any) => {
    this.openItem = litigation;
  };

  @action
  setMessage = (message: string) => {
    this.message = message;
  }

  @action
  setCurrentLitigationNumber = (litigationNumber: any) => {
    if (litigationNumber === '' || litigationNumber === null) {
      this.currentLitigationNumber = null;
    } else {
      this.currentLitigationNumber = litigationNumber;
    }
  };

  @action
  fetchAll = async () => {
    const [res] = await to(LitigationsRepo.getAllLitigations({}));

    if (res) {
      this.setLitigations(res.data);
    }

    // if (res && res.data) {
    //   this.onFetch(res.data.message.data, res.data.message);
    // }
    // if (error) {
    //   this.onError();
    // }
  };

  @action
  fetchOne = async (id: any) => {
    const [res] = await to(LitigationsRepo.one(id));

    if (res) {
      this.setOpenItem(res.data);
    }
  };

  @action
  fetchActiveLitigationsWithoutOther = async (params?: Query) => {
    this.loading = true;
    // const customQueryParams = {
    //   isArchive: false
    // };
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes,
      ...this.customQueryParams
    };


    // let response: any = await to(
    //   this.repo.getAll({ params: this.queryParams })
    // );

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fetchLitigationIdByLitigationNumber = async (litigationNumber: string) => {
    const response = await LitigationsRepo.fetchLitigationIdByLitigationNumber(
      litigationNumber
    );
    if (response) {
      if (response.data !== '') {
        this.setMessage('У бази већ постоји предмет са овим бројем!');
      } else {
        this.setMessage(null);
      }
      this.setCurrentLitigationNumber(response.data);
    }
  };

  @action
  fetchArchivedLitigations = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    const customQueryParams = {
      isArchive: true,
      limit: 100
    };
    this.queryParams = {
      ...params,
      include: this.includes,
      ...customQueryParams
    };

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    //console.log('METEAA: ', response.data.message);

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fillChildLitigations = async (parentId: any, children: any) => {
    const [response, error] = await to(
      LitigationsRepo.fillChildLitigations(parentId, children)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте попунили здружене предмете.'
        });
      }, 1000);
      litigationsStore.fetchActiveLitigationsWithoutOther();
    }
    if (error) {
      console.log('Err ', error);
    }
  };

  @action
  fillChildLitigationsPhase = async (phaseId: any, children: any) => {
    const [response, error] = await to(
      LitigationsRepo.fillChildLitigationsPhase(phaseId, children)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте попунили здружене предмете.'
        });
      }, 1000);
      litigationsStore.fetchActiveLitigationsWithoutOther();
    }
    if (error) {
      console.log('Err ', error);
    }
  };
}

export const litigationsStore = new LitigationsStore(LitigationsRepo);
export default litigationsStore;
