import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { filterReportsStore } from './FilterReportsStore';
import * as ExcelJS from 'exceljs';

export const showFilterField = (
  filterName: any,
  values: any,
  setFieldValue: any,
  allSelectOptions: any,
  disableSelectFilter: any
) => {
  let filter;

  switch (filterName) {
    case 'Врста предлога':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.proposal_type_id || ''}
          fullValue={values.proposal_type}
          customOnChange={disableSelectFilter}
          display="name"
          fromState={true}
          data={allSelectOptions.proposal_types}
          name="proposal_type_id"
          fullValueName="proposal_type"
          valueAttr="id"
          label="Врста предлога*"
        />
      );
      break;

    case 'Место прве стране':
      filter = (
        <Field
          component={TextField}
          name="city_first_party"
          type="text"
          label="Место прве стране*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          onChange={(e: any) => {
            setFieldValue('city_first_party', e.target.value);
            if (e.target.value !== '') disableSelectFilter();
          }}
        />
      );
      break;

    case 'Место друге стране':
      filter = (
        <Field
          component={TextField}
          customOnChange={disableSelectFilter}
          name="city_second_party"
          type="text"
          label="Место друге стране*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          onChange={(e: any) => {
            setFieldValue('city_second_party', e.target.value);
            if (e.target.value !== '') disableSelectFilter();
          }}
        />
      );
      break;

    case 'Начин избора миритеља односно арбитра':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          customOnChange={disableSelectFilter}
          value={values.conciliator_choosing_way_id || ''}
          fullValue={values.litigator_status}
          data={allSelectOptions.litigator_statuses}
          display="name"
          name="conciliator_choosing_way_id"
          fullValueName="litigator_status"
          valueAttr="id"
          label="Начин избора миритеља односно арбитра"
        />
      );
      break;

    case 'Миритељ односно арбитар':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          customOnChange={disableSelectFilter}
          value={values.conciliator_id || ''}
          fullValue={values.litigator}
          showFields={['litigator_firstname', 'litigator_lastname']}
          display="name"
          data={allSelectOptions.litigators}
          name="conciliator_id"
          fullValueName="litigator"
          valueAttr="id"
          label="Миритељ односно арбитар"
        />
      );
      break;

    case 'Сагласност':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          customOnChange={disableSelectFilter}
          value={values.consent_id || ''}
          fullValue={values.consent}
          data={allSelectOptions.consents}
          display="name"
          name="consent_id"
          fullValueName="consent"
          valueAttr="id"
          label="Сагласност"
        />
      );
      break;

    case 'Начин окончања поступка':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          customOnChange={disableSelectFilter}
          value={values.finalizing_procedure_way_id || ''}
          fullValue={values.finalizing_procedure_way}
          data={allSelectOptions.statuses}
          display="name"
          name="finalizing_procedure_way_id"
          fullValueName="finalizing_procedure_way"
          valueAttr="id"
          label="Начин окончања поступка"
        />
      );
      break;

    case 'Одбацивање':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          customOnChange={disableSelectFilter}
          value={values.rejection_reason_id || ''}
          fullValue={values.rejection_reason}
          data={allSelectOptions.rejection_reasons}
          display="name"
          name="rejection_reason_id"
          fullValueName="rejection_reason"
          valueAttr="id"
          label="Одбацивање"
        />
      );
      break;

    case 'Обустава':
      filter = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          customOnChange={disableSelectFilter}
          value={values.conclusion_id || ''}
          fullValue={values.conclusion}
          fullValueName="conclusion"
          display="name"
          data={allSelectOptions.conclusions}
          name="conclusion_id"
          valueAttr="id"
          label="Обустава"
        />
      );
      break;
  }

  return filter;
};

export const exportToExcel = () => {
  const { tableData } = filterReportsStore;

  const individualTable = tableData.filter(
    (item: any) => item.type === 'individual'
  );

  const collectiveTable = tableData.filter(
    (item: any) => item.type === 'collective'
  );

  const totalTable = tableData.filter((item: any) => item.type === 'total');

  const workbook = new ExcelJS.Workbook();

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 20000,
      height: 15000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible'
    }
  ];

  const firstSheet = workbook.addWorksheet('Индивидуални спорови');
  const secondSheet = workbook.addWorksheet('Колективни спорови');
  const thirdSheet = workbook.addWorksheet(
    'Укупан број поступака пред агенцијом'
  );

  const dataWithoutTypefilter = (tableWithTypefilter: any) =>
    tableWithTypefilter.map((data: any) => {
      const key = 'type';
      const { [key]: value, ...withoutType } = data;

      return withoutType;
    });

  const headers = [
    'Предмет спора',
    'Број поднетих предлога',
    'Поступци у току',
    'Решено у управном поступку',
    'Мериторно решено'
  ];

  const styleFirstRow = (sheet: any) => {
    sheet.addRow(headers).eachCell((cell: any) => {
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffd3d3d3' }
      };
    });

    console.log('OVO JE sheet', sheet);

    headers.forEach((header, index) => {
      sheet.getColumn(index + 1).width = index === 0 ? 40 : 25;
    });
  };

  const styleLastRow = (sheet: any) => {
    sheet &&
      sheet.lastRow &&
      sheet.lastRow.eachCell((cell: any) => {
        console.log('cell cell cell', cell);
        cell.font = { bold: true };
      });
  };

  // Style and add data from the first table to the first sheet
  styleFirstRow(firstSheet);
  dataWithoutTypefilter(individualTable).forEach((row: any) => {
    firstSheet.addRow(Object.values(row));
  });
  styleLastRow(firstSheet);

  // Style and add data from the second table to the second sheet
  styleFirstRow(secondSheet);
  dataWithoutTypefilter(collectiveTable).forEach((row: any) => {
    secondSheet.addRow(Object.values(row));
  });
  styleLastRow(secondSheet);

  // Add data from the third table to the third sheet
  styleFirstRow(thirdSheet);
  dataWithoutTypefilter(totalTable).forEach((row: any) => {
    thirdSheet.addRow(Object.values(row));
  });
  styleLastRow(thirdSheet);

  // Create a Blob from workbook instance
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Create a download link and trigger a click event to download the Excel file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Извештај.xlsx';
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  });
};

export const formikKeys = {
  dateFrom: 'Од',
  dateTo: 'До',
  litigation_type_id: 'Предмет спора',
  proposal_type_id: 'Врста предлога',
  city_first_party: 'Место прве стране',
  city_second_party: 'Место друге стране',
  conciliator_choosing_way_id: 'Начин избора миритеља односно арбитра',
  conciliator_id: 'Миритељ односно арбитар',
  consent_id: 'Сагласност',
  finalizing_procedure_way_id: 'Начин окончања поступка',
  rejection_reason_id: 'Одбацивање',
  conclusion_id: 'Обустава'
};

export const arrayNameOfTypes = {
  litigation_type_id: 'litigation_types',
  proposal_type_id: 'proposal_types',
  conciliator_choosing_way_id: 'litigator_statuses',
  conciliator_id: 'litigators',
  consent_id: 'consents',
  finalizing_procedure_way_id: 'statuses',
  rejection_reason_id: 'rejection_reasons',
  conclusion_id: 'conclusions'
};
