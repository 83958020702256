import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'statuses';

const getAllStatuses = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const StatusesRepo = new BaseRepo(type);

export default {
  ...StatusesRepo,
  getAllStatuses
};
