import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'strike_endings';

const getAllStrikeEndings = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const StrikeEndingsRepo = new BaseRepo(type);

export default {
  ...StrikeEndingsRepo,
  getAllStrikeEndings
};
