import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import StatusesRepo from '../repositories/StatusesRepo';
import { Status } from 'data/types';

class StatusesStore extends TableStore<any> {
  @observable statuses: Status[] = [];

  @action
  setStatuses = (data: Status[]) => {
    this.statuses = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StatusesRepo.getAllStatuses({}));
    if (res) {
      this.setStatuses(res.data);
    }
  };
}

export const statusesStore = new StatusesStore(StatusesRepo);
export default statusesStore;
