import { observable, action } from 'mobx';
import { ReactNode } from 'react';

import { Meta } from '../types';
import { Query, QueryParamsObjectType } from '../../lib/queryBuilder';
import to from '../../lib/awaitTo';
import BaseRepo from '../../data/repositories/BaseRepo';

interface AbstractStore<T> {
  data: T[];
  meta?: any;
  error: boolean;
  loading: boolean;
  queryParams?: Query;
  openItem?: T;
  repo: BaseRepo;
  showModal: boolean;
  customQueryParams?: QueryParamsObjectType;
}

abstract class TableStore<P> implements AbstractStore<P> {
  @observable data: P[] = [];

  @observable loading = false;

  @observable error = false;

  @observable openItem?: P;

  @observable meta?: any | null;

  @observable queryParams?: Query;

  @observable showModal = false;

  repo: BaseRepo;

  includes: string[] = [];

  customQueryParams?: QueryParamsObjectType;

  constructor(repo: BaseRepo) {
    this.repo = repo;
  }

  @action
  onFetch(data: P[], meta: any | null) {
    this.data = data;
    this.meta = meta;
    this.loading = false;
  }

  @action
  onError() {
    this.error = true;
    this.loading = false;
  }

  @action
  fetch = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes,
      ...this.customQueryParams
    };

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data, response.meta);
    }
    if (error) {
      this.onError();
    }
  };

  attachCustomColumn = (accessor: string, template: (i: P) => ReactNode) => {
    return this.data.map(item => ({
      ...item,
      [accessor]: template(item)
    }));
  };

  @action
  openModalForm = (item?: P) => {
    this.showModal = true;
    if (item) {
      this.openItem = item;
    }
  };

  @action
  closeModalForm = () => {
    this.showModal = false;
    this.openItem = undefined;
  };
}

export default TableStore;
