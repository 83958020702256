import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddLitigationTypeModal from './components/AddLitigationTypeModal';
import { litigationTypesListingStore } from './LitigationTypesListingStore';
import litigationTypesStore from 'data/stores/LitigationTypesStore';
import { RowInfo } from 'react-table';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class LitigationTypesListingPage extends Component {
  componentDidMount() {
    litigationTypesStore.fetchAll();
  }

  showAddLitigationTypeModal = () => {
    litigationTypesListingStore.hideAddButton();
    litigationTypesListingStore.openModalForm();
  };

  hideAddLitigationTypeModal = () => {
    litigationTypesListingStore.showAddButton();
    litigationTypesListingStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          litigationTypesListingStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible, data } = litigationTypesListingStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Шифра',
        id: 'litigation_type_code',
        accessor: 'litigation_type_code',
        sortable: false,
        width: 120
      },
      {
        Header: 'Поступак',
        id: 'procedure_type.name',
        accessor: 'procedure_type.name',
        sortable: false,
        width: 250
      },
      {
        Header: 'Назив врсте радног спора',
        id: 'litigation_name',
        accessor: 'litigation_name',
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            onClick={() => this.showAddLitigationTypeModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              getTdProps={this.getTdProps}
              fetchData={litigationTypesStore.fetch}
              data={litigationTypesStore.data}
              loading={litigationTypesStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddLitigationTypeModal
              showModal={litigationTypesListingStore.showModal}
              closeModalForm={this.hideAddLitigationTypeModal}
              openItem={litigationTypesListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default LitigationTypesListingPage;
