import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'other_litigations';

// const parseRequest = ({
//   name,
//   description,
//   toEmail,
//   fromEmail,
//   country
// }: any) => ({
//   name,
//   description,
//   toEmail,
//   fromEmail,
//   countryId: country.id
// });

const getInactiveOtherLitigations = (config: AxiosRequestConfig) => {
  return axios.get(`${type}?isActive=false`, config);
};

const getAllOtherLitigations = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const fetchOtherLitigationIdByLitigationNumber = (litigationNumber: string) => {
  return axios.get(
    `get-other-by-litigation-number?litigation_number=${litigationNumber}`
  );
};

const getAllOtherLitigationsWithoutPagination = () => {
  return axios.get('get-all-other-litigations-without-pagination');
};

const OtherLitigationsRepo = new BaseRepo(type);

export default {
  ...OtherLitigationsRepo,
  getAllOtherLitigations,
  getAllOtherLitigationsWithoutPagination,
  getInactiveOtherLitigations,
  fetchOtherLitigationIdByLitigationNumber
};
