import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { officeDocumentsStore } from '../../data/stores/OfficeDocumentsStore';
import OfficeDocumentsRepo from '../../data/repositories/OfficeDocumentsRepo';
import OfficeDocumentFileUploadsRepo from '../../data/repositories/OfficeDocumentFileUploadsRepo';
import { officeDocumentFileUploadsStore } from '../../data/stores/OfficeDocumentFileUploadsStore';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import LitigationsRepo from 'data/repositories/LitigationsRepo';
import OtherLitigationsRepo from 'data/repositories/OtherLitigationsRepo';
import { litigationsListingStore } from 'pages/LitigationsListingPage/LitigationsListingStore';
import { pageStore } from '../../data/stores/PageStore';
import { litigationDetailStore } from '../LitigationDetailPage/LitigationDetailStore';

class OfficeDocumentsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @observable preparedFilesForUpload: any = [];

  componentWillUnmount() {
    this.clear();
  }

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  clear = () => {
    this.preparedFilesForUpload = [];
  };

  @action
  addFileForUpload = (file: any) => {
    this.preparedFilesForUpload.push(file);
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createOfficeDocument = async (
    officeDocument: any,
    documentTypeId: number
  ) => {
    const { create } = OfficeDocumentsRepo;

    const [response] = await to(create(officeDocument));

    if (response) {
      // response.data === modelId
      this.preparedFilesForUpload.forEach((file: any) => {
        officeDocumentsListingStore.uploadOfficeDocumentFile(
          file,
          response.data,
          'OfficeDocument',
          documentTypeId
        );
      });

      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали нови предмет.'
        });
      }, 1000);

      officeDocumentsStore.fetchAllOfficeDocuments();
      // fetch updated litigations for dropdown
      const [responseAllActiveLitigations] = await to(
        LitigationsRepo.getAllActiveLitigationsWithoutPagination()
      );

      if (responseAllActiveLitigations) {
        litigationsListingStore.setLitigationsArray(
          responseAllActiveLitigations.data
        );
      }
      this.closeModalForm();
      this.clear();
    }
  };

  @action
  fetchCreatedDocumentsByLitigationId = async (params?: any) => {
    const { createdSwitch, setCreatedSwitch } = litigationDetailStore;
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes
    };

    const [response, error] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByLitigationId(
        officeDocumentFileUploadsStore.currentLitigationId
      )
    );

    // const currentLitigation: any = await LitigationsRepo.one(
    //   officeDocumentFileUploadsStore.currentLitigationId
    // );

    // const [resp, err] = await to(
    //   OfficeDocumentsRepo.getOfficeDocumentIdByLitigationNumber(
    //     currentLitigation.data.data.litigation_number
    //   )
    // );

    if (response && response.data) {
      //console.log('RESPO KRE ', response.data);
      let respFinal: any = response.data.message.sort(function(a: any, b: any) {
        // ovde redjam sve kreirane dokumente od najnovijeg do najstarijeg (po datumu)
        return new Date(b.created_at) - new Date(a.created_at);
      });

      this.onFetch(respFinal, null);
      // ovde dodati da menja tab u odnosu na to sta je fetch-ovao

      // setCreatedSwitch(!createdSwitch);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fetchScannedDocumentsByLitigationId = async (params?: any) => {
    const { createdSwitch, setCreatedSwitch } = litigationDetailStore;
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes
    };

    // const [response, error] = await to(
    //   OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByLitigationId(
    //     officeDocumentFileUploadsStore.currentLitigationId
    //   )
    // );

    const currentLitigation: any = await LitigationsRepo.one(
      officeDocumentFileUploadsStore.currentLitigationId
    );

    const [resp] = await to(
      OfficeDocumentsRepo.getOfficeDocumentIdByLitigationNumber(
        currentLitigation.data.data.litigation_number
      )
    );

    officeDocumentFileUploadsStore.setCurrentOfficeDocumentId(resp.data[0]);

    const [responseOfficeDocuments, errorOfficeDocuments] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByOfficeDocumentIds(
        resp.data
      )
    );

    if (responseOfficeDocuments.data) {
      // ovde sam izmenio zbog typescripta
console.log('FINAL ', responseOfficeDocuments.data);
      let respFinal: any = responseOfficeDocuments.data.sort(function(
        a: any,
        b: any
      ) {
        // ovde redjam sve skenirane dokumente od najnovijeg do najstarijeg (po datumu)
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.onFetch(respFinal, null);
      // ovde dodati da menja tab u odnosu na to sta je fetch-ovao
      // if (createdSwitch !== false) {
      // setCreatedSwitch(false);
      // }
    }
    if (errorOfficeDocuments) {
      this.onError();
    }
  };

  // @action
  // fetchByOfficeDocumentId = async (paramrs?: any) => {

  // }

  @action
  fetchByOtherLitigationId = async (params?: any) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes
    };

    console.log(
      'CURRRR ',
      officeDocumentFileUploadsStore.currentOfficeDocumentId
    );

    const [response, error] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByOtherLitigationId(
        officeDocumentFileUploadsStore.currentLitigationId
      )
    );

    const currentLitigation: any = await OtherLitigationsRepo.one(
      officeDocumentFileUploadsStore.currentLitigationId
    );

    const [resp] = await to(
      OfficeDocumentsRepo.getOfficeDocumentIdByLitigationNumber(
        currentLitigation.data.number
      )
    );

    const [responseOfficeDocuments, errorOfficeDocuments] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByOfficeDocumentIds(
        resp.data
      )
    );

    if (responseOfficeDocuments) {
    }

    if (response && response.data) {
      this.onFetch(
        responseOfficeDocuments.data.concat(response.data.message.data),
        null
      );
    }
    if (errorOfficeDocuments || error) {
      this.onError();
    }
  };

  @action
  fetchByLitigationNumber = async (params?: any) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes
    };

    const [response, error] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByOfficeDocumentIds(
        officeDocumentFileUploadsStore.currentOfficeDocumentId
      )
    );

    if (response && response.data) {
      this.onFetch(response.data, null);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  uploadOfficeDocumentFile = async (
    officeDocumentFile: any,
    modelId: any,
    model: string,
    documentTypeId?: number
  ) => {
    const { setCreatedSwitch } = litigationDetailStore;
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.uploadOfficeDocumentFile(
        officeDocumentFile,
        modelId,
        model,
        documentTypeId
      )
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте снимили фајл везан за предмет.'
        });
        officeDocumentFileUploadsStore.setCurrentOfficeDocumentFileId(
          response.data.id
        );

        if (model === 'Litigation') {
          litigationsStore.fetchActiveLitigationsWithoutOther();
          this.fetchCreatedDocumentsByLitigationId();
          // this.fetchScannedDocumentsByLitigationId(); latest fix
          // litigationsListingStore.closeModalForm();
          pageStore.setNavTitle('Преглед предмета');
          // litigationsListingStore.setOpenItem(litigation);
        } else if (model === 'OtherLitigation') {
          this.fetchByOtherLitigationId();
        } else if (model === 'OfficeDocument') {
          //console.log('nesto ', litigationDetailStore.openItem);
          this.fetchScannedDocumentsByLitigationId();
          litigationDetailStore.fetchOne(litigationDetailStore.openItem.id);
          // window.location.reload();
        } else {
          officeDocumentFileUploadsStore.fetchByOfficeDocumentId();
        }
        // this.fetchByLitigationNumber(
        //   officeDocumentFileUploadsStore.currentLitigationNumber
        // );

        // this.onFetch(response.data, null);
        // officeDocumentFileUploadsStore.fetchByLitigationId();
      }, 1000);
    }
  };

  @action
  prepareOfficeDocumentFileForUpload = async (
    officeDocumentFile: any,
    modelId: any,
    model: string
  ) => {
    this.addFileForUpload(officeDocumentFile);
    officeDocumentFileUploadsStore.data.push({
      name: officeDocumentFile[0].name
    });
  };

  @action
  updateOfficeDocumentType = async (
    officeDocumentType: any,
    officeDocumentFileId: any
  ) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.updateOfficeDocumentType(
        officeDocumentType,
        officeDocumentFileId
      )
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте земенили тип документа.'
        });
      }, 1000);
      // officeDocumentFileUploadsStore.fetch();
      // officeDocumentFileUploadsStore.fetch();
      officeDocumentsStore.fetchAllOfficeDocuments();
      // officeDocumentFileUploadsStore.fetchByLitigationId();
      this.closeModalForm();
    }
  };

  updateOfficeDocumentFile = async (
    officeDocumentFile: any,
    officeDocumentFileId: any
  ) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.updateOfficeDocumentFile(
        officeDocumentFile,
        officeDocumentFileId
      )
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте земенили фајл везан за предмет.'
        });
      }, 1000);
      // officeDocumentFileUploadsStore.fetch();
      officeDocumentFileUploadsStore.fetchByLitigationId();
      this.closeModalForm();
    }
  };

  @action
  updateOfficeDocument = async (data: any, officeDocumentId: any) => {
    const [response] = await to(
      OfficeDocumentsRepo.update(officeDocumentId, data)
    );

    if (response) {
      this.preparedFilesForUpload.forEach((file: any) => {
        officeDocumentsListingStore.uploadOfficeDocumentFile(
          file,
          data.id,
          'OfficeDocument'
        );
      });
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали документ везан за предмет.'
        });
      }, 1000);
      // officeDocumentFileUploadsStore.fetch();
      //officeDocumentFileUploadsStore.fetchByOfficeDocumentId();
      officeDocumentsStore.fetchAllOfficeDocuments();

      // fetch updated litigations for dropdown
      const [responseAllActiveLitigations] = await to(
        LitigationsRepo.getAllActiveLitigationsWithoutPagination()
      );

      if (responseAllActiveLitigations) {
        litigationsListingStore.setLitigationsArray(
          responseAllActiveLitigations.data
        );
      }

      // officeDocumentsStore.fetch();
      this.closeModalForm();
      this.clear();
    }
  };

  downloadOfficeDocumentFile = async (officeDocumentFileId: any) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.downloadOfficeDocumentFile(
        officeDocumentFileId
      )
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте скинули фајл везан за предмет.'
        });
      }, 1000);
      officeDocumentFileUploadsStore.fetch();
      this.closeModalForm();
    }
  };

  @action
  deleteOfficeDocumentFile = async (officeDocumentFileId: any) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.delete(officeDocumentFileId)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали фајл везан за предмет.'
        });
        this.fetchByOtherLitigationId(this.queryParams);
        this.closeModalForm();
      }, 1000);
    }
  };

  @action
  deleteOfficeDocumentFileScanned = async (officeDocumentFileId: any) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.delete(officeDocumentFileId)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали фајл везан за предмет.'
        });
        this.fetchScannedDocumentsByLitigationId();
        this.closeModalForm();
      }, 1000);
    }
  };

  @action
  deleteOfficeDocumentFileCreated = async (officeDocumentFileId: any) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.delete(officeDocumentFileId)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали фајл везан за предмет.'
        });
        this.fetchCreatedDocumentsByLitigationId();
        this.closeModalForm();
      }, 1000);
    }
  };

  @action
  deleteOfficeDocumentFileOtherLitigation = async (
    officeDocumentFileId: any
  ) => {
    const [response] = await to(
      OfficeDocumentFileUploadsRepo.delete(officeDocumentFileId)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали фајл везан за остали предмет.'
        });
        this.fetchByOtherLitigationId(this.queryParams);
        this.closeModalForm();
      }, 1000);
    }
  };
}

export const officeDocumentsListingStore = new OfficeDocumentsListingStore(
  OfficeDocumentsRepo
);
export default officeDocumentsListingStore;
