import { Component } from 'react';
import { observer } from 'mobx-react';
import { Col, Modal, Row } from 'react-bootstrap';
import Thumb from '../../components/Thumb/Thumb';
import DataTable from '../../components/DataTable';
import { Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddLegalFormTypeModal from './components/AddLegalFormTypeModal';
import { legalFormTypesStore } from '../../data/stores/LegalFormTypesStore';
import { legalFormTypesListingStore } from './LegalFormTypesListingStore';
import LegalFormCell from '../../components/TableCells/LegalFormCell';
import { authStore } from 'data/stores/AuthStore';
import { workerAvatarsStore } from 'data/stores/WorkerAvatarsStore';
import { Editor } from 'react-draft-wysiwyg';
import { pickDefaultValues } from '../../lib/form';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { TextField } from 'formik-mui';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CustomMaterialUiSelect from '../../components/CustomMaterialUiSelect/CustomMaterialUiSelect';
import './styles.css';
import { Field, Form, Formik } from 'formik';
import RolesRepo from 'data/repositories/RolesRepo';
import workersListingStore from 'pages/WorkersPage/WorkersListingStore';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import SearchResultsComponent from 'components/SearchResultsComponent';

const DEFAULT_VALUES = {
  id: '',
  role: null,
  role_id: null,
  firstname: '',
  lastname: '',
  phone: '',
  private_phone: '',
  email: '',
  private_email: '',
  position: '',
  professional_qualifications: '',
  additional_info: EditorState.createEmpty(),
  image_link: ''
};

@observer
class Account extends Component {
  componentDidMount() {}

  handleChange(files: any) {
    this.setState({
      files: files
    });
  }

  onSubmit = (data: any) => {
    const { authUser } = authStore;

    if (authUser && authUser.image_link === null) {
      data.image_link =
        process.env.REACT_APP_STORAGE_BASE_URL +
        '/' +
        workerAvatarsStore.imageLink;
    }

    if (authUser === undefined) {
      data.image_link =
        process.env.REACT_APP_STORAGE_BASE_URL +
        '/' +
        workerAvatarsStore.imageLink;
    }

    workersListingStore.updateWorker(data);
  };

  onUpload = (data: any, openItem?: any) => {
    workerAvatarsStore.setFile(data);
    openItem
      ? workerAvatarsStore.updateWorkerAvatar(data, openItem.id)
      : workerAvatarsStore.createWorkerAvatar(data);
  };

  getInitialValues = () => {
    const { authUser } = authStore;

    if (authUser && authUser.id) {
      if (authUser.image_link && authUser.image_link !== '') {
        workerAvatarsStore.setImageLink(authUser.image_link);
      }

      const blocksFromHtml = htmlToDraft(authUser.additional_info);
      const { contentBlocks, entityMap } = blocksFromHtml;

      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, authUser);

      return {
        ...pickedValues,
        role: authUser.roles[0],
        role_id: authUser.roles[0].id,
        additional_info: editorState
      };
    }

    return DEFAULT_VALUES;
  };

  render() {
    const { authUser } = authStore;
    const { imageLink, file, setImageLink } = workerAvatarsStore;
    const permissions = authUser.roles[0].permissions;
    var superAdmin: boolean = false;

    if (
      permissions.filter((e: any) => e.name === 'privilege-change').length > 0
    ) {
      superAdmin = true;
    }

    return (
      <SearchResultsComponent type="active">
        <Box className="account-div" style={{ padding: '10px' }}>
          <Paper>
            <Box
              style={{
                height: 'auto',
                minHeight: '60px',
                width: '100%',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px'
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: '16px',
                  color: '#888'
                }}
              >
                Мој налог
              </h3>
              <Button
                onClick={() => {
                  window.location = '/dashboard/litigations' as any;
                }}
                variant="contained"
                style={{
                  backgroundColor: '#fb8c00',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px'
                }}
              >
                <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                НАЗАД
              </Button>
            </Box>
            <Box style={{ padding: 30 }}>
              <Formik
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                render={({
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  setFieldTouched
                }) => (
                  <Form noValidate className="form-horizontal">
                    <Col md={3}>
                      {/* <DropzoneArea
                      initialFiles={[this.imageLink]}
                      dropzoneText={''}
                      showPreviewsInDropzone={true}
                      filesLimit={1}
                      onChange={(file: any) => setFieldValue('file', file)}
                    /> */}
                      <div
                        style={{
                          border: '2px dashed #ccc',
                          height: '300px',
                          overflow: 'hidden',
                          maxWidth: '330px'
                        }}
                      >
                        <input
                          id="file"
                          name="file"
                          type="file"
                          style={{
                            position: 'absolute',
                            height: '100%',
                            opacity: 0
                          }}
                          onChange={(event: any) => {
                            authUser && authUser.id
                              ? this.onUpload(
                                  event.currentTarget.files[0],
                                  authUser
                                )
                              : this.onUpload(event.currentTarget.files[0]);
                            //setFieldValue('file', event.currentTarget.files[0]);
                          }}
                          className="form-control"
                        />
                        {values.image_link && !file ? (
                          <img
                            style={{ width: '100%', maxWidth: '330px' }}
                            src={values.image_link}
                          />
                        ) : null}
                        <Thumb file={file} />
                      </div>
                    </Col>
                    <Col md={9}>
                      <Row>
                        <Col md={12}>
                          <CustomMaterialUiSelect
                            disabled={!superAdmin}
                            setFieldValue={setFieldValue}
                            value={values.role_id}
                            fullValue={values.role}
                            repo={RolesRepo}
                            display="name"
                            name="role_id"
                            fullValueName="role"
                            valueAttr="id"
                            label="Привилегије*"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Field
                            component={TextField}
                            name="firstname"
                            type="text"
                            label="Име"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            component={TextField}
                            name="lastname"
                            type="text"
                            label="Презиме"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="phone"
                            type="text"
                            label="Телефон*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="private_phone"
                            type="text"
                            label="Приватни телефон"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="email"
                            type="email"
                            label="Електронска пошта*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="private_email"
                            type="email"
                            label="Приватна електронска пошта"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Field
                            component={TextField}
                            name="position"
                            type="text"
                            label="Радно место*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            component={TextField}
                            name="professional_qualifications"
                            type="text"
                            label="Звање*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="form-group">
                            <Editor
                              editorState={values.additional_info}
                              wrapperClassName="textEditorWrap"
                              toolbarClassName="textEditorToolbar"
                              editorClassName="textEditorInput"
                              onEditorStateChange={(option: any) => {
                                return setFieldValue('additional_info', option);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* Add more fields */}
                    </Col>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Button
                        variant="text"
                        style={{
                          fontSize: 14,
                          color: 'black',
                          visibility: 'hidden'
                        }}
                      >
                        <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                        ОБРИШИ
                      </Button>
                      <Button
                        onClick={() => {
                          workersListingStore.showAddButton();
                        }}
                        type="submit"
                        variant="text"
                        style={{ fontSize: 14, color: 'black' }}
                      >
                        <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                        САЧУВАЈ
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </Box>
          </Paper>
        </Box>
      </SearchResultsComponent>
    );
  }
}

export default Account;
