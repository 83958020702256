import { AxiosPromise } from 'axios';

/**
 * Async await wrapper for easy error handling
 * @Example
 *  [ data, error ] = await to(userRepo.get(1));
 *  if(err) return false;
 */

export default (promise: AxiosPromise) =>
  promise
    .then(data => [data, undefined])
    .catch((error = true) => [undefined, error]);
