import { Row } from 'antd';
import { Col } from 'react-bootstrap';
import { useState } from 'react';
import NewRowFilter from './components/NewRowFilter';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import litigationsFilterStore from './LitigationsFilterStore';
import { Button } from '@mui/material';
import { exportToExcel } from './components/helper';
import ReviewsAgencyFilterTable from './components/ReviewsAgencyFilterTable';
import ReviewsLitigatorFilterTable from './components/ReviewsLitigatorFilterTable';

const initialData = {
  document_date_of_input: '',
  dateFrom: '',
  dateTo: '',
  litigation_number: '',
  working_on_user_id: '',
  procedure_type_id: '',
  legal_form_first_party_id: '',
  name_first_party: '',
  municipality_first_party: '',
  city_first_party: '',
  post_number_first_party: '',
  proposal_type_id: '',
  legal_form_first_party: '',
  name_second_party: '',
  municipality_second_party: '',
  city_second_party: '',
  post_number_second_party: '',
  conciliator_choosing_way_id: '',
  consent: '',
  rejection_reason_id: '',
  litigator: '',
  finalizing_procedure_way_id: '',
  conclusion_id: '',
  procedure_in_progress: null,
  payment_status: null
};

const ReviewsLitigatorPage = (): JSX.Element => {
  const [rowCount, setRowCount] = useState(1);
  const [valuesCount, setValuesCount] = useState(0);
  const {
    fetchSubjects,
    selectedItems,
    exportAllData,
    data: tableResults
  } = litigationsFilterStore;

  const addRow = () => {
    setRowCount(rowCount + 1);
  };

  const onSubmit = (data: any) => {
    fetchSubjects(data);
  };

  return (
    <div style={{ padding: '50px' }}>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={initialData}
        render={({ values, setFieldValue }) => {
          setValuesCount(
            Object.values(values).filter((x) => x !== '' && x != null).length
          );

          return (
            <Form>
              {Array.from({ length: rowCount }).map((_, index) => {
                const isLastRow = index === rowCount - 1; // Check if it's the last row

                return (
                  <NewRowFilter
                    key={index}
                    values={values}
                    setFieldValue={setFieldValue}
                    isLastRow={isLastRow}
                    rowCount={rowCount}
                    valuesCount={valuesCount}
                  />
                );
              })}

              <Row
                style={{
                  display: 'flex',
                  marginTop: '40px',
                  justifyContent: 'space-between'
                }}
              >
                <Col
                  md={4}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    onClick={addRow}
                    variant="contained"
                    style={{
                      backgroundColor: '#4051B5',
                      fontSize: 14,
                      fontWeight: 400,
                      color: 'white',
                      width: '166px',
                      height: '36px',
                      borderRadius: '4px',
                      marginLeft: '-15px'
                    }}
                  >
                    Додај филтер
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: '#fb8c00',
                      fontSize: 14,
                      fontWeight: 400,
                      color: 'white',
                      width: '166px',
                      height: '36px',
                      borderRadius: '4px',
                      display: !valuesCount ? 'none' : 'block'
                    }}
                  >
                    Примени филтер
                  </Button>
                </Col>
                <div style={{ display: 'flex', gap: '20px' }}>
                  {!!selectedItems.length ? (
                    <Button
                      onClick={() => exportToExcel(selectedItems)}
                      variant="contained"
                      style={{
                        backgroundColor: '#86736C',
                        fontSize: 14,
                        fontWeight: 400,
                        color: 'white',
                        width: '255px',
                        height: '36px',
                        borderRadius: '4px'
                      }}
                    >
                      Преузми одабране податке
                    </Button>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {litigationsFilterStore.meta && litigationsFilterStore.meta.total > 8000 ?
                        <p style={{ color: "red", margin: 0, marginRight: '20px' }}>Максимум за експорт: 8000 резултата</p>
                        : null}
                      <Button
                        onClick={() => exportAllData(exportToExcel)}
                        disabled={!tableResults.length || (litigationsFilterStore.meta && litigationsFilterStore.meta.total > 8000)}
                        style={{
                          backgroundColor: !!tableResults.length && litigationsFilterStore.meta.total <= 8000
                            ? '#218B82'
                            : '#cccccc',
                          fontSize: 14,
                          fontWeight: 400,
                          color: !!tableResults.length ? 'white' : '#666666',
                          width: '255px',
                          height: '36px',
                          borderRadius: '4px'
                        }}
                      >
                        Преузми све податке
                      </Button>
                    </div>
                  )}
                </div>
              </Row>
              <ReviewsLitigatorFilterTable />
            </Form>
          );
        }}
      />
    </div>
  );
};

export default observer(ReviewsLitigatorPage);
