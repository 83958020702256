import { observable, action, runInAction } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import * as authRepo from '../../data/repositories/AuthRepo';
import { LoginParams, RefreshTokenParams } from '../../data/types';

interface AuthStore {
  loading: boolean;
  invalidLogin: boolean;
  loggedIn: boolean;
  rememberMe: boolean;
}

interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

class AuthStore {
  @observable loading = false;

  @observable invalidLogin = false;

  @observable loggedIn = false;

  @observable authUser: any;

  @observable authUserPermissions: any;

  @observable requestCounter = 0;

  @observable errorMessage: string = '';

  @observable allSelectOptions: any = null;

  includes = ['roles.permissions', 'permissions'];

  @action
  increaseRequestCounter = () => {
    this.requestCounter += 1;
  };

  @action
  setAllSelectOptions = (data: any) => {
    this.setAllSelectOptions = data;
  };

  @action
  resetRequestCounter = () => {
    this.requestCounter = 0;
  };

  @action
  login(params: LoginParams) {
    this.loading = true;
    this.invalidLogin = false;
    if (params.rememberMe) {
      Cookies.set('remember_me', 'RememberMe', { sameSite: 'strict' });
    }
    authRepo
      .login(params)
      .then(this.onSuccessLogin)
      .catch(this.onErrorLogin);
  }

  @action
  logout = () => {
    this.loggedIn = false;
    this.clearAuthCookieData();
    Cookies.remove('remember_me');
    this.resetRequestCounter();
  };

  @action
  onSuccessLogin = (response: AxiosResponse<AuthResponse>) => {
    let data = response.data as any;
    if (data.message === 'Invalid login credentials') {
      this.invalidLogin = true;
      this.setErrorMessage('Погрешан емаил или шифра!');
    } else {
      this.loading = false;
      this.invalidLogin = false;
      this.setAuthToken(response.data.access_token);
      // set all select options to localstorage
      authRepo
        .getAllSelectOptions()
        .then((res: any) => {
          localStorage.setItem('allSelectOptions', JSON.stringify(res.data));
          this.setAllSelectOptions(res.data);
        })
        .catch((err: any) => console.log('ERROR ', err));
    }
    // this.setExpiresIn(response.data.expires_in);
    // if (Cookies.get('remember_me')) {
    //   this.setRefreshToken(response.data.refresh_token);
    // }
  };

  async getNewToken(params: RefreshTokenParams) {
    const response = await authRepo.refreshToken(params);
    return response;
  }

  @action
  onErrorLogin = () => {
    this.loading = false;
    this.invalidLogin = true;
    this.setErrorMessage('Погрешан емаил или шифра!');
    Cookies.remove('remember_me');
  };

  @action
  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  refreshToken(params: RefreshTokenParams) {
    authRepo
      .refreshToken(params)
      .then(this.onSuccessLogin)
      .catch(this.onErrorLogin);
  }

  @action
  setAuthToken = (token: string) => {
    Cookies.set('token', token, { sameSite: 'strict' });
    this.loggedIn = true;

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  setExpiresIn = (expiresIn: number) => {
    const now = new Date().getTime();
    Cookies.set('expires_in', (now + expiresIn * 1000).toString(), {
      sameSite: 'strict'
    });
  };

  hasTokenExpired = () => {
    const expiresIn = Cookies.get('expires_in');
    if (expiresIn) {
      return new Date().getTime() > parseInt(expiresIn, 10);
    }
    return false;
  };

  setRefreshToken = (refreshToken: string) => {
    Cookies.set('refresh_token', refreshToken, {
      sameSite: 'strict'
    });
  };

  clearAuthCookieData = () => {
    Cookies.remove('token');
    Cookies.remove('refresh_token');
    Cookies.remove('expires_in');
  };

  @action
  getAuthUser = () => {
    authRepo
      .getAuthUser({
        params: {
          include: this.includes
        }
      })
      .then((data: any) => {
        runInAction(() => {
          this.authUser = data.data;

          if (data.data.roles) {
            let rolesPermissionsNames: any = [];
            data.data.roles.forEach((role: any) => {
              const permissionNames = role.permissions.map(
                (permission: any) => permission.name
              );

              rolesPermissionsNames = [
                ...rolesPermissionsNames,
                ...permissionNames
              ];
            });

            // They are Direct Permissions for User - not Role
            if (data.data.permissions) {
              const directPermissions = data.data.permissions.map(
                (permission: any) => permission.name
              );
              this.authUserPermissions = [
                ...rolesPermissionsNames,
                ...directPermissions
              ];
            } else {
              this.authUserPermissions = [...rolesPermissionsNames];
            }
          }
        });
      })
      .catch(err => {
        this.logout();
      });
  };
}

export const authStore = new AuthStore();
export default authStore;
