/* eslint-disable import/prefer-default-export */
export function checkPermissions(userPermissions: any, givenPermissions: any) {
  let isTherePermission = false;

  givenPermissions.forEach((givenPermission: any) => {
    if (userPermissions.includes(givenPermission)) {
      isTherePermission = true;
    }
  });

  return isTherePermission;
}

export default checkPermissions;
