import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddLitigatorStatusModal from './components/AddLitigatorStatusModal';
import { litigatorStatusesStore } from '../../data/stores/LitigatorStatusesStore';
import { litigatorStatusesListingStore } from './LitigatorStatusesListingStore';
import { RowInfo } from 'react-table';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class LitigatorStatusesListingPage extends Component {
  componentDidMount() {
    litigatorStatusesStore.fetchAll();
  }

  showAddLitigatorStatusModal = () => {
    litigatorStatusesListingStore.hideAddButton();
    litigatorStatusesListingStore.openModalForm();
  };

  hideAddLitigatorStatusModal = () => {
    litigatorStatusesListingStore.showAddButton();
    litigatorStatusesListingStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          litigatorStatusesListingStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible } = litigatorStatusesListingStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Назив начина избора миритеља односно арбитра',
        id: 'name',
        accessor: 'name',
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            onClick={() => this.showAddLitigatorStatusModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={litigatorStatusesStore.fetch}
              getTdProps={this.getTdProps}
              data={litigatorStatusesStore.data}
              loading={litigatorStatusesStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddLitigatorStatusModal
              showModal={litigatorStatusesListingStore.showModal}
              closeModalForm={this.hideAddLitigatorStatusModal}
              openItem={litigatorStatusesListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default LitigatorStatusesListingPage;
