import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'questions';

const getAllQuestions = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const sendAnswerToUser = (data: any) => {
  return axios.post(`${type}/send-answer-to-user`, data);
};

const sendAnswerToControl = (data: any) => {
  return axios.post(`${type}/send-answer-to-control`, data);
};

const setBadAnswerStatus = (data: any) => {
  return axios.post(`${type}/set-bad-answer`, data);
};

const setGoodAnswerStatus = (data: any) => {
  return axios.post(`${type}/set-good-answer`, data);
};

const QuestionsRepo = new BaseRepo(type);

export default {
  ...QuestionsRepo,
  getAllQuestions,
  sendAnswerToUser,
  sendAnswerToControl,
  setBadAnswerStatus,
  setGoodAnswerStatus
};
