import { Component } from 'react';
import './style.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface DropZoneProps {
  id?: any;
  modelType?: string;
  title?: string;
  subtitle?: string;
  multiple?: boolean;
  method?: any;
  css?: any;
  accept?: string;
  callback?: any;
}

class DropZone extends Component<DropZoneProps> {
  handleUpload = (data: any, method: any, id: any, modelType?: string) => {
    if (modelType !== undefined && modelType !== null) {
      method(data, id, modelType);
    } else {
      method(data, id);
    }
  };

  render() {
    const {
      title,
      subtitle,
      multiple,
      method,
      id,
      css,
      accept,
      modelType
    } = this.props;

    return (
      <div className="dropzone" style={css}>
        <CloudUploadIcon
          style={{ fontSize: 40, marginRight: 40, color: 'rgba(0,0,0,.54)' }}
        />
        <div style={{ textAlign: 'center' }}>
          {title ? (
            <p style={{ fontWeight: 600, fontFamily: 'Arial', color: '#777' }}>
              {title}
            </p>
          ) : null}
          {subtitle ? (
            <div
              style={{ fontWeight: 300, fontFamily: 'Roboto' }}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            ></div>
          ) : null}
        </div>
        <input
          multiple={multiple}
          accept={accept}
          onChange={(e: any) => {
            if (modelType !== undefined && modelType !== null) {
              this.handleUpload(e.target.files, method, id, modelType);
            } else {
              this.handleUpload(e.target.files, method, id);
            }
          }}
          id="dropzone-upload"
          type="file"
          name="dropzone-upload"
        />
      </div>
    );
  }
}

export default DropZone;
