import React from 'react';

interface RenderIfProps {
  condition: boolean;
  children: React.ReactElement;
}

const RenderIf: React.FC<RenderIfProps> = ({ condition, children }) => {
  if (!condition) return null;
  return children;
};

export default RenderIf;
