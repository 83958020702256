import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'litigation_types';

const getAllLitigationTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const LitigationTypesRepo = new BaseRepo(type);

export default {
  ...LitigationTypesRepo,
  getAllLitigationTypes
};
