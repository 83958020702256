import { Component } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Table } from 'react-bootstrap';
// import Thumb from '../../components/Thumb/Thumb';
// import DataTable from '../../components/DataTable';
import to from '../../lib/awaitTo';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, TextField, Button, Paper } from '@mui/material';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import moment from 'moment';
// import { TextField } from 'formik-mui';
import { observable, action } from 'mobx';

import ReportsRepo from 'data/repositories/ReportsRepo';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class ReportsPage extends Component {
  @observable dateFrom: any = moment()
    .subtract(30, 'days')
    .format('DD MMM YYYY');

  @observable tableData: any = null;

  @observable dateTo: any = new Date();

  @action
  setDateFrom = (newDate: any) => {
    this.dateFrom = newDate;
  };

  @action
  setTableData = (tableData: any) => {
    this.tableData = tableData;
  };

  @action
  setDateTo = (newDate: any) => {
    this.dateTo = newDate;
  };

  initialReport = async () => {
    const [initialReportResponse] = await to(
      ReportsRepo.getInitialReport(
        moment(this.dateFrom).format('YYYY-MM-DD'),
        moment(this.dateTo).format('YYYY-MM-DD'),
        {}
      )
    );
    if (initialReportResponse) {
      this.setTableData(initialReportResponse.data);
    }
  };

  componentDidMount() {
    this.initialReport();
  }

  render() {
    if (this.tableData === null) {
      return null;
    }
    return (
      <SearchResultsComponent type="active">
        <Box className="account-div" style={{ padding: '10px' }}>
          <Paper>
            <Box
              style={{
                height: 'auto',
                minHeight: '60px',
                width: '100%',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px'
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: '16px',
                  color: '#888'
                }}
              >
                Извештаји
              </h3>
              <Button
                onClick={() => {
                  window.location = '/dashboard/litigations' as any;
                }}
                variant="contained"
                style={{
                  backgroundColor: '#fb8c00',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px'
                }}
              >
                <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                НАЗАД
              </Button>
            </Box>
            <Box style={{ padding: 30 }}>
              <Row>
                <Col md={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      InputProps={{
                        style: { fontSize: 16, width: '100%' }
                      }}
                      label="Од*"
                      value={this.dateFrom}
                      inputFormat="dd.MM.yyyy"
                      onChange={(newValue: any) => {
                        // setFieldValue('document_date_of_input', newValue);
                        this.setDateFrom(newValue);
                        this.initialReport();
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          variant="standard"
                          name="document_date_of_input"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      InputProps={{
                        style: { fontSize: 16, width: '100%' }
                      }}
                      label="До*"
                      value={this.dateTo}
                      inputFormat="dd.MM.yyyy"
                      onChange={(newValue: any) => {
                        // setFieldValue('document_date_of_input', newValue);
                        this.setDateTo(newValue);
                        this.initialReport();
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          variant="standard"
                          name="document_date_of_input"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
                <Col md={9}>
                  <Paper>
                    <Box
                      style={{
                        height: 'auto',
                        minHeight: '60px',
                        width: '100%',
                        backgroundColor: '#EEEEEE',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px'
                      }}
                    >
                      <h3
                        style={{
                          margin: 0,
                          fontSize: '16px',
                          color: '#888'
                        }}
                      >
                        ИНДИВИДУАЛНИ СПОРОВИ
                      </h3>
                    </Box>
                    <Table hover>
                      <thead>
                        <tr>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Предмет спора
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Број поднетих предлога
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Поступци у току
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Решено у управном поступку
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Мериторно решено
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.tableData
                          .filter((item: any) => {
                            return item.type === 'individual';
                          })
                          .map((element: any) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    maxWidth: '400px',
                                    width: '400px',
                                    fontWeight:
                                      element.subject === 'Укупно индивидуалних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.subject}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно индивидуалних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.broj_podnetih_predloga}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно индивидуалних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.postupci_u_toku}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно индивидуалних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.upravni_postupak}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно индивидуалних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.meritorno_reseno}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Paper>

                  <Paper>
                    <Box
                      style={{
                        height: 'auto',
                        minHeight: '60px',
                        width: '100%',
                        backgroundColor: '#EEEEEE',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px'
                      }}
                    >
                      <h3
                        style={{
                          margin: 0,
                          fontSize: '16px',
                          color: '#888'
                        }}
                      >
                        КОЛЕКТИВНИ СПОРОВИ
                      </h3>
                    </Box>
                    <Table hover>
                      <thead>
                        <tr>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Предмет спора
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Број поднетих предлога
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Поступци у току
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Решено у управном поступку
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Мериторно решено
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.tableData
                          .filter((item: any) => {
                            return item.type === 'collective';
                          })
                          .map((element: any) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    maxWidth: '400px',
                                    width: '400px',
                                    fontWeight:
                                      element.subject === 'Укупно колективних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.subject}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно колективних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.broj_podnetih_predloga}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно колективних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.postupci_u_toku}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно колективних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.upravni_postupak}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно колективних'
                                        ? 600
                                        : 300
                                  }}
                                >
                                  {element.meritorno_reseno}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Paper>

                  <Paper>
                    <Box
                      style={{
                        height: 'auto',
                        minHeight: '60px',
                        width: '100%',
                        backgroundColor: '#EEEEEE',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px'
                      }}
                    >
                      <h3
                        style={{
                          margin: 0,
                          fontSize: '16px',
                          color: '#888'
                        }}
                      >
                        УКУПАН БРОЈ ПОСТУПАКА ПРЕД АГЕНЦИЈОМ
                      </h3>
                    </Box>
                    <Table hover>
                      <thead>
                        <tr>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Предмет спора
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Број поднетих предлога
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Поступци у току
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Решено у управном поступку
                          </td>
                          <td
                            style={{
                              color: 'rgba(0,0,0,.6)',
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}
                          >
                            Мериторно решено
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.tableData
                          .filter((item: any) => {
                            return item.type === 'total';
                          })
                          .map((element: any) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    maxWidth: '400px',
                                    width: '400px',
                                    fontWeight:
                                      element.subject === 'Укупно' ? 600 : 300
                                  }}
                                >
                                  {element.subject}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно' ? 600 : 300
                                  }}
                                >
                                  {element.broj_podnetih_predloga}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно' ? 600 : 300
                                  }}
                                >
                                  {element.postupci_u_toku}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно' ? 600 : 300
                                  }}
                                >
                                  {element.upravni_postupak}
                                </td>
                                <td
                                  style={{
                                    fontWeight:
                                      element.subject === 'Укупно' ? 600 : 300
                                  }}
                                >
                                  {element.meritorno_reseno}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Paper>
                </Col>
              </Row>
            </Box>
          </Paper>
        </Box>
      </SearchResultsComponent>
    );
  }
}

export default ReportsPage;
