import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { strikeMethodsStore } from '../../data/stores/StrikeMethodsStore';
import StrikeMethodsRepo from '../../data/repositories/StrikeMethodsRepo';

class StrikeMethodsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitStrikeMethod = async (strikeMethod: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStrikeMethod(strikeMethod);
    }

    return this.createStrikeMethod(strikeMethod);
  };

  createStrikeMethod = async (strikeMethod: any) => {
    const { create } = StrikeMethodsRepo;

    const [response] = await to(create(strikeMethod));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали начин одржавања штрајка.'
        });
      }, 1000);
      strikeMethodsStore.fetch();
      this.closeModalForm();
    }
  };

  updateStrikeMethod = async (strikeMethod: any) => {
    const { update } = StrikeMethodsRepo;

    const [response] = await to(update(strikeMethod.id, strikeMethod));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали начин одржавања штрајка.'
        });
      }, 1000);
      strikeMethodsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const strikeMethodsListingStore = new StrikeMethodsListingStore(
  StrikeMethodsRepo
);
export default strikeMethodsListingStore;
