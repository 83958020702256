import React from 'react';

const PageLoader = () => (
  <div className="ph-item">
    <div className="ph-col-4">
      <div className="ph-picture" />
    </div>
    <div>
      <div className="ph-row">
        <div className="ph-col-4" />
        <div className="ph-col-8 empty" />
        <div className="ph-col-6" />
        <div className="ph-col-6 empty" />
        <div className="ph-col-2" />
        <div className="ph-col-10 empty" />
      </div>
    </div>

    <div className="ph-col-12">
      <div className="ph-picture" />
      <div className="ph-row">
        <div className="ph-col-10 big" />
        <div className="ph-col-2 empty big" />
        <div className="ph-col-4" />
        <div className="ph-col-8 empty" />
        <div className="ph-col-6" />
        <div className="ph-col-6 empty" />
        <div className="ph-col-12" />
      </div>
    </div>

    <div>
      <div className="ph-row">
        <div className="ph-col-6" />
        <div className="ph-col-6 empty" />
        <div className="ph-col-2" />
        <div className="ph-col-10 empty" />
        <div className="ph-col-8" />
        <div className="ph-col-4 empty" />
        <div className="ph-col-12" />
      </div>
    </div>

    <div className="ph-col-12">
      <div className="ph-picture" />
      <div className="ph-row">
        <div className="ph-col-10 big" />
        <div className="ph-col-2 empty big" />
        <div className="ph-col-4" />
        <div className="ph-col-8 empty" />
        <div className="ph-col-6" />
        <div className="ph-col-6 empty" />
        <div className="ph-col-12" />
      </div>
    </div>

    <div>
      <div className="ph-row">
        <div className="ph-col-6" />
        <div className="ph-col-6 empty" />
        <div className="ph-col-2" />
        <div className="ph-col-10 empty" />
      </div>
    </div>
  </div>
);

export default PageLoader;
