import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import LitigatorAvatarsRepo from '../repositories/LitigatorAvatarsRepo';
import { LitigatorAvatar } from 'data/types';
import LitigatorsRepo from 'data/repositories/LitigatorsRepo';
import { litigatorsStore } from '../stores/LitigatorsStore';

class LitigatorAvatarsStore extends TableStore<any> {
  @observable litigatorAvatar: LitigatorAvatar | null = null;

  @observable imageLink: any = null;

  @observable file: any = undefined;

  @action
  setImageLink = (imageLink: any) => {
    this.imageLink = imageLink;
  };

  @action
  setFile = (file: any) => {
    this.file = file;
  };

  @action
  setLitigatorAvatar = (litigatorAvatar: LitigatorAvatar | null) => {
    this.litigatorAvatar = litigatorAvatar;
  };

  @action
  clearLitigatorAvatar = () => {
    this.setFile(undefined);
    this.setLitigatorAvatar(null);
    this.setImageLink(null);
  };

  createLitigatorAvatar = async (litigatorAvatar: LitigatorAvatar) => {
    const [res] = await to(
      LitigatorAvatarsRepo.uploadLitigatorAvatar(litigatorAvatar)
    );
    if (res) {
      this.setImageLink(res.data);
      this.setLitigatorAvatar(res.data);
    }
  };

  updateLitigatorAvatar = async (
    litigatorAvatar: LitigatorAvatar,
    litigatorId: any
  ) => {
    const [res] = await to(
      LitigatorAvatarsRepo.uploadLitigatorAvatar(litigatorAvatar)
    );
    if (res) {
      const [response] = await to(
        LitigatorsRepo.update(litigatorId, {
          image_link: process.env.REACT_APP_STORAGE_BASE_URL + res.data
        })
      );
      if (response) {
        litigatorsStore.fetch();
      }
      this.setImageLink(res.data);
      this.setLitigatorAvatar(res.data);
    }
  };
}

export const litigatorAvatarsStore = new LitigatorAvatarsStore(
  LitigatorAvatarsRepo
);
export default litigatorAvatarsStore;
