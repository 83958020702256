import React from 'react';

interface AuthLayoutProps {
  children: React.ReactChild;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => (
  <div>
    <div className="content">{children}</div>
  </div>
);

export default AuthLayout;
