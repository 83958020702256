import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import LitigatorsRepo from '../repositories/LitigatorsRepo';

class LitigatorsStore extends TableStore<any> {
  @observable litigators: any;

  @action
  setLitigators = (data: any) => {
    this.litigators = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(LitigatorsRepo.getAllLitigators({}));
    if (res) {
      this.setLitigators(res.data);
    }
  };
}

export const litigatorsStore = new LitigatorsStore(LitigatorsRepo);
export default litigatorsStore;
