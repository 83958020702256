import { Link } from 'react-router-dom';
import { CellInfo } from 'react-table';

const LinkCell = ({ value }: CellInfo) => {
  if (!value) return null;
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        cursor: 'pointer'
      }}
      onClick={() => (window.location.href = value.link)}
    >
      {value.name}
    </div>
  );
};

export default LinkCell;
