import TableStore from './TableStore';
import { observable, action } from 'mobx';
import LegalFormTypesRepo from '../repositories/LegalFormTypesRepo';
import to from '../../lib/awaitTo';

class LegalFormTypesStore extends TableStore<any> {
  @observable legalFormTypes: any;

  @action
  setLegalFormTypes = (data: any) => {
    this.legalFormTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(LegalFormTypesRepo.getAllLegalFormTypes({}));
    if (res) {
      this.setLegalFormTypes(res.data);
    }
  };
}

export const legalFormTypesStore = new LegalFormTypesStore(LegalFormTypesRepo);
export default legalFormTypesStore;
