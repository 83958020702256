import React, { Component } from 'react';
import { pageStore } from '../../data/stores/PageStore';
import { observer } from 'mobx-react';

@observer
class DashboardPageTitle extends Component {
  render() {
    const { navTitle } = pageStore;

    return (
      <h3
        style={{
          paddingLeft: '20px',
          color: '#888',
          fontWeight: 700,
          fontSize: '16px',
          textAlign: 'left'
        }}
      >
        {navTitle}
      </h3>
    );
  }
}

export default DashboardPageTitle;
