import { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import { observable, action } from 'mobx';
import BaseRepo from 'data/repositories/BaseRepo';
import to from '../../lib/awaitTo';
import MenuItem from '@mui/material/MenuItem';
import { observer } from 'mobx-react';
import './style.css';

interface CustomMaterialUiSelectProps {
  value: any;
  fullValue?: any;
  fullValueName?: string;
  setFieldValue: any;
  repo?: BaseRepo;
  passedProp?: any;
  fromState?: boolean;
  stateValue?: any;
  InputLabelProps?: any;
  options?: any;
  name?: string;
  label?: string;
  valueAttr: string;
  data?: any;
  showFields?: any;
  queryParams?: any;
  display: string;
  disabled?: boolean;
  style?: any;
  customOnChange?: any;
}

@observer
class CustomMaterialUiSelect extends Component<CustomMaterialUiSelectProps> {
  @observable data: any = [];

  @observable options: any = [];

  @observable value: any = undefined;

  @observable hasError: any = false;

  @action
  setData = (data: any) => {
    this.data = data;
  };

  @action
  setHasError = (bool: boolean) => {
    this.hasError = bool;
  };

  @action
  clear = () => {
    this.data = [];
    this.value = undefined;
  };

  @action
  setValue = (value: any) => {
    this.value = value;
  };

  @action
  setOptions = (options: any) => {
    this.options = options;
  };

  onChange = (value: any) => {
    const { name, fullValue, setFieldValue, fullValueName } = this.props;
    if (name === 'procedure_type_id') {
      setFieldValue(name, null);
      setTimeout(() => {
        setFieldValue(name, value);
      }, 500);
    } else {
      setFieldValue(name, value);
      setFieldValue(fullValueName, fullValue);
    }

    this.setValue(value);
  };

  async componentWillMount() {
    const {
      repo,
      value,
      options,
      queryParams,
      data
      // stateValue,
      // fromState
    } = this.props;

    this.setValue(value);

    if (repo !== undefined) {
      let params = queryParams !== undefined ? queryParams : null;
      const [res] = await to(repo.getAll({ params: params }));
      if (res) {
        this.setData(res.data);
      }
    } else {
      this.setData(data);
    }

    if (options !== undefined && options !== []) {
      this.setOptions(options);
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  render() {
    const {
      label,
      valueAttr,
      showFields,
      display,
      disabled,
      style,
      fromState,
      stateValue,
      data,
      InputLabelProps,
      customOnChange
    } = this.props;

    let pickedItemFromState = null;
    if (fromState) {
      pickedItemFromState = data.filter((item: any) => {
        return item.id === stateValue;
      });
    }

    return (
      <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
        {InputLabelProps ? (
          <InputLabel className={disabled ? 'disabled-label-color' : ''} shrink>
            {label}
          </InputLabel>
        ) : (
          <InputLabel className={disabled ? 'disabled-label-color' : ''}>
            {label}
          </InputLabel>
        )}
        <Select
          className={disabled ? 'disabled-border-bottom-color' : ''}
          disabled={disabled}
          value={
            pickedItemFromState !== null &&
              pickedItemFromState !== undefined &&
              pickedItemFromState[0] !== undefined
              ? pickedItemFromState[0].id
              : this.value !== ''
                ? this.value
                : null
          }
          onChange={(e: any) => {
            this.onChange(e.target.value);

            if (customOnChange) {
              customOnChange(e.target.value);
            }
          }}
          style={{ ...style, fontSize: 16 }}
          label={label}
          MenuProps={{
            style: {
              maxHeight: 400
            }
          }}
        >
          {this.data.map((item: any, index: any) => {
            return (
              <MenuItem key={index} value={item[valueAttr]}>
                {showFields
                  ? item[showFields[0]] + ' ' + item[showFields[1]]
                  : item[display]}
              </MenuItem>
            );
          })}
          {!label!.includes('*') ? (
            <MenuItem key={'dsadads'} value={''}>
              -
            </MenuItem>
          ) : null}
          {this.options.map((item: any, index: any) => {
            return (
              <MenuItem key={index} value={item[valueAttr]}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
        {/* {this.hasError ? (
          <FormHelperText>Поље {label} је обавезно</FormHelperText>
        ) : null} */}
      </FormControl>
    );
  }
}

export default CustomMaterialUiSelect;
