import TableStore from './TableStore';
import { observable, action } from 'mobx';
import ProposalTypesRepo from '../repositories/ProposalTypesRepo';
import to from '../../lib/awaitTo';

class ProposalTypesStore extends TableStore<any> {
  @observable proposalTypes: any;

  @action
  setProposalTypes = (data: any) => {
    this.proposalTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(ProposalTypesRepo.getAllProposalTypes({}));
    if (res) {
      this.setProposalTypes(res.data);
    }
  };
}

export const proposalTypesStore = new ProposalTypesStore(ProposalTypesRepo);
export default proposalTypesStore;
