import { observer } from 'mobx-react';
import { checkPermissions } from '../../lib/checkPermissions';

import { authStore } from '../../data/stores/AuthStore';

interface PermissionGuardProps {
  permissionsWithAccess: string[];
  children?: any;
  fallback?: any;
}

function PermissonGuard({
  permissionsWithAccess,
  children,
  fallback = null
}: PermissionGuardProps) {
  const { authUserPermissions } = authStore;

  const showComponent = checkPermissions(
    authUserPermissions,
    permissionsWithAccess
  );

  if (!showComponent) {
    return fallback;
  }

  return children;
}

export default observer(PermissonGuard);
