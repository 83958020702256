import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddStrikeModal from './components/AddStrikeModal';
import { strikesStore } from '../../data/stores/StrikesStore';
import { strikesListingStore } from './StrikesListingStore';
import LitigatorFullNameCellWithAvatar from 'components/TableCells/LitigatorFullNameCellWithAvatar';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import { RowInfo } from 'react-table';
import './style.css';
import DateCell from 'components/TableCells/DateCell';
import SearchIcon from '@mui/icons-material/Search';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class StrikesListingPage extends Component {
  componentDidMount() {
    strikesStore.fetchAll();
  }

  showAddLitigationModal = () => {
    strikesListingStore.hideAddButton();
    strikesListingStore.openModalForm();
  };

  hideAddLitigationModal = () => {
    strikesListingStore.showAddButton();
    strikesListingStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          // page detail
          // window.location.href = '/dashboard/litigations/' + row.original.id;
          // modal detail

          strikesListingStore.openModalForm(row.original);
        }
      }
    };
  };

  itemAction = (strike: any) => {
    return (
      <div className="td-actions text-center">
        {/* <PermissionGuard permissionsWithAccess={permission.accidentDetails}> */}
        <Button
          style={{ color: 'black' }}
          onClick={() => {
            // strikesListingStore.openModalForm(litigation);
          }}
          startIcon={<SearchIcon />}
        >
          ПРИКАЗ
        </Button>
        {/* </PermissionGuard> */}
      </div>
    );
  };

  render() {
    // const { addButtonVisible } = strikesListingStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Датум',
        id: 'document_date_of_input',
        accessor: 'document_date_of_input',
        Cell: DateCell(),
        sortable: true,
        width: 100
      },
      {
        Header: 'Бр. предмета',
        accessor: 'strike_number',
        sortable: false,
        filterable: true,
        width: 120
      },
      {
        Header: 'Прва страна',
        id: 'name_first_party',
        accessor: 'name_first_party',
        sortable: false,
        filterable: true
      },
      {
        Header: 'Друга страна',
        accessor: 'name_second_party',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Врста штрајка',
        accessor: 'strike_type.name',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Миритељ и арбитар',
        accessor: 'litigator',
        Cell: LitigatorFullNameCellWithAvatar,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Обрађивач',
        id: 'worker.firstname',
        accessor: 'worker',
        Cell: WorkerFullNameCellWithAvatar,
        sortable: false,
        filterable: true
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={strikesStore.fetchAll}
              meta={strikesStore.meta}
              data={strikesStore.strikes}
              getTdProps={this.getTdProps}
              loading={strikesStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddStrikeModal
              className="add-strike-modal"
              showModal={strikesListingStore.showModal}
              closeModalForm={this.hideAddLitigationModal}
              openItem={strikesListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default StrikesListingPage;
