import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import FeeTypesRepo from '../repositories/FeeTypesRepo';

class FeeTypesStore extends TableStore<any> {
  @observable feeTypes: any;

  @action
  setFeeTypes = (data: any) => {
    this.feeTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(FeeTypesRepo.getAllFeeTypes({}));
    if (res) {
      this.setFeeTypes(res.data);
    }
  };
}

export const feeTypesStore = new FeeTypesStore(FeeTypesRepo);
export default feeTypesStore;
