import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import WorkersRepo from '../repositories/WorkersRepo';

class WorkersStore extends TableStore<any> {
  @observable workers: any[] = [];

  @action
  setWorkers = (data: any[]) => {
    this.workers = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(WorkersRepo.getAllWorkers({}));
    if (res) {
      this.setWorkers(res.data);
    }
  };
}

export const workersStore = new WorkersStore(WorkersRepo);
export default workersStore;
