import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import ConsentsRepo from '../../data/repositories/ConsentsRepo';
import consentsStore from '../../data/stores/ConsentsStore';

class ConsentsListStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitConsent = async (consent: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateConsent(consent);
    }

    return this.createConsent(consent);
  };

  createConsent = async (consent: any) => {
    const { create } = ConsentsRepo;

    const [response] = await to(create(consent));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту сагласности.'
        });
      }, 1000);
      consentsStore.fetch();
      this.closeModalForm();
    }
  };

  updateConsent = async (consent: any) => {
    const { update } = ConsentsRepo;

    const [response] = await to(update(consent.id, consent));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту сагласности.'
        });
      }, 1000);
      consentsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const consentsListStore = new ConsentsListStore(ConsentsRepo);
export default ConsentsListStore;
