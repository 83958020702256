import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { SelectComponentsProps } from 'react-select/src/Select';

import { FieldProps } from 'data/types';
import FormFieldContainer from 'components/FormField/FormFieldContainer';
import './styles.css';

type SelectType = 'async' | 'creatable' | 'fixed';
interface SelectFieldProps extends FieldProps {
  selectType: SelectType;
  selectInputProps?: SelectComponentsProps;
  inline?: boolean;
  autoFocus?: boolean;
  requiredClass?: boolean;
  label: string;
}

type ComponentMap = {
  [T in SelectType]: any;
};

const componentsMap: ComponentMap = {
  async: AsyncSelect,
  creatable: CreatableSelect,
  fixed: Select
};

const Component = ({
  selectType,
  ...props
}: {
  selectType: SelectType;
  styles: object;
  autoFocus: boolean | undefined;
  placeholder: string;
}) => {
  const Resolved = componentsMap[selectType];
  return <Resolved {...props} />;
};

const SelectField = ({
  label,
  errors,
  touched,
  selectInputProps,
  selectType,
  inline,
  autoFocus,
  requiredClass
}: SelectFieldProps) => (
  <FormFieldContainer
    errors={errors}
    touched={touched}
    inline={inline}
    requiredClass={requiredClass}
  >
    <Component
      styles={{
        control: (provided: object) => ({ ...provided, minHeight: '40px' })
      }}
      placeholder={label}
      autoFocus={autoFocus}
      selectType={selectType}
      {...selectInputProps}
    />
  </FormFieldContainer>
);

SelectField.defaultProps = {
  selectType: 'fixed'
};

export default SelectField;
