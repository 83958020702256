export const litigationsView = ['litigations-view'];
export const litigationsEdit = ['litigations-edit'];
export const litigationsDelete = ['litigations-delete'];

export const litigationsArchivedView = ['litigations-archived-view'];
export const litigationsArchivedEdit = ['litigations-archived-edit'];
export const litigationsArchivedDelete = ['litigations-archived-delete'];

export const otherLitigationsView = ['other-litigations-view'];
export const otherLitigationsEdit = ['other-litigations-edit'];
export const otherLitigationsDelete = ['other-litigations-delete'];

export const otherLitigationsArchivedView = ['other-litigations-archived-view'];
export const otherLitigationsArchivedEdit = ['other-litigations-archived-edit'];
export const otherLitigationsArchivedDelete = [
  'other-litigations-archived-delete'
];

export const strikesView = ['strikes-view'];
export const strikesEdit = ['strikes-edit'];
export const strikesDelete = ['strikes-delete'];

export const strikesArchivedView = ['strikes-archived-view'];
export const strikesArchivedEdit = ['strikes-archived-edit'];
export const strikesArchivedDelete = ['strikes-archived-delete'];

export const officeView = ['office-view'];
export const officeArchiveView = ['office-archive-view'];

export const reportsView = ['reports-view'];
export const litigatorsView = ['litigators-view'];
export const usersView = ['users-view'];
export const codeBooksView = ['code-books-view'];

export const questionsView = ['questions-view'];
export const questionsEdit = ['questions-edit'];
export const questionsDelete = ['questions-delete'];

export const answerGoodBad = ['answer-good-bad'];
export const sendAnswerToUser = ['send-answer-to-user'];
export const sendAnswerToControl = ['send-answer-to-control'];

export const createOfficeDocuments = ['create-office-documents'];

export const documentsView = ['documents-view'];
export const documentsUpload = ['documents-upload'];
export const documentsDelete = ['documents-delete'];

export const reviewsAgency = ['reviews-agency-view'];
export const reviewsLitigator = ['reviews-litigator-view'];
