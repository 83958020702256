import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import LitigationTypesRepo from 'data/repositories/LitigationTypesRepo';

class LitigationTypesStore extends TableStore<any> {
  @observable litigationTypes: any;

  @action
  setLitigationTypes = (data: any) => {
    this.litigationTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(LitigationTypesRepo.getAllLitigationTypes({}));

    if (res) {
      this.setLitigationTypes(res.data);
    }
  };
}

export const litigationTypesStore = new LitigationTypesStore(
  LitigationTypesRepo
);
export default litigationTypesStore;
