import { observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { setPageTitle } from '../../lib/routerHelper';

@observer
class PageTitle extends Component<RouteComponentProps> {
  componentDidMount() {
    const { match } = this.props;
    setPageTitle(match.path);
  }

  componentDidUpdate() {
    const { match } = this.props;
    setPageTitle(match.path);
  }

  render() {
    return null;
  }
}

export default withRouter(PageTitle);
