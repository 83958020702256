import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'strike_methods';

const getAllStrikeMethods = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const StrikeMethodsRepo = new BaseRepo(type);

export default {
  ...StrikeMethodsRepo,
  getAllStrikeMethods
};
