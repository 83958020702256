import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddDocumentTypeModal from './components/AddDocumentTypeModal';
import { documentTypesStore } from '../../data/stores/DocumentTypesStore';
import { documentTypesListingStore } from './DocumentTypesListingStore';
import { RowInfo } from 'react-table';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class DocumentTypesListingPage extends Component {
  componentDidMount() {
    documentTypesStore.fetchAll();
  }

  showAddDocumentTypeModal = () => {
    documentTypesListingStore.hideAddButton();
    documentTypesListingStore.openModalForm();
  };

  hideAddDocumentTypeModal = () => {
    documentTypesListingStore.showAddButton();
    documentTypesListingStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          documentTypesListingStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible } = documentTypesListingStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Редослед',
        id: 'order',
        accessor: 'order',
        sortable: false,
        width: 80
      },
      {
        Header: 'Назив врсте документа',
        id: 'name',
        accessor: 'name',
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            onClick={() => this.showAddDocumentTypeModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px',
              borderRadius: '4px',
              backgroundColor: '#1976d2',
              color: 'white'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={documentTypesStore.fetch}
              data={documentTypesStore.data}
              getTdProps={this.getTdProps}
              loading={documentTypesStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddDocumentTypeModal
              showModal={documentTypesListingStore.showModal}
              closeModalForm={this.hideAddDocumentTypeModal}
              openItem={documentTypesListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default DocumentTypesListingPage;
