import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import ProposalTypesRepo from '../../data/repositories/ProposalTypesRepo';
import proposalTypesStore from '../../data/stores/ProposalTypesStore';

class ProposalTypesListStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitProposalType = async (proposalType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateProposalType(proposalType);
    }

    return this.createProposalType(proposalType);
  };

  createProposalType = async (proposalType: any) => {
    const { create } = ProposalTypesRepo;

    const [response] = await to(create(proposalType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту предлога.'
        });
      }, 1000);
      proposalTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateProposalType = async (proposalType: any) => {
    const { update } = ProposalTypesRepo;

    const [response] = await to(update(proposalType.id, proposalType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту предлога.'
        });
      }, 1000);
      proposalTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const proposalTypesListStore = new ProposalTypesListStore(
  ProposalTypesRepo
);
export default ProposalTypesListStore;
