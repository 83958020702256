import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddExpenseTypeModal from './components/AddExpenseTypeModal';
import { expenseTypesStore } from '../../data/stores/ExpenseTypesStore';
import { expenseTypesListingStore } from './ExpenseTypesListingStore';
import { RowInfo } from 'react-table';
import PriceCell from 'components/TableCells/PriceCell';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class ExpenseTypesListingPage extends Component {
  componentDidMount() {
    expenseTypesStore.fetchAll();
  }

  showAddExpenseTypeModal = () => {
    expenseTypesListingStore.hideAddButton();
    expenseTypesListingStore.openModalForm();
  };

  hideAddExpenseTypeModal = () => {
    expenseTypesListingStore.showAddButton();
    expenseTypesListingStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          expenseTypesListingStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible } = expenseTypesListingStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Цена',
        id: 'price',
        accessor: 'price',
        sortable: false,
        Cell: PriceCell,
        width: 80
      },
      {
        Header: 'Назив услуге',
        id: 'name',
        accessor: 'name',
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            onClick={() => this.showAddExpenseTypeModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px',
              borderRadius: '4px',
              backgroundColor: '#1976d2',
              color: 'white'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={expenseTypesStore.fetch}
              data={expenseTypesStore.data}
              getTdProps={this.getTdProps}
              loading={expenseTypesStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddExpenseTypeModal
              showModal={expenseTypesListingStore.showModal}
              closeModalForm={this.hideAddExpenseTypeModal}
              openItem={expenseTypesListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default ExpenseTypesListingPage;
