import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import conclusionsStore from '../../data/stores/ConclusionsStore';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { conclusionsListStore } from './ConclusionsListStore';
import AddConclusionModal from './components/AddConclusionModal';
import { RowInfo } from 'react-table';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class ConclusionsListingPage extends Component {
  componentDidMount() {
    conclusionsStore.fetchAll();
  }

  showAddConclusionModal = () => {
    conclusionsListStore.hideAddButton();
    conclusionsListStore.openModalForm();
  };

  hideAddConclusionModal = () => {
    conclusionsListStore.showAddButton();
    conclusionsListStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          conclusionsListStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible } = conclusionsListStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Назив врсте закључка',
        id: 'name',
        accessor: 'name',
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            onClick={() => this.showAddConclusionModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              getTdProps={this.getTdProps}
              fetchData={conclusionsStore.fetch}
              data={conclusionsStore.data}
              loading={conclusionsStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddConclusionModal
              showModal={conclusionsListStore.showModal}
              closeModalForm={this.hideAddConclusionModal}
              openItem={conclusionsListStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default ConclusionsListingPage;
