import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { strikePlacesStore } from '../../data/stores/StrikePlacesStore';
import StrikePlacesRepo from '../../data/repositories/StrikePlacesRepo';

class StrikePlacesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitStrikePlace = async (strikePlace: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStrikePlace(strikePlace);
    }

    return this.createStrikePlace(strikePlace);
  };

  createStrikePlace = async (strikePlace: any) => {
    const { create } = StrikePlacesRepo;

    const [response] = await to(create(strikePlace));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали место штрајка.'
        });
      }, 1000);
      strikePlacesStore.fetch();
      this.closeModalForm();
    }
  };

  updateStrikePlace = async (strikePlace: any) => {
    const { update } = StrikePlacesRepo;

    const [response] = await to(update(strikePlace.id, strikePlace));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали место штрајка.'
        });
      }, 1000);
      strikePlacesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const strikePlacesListingStore = new StrikePlacesListingStore(
  StrikePlacesRepo
);
export default strikePlacesListingStore;
