import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'litigations';

// const parseRequest = ({
//   name,
//   description,
//   toEmail,
//   fromEmail,
//   country
// }: any) => ({
//   name,
//   description,
//   toEmail,
//   fromEmail,
//   countryId: country.id
// });

const getAllLitigations = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const fetchLitigationIdByLitigationNumber = (litigationNumber: string) => {
  return axios.get(
    `get-by-litigation-number?litigation_number=${litigationNumber}`
  );
};

const getAllLitigationsWithoutPagination = () => {
  return axios.get('get-all-litigations-without-pagination');
};

const getAllActiveLitigationsWithoutPagination = () => {
  return axios.get('get-all-active-litigations-without-pagination');
};

const checkExisting = (litigation: any) => {
  return axios.get(`check-existing-litigation?name_first_party=${litigation.name_first_party}&litigation_type_id=${litigation.litigation_type_id}&litigation_number=${litigation.litigation_number}`);
}

const fillChildLitigations = (parentId: any, childrenIds: any) => {
  const formData = new FormData();

  formData.append('parentId', parentId);
  // console.log('CH', childrenIds);
  childrenIds.forEach((item: any) => {
    formData.append('childrenIds[]', item);
  });

  return axios.post(`fill-child-litigations`, formData);
};

const fillChildLitigationsPhase = (phaseId: any, childrenIds: any) => {
  const formData = new FormData();

  formData.append('phaseId', phaseId);
  // console.log('CH', childrenIds);
  childrenIds.forEach((item: any) => {
    formData.append('childrenIds[]', item);
  });

  return axios.post(`fill-child-litigations-phase`, formData);
};

const deleteLitigationById = (litigationId: number) => {
  return axios.delete(`${type}/${litigationId}`);
};

const LitigationsRepo = new BaseRepo(type);

export default {
  ...LitigationsRepo,
  getAllLitigations,
  getAllLitigationsWithoutPagination,
  getAllActiveLitigationsWithoutPagination,
  fetchLitigationIdByLitigationNumber,
  checkExisting,
  fillChildLitigations,
  fillChildLitigationsPhase,
  deleteLitigationById
};
