import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LitigationsRepo from '../../data/repositories/LitigationsRepo';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import QuestionsRepo from '../../data/repositories/QuestionsRepo';
import questionsStore from 'data/stores/QuestionsStore';

class QuestionDetailsStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @observable openItem: any = null;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  @action
  setOpenItem = (openItem: any) => {
    this.openItem = openItem;
  };

  @action
  fetchOne = async (id: any) => {
    const { one } = QuestionsRepo;
    const [response] = await to(one(id));

    if (response) {
      this.setOpenItem(response.data);
      //   litigationsStore.fetch();
      //   this.closeModalForm();
    }
  };

  createQuestion = async (question: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateQuestion(question);
    }

    return this.saveQuestion(question);
  };

  saveQuestion = async (question: any) => {
    const { create } = QuestionsRepo;

    const [response] = await to(create(question));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали питање.'
        });
      }, 1000);
      litigationsStore.fetch();
      this.closeModalForm();
    }
  };

  updateQuestion = async (question: any) => {
    const [response] = await to(QuestionsRepo.update(question.id, question));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали питање.'
        });
        //questionDetailsStore.fetchOne(response.data.id);
        // window.location.reload();
        //questionsStore.fetch();
        //window.location.reload();
      }, 1000);
      // questionsStore.fetch();
      // window.location.reload();
      // this.closeModalForm();
    }
  };

  sendAnswerToUser = async (question: any) => {
    const [response] = await to(QuestionsRepo.sendAnswerToUser(question));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте послали одговор кориснику.'
        });
      }, 1000);
      // questionsStore.fetch();
      // questionDetailsStore.fetchOne(question.id);
      window.location.reload();
      // this.closeModalForm();
    }
  };

  sendAnswerToControl = async (question: any) => {
    const [response] = await to(QuestionsRepo.sendAnswerToControl(question));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте послали одговор на контролу.'
        });
      }, 1000);
      window.location.reload();
      // questionDetailsStore.fetchOne(response.data.id);
      // this.closeModalForm();
    }
  };

  setBadAnswerStatus = async (question: any) => {
    const [response] = await to(QuestionsRepo.setBadAnswerStatus(question));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Статус ажуриран на ”Одговор није добар”.'
        });
      }, 1000);
      // questionsStore.fetch();
      questionDetailsStore.fetchOne(question.id);
      // this.closeModalForm();
    }
  };

  setGoodAnswerStatus = async (question: any) => {
    const [response] = await to(QuestionsRepo.setGoodAnswerStatus(question));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Статус ажуриран на ”Одговор је добар”.'
        });
      }, 1000);
      // questionsStore.fetch();
      window.location.reload();
      // this.closeModalForm();
    }
  };

  deleteQuestion = async (questionId: any) => {
    const [response] = await to(QuestionsRepo.delete(questionId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали питање.'
        });
      }, 1000);
      // questionsStore.fetch();
      window.location.href = '/dashboard/questions';
      // this.closeModalForm();
    }
  };
}

export const questionDetailsStore = new QuestionDetailsStore(QuestionsRepo);
export default questionDetailsStore;
