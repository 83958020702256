import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import LitigatorStatusesRepo from '../repositories/LitigatorStatusesRepo';

class LitigatorStatusesStore extends TableStore<any> {
  @observable litigatorStatuses: any;

  @action
  setLitigatorStatuses = (data: any) => {
    this.litigatorStatuses = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(LitigatorStatusesRepo.getAllLitigatorStatuses({}));
    if (res) {
      this.setLitigatorStatuses(res.data);
    }
  };
}

export const litigatorStatusesStore = new LitigatorStatusesStore(
  LitigatorStatusesRepo
);
export default litigatorStatusesStore;
