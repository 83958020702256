import { CellInfo } from 'react-table';

const StatusCell = (value: CellInfo) => {
  const { original } = value;
  const status = {
    'no-answer': 'неодговорено',
    'send-answer': 'одговор послат кориснику',
    new: 'неодговорено',
    control: 'послато на контролу',
    'bad-answer':
      '<span style="color: red">питање враћено са ревизије, одговор није добар</span>',
    'good-answer':
      '<span style="color: green">питање враћено са ревизије, одговор је добар</span>'
  } as any;
  return (
    <div
      dangerouslySetInnerHTML={{ __html: status[original.status] }}
      style={{ display: 'flex', alignItems: 'center' }}
    ></div>
  );
};

export default StatusCell;
