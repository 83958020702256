import { fi } from 'date-fns/locale';
import React from 'react';
import { CellInfo } from 'react-table';

const LitigatorFullNameCellWithAvatar = ({ value }: CellInfo) => {
  if (!value) return null;
  const firstName = value.litigator_firstname || '';
  const lastName = value.litigator_lastname || '';
  const imageLink = value.image_link || '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        style={{
          height: 30,
          width: 30,
          borderRadius: '50%',
          marginRight: 5
        }}
        src={
          !imageLink.includes('null')
            ? imageLink
            : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
        }
        alt="userimage"
      />
      {firstName} {lastName}
    </div>
  );
};

export default LitigatorFullNameCellWithAvatar;
