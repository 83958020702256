import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import RejectionReasonsRepo from '../repositories/RejectionReasonsRepo';

class RejectionReasonsStore extends TableStore<any> {
  @observable rejectionReasons: any;

  @action
  setRejectionReasons = (data: any) => {
    this.rejectionReasons = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(RejectionReasonsRepo.getAllRejectionReasons({}));
    if (res) {
      this.setRejectionReasons(res.data);
    }
  };
}

export const rejectionReasonsStore = new RejectionReasonsStore(
  RejectionReasonsRepo
);
export default rejectionReasonsStore;
