import { Card } from '@material-ui/core';
import { Box, CardContent, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { authStore } from 'data/stores/AuthStore';
import { Redirect } from 'react-router';
import { LoginParams } from 'data/types';
import { Field, Form, Formik } from 'formik';
import { Component } from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import { observer } from 'mobx-react';
import ErrorMessage from 'components/ErrorMessage';
import { action, observable } from 'mobx';
import axios, { AxiosRequestConfig } from 'axios';

@observer
class Review extends Component {

    async componentWillMount() {
        const { id, party } = this.props.match.params;

        console.log(party)

        let response = await axios.get(process.env.REACT_APP_API_URL + 'check-review/' + id);

        // console.log('RES ', response);
        if (response.data) {
            if (Number(response.data.data.review_first_party_completed) === 1 && party === 'fp') {
                this.setShowBadLink();
            }

            if (Number(response.data.data.review_second_party_completed) === 1 && party === 'sp') {
                this.setShowBadLink();
            }
        }

        if (response.data === "no litigation" || (party !== 'fp' && party !== 'sp')) {
            this.setShowBadLink();
        }

    }

    @observable showThankYou: boolean = false;
    @observable showBadLink: boolean = false;

    @action
    setShowThankYou = () => {
        this.showThankYou = !this.showThankYou;
    }

    @action
    setShowBadLink = () => {
        this.showBadLink = !this.showBadLink;
    }

    onSubmit = async (data: any) => {
        console.log(data);
        const { id, party } = this.props.match.params;
        // console.log('id ', id);
        // return;

        let response = await axios.patch(process.env.REACT_APP_API_URL + `rate-agency-and-litigator-${party === 'sp' ? 'second' : 'first'}-party/` + id, data);

        if (response.data) {
            this.setShowThankYou();
        }


    };

    render() {
        const { loggedIn, invalidLogin, errorMessage } = authStore;
        const { id, party } = this.props.match.params;

        if (this.showBadLink) {
            return "Неисправан линк, или је истекао...";
        } else {

            return (
                <Box
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box style={{ width: '600px', height: 'auto' }}>
                        <Card>
                            <CardContent
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {this.showThankYou ?
                                    <h3 style={{ textAlign: "center" }}>Хвала што сте попунили анкету!</h3>
                                    :
                                    <>
                                        {party === 'sp' ? <Formik
                                            onSubmit={this.onSubmit}
                                            initialValues={{
                                                second_party_rate_agency: '',
                                                second_party_rate_litigator: '',
                                                second_party_note: ''
                                            }}
                                            render={({
                                                values,
                                                setFieldValue,
                                                errors,
                                                touched,
                                                setFieldTouched
                                            }) => (
                                                <Form noValidate className="form-horizontal">
                                                    {/* <Field
                                                        component={TextField}
                                                        name="second_party_rate_agency"
                                                        type="number"
                                                        min="1"
                                                        max="5"
                                                        onChange={(e: any) => {
                                                            setFieldValue('second_party_rate_agency', Number(e.currentTarget.value));
                                                        }}
                                                        label="Оцените ваше искуство са Агенцијом од 1 до 5, где је (5 одличан, 1 недовољан)"
                                                        variant="standard"
                                                        inputProps={{
                                                            style: { fontSize: 16 }, min: 1,
                                                            max: 5,
                                                        }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    /> */}
                                                    <Field name="second_party_rate_agency">
                                                        {({ field }: any) => (
                                                            <FormControl component="fieldset">
                                                                <FormLabel style={{ fontSize: 16 }}>Оцените ваше искуство са Агенцијом од 1 до 5, где је (5 одличан, 1 недовољан)</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    {...field}
                                                                    onChange={(e) => setFieldValue("second_party_rate_agency", e.target.value)}
                                                                >
                                                                    {[1, 2, 3, 4, 5].map((option) => (
                                                                        <FormControlLabel
                                                                            key={option}
                                                                            value={option.toString()}
                                                                            control={<Radio />}
                                                                            label={option.toString()}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        component={TextField}
                                                        name="second_party_note_agency"
                                                        type="text"
                                                        label="Молимо вас изнесите образложење, примедбу или предлог у вези вашег искуства са Агенцијом:"
                                                        multiline
                                                        // value={''}
                                                        onChange={(e: any) => {
                                                            setFieldValue('second_party_note_agency', e.currentTarget.value);
                                                        }}
                                                        rows={5}
                                                        variant="outlined"
                                                        InputProps={{ style: { fontSize: 16 } }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Roboto',
                                                            marginTop: 30
                                                        }}
                                                    />
                                                    {/* <Field
                                                        component={TextField}
                                                        name="second_party_rate_litigator"
                                                        type="number"
                                                        onChange={(e: any) => {
                                                            setFieldValue('second_party_rate_litigator', Number(e.currentTarget.value));
                                                        }}
                                                        label="Оцените ваше искуство са миритељем/арбитром од 1 до 5 (5 одличан, 1 недовољан)"
                                                        variant="standard"
                                                        inputProps={{
                                                            style: { fontSize: 16 }, min: 1,
                                                            max: 5,
                                                        }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    /> */}
                                                    <Field name="second_party_rate_litigator">
                                                        {({ field }: any) => (
                                                            <FormControl component="fieldset">
                                                                <FormLabel style={{ fontSize: 16 }}>Оцените ваше искуство са миритељем/арбитром од 1 до 5 (5 одличан, 1 недовољан)</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    {...field}
                                                                    onChange={(e) => setFieldValue("second_party_rate_litigator", e.target.value)}
                                                                >
                                                                    {[1, 2, 3, 4, 5].map((option) => (
                                                                        <FormControlLabel
                                                                            key={option}
                                                                            value={option.toString()}
                                                                            control={<Radio />}
                                                                            label={option.toString()}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        component={TextField}
                                                        name="second_party_note_litigator"
                                                        type="text"
                                                        label="Молимо вас изнесите образложење, примедбу или предлог у вези рада миритеља/арбитра:"
                                                        multiline
                                                        // value={''}
                                                        onChange={(e: any) => {
                                                            setFieldValue('second_party_note_litigator', e.currentTarget.value);
                                                        }}
                                                        rows={5}
                                                        variant="outlined"
                                                        InputProps={{ style: { fontSize: 16 } }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            marginTop: 30,
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        message={errorMessage}
                                                        show={invalidLogin}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        style={{
                                                            padding: '5px 80px',
                                                            fontFamily: 'Roboto',
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            marginBottom: '0px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            backgroundColor: '#3f51b5'
                                                        }}
                                                    >
                                                        Потврди
                                                    </Button>
                                                </Form>
                                            )}
                                        /> : <Formik
                                            onSubmit={this.onSubmit}
                                            initialValues={{
                                                first_party_rate_agency: '',
                                                first_party_rate_litigator: '',
                                                first_party_note: ''
                                            }}
                                            render={({
                                                values,
                                                setFieldValue,
                                                errors,
                                                touched,
                                                setFieldTouched
                                            }) => (
                                                <Form noValidate className="form-horizontal">
                                                    {/* <Field
                                                        component={TextField}
                                                        name="first_party_rate_agency"
                                                        type="number"
                                                        min="1"
                                                        max="5"
                                                        onChange={(e: any) => {
                                                            setFieldValue('first_party_rate_agency', Number(e.currentTarget.value));
                                                        }}
                                                        label="Оцените ваше искуство са Агенцијом од 1 до 5, где је (5 одличан, 1 недовољан)"
                                                        variant="standard"
                                                        inputProps={{
                                                            style: { fontSize: 16 }, min: 1,
                                                            max: 5,
                                                        }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    /> */}
                                                    <Field name="first_party_rate_agency">
                                                        {({ field }: any) => (
                                                            <FormControl component="fieldset">
                                                                <FormLabel style={{ fontSize: 16 }}>Оцените ваше искуство са Агенцијом од 1 до 5, где је (5 одличан, 1 недовољан)</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    {...field}
                                                                    onChange={(e) => setFieldValue("first_party_rate_agency", e.target.value)}
                                                                >
                                                                    {[1, 2, 3, 4, 5].map((option) => (
                                                                        <FormControlLabel
                                                                            key={option}
                                                                            value={option.toString()}
                                                                            control={<Radio />}
                                                                            label={option.toString()}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        component={TextField}
                                                        name="first_party_note_agency"
                                                        type="text"
                                                        label="Молимо вас изнесите образложење, примедбу или предлог у вези вашег искуства са Агенцијом:"
                                                        multiline
                                                        // value={''}
                                                        onChange={(e: any) => {
                                                            setFieldValue('first_party_note_agency', e.currentTarget.value);
                                                        }}
                                                        rows={5}
                                                        variant="outlined"
                                                        InputProps={{ style: { fontSize: 16 } }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            marginTop: 30,
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    />
                                                    {/* <Field
                                                        component={TextField}
                                                        name="first_party_rate_litigator"
                                                        type="number"
                                                        onChange={(e: any) => {
                                                            setFieldValue('first_party_rate_litigator', Number(e.currentTarget.value));
                                                        }}
                                                        label="Оцените ваше искуство са миритељем/арбитром од 1 до 5 (5 одличан, 1 недовољан)"
                                                        variant="standard"
                                                        inputProps={{
                                                            style: { fontSize: 16 }, min: 1,
                                                            max: 5,
                                                        }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    /> */}
                                                    <Field name="first_party_rate_litigator">
                                                        {({ field }: any) => (
                                                            <FormControl component="fieldset">
                                                                <FormLabel style={{ fontSize: 16 }}>Оцените ваше искуство са миритељем/арбитром од 1 до 5 (5 одличан, 1 недовољан)</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    {...field}
                                                                    onChange={(e) => setFieldValue("first_party_rate_litigator", e.target.value)}
                                                                >
                                                                    {[1, 2, 3, 4, 5].map((option) => (
                                                                        <FormControlLabel
                                                                            key={option}
                                                                            value={option.toString()}
                                                                            control={<Radio />}
                                                                            label={option.toString()}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                    <Field
                                                        component={TextField}
                                                        name="first_party_note_litigator"
                                                        type="text"
                                                        label="Молимо вас изнесите образложење, примедбу или предлог у вези рада миритеља/арбитра:"
                                                        multiline
                                                        // value={''}
                                                        onChange={(e: any) => {
                                                            setFieldValue('first_party_note_litigator', e.currentTarget.value);
                                                        }}
                                                        rows={5}
                                                        variant="outlined"
                                                        InputProps={{ style: { fontSize: 16 } }}
                                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                                        style={{
                                                            marginTop: 30,
                                                            width: '100%',
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        message={errorMessage}
                                                        show={invalidLogin}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        style={{
                                                            padding: '5px 80px',
                                                            fontFamily: 'Roboto',
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            marginBottom: '0px',
                                                            width: '100%',
                                                            marginTop: '20px',
                                                            backgroundColor: '#3f51b5'
                                                        }}
                                                    >
                                                        Потврди
                                                    </Button>
                                                </Form>
                                            )}
                                        />}
                                    </>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                </Box>

            );
        }
    }
}

export default Review;
