import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { rejectionReasonsStore } from '../../data/stores/RejectionReasonsStore';
import RejectionReasonsRepo from '../../data/repositories/RejectionReasonsRepo';

class RejectionReasonsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitRejectionReason = async (rejectionReason: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateRejectionReason(rejectionReason);
    }

    return this.createRejectionReason(rejectionReason);
  };

  createRejectionReason = async (rejectionReason: any) => {
    const { create } = RejectionReasonsRepo;

    const [response] = await to(create(rejectionReason));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали разлог одбацивања.'
        });
      }, 1000);
      rejectionReasonsStore.fetch();
      this.closeModalForm();
    }
  };

  updateRejectionReason = async (rejectionReason: any) => {
    const { update } = RejectionReasonsRepo;

    const [response] = await to(update(rejectionReason.id, rejectionReason));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали разлог одбацивања.'
        });
      }, 1000);
      rejectionReasonsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const rejectionReasonsListingStore = new RejectionReasonsListingStore(
  RejectionReasonsRepo
);
export default RejectionReasonsListingStore;
