import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';
import { AnyARecord } from 'dns';

const type = 'office_document_file_uploads';
const update = 'office_document_file_uploads/update';
const updateDocumentType = 'office_document_file_uploads/update-document-type';
const download = 'office_document_file_uploads/download';
const shareDocumentType = 'office_document_file_uploads/share-document';

const uploadOfficeDocumentFile = (
  data: any,
  modelId: any,
  model: string,
  documentTypeId?: any
) => {
  const formData = new FormData();

  formData.append('file', data[0]);
  formData.append('model_id', modelId);
  formData.append('model', model);
  if (documentTypeId !== undefined) {
    formData.append('document_type_id', documentTypeId);
  }

  return axios.post(`/${type}`, formData);
};

const updateOfficeDocumentFile = (data: any, officeDocumentFileId: any) => {
  const formData = new FormData();

  formData.append('file', data[0]);

  return axios.post(`/${update}/${officeDocumentFileId}`, formData);
};

const updateOfficeDocumentType = (
  documentType: any,
  officeDocumentFileId: any
) => {
  const formData = new FormData();

  formData.append('id', officeDocumentFileId);
  formData.append('document_type_id', documentType);

  return axios.post(`/${updateDocumentType}`, formData);
};

const downloadOfficeDocumentFile = (officeDocumentFileId: any) => {
  return axios.get(`${download}/${officeDocumentFileId}`);
};

const getAllOfficeDocumentFileUploads = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const getOfficeDocumentFileUploadsByLitigationId = (
  litigationId: number,
  config?: AxiosRequestConfig
) => {
  return axios.get(
    `${type}?search=model_id:${litigationId};model:Litigation`,
    config
  );
};

const getOfficeDocumentFileUploadsByOtherLitigationId = (
  litigationId: number,
  config?: AxiosRequestConfig
) => {
  return axios.get(
    `${type}?search=model_id:${litigationId};model:OtherLitigation`,
    config
  );
};

const getOfficeDocumentFileUploadsByOfficeDocumentIds = (
  officeDocumentIds: any,
  config?: AxiosRequestConfig
) => {
  return axios.get(
    `${type}?search=model_ids:${officeDocumentIds};model:OfficeDocument`,
    config
  );
};

const shareDocument = (officeDocumentFileId: any, children: any) => {
  const formData = new FormData();

  formData.append('officeDocumentFileId', officeDocumentFileId);
  formData.append('children', children);

  return axios.post(`/${shareDocumentType}`, formData);
};

const OfficeDocumentFileUploadsRepo = new BaseRepo(type);

export default {
  ...OfficeDocumentFileUploadsRepo,
  getAllOfficeDocumentFileUploads,
  updateOfficeDocumentFile,
  updateOfficeDocumentType,
  downloadOfficeDocumentFile,
  uploadOfficeDocumentFile,
  getOfficeDocumentFileUploadsByLitigationId,
  getOfficeDocumentFileUploadsByOtherLitigationId,
  getOfficeDocumentFileUploadsByOfficeDocumentIds,
  shareDocument
};
