import { Formik, Form } from 'formik';
import axios from 'axios';
import React, { Component } from 'react';

class ImportOfficeDocuments extends Component {
  onSubmit = (data: any) => {
    var formData = new FormData();
    formData.append('image', data.importJson);
    axios
      .post('/importDocumentsJson', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => console.log(res));
  };

  render() {
    return (
      <div>
        <h1>Import page</h1>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{ importJson: undefined }}
          render={({
            values,
            setFieldValue,
            errors,
            touched,
            setFieldTouched
          }) => (
            <Form noValidate className="form-horizontal">
              <input
                type="file"
                name="importJson"
                onChange={(event: any) => {
                  setFieldValue('importJson', event.currentTarget.files[0]);
                }}
              />

              <button style={{ marginTop: 30 }} type="submit">
                Import
              </button>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default ImportOfficeDocuments;
