import { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Button, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import { otherLitigationsListingStore } from '../OtherLitigationsListingStore';
import { DropzoneArea } from 'material-ui-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import AttachmentNameCell from '../../../components/TableCells/AttachmentNameCell';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete/VirtualizedAutocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import './style.css';
import { Box, Grid, Switch } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import to from '../../../lib/awaitTo';
import ServerSelect from 'components/ServerSelect';
import { LitigatorType } from '../../../data/types';
import LitigatorTypesRepo from '../../../data/repositories/LitigatorTypesRepo';

import { alpha, styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { pink } from '@mui/material/colors';
import SelectField from 'components/SelectField';
import { observable, action } from 'mobx';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Thumb from 'components/Thumb';
import { litigatorAvatarsStore } from '../../../data/stores/LitigatorAvatarsStore';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import { TextField as TextFIELD } from '@material-ui/core';
import moment from 'moment';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import WorkersRepo from 'data/repositories/WorkersRepo';
import ServerSelectAutocomplete from 'components/ServerSelectAutocomplete';
import PhasesRepo from 'data/repositories/PhasesRepo';
import ProcedureTypesRepo from 'data/repositories/ProcedureTypesRepo';
import ProposalTypesRepo from 'data/repositories/ProposalTypesRepo';
import LegalFormTypesRepo from 'data/repositories/LegalFormTypesRepo';
import LitigationsRepo from 'data/repositories/LitigationsRepo';
import LitigatorStatusesRepo from 'data/repositories/LitigatorStatusesRepo';
import LitigatorsRepo from 'data/repositories/LitigatorsRepo';
import DocumentTypesRepo from 'data/repositories/DocumentTypesRepo';
import ConsentsRepo from 'data/repositories/ConsentsRepo';
import StatusesRepo from 'data/repositories/StatusesRepo';
import RejectionReasonsRepo from 'data/repositories/RejectionReasonsRepo';
import TemplatesRepo from 'data/repositories/TemplatesRepo';
import ConclusionsRepo from 'data/repositories/ConclusionsRepo';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import Menu from '@mui/material/Menu';
import { officeDocumentsListingStore } from '../../OfficeDocumentsListingPage/OfficeDocumentsListingStore';
import DropZone from '../../../components/DropZone';
import DataTable from '../../../components/DataTable';
import { officeDocumentFileUploadsStore } from '../../../data/stores/OfficeDocumentFileUploadsStore';
import LitigationTypesRepo from 'data/repositories/LitigationTypesRepo';
import authStore from 'data/stores/AuthStore';
import { RouteComponentProps } from 'react-router';

interface AddOtherLitigationModalProps {
  className?: string;
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
  backLink?: boolean;
}

const DEFAULT_VALUES = {
  id: '',
  status: '',
  document_type_id: null,
  entry_date: '',
  number: null,
  id_worker: null,
  worker: null,
  procedure_type_id: null,
  procedure_type: null,
  notes: null,
  other: false
};

const GreenSwitch = withStyles({
  colorSecondary: {
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'purple'
    }
  },
  track: {
    backgroundColor: 'blue'
  }
})(Switch);

const columns = [
  {
    Header: '#',
    id: 'id',
    accessor: 'id',
    sortable: false,
    width: 50
  },
  // {
  //   Header: 'Врста документа',
  //   id: 'name',
  //   accessor: (officeDocument: any) => officeDocument,
  //   Cell: AttachmentNameCell,
  //   sortable: false
  // },
  {
    Header: 'Врста документа',
    id: 'name',
    accessor: 'name',
    sortable: false,
    width: 80
  },
  {
    Header: 'Датум',
    id: 'created_at',
    accessor: 'created_at',
    sortable: false,
    width: 80
  },
  {
    Header: 'Време',
    id: 'time',
    accessor: 'time',
    sortable: false,
    width: 80
  },
  {
    Header: 'Тип',
    id: 'type',
    accessor: 'type',
    sortable: false,
    width: 80
  },
  {
    Header: 'Акције',
    accessor: 'actions',
    sortable: false,
    filterable: false,
    width: 260
  }
];

@observer
class AddOtherLitigationModal extends Component<any> {
  @observable switchValue: any = 1;

  @observable anchorEl: any = false;

  @observable otherLitigationsArray: any[] = [];

  @observable open: boolean = Boolean(this.anchorEl);

  @observable documentTypes: any = [];

  @observable templates: any = [];

  @observable documentButtonSwitch: boolean = false;

  @observable allSelectOptions: any = null;

  @action
  setAllSelectOptions = (data: any) => {
    this.allSelectOptions = data;
  };

  fetchDocumentTypesByPhaseId = async () => {
    const { openItem } = this.props;

    let queryParams = openItem ? openItem.procedure_phase_id : '';
    const [res] = await to(
      DocumentTypesRepo.getAll({
        params: { search: `phase_id:${queryParams}` }
      })
    );
    if (res) {
      this.setDocumentTypes(res.data);
    }
  };

  fetchAllTemplates = async () => {
    const [res] = await to(TemplatesRepo.getAll({}));
    if (res) {
      this.setTemplates(res.data);
    }
  };

  async componentWillMount() {
    const allSelectOptions: any = JSON.parse(
      localStorage.getItem('allSelectOptions') || ''
    );

    this.setAllSelectOptions(allSelectOptions);

    const [response] = await to(LitigationsRepo.getAll({}));

    if (response) {
    }

    const [responseAllLitigations] = await to(
      LitigationsRepo.getAllLitigationsWithoutPagination()
    );

    if (responseAllLitigations) {
      this.setOtherLitigationsArray(responseAllLitigations.data);
    }
  }

  componentWillUnmount() {
    litigatorAvatarsStore.clearLitigatorAvatar();
  }

  @action
  setDocumentButtonSwitch = (bool: boolean) => {
    this.documentButtonSwitch = bool;
  };

  @action
  setTemplates = (templates: any) => {
    this.templates = templates;
  };

  @action
  setOtherLitigationsArray = (otherLitigations: any) => {
    this.otherLitigationsArray = otherLitigations;
  };

  @action
  toggleDocumentButtonSwitch = () => {
    this.documentButtonSwitch = !this.documentButtonSwitch;
  };

  @action
  setSwitchValue = (value: any) => {
    this.switchValue = value;
  };

  @action
  setDocumentTypes = (documentTypes: string) => {
    this.documentTypes = documentTypes;
  };

  @action
  setAnchorEl = (anchorEl: null | HTMLElement) => {
    console.log(anchorEl);
    this.anchorEl = anchorEl;
  };

  @action
  setOpen = (bool: any) => {
    this.open = bool;
  };

  onSubmit = (data: any) => {
    otherLitigationsListingStore.updateOtherLitigation(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;
    let hasParent = 0;

    if (openItem && openItem.id) {
      if (openItem.status) {
        this.setSwitchValue(1);
      } else {
        this.setSwitchValue(0);
      }

      officeDocumentFileUploadsStore.setCurrentLitigationId(openItem.id);

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      return {
        ...pickedValues,
        entry_date: moment
          .unix(openItem.entry_date / 1000)
          .format('YYYY-MM-DD'),
        time_date: moment.unix(openItem.entry_date / 1000).format('HH:mm')
      };
    }

    return DEFAULT_VALUES;
  };

  handleChangeSwitchLabel = () => {
    if (this.switchValue) {
      this.setSwitchValue(0);
    } else {
      this.setSwitchValue(1);
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.fetchAllTemplates();
    // this.fetchDocumentTypesByPhaseId();
    this.setAnchorEl(event.currentTarget);
    this.setOpen(true);
  };
  handleClose = () => {
    this.setAnchorEl(null);
    this.setOpen(false);
  };

  itemAction = (officeDocumentFileUpload: any) => (
    <div style={{ display: 'block' }}>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 9999 }}
      >
        <Button
          style={{ color: 'inherit' }}
          onClick={() => {
            officeDocumentsListingStore.deleteOfficeDocumentFileOtherLitigation(
              officeDocumentFileUpload.id
            );
          }}
        >
          <DeleteIcon style={{ fontSize: 24, marginRight: 3 }} />
          Обриши документ
        </Button>
      </div>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 9999 }}
      >
        <Button
          style={{ color: 'inherit' }}
          onClick={() => {
            window.open(officeDocumentFileUpload.link, '_blank');
          }}
        >
          <PrintIcon style={{ fontSize: 24, marginRight: 3 }} />
          Погледај документ
        </Button>
      </div>
    </div>
  );

  render() {
    const { showModal, closeModalForm, className, backLink } = this.props;
    const { history, openItem } = this.props;

    // if (imageLink) {
    //   setImageLink(imageLink);
    // }

    return (
      <div style={{ position: 'relative' }}>
        <Modal
          className={className}
          show={showModal}
          onHide={() => closeModalForm()}
        >
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Измена постојећег предмета
            <div>
              <Button
                onClick={() => this.toggleDocumentButtonSwitch()}
                variant="contained"
                style={{
                  backgroundColor: '#4051B5',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px',
                  marginRight: 15
                }}
              >
                {this.documentButtonSwitch ? (
                  <InsertDriveFileIcon
                    style={{ fontSize: 16, color: 'white' }}
                  />
                ) : (
                  <InsertDriveFileIcon
                    style={{ fontSize: 16, color: 'white' }}
                  />
                )}

                {this.documentButtonSwitch ? 'ДЕТАЉИ' : 'ДОКУМЕНТА'}
              </Button>
              <Button
                onClick={this.handleClick}
                variant="contained"
                style={{
                  backgroundColor: '#E30022',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px'
                }}
              >
                <AddIcon style={{ fontSize: 16, color: 'white' }} /> КРЕИРАЈ
              </Button>
              {/* CREATE MENU */}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={this.anchorEl}
                open={this.open}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {this.templates.map((item: any, index: any) => (
                  <MenuItem
                    onClick={(e: any) => {
                      // let data = openItem;

                      let object = {} as any;
                      if (item.name !== null) {
                        object.name = item.name;
                      } else {
                        object.name = '';
                      }

                      if (openItem.litigation_number !== null) {
                        object.litigation_number = openItem.litigation_number;
                      } else {
                        object.name = '';
                      }

                      if (openItem.procedure_type !== null) {
                        object.procedure_type = openItem.procedure_type.value;
                      } else {
                        object.procedure_type = '';
                      }

                      if (openItem.name_first_party !== null) {
                        object.name_first_party = openItem.name_first_party;
                      } else {
                        object.name_first_party = '';
                      }

                      if (openItem.name_second_party !== null) {
                        object.name_second_party = openItem.name_second_party;
                      } else {
                        object.name_second_party = '';
                      }

                      if (openItem.litigation_type !== null) {
                        object.litigation_type =
                          openItem.litigation_type.litigation_name;
                      } else {
                        object.litigation_type = '';
                      }

                      if (openItem.litigator !== null) {
                        object.litigator = openItem.litigator.document_name;
                      } else {
                        object.litigator = '';
                      }

                      object.document_date_of_input =
                        openItem.document_date_of_input;

                      object.address_first_party_line_1 =
                        openItem.street_first_party +
                        ' ' +
                        openItem.street_number_first_party;

                      object.address_first_party_line_2 =
                        openItem.post_number_first_party +
                        ' ' +
                        openItem.city_first_party +
                        ', ' +
                        openItem.municipality_first_party;

                      axios({
                        url: 'getDownload', //your url
                        method: 'POST',
                        responseType: 'blob', // important
                        data: object
                      }).then(response => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${item.name}.docx`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                      });
                      this.handleClose();
                    }}
                  >
                    {item.order}. {item.name}
                  </MenuItem>
                ))}
              </Menu>
              <Button
                onClick={() => {
                  if (backLink === undefined) {
                    otherLitigationsListingStore.showAddButton();
                    litigatorAvatarsStore.clearLitigatorAvatar();
                    if (this.documentButtonSwitch) {
                      this.toggleDocumentButtonSwitch();
                    }
                    closeModalForm();
                  } else {
                    window.history.back();
                  }
                }}
                variant="contained"
                style={{
                  backgroundColor: '#fb8c00',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px',
                  marginLeft: '15px'
                }}
              >
                <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                НАЗАД
              </Button>
            </div>
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body
                  className="add-other-litigation-div"
                  style={{
                    minHeight: this.documentButtonSwitch ? 'auto' : 620
                  }}
                >
                  {/* Document files */}

                  {/* Litigation data */}
                  <div
                    style={{
                      display: this.documentButtonSwitch ? 'none' : 'block'
                    }}
                  >
                    <Col md={12}>
                      <Row>
                        <Col md={3}>
                          <Row>
                            <Col md={12}>
                              <GreenSwitch
                                onChange={() => {
                                  setFieldValue(
                                    'status',
                                    this.switchValue ? 0 : 1
                                  );
                                  this.handleChangeSwitchLabel();
                                }}
                                // disabled={!this.switchValue}
                                defaultChecked={this.switchValue ? true : false}
                              />

                              {this.switchValue
                                ? 'Поступак у току'
                                : 'Предмет је архивиран'}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={9}></Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              InputProps={{
                                style: { fontSize: 16, width: '100%' }
                              }}
                              label="Датум уписа*"
                              value={values.entry_date}
                              disabled
                              inputFormat="dd.MM.yyyy"
                              onChange={(newValue: any) => {
                                setFieldValue('entry_date', newValue);
                              }}
                              renderInput={(params: any) => (
                                <TextFIELD
                                  variant="standard"
                                  name="entry_date"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                          <Field
                            disabled={true}
                            component={TextField}
                            type="text"
                            label="Време уписа*"
                            variant="standard"
                            name="time_date"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="number"
                            type="text"
                            label="Број предмета*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.id_worker}
                            fullValue={values.worker}
                            showFields={['firstname', 'lastname']}
                            // repo={WorkersRepo}
                            data={this.allSelectOptions.workers}
                            display="name"
                            name="id_worker"
                            fullValueName="worker"
                            valueAttr="id"
                            label="Обрађивач*"
                            queryParams={{ search: 'role_id:4' }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      <Row>
                        <Col md={12}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="notes"
                            type="text"
                            label="Напомене"
                            multiline
                            rows={5}
                            variant="standard"
                            InputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <Button
                      variant="text"
                      style={{
                        fontSize: 14,
                        color: 'black',
                        display: this.documentButtonSwitch ? 'none' : 'flex'
                      }}
                    >
                      <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                      ОБРИШИ
                    </Button>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      onClick={() => {
                        otherLitigationsListingStore.showAddButton();
                      }}
                      type="submit"
                      variant="text"
                      style={{
                        fontSize: 14,
                        color: 'black',
                        display: this.documentButtonSwitch ? 'none' : 'flex'
                      }}
                    >
                      <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                      САЧУВАЈ
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          />
          <div
            style={{
              display: this.documentButtonSwitch ? 'block' : 'none',
              padding: '0 20px',
              minHeight: '570px'
            }}
          >
            <DropZone
              modelType="OtherLitigation"
              id={officeDocumentFileUploadsStore.currentLitigationId}
              css={{ marginBottom: '10px' }}
              title={`Превуците документ овде, или кликните како бисте га одабрали`}
              subtitle={``}
              multiple={false}
              method={officeDocumentsListingStore.uploadOfficeDocumentFile}
            />

            <DataTable
              enableCaching={false}
              // getTdProps={this.getTdProps}
              minRows={5}
              columns={columns}
              fetchData={officeDocumentsListingStore.fetchByOtherLitigationId}
              data={officeDocumentsListingStore.attachCustomColumn(
                'actions',
                this.itemAction
              )}
              loading={officeDocumentsListingStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />

            {/* <OfficeDocumentsFileUploadsDataTable
                      litigationId={values.id}
                    /> */}
          </div>
        </Modal>
        {/* <Dialog
          open={this.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Да ли сте сигурни да желите да трајно обришете ову шему
              докумената? Трајно обрисане податке је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Не, одустани</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default AddOtherLitigationModal;
