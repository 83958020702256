import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'proposal_types';

const getAllProposalTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const ProposalTypesRepo = new BaseRepo(type);

export default {
  ...ProposalTypesRepo,
  getAllProposalTypes
};
