import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import StrikeMethodsRepo from '../repositories/StrikeMethodsRepo';

class StrikeMethodsStore extends TableStore<any> {
  @observable strikeMethods: any;

  @action
  setStrikeMethods = (data: any) => {
    this.strikeMethods = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StrikeMethodsRepo.getAllStrikeMethods({}));
    if (res) {
      this.setStrikeMethods(res.data);
    }
  };
}

export const strikeMethodsStore = new StrikeMethodsStore(StrikeMethodsRepo);
export default strikeMethodsStore;
