import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';
import { stateToHTML } from 'draft-js-export-html';
import { forEach } from 'lodash';

const type = 'litigators';

const parseRequest = (data: any) => {
  let request = {} as any;

  forEach(data, (field: any, key: any) => {
    if (key === 'biography' && field) {
      request.biography = stateToHTML(field.getCurrentContent());
    } else {
      request[key] = field;
    }
  });

  return request;
};

const getAllLitigators = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const LitigatorsRepo = new BaseRepo(type, {
  create: parseRequest,
  update: parseRequest
});

export default {
  ...LitigatorsRepo,
  getAllLitigators
};
