import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';
import { stateToHTML } from 'draft-js-export-html';

const type = 'users';

export const parseRequest = (formData: any) => {
  const { additional_info } = formData;

  const dataToReturn: any = { ...formData };

  if (additional_info) {
    dataToReturn.additional_info = stateToHTML(
      additional_info.getCurrentContent()
    );
  }

  return dataToReturn;
};

const getAllWorkers = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const WorkersRepo = new BaseRepo(type);

export default {
  ...WorkersRepo,
  getAllWorkers
};
