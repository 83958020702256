import { Component } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Table } from 'react-bootstrap';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, TextField, Button, Paper } from '@mui/material';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import moment from 'moment';

import SearchResultsComponent from 'components/SearchResultsComponent';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import { Form, Formik } from 'formik';
import { filterReportsStore } from './FilterReportsStore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {
  showFilterField,
  exportToExcel,
  formikKeys,
  arrayNameOfTypes
} from './helper';

const DEFAULT_VALUES = {
  dateFrom: moment()
    .subtract(30, 'days')
    .format('DD MMM YYYY'),
  dateTo: moment().format('DD MMM YYYY'),
  proposal_type_id: '',
  city_first_party: '',
  city_second_party: '',
  conciliator_choosing_way_id: '',
  conciliator_id: '',
  consent_id: '',
  finalizing_procedure_way_id: '',
  rejection_reason_id: '',
  conclusion_id: ''
};

@observer
class FilterReportsPage extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      filterName: '',
      choseFilterVisible: true,
      disableFilterSelect: false
    };
  }

  onSubmit = (data: any) => {
    const { requestReport } = filterReportsStore;

    requestReport(data);
  };

  rerenderChoseFilter = () => {
    this.setState({
      filterName: '',
      choseFilterVisible: false,
      disableFilterSelect: false
    });

    setTimeout(() => {
      this.setState({
        filterName: '',
        choseFilterVisible: true,
        disableFilterSelect: false
      });
    }, 500);
  };

  renderChips(data: any, setFieldValue: any) {
    const { addFilter } = filterReportsStore;

    const isValidDate = (dateString: any) => {
      const date = new Date(dateString);
      return !isNaN(date.getTime());
    };

    const allSelectOptions: any = JSON.parse(
      localStorage.getItem('allSelectOptions') || ''
    );

    const filteredObject = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== null && value !== undefined && value !== ''
      )
    );

    const nameOfValue = (idOrString: any, array: any[]) => {
      if (isNaN(Number(idOrString)) || !array) {
        if (isValidDate(idOrString))
          return moment(idOrString).format('DD MMM YYYY');

        return idOrString;
      }

      const findObject = array.find((type) => type.id === idOrString);

      if (findObject) {
        if (findObject.name) return findObject.name;
        if (findObject.litigation_name) return findObject.litigation_name;
        if (findObject.litigator_firstname && findObject.litigator_lastname)
          return `${findObject.litigator_firstname} ${findObject.litigator_lastname}`;
      }

      return '';
    };

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingLeft: '15px',
          gap: '20px',
          alignItems: 'baseline',
          marginBottom: '10px'
        }}
      >
        <p>Филтери подешени за претрагу:</p>
        <Stack
          direction="row"
          spacing={1}
          style={{ flexWrap: 'wrap', rowGap: '8px' }}
        >
          {Object.entries(filteredObject).map(([key, value]) => {
            return (
              <Chip
                disabled={key === 'dateFrom' || key === 'dateTo'}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  color: 'rgba(0, 0, 0, 0.87)',
                  opacity: 1
                }}
                key={key}
                label={`${formikKeys[key]}: ${nameOfValue(
                  value as string,
                  allSelectOptions[arrayNameOfTypes[key as string]]
                )}`}
                onDelete={() => {
                  // Handle chip deletion if needed
                  setFieldValue(key, '');
                  addFilter(formikKeys[key]);
                  this.rerenderChoseFilter();
                }}
              />
            );
          })}
        </Stack>
      </div>
    );
  }

  render() {
    const allSelectOptions: any = JSON.parse(
      localStorage.getItem('allSelectOptions') || ''
    );

    const { tableData, remainingFilters, removeFilter } = filterReportsStore;
    const {
      filterName,
      choseFilterVisible,
      disableFilterSelect
    }: any = this.state;

    const disableField = () => {
      this.setState({
        ...this.state,
        disableFilterSelect: true
      });
    };

    return (
      <SearchResultsComponent type="active">
        <Box className="account-div" style={{ padding: '10px' }}>
          <Paper>
            <Box
              style={{
                height: 'auto',
                minHeight: '60px',
                width: '100%',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px'
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: '16px',
                  color: '#888'
                }}
              >
                Извештаји
              </h3>

              <div
                style={{
                  display: 'flex',
                  gap: '20px'
                }}
              >
                <Button
                  onClick={() => exportToExcel()}
                  style={{
                    // marginTop: '20px',
                    backgroundColor: '#4051B5',
                    fontSize: 14,
                    fontWeight: 400,
                    color: 'white',
                    width: '205px',
                    height: '36px',
                    borderRadius: '4px'
                  }}
                >
                  Преузими податаке
                </Button>
                <Button
                  onClick={() => {
                    window.location = '/dashboard/litigations' as any;
                  }}
                  variant="contained"
                  style={{
                    backgroundColor: '#fb8c00',
                    fontSize: 14,
                    fontWeight: 400,
                    color: 'white',
                    width: '205px',
                    height: '36px',
                    borderRadius: '4px'
                  }}
                >
                  <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                  НАЗАД
                </Button>
              </div>
            </Box>

            <Formik
              onSubmit={this.onSubmit}
              initialValues={DEFAULT_VALUES}
              render={({ values, setFieldValue }) => (
                <Form noValidate className="form-horizontal">
                  <Box style={{ padding: 30 }}>
                    <Row>
                      <Col md={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            InputProps={{
                              style: { fontSize: 16, width: '100%' }
                            }}
                            label="Од*"
                            value={values.dateFrom}
                            inputFormat="dd.MM.yyyy"
                            onChange={(newValue: any) =>
                              setFieldValue('dateFrom', newValue)
                            }
                            renderInput={(params: any) => (
                              <TextField
                                variant="standard"
                                name="document_date_of_input"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            InputProps={{
                              style: { fontSize: 16, width: '100%' }
                            }}
                            label="До*"
                            value={values.dateTo}
                            inputFormat="dd.MM.yyyy"
                            onChange={(newValue: any) =>
                              setFieldValue('dateTo', newValue)
                            }
                            renderInput={(params: any) => (
                              <TextField
                                variant="standard"
                                name="document_date_of_input"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>

                        {choseFilterVisible && (
                          <CustomMaterialUiSelect
                            setFieldValue={() => {}}
                            customOnChange={(e: string) => {
                              this.setState({
                                filterName: e
                              });
                            }}
                            value={'id'}
                            display="id"
                            data={remainingFilters.filter(
                              (filter: any) => filter.visible
                            )}
                            valueAttr="id"
                            label="Изаберите филтер"
                            disabled={disableFilterSelect}
                          />
                        )}

                        {showFilterField(
                          filterName,
                          values,
                          setFieldValue,
                          allSelectOptions,
                          disableField
                        )}

                        <div
                          style={{
                            display: 'flex',
                            gap: '20px',
                            flexWrap: 'wrap',
                            margin: '40px 0'
                          }}
                        >
                          <Button
                            onClick={() => {
                              this.setState({ filterName: '' });
                              removeFilter(filterName);
                              this.rerenderChoseFilter();
                            }}
                            style={{
                              // marginTop: '40px',
                              backgroundColor: '#8400e3',
                              fontSize: 14,
                              fontWeight: 400,
                              color: 'white',
                              width: '205px',
                              height: '36px',
                              borderRadius: '4px'
                            }}
                          >
                            Додај филтер
                          </Button>
                          <Button
                            type="submit"
                            style={{
                              // marginTop: '40px',
                              backgroundColor: '#E30022',
                              fontSize: 14,
                              fontWeight: 400,
                              color: 'white',
                              width: '205px',
                              height: '36px',
                              borderRadius: '4px'
                            }}
                          >
                            Претражи
                          </Button>
                        </div>
                      </Col>

                      {this.renderChips(values, setFieldValue)}

                      {!tableData && (
                        <Col md={9}>
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: '50px',
                              color: 'rgb(133, 133, 133)'
                            }}
                          >
                            Користите дугме претражи
                          </div>
                        </Col>
                      )}
                      {tableData && (
                        <Col md={9}>
                          <Paper>
                            <Box
                              style={{
                                height: 'auto',
                                minHeight: '60px',
                                width: '100%',
                                backgroundColor: '#EEEEEE',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px'
                              }}
                            >
                              <h3
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  color: '#888'
                                }}
                              >
                                ИНДИВИДУАЛНИ СПОРОВИ
                              </h3>
                            </Box>
                            <Table hover>
                              <thead>
                                <tr>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Предмет спора
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Број поднетих предлога
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Поступци у току
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Решено у управном поступку
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Мериторно решено
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData
                                  .filter((item: any) => {
                                    return item.type === 'individual';
                                  })
                                  .map((element: any) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            maxWidth: '400px',
                                            width: '400px',
                                            fontWeight:
                                              element.subject ===
                                              'Укупно индивидуалних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.subject}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно индивидуалних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.broj_podnetih_predloga}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно индивидуалних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.postupci_u_toku}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно индивидуалних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.upravni_postupak}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно индивидуалних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.meritorno_reseno}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Paper>

                          <Paper>
                            <Box
                              style={{
                                height: 'auto',
                                minHeight: '60px',
                                width: '100%',
                                backgroundColor: '#EEEEEE',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px'
                              }}
                            >
                              <h3
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  color: '#888'
                                }}
                              >
                                КОЛЕКТИВНИ СПОРОВИ
                              </h3>
                            </Box>
                            <Table hover>
                              <thead>
                                <tr>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Предмет спора
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Број поднетих предлога
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Поступци у току
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Решено у управном поступку
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Мериторно решено
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData
                                  .filter((item: any) => {
                                    return item.type === 'collective';
                                  })
                                  .map((element: any) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            maxWidth: '400px',
                                            width: '400px',
                                            fontWeight:
                                              element.subject ===
                                              'Укупно колективних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.subject}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно колективних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.broj_podnetih_predloga}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно колективних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.postupci_u_toku}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно колективних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.upravni_postupak}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject ===
                                              'Укупно колективних'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.meritorno_reseno}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Paper>

                          <Paper>
                            <Box
                              style={{
                                height: 'auto',
                                minHeight: '60px',
                                width: '100%',
                                backgroundColor: '#EEEEEE',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px'
                              }}
                            >
                              <h3
                                style={{
                                  margin: 0,
                                  fontSize: '16px',
                                  color: '#888'
                                }}
                              >
                                УКУПАН БРОЈ ПОСТУПАКА ПРЕД АГЕНЦИЈОМ
                              </h3>
                            </Box>
                            <Table hover>
                              <thead>
                                <tr>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Предмет спора
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Број поднетих предлога
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Поступци у току
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Решено у управном поступку
                                  </td>
                                  <td
                                    style={{
                                      color: 'rgba(0,0,0,.6)',
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Мериторно решено
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData
                                  .filter((item: any) => {
                                    return item.type === 'total';
                                  })
                                  .map((element: any) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            maxWidth: '400px',
                                            width: '400px',
                                            fontWeight:
                                              element.subject === 'Укупно'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.subject}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject === 'Укупно'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.broj_podnetih_predloga}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject === 'Укупно'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.postupci_u_toku}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject === 'Укупно'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.upravni_postupak}
                                        </td>
                                        <td
                                          style={{
                                            fontWeight:
                                              element.subject === 'Укупно'
                                                ? 600
                                                : 300
                                          }}
                                        >
                                          {element.meritorno_reseno}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Paper>
                        </Col>
                      )}
                    </Row>
                  </Box>
                </Form>
              )}
            />
          </Paper>
        </Box>
      </SearchResultsComponent>
    );
  }
}

export default observer(FilterReportsPage);
