import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import GasExpenseTypesRepo from '../../data/repositories/GasExpenseTypesRepo';
import { gasExpenseTypesStore } from '../../data/stores/GasExpenseTypesStore';

class GasExpenseTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createGasExpenseType = async (gasExpenseType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateGasExpenseType(gasExpenseType);
    }

    return this.saveGasExpenseType(gasExpenseType);
  };

  saveGasExpenseType = async (expenseType: any) => {
    const { create } = GasExpenseTypesRepo;

    const [response] = await to(create(expenseType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали цену горива.'
        });
      }, 1000);
      gasExpenseTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateGasExpenseType = async (gasExpenseType: any) => {
    const { update } = GasExpenseTypesRepo;

    const [response] = await to(update(gasExpenseType.id, gasExpenseType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали цену горива.'
        });
      }, 1000);
      gasExpenseTypesStore.fetch();
      this.closeModalForm();
    }
  };

  deleteGasExpenseType = async (gasExpenseTypeId: any) => {
    const [response] = await to(GasExpenseTypesRepo.delete(gasExpenseTypeId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали цену горива.'
        });
      }, 1000);
      gasExpenseTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const gasExpenseTypesListingStore = new GasExpenseTypesListingStore(
  GasExpenseTypesRepo
);
export default GasExpenseTypesListingStore;
