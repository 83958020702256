import { Card } from '@material-ui/core';
import { Box, CardContent, Button, TextField } from '@mui/material';
import { authStore } from 'data/stores/AuthStore';
import { Redirect } from 'react-router';
import { LoginParams } from 'data/types';
import { Field, Form, Formik } from 'formik';
import { Component } from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import { observer } from 'mobx-react';
import ErrorMessage from 'components/ErrorMessage';

@observer
class Login extends Component {
  onSubmit = (data: LoginParams) => {
    authStore.login(data);
  };

  render() {
    const { loggedIn, invalidLogin, errorMessage } = authStore;

    if (loggedIn) {
      return <Redirect to="/dashboard/litigations" />;
    }

    return (
      <AuthLayout>
        <Box
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box style={{ width: '600px', height: 'auto' }}>
            <Card>
              <CardContent
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ width: '145px' }}>
                  <img
                    style={{ width: '100%' }}
                    src="grb.png"
                    alt="logo"
                  />
                </div>
                <div style={{ width: 'calc(100% - 145px)' }}>
                  <Formik
                    onSubmit={this.onSubmit}
                    initialValues={{
                      email: '',
                      password: '',
                      rememberMe: false
                    }}
                    render={({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      setFieldTouched
                    }) => (
                      <Form noValidate className="form-horizontal">
                        <Field
                          component={TextField}
                          name="email"
                          type="text"
                          onChange={(e: any) => {
                            setFieldValue('email', e.currentTarget.value);
                          }}
                          label="Ваша електронска адреса*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                        <Field
                          component={TextField}
                          name="password"
                          type="password"
                          onChange={(e: any) => {
                            setFieldValue('password', e.currentTarget.value);
                          }}
                          label="Ваша лозинка*"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                        <ErrorMessage
                          message={errorMessage}
                          show={invalidLogin}
                        />
                        <Button
                          variant="contained"
                          type="submit"
                          style={{
                            padding: '5px 80px',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontWeight: 400,
                            marginBottom: '0px',
                            width: '100%',
                            marginTop: '20px',
                            backgroundColor: '#3f51b5'
                          }}
                        >
                          ПРИЈАВИТЕ СЕ
                        </Button>
                      </Form>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </AuthLayout>
    );
  }
}

export default Login;
