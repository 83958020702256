import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { procedureTypesStore } from '../../data/stores/ProcedureTypesStore';
import ProcedureTypesRepo from '../../data/repositories/ProcedureTypesRepo';

class ProcedureTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitProcedureType = async (procedureType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateProcedureType(procedureType);
    }

    return this.createProcedureType(procedureType);
  };

  createProcedureType = async (procedureType: any) => {
    const { create } = ProcedureTypesRepo;

    const [response] = await to(create(procedureType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту поступка.'
        });
      }, 1000);
      procedureTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateProcedureType = async (procedureType: any) => {
    const { update } = ProcedureTypesRepo;

    const [response] = await to(update(procedureType.id, procedureType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту поступка.'
        });
      }, 1000);
      procedureTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const procedureTypesListingStore = new ProcedureTypesListingStore(
  ProcedureTypesRepo
);
export default ProcedureTypesListingStore;
