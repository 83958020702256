import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from './components/RouteWithSubRoutes';
import routes from './routes';
import PageNotFound from '../src/pages/PageNotFound';
import LoadingBar from 'components/LoadingBar/LoadingBar';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <LoadingBar />
        <Switch>
          <Redirect exact from="/" to="/login" />
          {routes.map(route => (
            <RouteWithSubRoutes {...route} />
          ))}
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
