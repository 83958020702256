import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LitigationsRepo from '../../data/repositories/LitigationsRepo';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import { litigationDetailStore } from 'pages/LitigationDetailPage/LitigationDetailStore';

class LitigationsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;
  @observable litigationsArray: any[] = [];

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  setLitigationsArray = (litigations: any) => {
    this.litigationsArray = litigations;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createLitigation = async (litigation: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLitigation(litigation);
    }

    return this.saveLitigation(litigation);
  };

  saveLitigation = async (litigation: any) => {
    const { create } = LitigationsRepo;

    const [response] = await to(create(litigation));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали предмет.'
        });
      }, 1000);
      litigationsStore.fetch();
      this.closeModalForm();
    }
  };

  updateLitigation = async (litigation: any) => {
    const [response] = await to(
      LitigationsRepo.update(litigation.id, litigation)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали предмет.'
        });
      }, 1000);

      // fetch updated litigations for dropdown
      const [responseAllLitigations] = await to(
        LitigationsRepo.getAllLitigationsWithoutPagination()
      );

      if (responseAllLitigations) {
        litigationsListingStore.setLitigationsArray(
          responseAllLitigations.data
        );
      }

      litigationDetailStore.fetchOne(litigation.id);
      litigationsStore.fetchAll();
    }
  };

  checkForExistingLitigation = async (litigation: any) => {
    const [response] = await to(
      LitigationsRepo.checkExisting(litigation)
    );

    if (response) {
      if (response.data) {
        return response.data;
      }

      // setTimeout(() => {
      //   Toast.fire({
      //     type: 'success',
      //     title: 'Успешно сте ажурирали предмет.'
      //   });
      // }, 1000);

      // // fetch updated litigations for dropdown
      // const [responseAllLitigations] = await to(
      //   LitigationsRepo.getAllLitigationsWithoutPagination()
      // );

      // if (responseAllLitigations) {
      //   litigationsListingStore.setLitigationsArray(
      //     responseAllLitigations.data
      //   );
      // }

      // litigationDetailStore.fetchOne(litigation.id);
      // litigationsStore.fetchAll();
    }
  }
}

export const litigationsListingStore = new LitigationsListingStore(
  LitigationsRepo
);
export default litigationsListingStore;
