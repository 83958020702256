import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { strikeCessationsStore } from '../../data/stores/StrikeCessationsStore';
import StrikeCessationsRepo from '../../data/repositories/StrikeCessationsRepo';

class StrikeCessationsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitStrikeCessation = async (strikeCessation: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStrikeCessation(strikeCessation);
    }

    return this.createStrikeCessation(strikeCessation);
  };

  createStrikeCessation = async (strikeCessation: any) => {
    const { create } = StrikeCessationsRepo;

    const [response] = await to(create(strikeCessation));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали начин престанка штрајка.'
        });
      }, 1000);
      strikeCessationsStore.fetch();
      this.closeModalForm();
    }
  };

  updateStrikeCessation = async (strikeCessation: any) => {
    const { update } = StrikeCessationsRepo;

    const [response] = await to(update(strikeCessation.id, strikeCessation));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали начин престанка штрајка.'
        });
      }, 1000);
      strikeCessationsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const strikeCessationsListingStore = new StrikeCessationsListingStore(
  StrikeCessationsRepo
);
export default strikeCessationsListingStore;
