import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as XLSX from 'xlsx';
import AddLitigatorModal from './components/AddLitigatorModal';
import { litigatorsStore } from '../../data/stores/LitigatorsStore';
import { litigatorsListingStore } from './LitigatorsListingStore';
import LitigatorTypeCell from 'components/TableCells/LitigatorTypeCell';
import LitigatorFullNameCellWithAvatar from 'components/TableCells/LitigatorFullNameCellWithAvatar';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import { RowInfo } from 'react-table';
import SearchResultsComponent from 'components/SearchResultsComponent';
import authStore from 'data/stores/AuthStore';

@observer
class LitigatorsListingPage extends Component {
  componentDidMount() {
    litigatorsStore.fetchAll();
  }

  showAddLitigatorModal = () => {
    litigatorsListingStore.hideAddButton();
    litigatorsListingStore.openModalForm();
  };

  hideAddLitigatorModal = () => {
    litigatorsListingStore.showAddButton();
    litigatorsListingStore.closeModalForm();
  };

  exportExcel = () => {
    let data = litigatorsStore.data;
    let updatedData = data.map(item => {

      let updatedItem = { ...item };
      updatedItem.litigator_type = item.litigator_type.name;  // Update the litigator_type field
      return updatedItem;

    });


    const ws = XLSX.utils.json_to_sheet(updatedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Миритељи');

    // Define the filename and file type
    const filename = 'миритељи-арбитри-експорт.xlsx';

    // Create a Blob to save the workbook
    XLSX.writeFile(wb, filename);
  }

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          litigatorsListingStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible } = litigatorsListingStore;
    const { authUser } = authStore;
    let columns;
    console.log(authUser.roles[0].name)
    if (authUser.roles[0].name === 'Мастер администратор') {
      columns = [
        {
          Header: 'Назив миритеља односно арбитра',
          id: 'litigator_firstname',
          accessor: (litigator: any) => litigator,
          Cell: LitigatorFullNameCellWithAvatar,
          sortable: false,
          filterable: true
        },
        {
          Header: 'Миритељ/Арбитар',
          id: 'litigator_type.name',
          accessor: 'litigator_type.name',
          sortable: false,
          filterable: true
        },
        {
          Header: 'Приватни телефон',
          accessor: 'private_phone',
          sortable: false,
          filterable: false
        },
        {
          Header: 'Електронска пошта',
          accessor: 'email',
          sortable: false,
          filterable: false
        },
        {
          Header: 'Бр. решења уписа у именик',
          accessor: 'directory_number',
          sortable: false,
          filterable: true
        },
        {
          Header: 'Просечна оцена',
          accessor: 'rating',
          sortable: false,
          filterable: false
        },
        {
          Header: 'Град',
          accessor: 'place',
          sortable: false,
          filterable: true
        }
      ];
    } else {
      columns = [
        {
          Header: 'Назив миритеља односно арбитра',
          id: 'litigator_firstname',
          accessor: (litigator: any) => litigator,
          Cell: LitigatorFullNameCellWithAvatar,
          sortable: false,
          filterable: true
        },
        {
          Header: 'Миритељ/Арбитар',
          id: 'litigator_type.name',
          accessor: 'litigator_type.name',
          sortable: false,
          filterable: true
        },
        {
          Header: 'Приватни телефон',
          accessor: 'private_phone',
          sortable: false,
          filterable: false
        },
        {
          Header: 'Електронска пошта',
          accessor: 'email',
          sortable: false,
          filterable: false
        },
        {
          Header: 'Бр. решења уписа у именик',
          accessor: 'directory_number',
          sortable: false,
          filterable: true
        },
        {
          Header: 'Град',
          accessor: 'place',
          sortable: false,
          filterable: true
        }
      ];
    }


    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            variant="contained"
            onClick={this.exportExcel}
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px',
              borderRadius: '4px',
              backgroundColor: '#FB8C00',
              color: 'white'
            }}>Извоз</Button>
          <Button
            onClick={() => this.showAddLitigatorModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px',
              borderRadius: '4px',
              backgroundColor: '#1976d2',
              color: 'white'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <h4>Укупно {litigatorsStore.data.length}</h4>
            <DataTable
              enableCaching={false}
              columns={columns}
              showPaginationBottom={false}
              fetchData={litigatorsStore.fetch}
              data={litigatorsStore.data}
              getTdProps={this.getTdProps}
              loading={litigatorsStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />
            <AddLitigatorModal
              showModal={litigatorsListingStore.showModal}
              closeModalForm={this.hideAddLitigatorModal}
              openItem={litigatorsListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default LitigatorsListingPage;
