import { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import { strikesListingStore } from '../StrikesListingStore';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import './style.css';
import to from '../../../lib/awaitTo';
import { observable, action } from 'mobx';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { litigatorAvatarsStore } from '../../../data/stores/LitigatorAvatarsStore';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextFIELD from '@mui/material/TextField';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ServerSelectAutocomplete from 'components/ServerSelectAutocomplete';
import StrikesRepo from 'data/repositories/StrikesRepo';
import DocumentTypesRepo from 'data/repositories/DocumentTypesRepo';
import TemplatesRepo from 'data/repositories/TemplatesRepo';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import { officeDocumentsListingStore } from '../../OfficeDocumentsListingPage/OfficeDocumentsListingStore';
import DropZone from '../../../components/DropZone';
import DataTable from '../../../components/DataTable';
import { officeDocumentFileUploadsStore } from '../../../data/stores/OfficeDocumentFileUploadsStore';

interface AddStrikeModalProps {
  className?: string;
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
  backLink?: boolean;
}

const DEFAULT_VALUES = {
  id: '',
  document_type_id: null,
  document_date_of_input: '',
  strike_number: null,
  working_on_user_id: null,
  worker: null,
  strike_representative: null,
  notes: '',
  conciliator_choosing_way_id: null,
  conciliator_id: null,
  litigator: null,
  strike_type: null,
  strike_type_id: null,
  participants_requests: null,
  litigator_id: null,
  isParent: false,
  legal_form_first_party_id: null,
  legal_form_first_party: null,
  legal_form_second_party_id: null,
  legal_form_second_party: null,
  municipality_first_party: '',
  municipality_second_party: '',
  name_first_party: '',
  name_second_party: '',
  street_first_party: '',
  street_second_party: '',
  phone_first_party: '',
  phone_second_party: '',
  street_number_first_party: '',
  street_number_second_party: '',
  city_first_party_id: null,
  city_first_party: null,
  city_second_party_id: null,
  city_second_party: null,
  post_number_first_party: null,
  post_number_second_party: null,
  status: ''
};

const columns = [
  {
    Header: '#',
    id: 'id',
    accessor: 'id',
    sortable: false,
    width: 50
  },
  {
    Header: 'Датум захтева',
    id: 'created_at',
    accessor: 'created_at',
    sortable: false,
    width: 80
  },
  {
    Header: 'Датум покретања',
    id: 'strike_begin_date',
    accessor: 'strike_begin_date',
    sortable: false,
    width: 80
  },
  {
    Header: 'Прва страна',
    id: 'first_side',
    accessor: 'first_side',
    sortable: false,
    width: 80
  },
  {
    Header: 'Друга страна',
    id: 'second_side',
    accessor: 'second_side',
    sortable: false,
    width: 80
  },
  {
    Header: 'Врста штрајка',
    id: 'strike_type',
    accessor: (strike: any) => strike,
    sortable: false
  },
  {
    Header: 'Миритељ и арбитар',
    id: 'litigator',
    accessor: 'litigator',
    sortable: false,
    width: 80
  },
  {
    Header: 'Обрађивач',
    id: 'worker',
    accessor: 'worker',
    sortable: false,
    width: 80
  }
];

@observer
class AddStrikeModal extends Component<AddStrikeModalProps> {
  @observable switchValue: any = 1;

  @observable createdSwitch: boolean = true;

  @observable isParent: any = 0;

  @observable hasParent: any = 0;

  @observable fillFirstSide: any = 0;

  @observable fillSecondSide: any = 0;

  @observable parent_id: any = null;

  @observable parent_id_litigation_number: any = null;

  @observable paymentStatus: any = 0;

  @observable anchorEl: any = false;

  @observable strikesArray: any[] = [];

  @observable open: boolean = Boolean(this.anchorEl);

  @observable documentTypes: any = [];

  @observable templates: any = [];

  @observable documentButtonSwitch: boolean = false;

  @observable allSelectOptions: any = null;

  @action
  setAllSelectOptions = (data: any) => {
    this.allSelectOptions = data;
  };

  @action
  setCreatedSwitch = (bool: boolean) => {
    this.createdSwitch = bool;
  };

  fetchDocumentTypesByPhaseId = async () => {
    const { openItem } = this.props;

    let queryParams = openItem ? openItem.procedure_phase_id : '';
    const [res] = await to(
      DocumentTypesRepo.getAll({
        params: { search: `phase_id:${queryParams}` }
      })
    );
    if (res) {
      this.setDocumentTypes(res.data);
    }
  };

  fetchAllTemplates = async () => {
    const [res] = await to(TemplatesRepo.getAll({}));
    if (res) {
      this.setTemplates(res.data);
    }
  };

  async componentWillMount() {
    const allSelectOptions: any = JSON.parse(
      localStorage.getItem('allSelectOptions') || ''
    );

    this.setAllSelectOptions(allSelectOptions);

    const [response] = await to(StrikesRepo.getAll({}));

    if (response) {
    }

    const [responseAllStrikes] = await to(
      StrikesRepo.getAllStrikesWithoutPagination()
    );

    if (responseAllStrikes) {
      this.setStrikesArray(responseAllStrikes.data);
    }
  }

  componentWillUnmount() {
    litigatorAvatarsStore.clearLitigatorAvatar();
  }

  @action
  setDocumentButtonSwitch = (bool: boolean) => {
    this.documentButtonSwitch = bool;
  };

  @action
  setTemplates = (templates: any) => {
    this.templates = templates;
  };

  @action
  setStrikesArray = (strikes: any) => {
    this.strikesArray = strikes;
  };

  @action
  toggleDocumentButtonSwitch = () => {
    this.documentButtonSwitch = !this.documentButtonSwitch;
  };

  @action
  setSwitchValue = (value: any) => {
    this.switchValue = value;
  };

  @action
  setDocumentTypes = (documentTypes: string) => {
    this.documentTypes = documentTypes;
  };

  @action
  setAnchorEl = (anchorEl: null | HTMLElement) => {
    this.anchorEl = anchorEl;
  };

  @action
  setOpen = (bool: any) => {
    this.open = bool;
  };

  onSubmit = (data: any) => {
    strikesListingStore.updateStrike(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;

    if (openItem && openItem.id) {
      if (openItem.status) {
        this.setSwitchValue(1);
      } else {
        this.setSwitchValue(0);
      }

      officeDocumentFileUploadsStore.setCurrentLitigationId(openItem.id);

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      return {
        ...pickedValues,
        document_time_of_input: moment(openItem.document_date_of_input).format(
          'HH:mm:ss'
        )
      };
    }

    return DEFAULT_VALUES;
  };

  handleChangeSwitchLabel = () => {
    if (this.switchValue) {
      this.setSwitchValue(0);
    } else {
      this.setSwitchValue(1);
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.fetchAllTemplates();
    this.setAnchorEl(event.currentTarget);
    this.setOpen(true);
  };
  handleClose = () => {
    this.setAnchorEl(null);
    this.setOpen(false);
  };

  itemAction = (officeDocumentFileUpload: any) => (
    <div style={{ display: 'block' }}>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 9999 }}
      >
        <Button
          style={{ color: 'inherit' }}
          onClick={() => {
            this.createdSwitch
              ? officeDocumentsListingStore.deleteOfficeDocumentFileScanned(
                  officeDocumentFileUpload.id
                )
              : officeDocumentsListingStore.deleteOfficeDocumentFileCreated(
                  officeDocumentFileUpload.id
                );
          }}
        >
          <DeleteIcon style={{ fontSize: 24, marginRight: 3 }} />
          Обриши документ
        </Button>
      </div>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 9999 }}
      >
        <Button
          style={{ color: 'inherit' }}
          onClick={() => {
            window.open(officeDocumentFileUpload.link, '_blank');
          }}
        >
          <PrintIcon style={{ fontSize: 24, marginRight: 3 }} />
          Погледај документ
        </Button>
      </div>
    </div>
  );

  render() {
    const { showModal, closeModalForm, className, backLink } = this.props;

    // if (imageLink) {
    //   setImageLink(imageLink);
    // }

    return (
      <div style={{ position: 'relative' }}>
        <Modal className={className} show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Измена постојећег предмета
            <div>
              <Button
                onClick={() => this.toggleDocumentButtonSwitch()}
                variant="contained"
                style={{
                  backgroundColor: '#4051B5',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px',
                  marginRight: 15
                }}
              >
                {this.documentButtonSwitch ? (
                  <InsertDriveFileIcon
                    style={{ fontSize: 16, color: 'white' }}
                  />
                ) : (
                  <InsertDriveFileIcon
                    style={{ fontSize: 16, color: 'white' }}
                  />
                )}

                {this.documentButtonSwitch ? 'ДЕТАЉИ' : 'ДОКУМЕНТА'}
              </Button>
              <Button
                onClick={() => {
                  if (backLink === undefined) {
                    strikesListingStore.showAddButton();
                    litigatorAvatarsStore.clearLitigatorAvatar();
                    if (this.documentButtonSwitch) {
                      this.toggleDocumentButtonSwitch();
                    }
                    closeModalForm();
                  } else {
                    window.history.back();
                  }
                }}
                variant="contained"
                style={{
                  backgroundColor: '#fb8c00',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px',
                  marginLeft: '15px'
                }}
              >
                <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                НАЗАД
              </Button>
            </div>
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body className="add-litigation-div">
                  {/* Document files */}

                  {/* Litigation data */}
                  <div
                    style={{
                      display: this.documentButtonSwitch ? 'none' : 'block'
                    }}
                  >
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              InputProps={{
                                style: { fontSize: 16, width: '100%' }
                              }}
                              label="Датум уписа*"
                              value={values.document_date_of_input}
                              disabled
                              inputFormat="dd-MM-yyyy"
                              onChange={newValue => {
                                setFieldValue(
                                  'document_date_of_input',
                                  newValue
                                );
                              }}
                              renderInput={params => (
                                <TextFIELD
                                  variant="standard"
                                  name="document_date_of_input"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            disabled={true}
                            component={TextField}
                            type="text"
                            label="Време уписа*"
                            variant="standard"
                            name="document_time_of_input"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            component={TextField}
                            name="document_type_id"
                            type="text"
                            label="Врста документа*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            component={TextField}
                            name="strike_number"
                            type="text"
                            label="Број предмета*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <CustomMaterialUiSelect
                            setFieldValue={setFieldValue}
                            value={values.working_on_user_id}
                            fullValue={values.worker}
                            showFields={['firstname', 'lastname']}
                            // repo={WorkersRepo}
                            data={this.allSelectOptions.workers}
                            display="name"
                            name="working_on_user_id"
                            fullValueName="worker"
                            valueAttr="id"
                            label="Обрађивач*"
                            queryParams={{ search: 'role_id:4' }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            component={TextField}
                            name="notes"
                            type="text"
                            label="Напомене"
                            multiline
                            rows={5}
                            variant="standard"
                            InputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            setFieldValue={setFieldValue}
                            value={values.legal_form_first_party_id}
                            fullValue={values.legal_form_first_party}
                            // repo={LegalFormTypesRepo}
                            data={this.allSelectOptions.legal_form_types}
                            display="name"
                            name="legal_form_first_party_id"
                            fullValueName="legal_form_first_party"
                            valueAttr="id"
                            label="Правна форма прве стране"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            setFieldValue={setFieldValue}
                            value={values.legal_form_second_party_id}
                            fullValue={values.legal_form_second_party}
                            // repo={LegalFormTypesRepo}
                            data={this.allSelectOptions.legal_form_types}
                            display="name"
                            name="legal_form_second_party_id"
                            fullValueName="legal_form_second_party"
                            valueAttr="id"
                            label="Правна форма друге стране"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <ServerSelectAutocomplete
                            label={'Име и презиме/назив прве стране*'}
                            id={'nesto'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'name_first_party'}
                            fieldValue={'name_first_party'}
                            setFieldValue={setFieldValue}
                            value={values.name_first_party}
                          />
                        </Col>
                        <Col md={6}>
                          <ServerSelectAutocomplete
                            label={'Име и презиме/назив друге стране*'}
                            id={'nesto1'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'name_second_party'}
                            fieldValue={'name_second_party'}
                            setFieldValue={setFieldValue}
                            value={values.name_second_party}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="municipality_first_party"
                            type="text"
                            label="Општина прве стране"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="phone_first_party"
                            type="text"
                            label="Контакт број"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="municipality_second_party"
                            type="text"
                            label="Општина друге стране"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="phone_second_party"
                            type="text"
                            label="Контакт број"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            label={'Улица прве стране*'}
                            id={'nesto2'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'street_first_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'street_first_party'}
                            value={values.street_first_party}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="street_number_first_party"
                            type="text"
                            label="Број прве стране*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto',
                              marginTop: 12
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            label={'Улица друге стране*'}
                            id={'nesto3'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'street_second_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'street_second_party'}
                            value={values.street_second_party}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={TextField}
                            name="street_number_second_party"
                            type="text"
                            label="Број друге стране*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto',
                              marginTop: 12
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            label={'Место прве стране*'}
                            id={'nesto4'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'city_first_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'city_first_party'}
                            value={values.city_first_party}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            label={'Поштански број прве стране*'}
                            id={'nesto5'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'post_number_first_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'post_number_first_party'}
                            value={values.post_number_first_party}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            label={'Место друге стране*'}
                            id={'nesto6'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'city_second_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'city_second_party'}
                            value={values.city_second_party}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Поштански број друге стране*'}
                            id={'nesto7'}
                            repo={StrikesRepo}
                            data={this.strikesArray}
                            property={'post_number_second_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'post_number_second_party'}
                            value={values.post_number_second_party}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="strike_representative"
                            type="text"
                            label="Представник*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto',
                              marginTop: 12
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            setFieldValue={setFieldValue}
                            value={values.strike_type_id}
                            fullValue={values.strike_type}
                            // showFields={['firstname', 'lastname']}
                            display="name"
                            // repo={LitigatorsRepo}
                            data={this.allSelectOptions.strike_types}
                            name="strike_type_id"
                            fullValueName="strike_type"
                            valueAttr="id"
                            label="Врста штрајка"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="participants_requests"
                            type="text"
                            label="Захтеви учесника"
                            multiline
                            rows={5}
                            variant="standard"
                            InputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.rejection_reason_id}
                            fullValue={values.rejection_reason}
                            // repo={RejectionReasonsRepo}
                            data={this.allSelectOptions.rejection_reasons}
                            display="name"
                            name="rejection_reason_id"
                            fullValueName="rejection_reason"
                            valueAttr="id"
                            label="Одбацивање"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.conclusion_id}
                            fullValue={values.conclusion}
                            fullValueName="conclusion"
                            display="name"
                            // repo={ConclusionsRepo}
                            data={this.allSelectOptions.conclusions}
                            name="conclusion_id"
                            valueAttr="id"
                            label="Обустава"
                          />
                        </Col>
                      </Row> */}
                      {/* Add more fields */}
                    </Col>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <Button
                      variant="text"
                      style={{
                        fontSize: 14,
                        color: 'black',
                        display: this.documentButtonSwitch ? 'none' : 'flex'
                      }}
                    >
                      <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                      ОБРИШИ
                    </Button>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {values.isParent ? (
                      <Button
                        onClick={() => {
                          console.log('POPUNI');
                        }}
                        variant="text"
                        style={{
                          fontSize: 14,
                          color: 'black',
                          display: this.documentButtonSwitch ? 'none' : 'flex'
                        }}
                      >
                        <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                        ПОПУНИ ЗДРУЖЕНЕ ПРЕДМЕТЕ
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        strikesListingStore.showAddButton();
                      }}
                      type="submit"
                      variant="text"
                      style={{
                        fontSize: 14,
                        color: 'black',
                        display: this.documentButtonSwitch ? 'none' : 'flex'
                      }}
                    >
                      <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                      САЧУВАЈ
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          />
          <div
            style={{
              display: this.documentButtonSwitch ? 'block' : 'none',
              padding: '0 20px',
              minHeight: '570px'
            }}
          >
            <DropZone
              modelType={this.createdSwitch ? 'OfficeDocument' : 'Litigation'}
              id={
                this.createdSwitch
                  ? officeDocumentFileUploadsStore.currentOfficeDocumentId
                  : officeDocumentFileUploadsStore.currentLitigationId
              }
              css={{ marginBottom: '10px' }}
              title={`Превуците документ овде, или кликните како бисте га одабрали`}
              subtitle={``}
              multiple={false}
              method={officeDocumentsListingStore.uploadOfficeDocumentFile}
            />

            {this.createdSwitch ? (
              <DataTable
                enableCaching={false}
                showPaginationBottom={false}
                // getTdProps={this.getTdProps}
                minRows={10}
                columns={columns}
                fetchData={
                  officeDocumentsListingStore.fetchScannedDocumentsByLitigationId
                }
                data={officeDocumentsListingStore.attachCustomColumn(
                  'actions',
                  this.itemAction
                )}
                loading={officeDocumentsListingStore.loading}
                defaultSort={[{ id: 'createdAt', desc: true }]}
              />
            ) : (
              <>
                <DataTable
                  enableCaching={false}
                  showPaginationBottom={false}
                  // getTdProps={this.getTdProps}
                  minRows={10}
                  columns={columns}
                  fetchData={
                    officeDocumentsListingStore.fetchCreatedDocumentsByLitigationId
                  }
                  data={officeDocumentsListingStore.attachCustomColumn(
                    'actions',
                    this.itemAction
                  )}
                  loading={officeDocumentsListingStore.loading}
                  defaultSort={[{ id: 'createdAt', desc: true }]}
                />
              </>
            )}
            {/* <OfficeDocumentsFileUploadsDataTable
                      litigationId={values.id}
                    /> */}
            <Button
              style={{ height: 50, padding: 15 }}
              onClick={() => {
                this.setCreatedSwitch(!this.createdSwitch);
              }}
            >
              {this.createdSwitch
                ? 'ПРИКАЗ КРЕИРАНИХ ДОКУМЕНАТА'
                : 'ПРИКАЗ СКЕНИРАНИХ ДОКУМЕНАТА'}
            </Button>
          </div>
        </Modal>
        {/* <Dialog
          open={this.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Да ли сте сигурни да желите да трајно обришете ову шему
              докумената? Трајно обрисане податке је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Не, одустани</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default AddStrikeModal;
