import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { statusesStore } from '../../data/stores/StatusesStore';
import StatusesRepo from '../../data/repositories/StatusesRepo';
import { Status } from 'data/types';

class StatusesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitStatus = async (status: Status) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStatus(status);
    }

    return this.createStatus(status);
  };

  createStatus = async (status: Status) => {
    const { create } = StatusesRepo;

    const [response] = await to(create(status));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту статуса.'
        });
      }, 1000);
      statusesStore.fetch();
      this.closeModalForm();
    }
  };

  updateStatus = async (status: Status) => {
    const { update } = StatusesRepo;

    const [response] = await to(update(status.id, status));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту статуса.'
        });
      }, 1000);
      statusesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const statusesListingStore = new StatusesListingStore(StatusesRepo);
export default StatusesListingStore;
