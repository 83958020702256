import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import { litigatorTypesListingStore } from '../LitigatorTypesListingStore';

interface AddLitigatorTypeModalProps {
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
}

const DEFAULT_VALUES = {
  id: '',
  name: ''
};

@observer
class AddLitigatorTypeModal extends Component<AddLitigatorTypeModalProps> {
  onSubmit = (data: any) => {
    litigatorTypesListingStore.submitLitigatorType(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;

    return pickDefaultValues(DEFAULT_VALUES, openItem);
  };

  render() {
    const { showModal, closeModalForm } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <Modal show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Додавање нове врсте миритеља и арбитра
            <Button
              onClick={() => {
                litigatorTypesListingStore.showAddButton();
                closeModalForm();
              }}
              variant="contained"
              style={{
                backgroundColor: '#fb8c00',
                fontSize: 14,
                fontWeight: 400,
                color: 'white',
                width: '205px',
                height: '36px',
                borderRadius: '4px'
              }}
            >
              <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
              НАЗАД
            </Button>
          </Modal.Header>
          <Formik
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body>
                  <Field
                    component={TextField}
                    name="name"
                    type="text"
                    label="Назив врсте миритеља и арбитра*"
                    variant="standard"
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    style={{
                      width: '100%',
                      fontFamily: 'Roboto'
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                    ОБРИШИ
                  </Button>
                  <Button
                    onClick={() => litigatorTypesListingStore.showAddButton()}
                    type="submit"
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                    САЧУВАЈ
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          />
        </Modal>
      </div>
    );
  }
}

export default AddLitigatorTypeModal;
