import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import StrikeTypesRepo from '../repositories/StrikeTypesRepo';

class StrikeTypesStore extends TableStore<any> {
  @observable strikeTypes: any;

  @action
  setStrikeTypes = (data: any) => {
    this.strikeTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StrikeTypesRepo.getAllStrikeTypes({}));
    if (res) {
      this.setStrikeTypes(res.data);
    }
  };
}

export const strikeTypesStore = new StrikeTypesStore(StrikeTypesRepo);
export default strikeTypesStore;
