import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import ExpenseTypesRepo from '../../data/repositories/ExpenseTypesRepo';
import { expenseTypesStore } from '../../data/stores/ExpenseTypesStore';

class ExpenseTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createExpenseType = async (expenseType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateExpenseType(expenseType);
    }

    return this.saveExpenseType(expenseType);
  };

  saveExpenseType = async (expenseType: any) => {
    const { create } = ExpenseTypesRepo;

    const [response] = await to(create(expenseType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали цену услуге.'
        });
      }, 1000);
      expenseTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateExpenseType = async (expenseType: any) => {
    const { update } = ExpenseTypesRepo;

    const [response] = await to(update(expenseType.id, expenseType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали цену услуге.'
        });
      }, 1000);
      expenseTypesStore.fetch();
      this.closeModalForm();
    }
  };

  deleteExpenseType = async (expenseTypeId: any) => {
    const [response] = await to(ExpenseTypesRepo.delete(expenseTypeId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали цену услуге.'
        });
      }, 1000);
      expenseTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const expenseTypesListingStore = new ExpenseTypesListingStore(
  ExpenseTypesRepo
);
export default ExpenseTypesListingStore;
