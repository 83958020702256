import TableStore from './TableStore';
import { observable, action } from 'mobx';
import ConclusionsRepo from '../repositories/ConclusionsRepo';
import to from '../../lib/awaitTo';

class ConclusionsStore extends TableStore<any> {
  @observable conclusions: any;

  @action
  setConclusions = (data: any) => {
    this.conclusions = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(ConclusionsRepo.getAllConclusions({}));
    if (res) {
      this.setConclusions(res.data);
    }
  };
}

export const conclusionsStore = new ConclusionsStore(ConclusionsRepo);
export default conclusionsStore;
