/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import flow from 'lodash/flow';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { authStore } from '../data/stores/AuthStore';
import { Toast, genericError, WarningDialog } from '../lib/dialogs';
import { loadingBarStore } from '../data/stores/LoadingBarStore';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common.Pragma = 'no-cache';
axios.defaults.headers.common.Expires = '0';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const token = Cookies.get('token');

if (token) {
  authStore.setAuthToken(token);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mergeAttributes = ({ attributes, type, ...rest }: any) => ({
  ...JSON.parse(JSON.stringify(attributes, (_k, v) => (v === null ? '' : v))),
  ...rest
});

const mergeAttributesList = (data: object[]) => data.map(mergeAttributes);

const withIncluded = (data: any) => {
  const keys = Object.keys(data);
  keys.forEach(key => {
    const datum = data[key];
    if (datum && datum.data && datum.data.attributes) {
      data[key] = mergeAttributes(datum.data);
      return withIncluded(data[key]);
    }
    if (datum && datum.data && Array.isArray(datum.data)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parseData = flow([mergeAttributesList, withIncludedList]);
      data[key] = parseData(datum.data);
    }
  });
  return data;
};

const withIncludedList = (data: object[]) => {
  return data.map(item => withIncluded(item));
};

const responseSuccessHandler = (response: any) => {
  loadingBarStore.decrease();

  if (
    response.data &&
    response.data.data &&
    Array.isArray(response.data.data)
  ) {
    const { data, meta } = response.data;
    const parseData = flow([mergeAttributesList, withIncludedList]);
    return { data: parseData(data), meta };
  }

  if (response.data && response.data.data && response.data.data.attributes) {
    const parseData = flow([
      mergeAttributes,
      (data: object) => withIncluded(data)
    ]);

    return parseData(response.data.data);
  }
  return response;
};

const errorInfoToast = (error: any) => {
  let code: string | undefined;
  let message: string | undefined;
  let messageDetails: string | undefined;

  if (error.data.errors.message) {
    message = get(error, 'data.errors.message');
  } else {
    message = get(error, 'data.message');
  }

  if (error.status) {
    code = get(error, 'status');
  } else if (error.data.errors.responseCode) {
    code = get(error, 'data.errors.responseCode');
  } else if (error.data.errors.response_code) {
    code = get(error, 'data.errors.response_code');
  } else if (error.data.errors.statusCode) {
    code = get(error, 'data.errors.statusCode');
  } else if (error.data.errors.status_code) {
    code = get(error, 'data.errors.status_code');
  }

  // This is for ClientDetailsByMobileNumPage if there's not Client
  if (
    error.status === 404 &&
    error.data.errors.key === 'CANT_FIND_CLIENT_INVALID_PHONE_NUMBER'
  ) {
    return;
  }

  if (error.status === 422 || error.status === 417) {
    const messageList = get(error, 'data.errors.data');
    const detailsKeys = Object.keys(messageList);
    const detailsValues = Object.values(messageList) as any;

    if (typeof messageList !== 'string') {
      messageDetails = detailsKeys
        .map(
          (detailKey, index) =>
            `<li><b>${detailKey}:</b> ${detailsValues[index]}</li>`
        )
        .join('')
        .toString();
    } else messageDetails = messageList;
  }

  if (message && !isUndefined(code)) {
    setTimeout(
      () =>
        WarningDialog.fire({
          type: 'error',
          title: `Code: ${code}`,
          text: message,
          html: messageDetails,
          confirmButtonText: 'Okay',
          showCancelButton: false
        }),
      500
    );
  } else {
    genericError();
  }
};

const responseErrorHandler = (error: AxiosError) => {
  const { requestCounter } = authStore;

  loadingBarStore.decrease();

  if (error.response && error.response.status === 401) {
    authStore.increaseRequestCounter();

    if (
      error.response.data &&
      error.response.data.message ===
        'The user credentials were incorrect. (invalid_credentials)'
    ) {
      Toast.fire({
        type: 'error',
        text: 'Username or password is not valid'
      });
      return authStore.logout();
    }

    if (requestCounter > 50) {
      return authStore.logout();
    }

    const remmberMe = Cookies.get('remember_me');

    if (isUndefined(remmberMe)) {
      Toast.fire({
        type: 'error',
        title: 'Logged out! Your session has expired please login again!'
      });
      return authStore.logout();
    }
    error.config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    // error.config.headers.Accept = 'application/json';

    const { config } = error;
    return new Promise((resolve, reject) => {
      axios
        .request(config)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
  if (error.response && error.response.data) {
    errorInfoToast(error.response);
    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
};

const requestSuccessHandler = (request: any) => {
  loadingBarStore.add();
  const refreshToken = Cookies.get('refresh_token');
  const remmberMe = Cookies.get('remember_me');

  if (authStore.hasTokenExpired()) {
    authStore.clearAuthCookieData();

    if (remmberMe === 'RememberMe' && !isUndefined(refreshToken)) {
      return authStore
        .getNewToken({
          refresh_token: refreshToken
        })
        .then((response: any) => {
          const { data } = response;

          Cookies.set('refresh_token', data.refresh_token);
          Cookies.set('token', data.access_token);
          authStore.setExpiresIn(data.expires_in);

          request.headers.Authorization = `Bearer ${data.access_token}`;
          axios.defaults.headers.Authorization = `Bearer ${data.access_token}`;

          authStore.resetRequestCounter();

          return request;
        })
        .catch((err: any) => Promise.reject(err));
    }

    Toast.fire({
      type: 'error',
      title: 'Logged out! Your session has expired please login again!'
    });
    return authStore.logout();
  }

  return request;
};

const requestErrorHandler = (error: AxiosError) => {
  return Promise.reject(error);
};

axios.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

axios.interceptors.request.use(requestSuccessHandler, requestErrorHandler);
