import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'procedure_types';

const getAllProcedureTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const ProcedureTypesRepo = new BaseRepo(type);

export default {
  ...ProcedureTypesRepo,
  getAllProcedureTypes
};
