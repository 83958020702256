import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import StrikeEndingsRepo from '../repositories/StrikeEndingsRepo';

class StrikeEndingsStore extends TableStore<any> {
  @observable strikeEndings: any;

  @action
  setStrikeEndings = (data: any) => {
    this.strikeEndings = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StrikeEndingsRepo.getAllStrikeEndings({}));
    if (res) {
      this.setStrikeEndings(res.data);
    }
  };
}

export const strikeEndingsStore = new StrikeEndingsStore(StrikeEndingsRepo);
export default strikeEndingsStore;
