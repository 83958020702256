import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'office_documents';

const getAllOfficeDocuments = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const getOfficeDocumentIdByLitigationNumber = (litigationNumber: any) => {
  return axios.get(
    `${type}/get-office-document-id-by-litigation-number?litigationNumber=${litigationNumber}`
  );
};

const OfficeDocumentsRepo = new BaseRepo(type);

export default {
  ...OfficeDocumentsRepo,
  getAllOfficeDocuments,
  getOfficeDocumentIdByLitigationNumber
};
