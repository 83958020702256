import DataTable from 'components/DataTable';
import DateCell from 'components/TableCells/DateCell';
import LitigationNumberCell from 'components/TableCells/LitigationNumberCell';
import LitigatorFullNameCellWithAvatar from 'components/TableCells/LitigatorFullNameCellWithAvatar';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import litigationsFilterStore from '../LitigationsFilterStore';
import { CellInfo, RowInfo } from 'react-table';
import pageStore from 'data/stores/PageStore';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { observer } from 'mobx-react';
import litigationsListingStore from 'pages/LitigationsListingPage/LitigationsListingStore';
import { exportToExcel } from './helper';

const ReviewsLitigatorFilterTable = () => {
  const {
    selectedItems,
    addSelectedItem,
    removeSelectedItem,
    exportAllData
  } = litigationsFilterStore;

  const columns = [
    {
      Header: 'Изабери',
      id: 'checkbox-col',
      Cell: (data: CellInfo) => {
        return (
          <label
            style={{
              display: 'flex',
              height: 50,
              width: 100,
              lineHeight: 50,
              alignItems: 'center'
            }}
            htmlFor={`check-${data.original.id}`}
          >
            <input
              id={`check-${data.original.id}`}
              style={{
                marginLeft: '20px',
                cursor: 'pointer',
                width: '15px',
                height: '15px'
              }}
              type="checkbox"
              checked={selectedItems.some(
                (selectedItem: any) => selectedItem.id === data.original.id
              )}
              onClick={(e: any) => {
                e.stopPropagation();

                if (e.target.checked) {
                  addSelectedItem(data.original);
                } else {
                  removeSelectedItem(data.original);
                }
              }}
            />
          </label>
        );
      },
      sortable: false,
      filterable: false,
      width: 87
    },
    {
      Header: 'Датум',
      id: 'document_date_of_input',
      accessor: 'document_date_of_input',
      Cell: DateCell(),
      sortable: false,
      filterable: false,
      width: 100
    },
    {
      Header: 'Бр. предмета',
      accessor: 'litigation_number',
      Cell: LitigationNumberCell,
      sortable: false,
      filterable: false,
      width: 120
    },
    {
      Header: 'Прва страна',
      id: 'name_first_party',
      accessor: 'name_first_party',
      sortable: false,
      filterable: false
    },
    {
      Header: 'Друга страна',
      accessor: 'name_second_party',
      sortable: false,
      filterable: false
    },
    {
      Header: 'Оцена миритеља прве стране',
      accessor: 'first_party_rate_litigator',
      sortable: false,
      filterable: false
    },
    {
      Header: 'Коментар прве стране за миритеља',
      accessor: 'first_party_note_litigator',
      sortable: false,
      filterable: false
    },
    {
      Header: 'Оцена миритеља друге стране',
      accessor: 'second_party_rate_litigator',
      sortable: false,
      filterable: false
    },
    {
      Header: 'Коментар друге стране за миритеља',
      accessor: 'second_party_note_litigator',
      sortable: false,
      filterable: false
    },
    // {
    //   Header: 'Миритељ и арбитар',
    //   id: 'litigator.litigator_firstname',
    //   accessor: 'litigator',
    //   Cell: LitigatorFullNameCellWithAvatar,
    //   sortable: false,
    //   filterable: false
    // },
    // {
    //   Header: 'Обрађивач',
    //   id: 'worker.firstname',
    //   accessor: 'worker',
    //   Cell: WorkerFullNameCellWithAvatar,
    //   sortable: false,
    //   filterable: false
    // },
    {
      Header: 'Опције',
      accessor: 'actions',
      filterable: false,
      sortable: false
    }
  ];

  const itemAction = (litigation: any) => {
    return (
      <div className="td-actions text-center">
        {/* <PermissionGuard permissionsWithAccess={permission.accidentDetails}> */}
        <Button
          style={{ color: 'black' }}
          onClick={() => {
            litigationsListingStore.openModalForm(litigation);
          }}
          startIcon={<SearchIcon />}
        >
          ПРИКАЗ
        </Button>
        {/* </PermissionGuard> */}
      </div>
    );
  };

  const getTdProps = (_state: any, row?: RowInfo, column?: any): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original && column.id !== 'checkbox-col') {
          // page detail
          window.location.href = '/dashboard/litigations/' + row.original.id;
          // modal detail
          pageStore.setNavTitle(
            pageStore.navTitle + ' ' + row.original.litigation_number
          );
          //litigationsArchiveStore.openModalForm(row.original);
        }
      }
    };
  };

  const { data: tableResults } = litigationsFilterStore;

  return (
    <>
      <DataTable
        ifEmptyRenderMessage="Нема резултата"
        style={{ marginTop: '50px' }}
        enableCaching={false}
        columns={columns}
        fetchData={litigationsFilterStore.fetchSubjects}
        meta={litigationsFilterStore.meta}
        data={litigationsFilterStore.attachCustomColumn('actions', itemAction)}
        getTdProps={getTdProps}
        loading={litigationsFilterStore.loading}
        defaultSort={[{ id: 'createdAt', desc: true }]}
      />

      <div style={{ display: 'flex', gap: '20px' }}>
        {!!selectedItems.length ? (
          <Button
            onClick={() => exportToExcel(selectedItems)}
            variant="contained"
            style={{
              backgroundColor: '#86736C',
              marginTop: '20px',
              fontSize: 14,
              fontWeight: 400,
              color: 'white',
              width: '255px',
              height: '36px',
              borderRadius: '4px'
            }}
          >
            Преузми одабране податке
          </Button>
        ) : (
          <Button
            onClick={() => exportAllData(exportToExcel)}
            disabled={!tableResults.length || (litigationsFilterStore.meta && litigationsFilterStore.meta.total > 8000)}
            style={{
              backgroundColor: !!tableResults.length && (litigationsFilterStore.meta && litigationsFilterStore.meta.total <= 8000) ? '#218B82' : '#cccccc',
              marginTop: '20px',
              fontSize: 14,
              fontWeight: 400,
              color: !!tableResults.length ? 'white' : '#666666',
              width: '255px',
              height: '36px',
              borderRadius: '4px'
            }}
          >
            Преузми све податке
          </Button>
        )}
      </div>
    </>
  );
};

export default observer(ReviewsLitigatorFilterTable);
