import BaseRepo from './BaseRepo';
import axios from 'axios';

const type = 'litigator_avatars';

const LitigatorAvatarsRepo = new BaseRepo(type);

const uploadLitigatorAvatar = (data: any) => {
  const formData = new FormData();

  formData.append('file', data);

  return axios.post(`/${type}`, formData);
};

export default {
  ...LitigatorAvatarsRepo,
  uploadLitigatorAvatar
};
