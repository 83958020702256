export const DEBOUNCE_TIME = 500; // used when user type to search e.g. CountrySelect
export const DATETIME_FORMAT = 'DD.MM.YYYY. HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY.';
export const TIME_FORMAT = 'HH:mm:ss';
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
export const REQUEST_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const HOLLYDAY_FORMAT = 'DD-MM';
export const HOLLYDAY_REGEX = /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])$/;
export const PRIMARY_COLOR = '#f47e20';
