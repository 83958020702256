import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import LitigatorTypesRepo from '../repositories/LitigatorTypesRepo';

class LitigatorTypesStore extends TableStore<any> {
  @observable litigatorTypes: any;

  @action
  setLitigatorTypes = (data: any) => {
    this.litigatorTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(LitigatorTypesRepo.getAllLitigatorTypes({}));
    if (res) {
      this.setLitigatorTypes(res.data);
    }
  };
}

export const litigatorTypesStore = new LitigatorTypesStore(LitigatorTypesRepo);
export default litigatorTypesStore;
