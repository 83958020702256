import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { List } from 'react-virtualized';

const ListboxComponent: any = React.forwardRef(function ListboxComponent(
  props: any,
  ref: any
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={600}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props: any) => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizedAutocomplete(props: any) {
  const {
    label,
    data,
    setFieldValue,
    fieldValue,
    disabled,
    // value,
    style,
    idVal,
    // fullValue,
    fullValueKey
  } = props;

  let pickedVal = undefined;
  if (idVal) {
    pickedVal = data.filter((item: any) => {
      return idVal === item.id;
    });
  }

  return (
    <Autocomplete
      disabled={disabled}
      style={style}
      id="virtualize-demo"
      disableListWrap
      value={
        pickedVal !== undefined &&
        pickedVal !== null &&
        pickedVal[0] !== undefined
          ? pickedVal[0].litigation_number +
            ' - ' +
            pickedVal[0].name_second_party
          : null
      }
      ListboxComponent={ListboxComponent}
      options={data.map((item: any) => {
        return `${item.litigation_number} - ${item.name_second_party}`;
      })}
      onChange={(e: any, value: any) => {
        // pick parent litigation based on name_second_party prop
        let newParent = data.filter((item: any) => {
          return value.includes(item.litigation_number);
        });

        setFieldValue(fullValueKey, newParent[0]);
        setFieldValue(fieldValue, newParent[0].id);
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="standard"
          InputLabelProps={{
            style: {
              fontSize: 16,
              position: 'relative',
              paddingBottom: '5px',
              top: '2px',
              marginBottom: '0px'
            }
          }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            style: {
              fontSize: 16,
              position: 'relative',
              paddingBottom: '5px',
              top: '2px',
              marginBottom: '0px'
            }
          }}
          label={label}
          fullWidth
        />
      )}
    />
  );
}
