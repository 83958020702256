import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import StrikePlacesRepo from '../repositories/StrikePlacesRepo';

class StrikePlacesStore extends TableStore<any> {
  @observable strikePlaces: any;

  @action
  setStrikePlaces = (data: any) => {
    this.strikePlaces = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StrikePlacesRepo.getAllStrikePlaces({}));
    if (res) {
      this.setStrikePlaces(res.data);
    }
  };
}

export const strikePlacesStore = new StrikePlacesStore(StrikePlacesRepo);
export default strikePlacesStore;
