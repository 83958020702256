import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import { Query } from '../../lib/queryBuilder';
import OtherLitigationsRepo from 'data/repositories/OtherLitigationsRepo';
import axios from 'axios';

class OtherLitigationsStore extends TableStore<any> {
  @observable otherLitigations: any;
  @observable openItem: any;
  @observable currentOtherLitigationNumber: any = null;

  @action
  setOtherLitigations = (data: any) => {
    this.otherLitigations = data;
  };

  @action
  setOpenItem = (otherLitigation: any) => {
    this.openItem = otherLitigation;
  };

  @action
  setCurrentOtherLitigationNumber = (otherLitigationNumber: any) => {
    if (otherLitigationNumber === '' || otherLitigationNumber === null) {
      this.currentOtherLitigationNumber = null;
    } else {
      this.currentOtherLitigationNumber = otherLitigationNumber;
    }
  };

  @action
  fetchAll = async () => {
    const [res] = await to(OtherLitigationsRepo.getAllOtherLitigations({}));

    if (res) {
      this.setOtherLitigations(res.data);
    }
  };

  @action
  fetchOne = async (id: any) => {
    const [res] = await to(OtherLitigationsRepo.one(id));

    if (res) {
      this.setOpenItem(res.data);
    }
  };

  @action
  fetchActiveOtherLitigationsWithoutOther = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes,
      ...this.customQueryParams
    };

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fetchInactiveOtherLitigationsWithoutOther = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes,
      ...this.customQueryParams
    };

    // let response: any = await to(
    //   this.repo.getAll({ params: this.queryParams })
    // );

    const [response, error] = await to(
      OtherLitigationsRepo.getInactiveOtherLitigations({})
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fetchOtherLitigationIdByLitigationNumber = async (
    otherLitigationNumber: string
  ) => {
    const response = await OtherLitigationsRepo.fetchOtherLitigationIdByLitigationNumber(
      otherLitigationNumber
    );
    if (response) {
      this.setCurrentOtherLitigationNumber(response.data);
    }
  };

  @action
  fetchArchivedOtherLitigations = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    const customQueryParams = {
      isArchive: true
    };
    this.queryParams = {
      ...params,
      include: this.includes,
      ...customQueryParams
    };

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };
}

export const otherLitigationsStore = new OtherLitigationsStore(
  OtherLitigationsRepo
);
export default otherLitigationsStore;
