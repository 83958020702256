import BaseRepo from './BaseRepo';
import axios from 'axios';

const type = 'worker_avatars';

const WorkerAvatarsRepo = new BaseRepo(type);

const uploadWorkerAvatar = (data: any) => {
  const formData = new FormData();

  formData.append('file', data);

  return axios.post(`/${type}`, formData);
};

export default {
  ...WorkerAvatarsRepo,
  uploadWorkerAvatar
};
