import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'templates';
const update = 'templates/update';
const download = 'templates/download';

const uploadTemplate = (data: any) => {
  const formData = new FormData();

  formData.append('file', data[0]);

  return axios.post(`/${type}`, formData);
};

const updateTemplate = (data: any, templateId: any) => {
  const formData = new FormData();

  formData.append('file', data[0]);

  return axios.post(`/${update}/${templateId}`, formData);
};

const getAllTemplates = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const downloadTemplate = (templateId: any) => {
  return axios.get(`${download}/${templateId}`);
};

const TemplatesRepo = new BaseRepo(type);

export default {
  ...TemplatesRepo,
  getAllTemplates,
  uploadTemplate,
  updateTemplate,
  downloadTemplate
};
