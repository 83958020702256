import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { phasesStore } from '../../data/stores/PhasesStore';
import PhasesRepo from '../../data/repositories/PhasesRepo';

class PhasesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitPhase = async (phase: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updatePhase(phase);
    }

    return this.createPhase(phase);
  };

  createPhase = async (phase: any) => {
    const { create } = PhasesRepo;

    const [response] = await to(create(phase));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту фазе.'
        });
      }, 1000);
      phasesStore.fetch();
      this.closeModalForm();
    }
  };

  updatePhase = async (phase: any) => {
    const { update } = PhasesRepo;

    const [response] = await to(update(phase.id, phase));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту фазе.'
        });
      }, 1000);
      phasesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const phasesListingStore = new PhasesListingStore(PhasesRepo);
export default PhasesListingStore;
