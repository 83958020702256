import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import WorkersRepo, { parseRequest } from '../../data/repositories/WorkersRepo';
import { workersStore } from '../../data/stores/WorkersStore';

class WorkersListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createWorker = async (worker: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateWorker(worker);
    }

    return this.saveWorker(worker);
  };

  saveWorker = async (worker: any) => {
    const { create } = WorkersRepo;

    const [response] = await to(create(parseRequest(worker)));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали корисника.'
        });
      }, 1000);
      workersStore.fetch();
      this.closeModalForm();
    }
  };

  updateWorker = async (worker: any) => {
    const { update } = WorkersRepo;

    const request = parseRequest(worker);

    const [response] = await to(update(worker.id, request));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали корисника.'
        });
      }, 1000);
      workersStore.fetch();
      this.closeModalForm();
    }
  };
}

export const workersListingStore = new WorkersListingStore(WorkersRepo);
export default workersListingStore;
