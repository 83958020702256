import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'search';

const searchByKeyword = (
  keyword: string | '',
  searchType: boolean,
  entity: string,
  config: AxiosRequestConfig
) => {
  return axios.get(
    `${type}?keyword=${keyword}&isArchive=${searchType}&entity=${entity}`,
    config
  );
};

const SearchRepo = new BaseRepo(type);

export default {
  ...SearchRepo,
  searchByKeyword
};
