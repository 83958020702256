import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import ExpenseTypesRepo from '../repositories/ExpenseTypesRepo';

class ExpenseTypesStore extends TableStore<any> {
  @observable expenseTypes: any;

  @action
  setExpenseTypes = (data: any) => {
    this.expenseTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(ExpenseTypesRepo.getAllExpenseTypes({}));
    if (res) {
      this.setExpenseTypes(res.data);
    }
  };
}

export const expenseTypesStore = new ExpenseTypesStore(ExpenseTypesRepo);
export default expenseTypesStore;
