import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import OfficeDocumentFileUploadsRepo from '../repositories/OfficeDocumentFileUploadsRepo';

class OfficeDocumentFileUploadsStore extends TableStore<any> {
  @observable officeDocumentFileUploads: any;
  @observable currentLitigationId: any;
  @observable currentLitigationNumber: any;
  @observable currentOfficeDocumentFileId: any;
  @observable currentOfficeDocumentId: any;

  @action
  setOfficeDocumentFileUploads = (data: any) => {
    this.officeDocumentFileUploads = data;
  };

  @action
  setCurrentOfficeDocumentFileId = (officeDocumentFileId: any) => {
    this.currentOfficeDocumentFileId = officeDocumentFileId;
  };

  @action
  setCurrentOfficeDocumentId = (officeDocumentId: any) => {
    this.currentOfficeDocumentId = officeDocumentId;
  };

  @action
  setCurrentLitigationId = (litigationId: any) => {
    this.currentLitigationId = litigationId;
  };

  @action
  setCurrentLitigationNumber = (litigationNumber: any) => {
    this.currentLitigationNumber = litigationNumber;
  };

  @action
  clear = () => {
    this.setCurrentLitigationId(undefined);
    this.setCurrentLitigationNumber(undefined);
    this.setOfficeDocumentFileUploads(undefined);
    this.data = [];
  };

  @action
  fetchAll = async () => {
    const [res] = await to(
      OfficeDocumentFileUploadsRepo.getAllOfficeDocumentFileUploads({})
    );
    if (res) {
      //this.onFetch(res.data, res.meta);
      this.onFetch(res.data.message.data, res.data.message);
      this.setOfficeDocumentFileUploads(res.data);
    }
  };

  @action
  delete = async (officeDocumentFileUploadId: any) => {
    const [res] = await to(
      OfficeDocumentFileUploadsRepo.delete(officeDocumentFileUploadId)
    );
    if (res) {
      this.fetchByOfficeDocumentId();
      //this.onFetch(res.data, res.meta);
    }
  };

  @action
  fetchByLitigationId = async () => {
    const [res] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByLitigationId(
        this.currentLitigationId
      )
    );
    if (res) {
      this.onFetch(res.data, null);
    }
    //  this.fetch({ search: `litigation_id:${this.currentLitigationId}` });
  };

  @action
  fetchByOfficeDocumentId = async () => {
    const [res] = await to(
      OfficeDocumentFileUploadsRepo.getOfficeDocumentFileUploadsByOfficeDocumentIds(
        this.currentOfficeDocumentId
      )
    );
    if (res) {
      this.onFetch(res.data, null);
    }
    //  this.fetch({ search: `litigation_id:${this.currentLitigationId}` });
  };

  @action
  shareDocument = async (
    officeDocumentFileUploadId: number,
    children: number[]
  ) => {
    const [res] = await to(
      OfficeDocumentFileUploadsRepo.shareDocument(
        officeDocumentFileUploadId,
        children
      )
    );
    if (res) {
      this.onFetch(res.data, null);
    }
  };
}

export const officeDocumentFileUploadsStore = new OfficeDocumentFileUploadsStore(
  OfficeDocumentFileUploadsRepo
);
export default officeDocumentFileUploadsStore;
