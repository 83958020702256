import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import OtherLitigationsRepo from '../../data/repositories/OtherLitigationsRepo';
import { otherLitigationsStore } from '../../data/stores/OtherLitigationsStore';

class OtherLitigationsArchiveStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createOtherLitigation = async (otherLitigation: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateOtherLitigation(otherLitigation);
    }

    return this.saveOtherLitigation(otherLitigation);
  };

  saveOtherLitigation = async (otherLitigation: any) => {
    const { create } = OtherLitigationsRepo;

    const [response] = await to(create(otherLitigation));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали (остали) предмет.'
        });
      }, 1000);
      otherLitigationsStore.fetch();
      this.closeModalForm();
    }
  };

  updateOtherLitigation = async (otherLitigation: any) => {
    const [response] = await to(
      OtherLitigationsRepo.update(otherLitigation.id, otherLitigation)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали (остали) предмет.'
        });
      }, 1000);
      otherLitigationsStore.fetchActiveOtherLitigationsWithoutOther();
      this.closeModalForm();
    }
  };
}

export const otherLitigationsArchiveStore = new OtherLitigationsArchiveStore(
  OtherLitigationsRepo
);
export default otherLitigationsArchiveStore;
