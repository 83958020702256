import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import DocumentTypesRepo from '../repositories/DocumentTypesRepo';

class DocumentTypesStore extends TableStore<any> {
  @observable documentTypes: any;

  @action
  setDocumentTypes = (data: any) => {
    this.documentTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(DocumentTypesRepo.getAllDocumentTypes({}));
    if (res) {
      this.setDocumentTypes(res.data);
    }
  };
}

export const documentTypesStore = new DocumentTypesStore(DocumentTypesRepo);
export default documentTypesStore;
