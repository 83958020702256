import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddOfficeDocumentModal from './components/AddOfficeDocumentModal';
import { officeDocumentsStore } from '../../data/stores/OfficeDocumentsStore';
import { officeDocumentsListingStore } from './OfficeDocumentsListingStore';
import DateCell from '../../components/TableCells/DateCell';
import { RowInfo } from 'react-table';
import BoldCell from '../../components/TableCells/BoldCell';
import officeDocumentFileUploadsStore from 'data/stores/OfficeDocumentFileUploadsStore';
import SearchResultsComponent from 'components/SearchResultsComponent';

const columns = [
  {
    Header: '#',
    id: 'id',
    accessor: 'id',
    sortable: false,
    width: 50
  },
  {
    Header: 'Датум',
    id: 'created_at',
    accessor: 'created_at',
    Cell: DateCell(),
    sortable: false,
    width: 120
  },
  {
    Header: 'Број',
    id: 'litigation_number',
    accessor: 'litigation_number',
    Cell: BoldCell,
    sortable: false,
    width: 200
  },
  {
    Header: 'Врста документа',
    id: 'document_type.name',
    accessor: 'document_type.name',
    sortable: false
  },
  {
    Header: 'Напомене',
    id: 'notes',
    accessor: 'notes',
    sortable: false
  }
];

@observer
class OfficeDocumentsListingPage extends Component {
  componentDidMount() {
    officeDocumentsStore.fetchAll();
  }

  showAddOfficeDocumentModal = () => {
    officeDocumentsListingStore.hideAddButton();
    officeDocumentsListingStore.openModalForm();
  };

  hideAddOfficeDocumentModal = () => {
    officeDocumentsListingStore.showAddButton();
    officeDocumentsListingStore.closeModalForm();

    //clear state for uploaded files
    officeDocumentFileUploadsStore.setCurrentOfficeDocumentId(null);
    officeDocumentFileUploadsStore.setCurrentOfficeDocumentFileId(null);
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          officeDocumentsListingStore.openModalForm(row.original);
        }
      }
    };
  };

  render() {
    const { addButtonVisible } = officeDocumentsListingStore;

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <Button
            onClick={() => this.showAddOfficeDocumentModal()}
            variant="contained"
            style={{
              visibility: addButtonVisible ? 'visible' : 'hidden',
              padding: '5px 80px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 400,
              float: 'right',
              marginBottom: '15px',
              width: '205px',
              marginRight: '15px'
            }}
          >
            <AddIcon style={{ fontSize: '14px', color: 'white' }} />
            Додај
          </Button>

          <div>
            <DataTable
              enableCaching={false}
              getTdProps={this.getTdProps}
              columns={columns}
              meta={officeDocumentsStore.meta}
              fetchData={officeDocumentsStore.fetchAllOfficeDocuments}
              data={officeDocumentsStore.data}
              loading={officeDocumentsStore.loading}
              defaultSort={[{ id: 'updatedAt', desc: true }]}
            />
            <AddOfficeDocumentModal
              showModal={officeDocumentsListingStore.showModal}
              closeModalForm={this.hideAddOfficeDocumentModal}
              openItem={officeDocumentsListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default OfficeDocumentsListingPage;
