import { Component } from 'react';
import { Route } from '../../routes';
import Navbar from '../Navbar';
import { Redirect, RouteProps } from 'react-router';
import PageTitle from '../PageTitle';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import MainMenu from '../MainMenu';
import DashboardPageTitle from '../DashboardPageTitle';
import { authStore } from 'data/stores/AuthStore';
import { observer } from 'mobx-react';
import PageLoader from 'components/PageLoader';
// import { Outlet } from 'react-router-dom';

interface DashboardLayoutProps extends RouteProps {
  children: React.ReactChildren;
  routes: Route[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedRoutes = (routes: Route[]): any => {
  return routes.map(route => {
    if (route.placeholder && route.routes) {
      return getNestedRoutes(route.routes);
    }

    return <RouteWithSubRoutes {...route} />;
  });
};

@observer
class Layout extends Component<DashboardLayoutProps> {
  componentDidMount() {
    const { loggedIn, getAuthUser } = authStore;

    if (loggedIn) {
      getAuthUser();
    }
  }

  render() {
    const { routes } = this.props;
    const { loggedIn, authUser } = authStore;

    if (!loggedIn) {
      return <Redirect to="/login" />;
    }

    if (!authUser) {
      return <PageLoader />;
    }

    return (
      <>
        <Navbar />
        <PageTitle />
        <DashboardPageTitle />
        <MainMenu />
        {getNestedRoutes(routes)}
      </>
    );
  }
}

export default Layout;
