import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'litigator_statuses';

const getAllLitigatorStatuses = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const LitigatorStatusesRepo = new BaseRepo(type);

export default {
  ...LitigatorStatusesRepo,
  getAllLitigatorStatuses
};
