import TableStore from './TableStore';
import { observable, action } from 'mobx';
import QuestionsRepo from '../repositories/QuestionsRepo';
import to from '../../lib/awaitTo';

class QuestionsStore extends TableStore<any> {
  @observable questions: any;

  @action
  setQuestions = (data: any) => {
    this.questions = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(QuestionsRepo.getAllQuestions({}));
    if (res) {
      this.setQuestions(res.data);
    }
  };
}

export const questionsStore = new QuestionsStore(QuestionsRepo);
export default questionsStore;
