import { action, observable, runInAction } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { pickBy } from 'lodash';
import reportsFilterRepo from 'data/repositories/ReportsFilterRepo';

class FilterReportsStore extends TableStore<any> {
  @observable tableData: any = null;

  @observable remainingFilters = [
    { id: 'Врста предлога', visible: true },
    { id: 'Место прве стране', visible: true },
    { id: 'Место друге стране', visible: true },
    { id: 'Начин избора миритеља односно арбитра', visible: true },
    { id: 'Миритељ односно арбитар', visible: true },
    { id: 'Сагласност', visible: true },
    { id: 'Начин окончања поступка', visible: true },
    { id: 'Одбацивање', visible: true },
    { id: 'Обустава', visible: true }
  ];

  @action
  removeFilter = (filterName: string) => {
    runInAction(() => {
      this.remainingFilters = this.remainingFilters.map((item: any) =>
        item.id !== filterName ? item : { ...item, visible: false }
      );
    });
  };

  @action
  addFilter = (filterName: string) => {
    runInAction(() => {
      this.remainingFilters = this.remainingFilters.map((item: any) =>
        item.id !== filterName ? item : { ...item, visible: true }
      );
    });
  };

  @action
  requestReport = async (data: any) => {
    const filledData = pickBy(data);
    const { dateFrom, dateTo, ...remainingData } = filledData;

    const [response] = await to(
      reportsFilterRepo.getInitialReport(dateFrom, dateTo, remainingData)
    );

    if (response) {
      runInAction(() => {
        this.tableData = response.data;
      });
    }
  };
}

export const filterReportsStore = new FilterReportsStore(reportsFilterRepo);
export default FilterReportsStore;
