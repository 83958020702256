import axios, { AxiosRequestConfig } from 'axios';

import { LoginParams, RefreshTokenParams } from '../../data/types';

export const login = (data: LoginParams) => {
  return axios.post('/login', data);
};

export const getAuthUser = (config: AxiosRequestConfig) => {
  return axios.get('/authenticated-user', config);
};

export const getAllSelectOptions = () => {
  return axios.get('/getallselectoptions');
};

export const logout = () => {};

export const refreshToken = (data: RefreshTokenParams) => {
  return axios.post('clients/admin/refresh', data);
};
