import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import OfficeDocumentsRepo from '../repositories/OfficeDocumentsRepo';
import { Query } from '../../lib/queryBuilder';

class OfficeDocumentsStore extends TableStore<any> {
  @observable officeDocuments: any;

  @action
  setOfficeDocuments = (data: any) => {
    this.officeDocuments = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(OfficeDocumentsRepo.getAllOfficeDocuments({}));
    if (res) {
      this.setOfficeDocuments(res.data);
    }
  };

  @action
  fetchAllOfficeDocuments = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes,
      ...this.customQueryParams
    };

    // let response: any = await to(
    //   this.repo.getAll({ params: this.queryParams })
    // );

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };
}

export const officeDocumentsStore = new OfficeDocumentsStore(
  OfficeDocumentsRepo
);
export default officeDocumentsStore;
