import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'reports';

const getInitialReport = (
  fromDate: any,
  toDate: any,
  config: AxiosRequestConfig
) => {
  return axios.get(`${type}?dateFrom=${fromDate}&dateTo=${toDate}`, config);
};

const ReportsRepo = new BaseRepo(type);

export default {
  ...ReportsRepo,
  getInitialReport
};
