import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'document_types';

const getAllDocumentTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const DocumentTypesRepo = new BaseRepo(type);

export default {
  ...DocumentTypesRepo,
  getAllDocumentTypes
};
