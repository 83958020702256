import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddOtherLitigationModal from './components/AddOtherLitigationModal';
import { otherLitigationsStore } from '../../data/stores/OtherLitigationsStore';
import { otherLitigationsArchiveStore } from './OtherLitigationsArchiveStore';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import { RowInfo } from 'react-table';
import './style.css';
import DateFromTimestampCell from 'components/TableCells/DateFromTimestampCell';
import SearchIcon from '@mui/icons-material/Search';
import LitigationNumberCell from 'components/TableCells/LitigationNumberCell';
import axios from 'axios';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class OtherLitigationsArchivePage extends Component {
  componentDidMount() {
    otherLitigationsStore.fetchAll();
  }

  showAddLitigationModal = () => {
    otherLitigationsArchiveStore.hideAddButton();
    otherLitigationsArchiveStore.openModalForm();
  };

  hideAddLitigationModal = () => {
    otherLitigationsArchiveStore.showAddButton();
    otherLitigationsArchiveStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          // page detail
          // window.location.href = '/dashboard/litigations/' + row.original.id;
          // modal detail

          otherLitigationsArchiveStore.openModalForm(row.original);
        }
      }
    };
  };

  //   itemAction = (litigation: any) => {
  //     return (
  //       <div className="td-actions text-center">
  //         {/* <PermissionGuard permissionsWithAccess={permission.accidentDetails}> */}
  //         <Button
  //           style={{ color: 'black' }}
  //           onClick={() => {
  //             // litigationsListingStore.openModalForm(litigation);
  //           }}
  //           startIcon={<SearchIcon />}
  //         >
  //           ПРИКАЗ
  //         </Button>
  //         {/* </PermissionGuard> */}
  //       </div>
  //     );
  //   };

  render() {
    const { addButtonVisible } = otherLitigationsArchiveStore;

    const columns = [
      // {
      //   Header: '#',
      //   id: 'id',
      //   accessor: 'id',
      //   sortable: false,
      //   width: 50
      // },
      {
        Header: 'Датум',
        id: 'document_date_of_input',
        accessor: 'entry_date',
        Cell: DateFromTimestampCell(),
        sortable: true,
        width: 100
      },
      {
        Header: 'Бр. предмета',
        accessor: 'number',
        sortable: false,
        filterable: true,
        width: 200
      },
      {
        Header: 'Обрађивач',
        id: 'worker.firstname',
        accessor: 'worker',
        Cell: WorkerFullNameCellWithAvatar,
        sortable: false,
        filterable: true,
        width: 280
      },
      {
        Header: 'Напомене',
        id: 'notes',
        accessor: 'notes',
        sortable: false,
        filterable: true
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          {/* <Button
          onClick={() => console.log('data')}
          variant="contained"
          style={{
            visibility: 'hidden',
            padding: '5px 80px',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            float: 'right',
            marginBottom: '15px',
            width: '205px',
            marginRight: '15px',
            borderRadius: '4px',
            backgroundColor: '#1976d2',
            color: 'white'
          }}
        >
          <AddIcon style={{ fontSize: '14px', color: 'white' }} />
          Додај
        </Button> */}

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={
                otherLitigationsStore.fetchInactiveOtherLitigationsWithoutOther
              }
              meta={otherLitigationsStore.meta}
              data={otherLitigationsStore.data}
              getTdProps={this.getTdProps}
              loading={otherLitigationsStore.loading}
              defaultSort={[{ id: 'entryDate', desc: true }]}
            />
            <AddOtherLitigationModal
              className="add-other-litigation-modal"
              showModal={otherLitigationsArchiveStore.showModal}
              closeModalForm={this.hideAddLitigationModal}
              openItem={otherLitigationsArchiveStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default OtherLitigationsArchivePage;
