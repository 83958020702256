import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'legal_form_types';

const getAllLegalFormTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const LegalFormTypesRepo = new BaseRepo(type);

export default {
  ...LegalFormTypesRepo,
  getAllLegalFormTypes
};
