import { Component } from 'react';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable';
import { Button } from '@mui/material';
import { RowInfo } from 'react-table';
import { observable, action } from 'mobx';

import templatesStore from '../../data/stores/TemplatesStore';
import { templatesPageStore } from './TemplatesPageStore';
import DropZone from 'components/DropZone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinkCell from 'components/TableCells/LinkCell';
import SearchResultsComponent from 'components/SearchResultsComponent';

@observer
class TemplatesPage extends Component {
  @observable open: boolean = false;

  @observable currentTemplate: any;

  @action
  setOpen = (bool: boolean) => {
    this.open = bool;
  };

  @action
  setCurrentTemplate = (currentTemplate: any) => {
    this.currentTemplate = currentTemplate;
  };

  @action
  handleClose = () => {
    this.setOpen(false);
  };

  @action
  handleOpen = () => {
    this.setOpen(true);
  };

  handleDelete = (templateId: any) => {
    templatesPageStore.deleteTemplate(templateId);
    this.handleClose();
  };

  //   getTdProps = (_state: any, row?: RowInfo): object => {
  //     return {
  //       style: { cursor: 'pointer' },
  //       onClick: () => {
  //         if (row && row.original) {
  //           window.location.href = row.original.link;
  //         }
  //       }
  //     };
  //   };

  itemAction = (template: any) => (
    <div
      className="td-actions text-right"
      style={{ display: 'flex', zIndex: 2, position: 'relative' }}
    >
      <DropZone
        title={`Замени документ`}
        subtitle={`Могу се послати искључиво <span style="color: #ff4500 !important">DOCX</span> типови фајлова! </p>`}
        multiple={false}
        id={template.id}
        method={templatesPageStore.updateTemplate}
      />

      {/* <Button
        onClick={() => {
          this.setCurrentTemplate(template);
          this.handleOpen();
        }}
      >
        Обриши
      </Button> */}
    </div>
  );

  render() {
    const { addButtonVisible } = templatesPageStore;

    const columns = [
      {
        Header: '#',
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50
      },
      {
        Header: 'Редослед',
        id: 'order',
        accessor: 'order',
        sortable: false,
        width: 80
      },
      {
        Header: 'Шема документа',
        id: 'name',
        accessor: (template: any) => template,
        Cell: LinkCell,
        sortable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        filterable: false,
        width: 600
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          <div>
            <DropZone
              css={{ marginBottom: '10px' }}
              title={`Превуците шему документа овде, или кликните како бисте је одабрали`}
              subtitle={`Могу се послати искључиво <span style="color: #ff4500 !important">DOCX</span> типови фајлова! </p>`}
              multiple={false}
              method={templatesPageStore.uploadTemplate}
            />
            <DataTable
              enableCaching={false}
              // getTdProps={this.getTdProps}
              columns={columns}
              fetchData={templatesStore.fetch}
              data={templatesStore.attachCustomColumn(
                'actions',
                this.itemAction
              )}
              loading={templatesStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />

            {/* Delete Dialog */}
            <Dialog
              open={this.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Молимо вас да потврдите'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Да ли сте сигурни да желите да трајно обришете '
                  {this.currentTemplate && this.currentTemplate.name}'? Трајно
                  обрисане податке је немогуће повратити!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Не, одустани</Button>
                <Button
                  onClick={() => this.handleDelete(this.currentTemplate.id)}
                  autoFocus
                >
                  Да, обриши
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default TemplatesPage;
