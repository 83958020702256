import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LitigationsRepo from '../../data/repositories/LitigationsRepo';
import { litigationsStore } from '../../data/stores/LitigationsStore';

class LitigationsArchiveStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createLitigation = async (litigation: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLitigation(litigation);
    }

    return this.saveLitigation(litigation);
  };

  saveLitigation = async (litigation: any) => {
    const { create } = LitigationsRepo;

    const [response] = await to(create(litigation));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали предмет.'
        });
      }, 1000);
      litigationsStore.fetch();
      this.closeModalForm();
    }
  };

  updateLitigation = async (litigation: any) => {
    const [response] = await to(
      LitigationsRepo.update(litigation.id, litigation)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали предмет.'
        });
      }, 1000);
      // litigationsStore.fetch();
      // this.closeModalForm();
    }
  };
}

export const litigationsArchiveStore = new LitigationsArchiveStore(
  LitigationsRepo
);
export default litigationsArchiveStore;
