import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import SearchRepo from '../repositories/SearchRepo';
import { Query } from 'lib/queryBuilder';

class SearchStore extends TableStore<any> {
  @observable searchKeyword: string = '';
  @observable searchType: boolean = false;
  @observable searchResults: any = null;
  @observable dataFetched: any = null;
  @observable searchEntity: string = 'litigations';

  @action
  setSearchResults = (data: any) => {
    this.searchResults = data;
  };

  @action
  setDataFetched = (data: any) => {
    this.dataFetched = data;
  };

  @action
  setSearchType = (type: boolean) => {
    this.searchType = type;
  };

  @action
  setSearchEntity = (entity: string) => {
    this.searchEntity = entity;
  };

  @action
  setSearchKeyword = (keyword: string) => {
    this.searchKeyword = keyword;
  };

  @action
  searchByKeyword = async (params?: Query) => {
    console.log('PARAMS ', params);
    this.loading = true;
    this.error = false;
    // const customQueryParams = {
    //   isArchive: true
    // };
    this.queryParams = {
      ...params,
      include: this.includes
      // ...customQueryParams
    };

    const [res, error] = await to(
      SearchRepo.searchByKeyword(
        this.searchKeyword,
        this.searchType,
        this.searchEntity,
        { params: this.queryParams }
      )
    );
    if (res && res.data) {
      this.setDataFetched(true);
      this.onFetch(res.data.message.data, res.data.message);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  failedSearch = async () => {
    const [res, error] = await to(
      SearchRepo.searchByKeyword(
        'dasdasdasdd',
        this.searchType,
        this.searchEntity,
        {}
      )
    );
    if (res && res.data) {
      this.onFetch(res.data.message.data, res.data.message);
    }
    if (error) {
      this.onError();
    }
  };
}

export const searchStore = new SearchStore(SearchRepo);
export default searchStore;
