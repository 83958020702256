import { RouteComponentProps } from 'react-router';
import Layout from './components/Layout';
import LitigationsListingPage from './pages/LitigationsListingPage';
import ProposalTypesListPage from './pages/ProposalTypesListPage';
import QuestionsListingPage from './pages/QuestionsListingPage';
import ConsentsListPage from './pages/ConsentsListPage';
import ConclusionsListingPage from './pages/ConclusionsListingPage';
import LitigationTypesListingPage from './pages/LitigationsTypesListingPage';
import ProcedureTypesListingPage from './pages/ProcedureTypesListingPage';
import LegalFormTypesListingPage from './pages/LegalFormTypesListingPage';
import StatusesListingPage from 'pages/StatusesListingPage';
import Login from 'pages/Login';
import * as permission from './permission-consts';
import WelcomePage from 'pages/WelcomePage';
import PhasesListingPage from 'pages/PhasesListingPage';
import LitigatorStatusesListingPage from 'pages/LitigatorStatusesListingPage';
import Account from 'pages/Account';
import LitigatorsListingPage from 'pages/LitigatorsListingPage';
import RejectionReasonsListingPage from 'pages/RejectionReasonsListingPage';
import LitigatorTypesListingPage from 'pages/LitigatorTypesListingPage';
import FeeTypesListingPage from 'pages/FeeTypesListingPage';
import ExpenseTypesListingPage from 'pages/ExpenseTypesListingPage';
import GasExpenseTypesListingPage from 'pages/GasExpenseTypesListingPage';
import TemplatesPage from 'pages/TemplatesPage';
import OfficeDocumentsListingPage from 'pages/OfficeDocumentsListingPage/OfficeDocumentsListingPage';
import DocumentTypesListingPage from 'pages/DocumentTypesListingPage';
import WorkersPage from 'pages/WorkersPage';
import ReportsPage from 'pages/ReportsPage';
import LitigationsArchivePage from 'pages/LitigationsArchivePage';
import LitigationDetailPage from 'pages/LitigationDetailPage';
import Import from 'pages/Import/Import';
import ImportOfficeDocuments from 'pages/ImportOfficeDocuments/ImportOfficeDocuments';
import QuestionDetailsPage from 'pages/QuestionDetailsPage';
import ImportOtherLitigations from 'pages/ImportOtherLitigations';
import OtherLitigationsListingPage from 'pages/OtherLitigationsListingPage/OtherLitigationsListingPage';
import OtherLitigationsArchivePage from 'pages/OtherLitigationsArchivePage/OtherLitigationsArchivePage';
import StrikesListingPage from 'pages/StrikesListingPage/StrikesListingPage';
import StrikeTypesListingPage from 'pages/StrikeTypesListingPage/StikeTypesListingPage';
import StrikePlacesListingPage from 'pages/StrikePlacesListingPage/StrikePlacesListingPage';
import StrikeMethodsListingPage from 'pages/StrikeMethodsListingPage/StrikeMethodsListingPage';
import StrikeCessationsListingPage from 'pages/StrikeCessationsListingPage/StrikeCessationsListingPage';
import StrikeEndingsListingPage from 'pages/StrikeEndingsListingPage/StrikeEndingsListingPage';
import LitigationsFilterPage from 'pages/LitigationsFilterPage/LitigationsFilterPage';
import FilterReportsPage from 'pages/ReportsPage/FilterReportsPage';
import Review from 'pages/Review/Review';
import ReviewsAgencyPage from 'pages/ReviewsAgencyPage/ReviewsAgencyPage';
import ReviewsLitigatorPage from 'pages/ReviewsLitigatorPage/ReviewsLitigatorPage';

// export type Layout = 'auth' | 'dashboard';

// TO-DO: remove mini from routes object and change it with bullet points in side menu

export interface Route {
  path: string;
  key: string;
  name: string;
  icon?: string;
  mini?: string;
  showOnMenu: boolean;
  placeholder?: boolean;
  routes?: Route[];
  permissions?: any;
  skipPermissionCheck?: boolean;
  component?:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

const appRoutes: Route[] = [
  {
    path: '/review/:party/:id',
    key: 'review',
    name: 'Review',
    showOnMenu: false,
    skipPermissionCheck: true,
    component: Review
  },
  {
    path: '/login',
    key: 'login',
    name: 'Login',
    showOnMenu: false,
    skipPermissionCheck: true,
    component: Login
  },
  {
    path: '/import',
    key: 'import',
    name: 'Import',
    showOnMenu: false,
    skipPermissionCheck: true,
    component: Import
  },
  {
    path: '/importofficedocuments',
    key: 'import.office.documents',
    name: 'Import Office Documents',
    showOnMenu: false,
    skipPermissionCheck: true,
    component: ImportOfficeDocuments
  },
  {
    path: '/importotherlitigations',
    key: 'import.other.litigations',
    name: 'Import Other Litigations',
    showOnMenu: false,
    skipPermissionCheck: true,
    component: ImportOtherLitigations
  },
  {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    showOnMenu: false,
    component: Layout,
    skipPermissionCheck: true,
    routes: [
      {
        path: '/dashboard/welcome',
        key: 'dashboard.welcome',
        name: 'Добродошли',
        component: WelcomePage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/questions',
        key: 'dashboard.questions',
        name: 'Преглед питања',
        component: QuestionsListingPage,
        permissions: permission.questionsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/questions/:id',
        key: 'dashboard.questions.details',
        name: 'Преглед питања',
        component: QuestionDetailsPage,
        permissions: permission.questionsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigations',
        key: 'dashboard.litigations',
        name: 'Преглед предмета',
        component: LitigationsListingPage,
        permissions: permission.litigationsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/filter-litigations',
        key: 'dashboard.filter.litigations',
        name: 'Филтрирање предмета',
        component: LitigationsFilterPage,
        permissions: permission.litigationsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/reviews-agency',
        key: 'dashboard.reviews.agency',
        name: 'Оцене Агенције',
        component: ReviewsAgencyPage,
        permissions: permission.reviewsAgency,
        showOnMenu: false
      },
      {
        path: '/dashboard/reviews-litigator',
        key: 'dashboard.reviews.litigator',
        name: 'Оцене Миритеља',
        component: ReviewsLitigatorPage,
        permissions: permission.reviewsLitigator,
        showOnMenu: false
      },
      {
        path: '/dashboard/other-litigations',
        key: 'dashboard.other.litigations',
        name: 'Преглед осталих предмета',
        component: OtherLitigationsListingPage,
        permissions: permission.otherLitigationsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/other-litigations-archive',
        key: 'dashboard.other.litigations.archive',
        name: 'Преглед архиве осталих предмета',
        component: OtherLitigationsArchivePage,
        permissions: permission.otherLitigationsArchivedView,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigations/:id',
        key: 'dashboard.litigations.details',
        name: 'Предмет',
        permissions: permission.litigationsView,
        component: LitigationDetailPage,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigations-archive',
        key: 'dashboard.litigations.archive',
        name: 'Архива предмета',
        component: LitigationsArchivePage,
        permissions: permission.litigationsArchivedView,
        showOnMenu: false
      },
      {
        path: '/dashboard/strikes',
        key: 'dashboard.strikes',
        name: 'Преглед захтева за штрајк',
        component: StrikesListingPage,
        permissions: permission.strikesView,
        showOnMenu: false
      },
      {
        path: '/dashboard/procedure-types',
        key: 'dashboard.procedure.types',
        name: 'Врсте поступка',
        component: ProcedureTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/phases',
        key: 'dashboard.phases',
        name: 'Врсте фаза',
        component: PhasesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigator-statuses',
        key: 'dashboard.litigator.statuses',
        name: 'Врсте начина избора миритеља односно арбитра',
        component: LitigatorStatusesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/legal-form-types',
        key: 'dashboard.legal.form.types',
        name: 'Врсте правних форми',
        component: LegalFormTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/statuses',
        key: 'dashboard.statuses',
        name: 'Врсте статуса',
        component: StatusesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/templates',
        key: 'dashboard.templates',
        name: 'Шеме докумената',
        component: TemplatesPage,
        // skipPermissionCheck: true,
        permissions: permission.codeBooksView,
        showOnMenu: false
      },
      {
        path: '/dashboard/workers',
        key: 'dashboard.workers',
        name: 'Преглед корисника',
        component: WorkersPage,
        permissions: permission.usersView,
        showOnMenu: false
      },
      {
        path: '/dashboard/reports',
        key: 'dashboard.reports',
        name: 'Извештаји',
        component: ReportsPage,
        permissions: permission.reportsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/filter-reports',
        key: 'dashboard.filter.reports',
        name: 'Филтрирање Извештаја',
        component: FilterReportsPage,
        permissions: permission.reportsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/office-documents',
        key: 'dashboard.office.documents',
        name: 'Преглед докумената',
        component: OfficeDocumentsListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/office-documents-types',
        key: 'dashboard.office.documents.types',
        name: 'Врсте докумената',
        component: DocumentTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigators',
        key: 'dashboard.litigators',
        name: 'Преглед миритеља и арбитара',
        component: LitigatorsListingPage,
        permissions: permission.litigatorsView,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigator-types',
        key: 'dashboard.litigator.types',
        name: 'Врсте миритеља и арбитара',
        component: LitigatorTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/expense-types',
        key: 'dashboard.expense.types',
        name: 'Ценовник услуга',
        component: ExpenseTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/gas-expense-types',
        key: 'dashboard.gas.expense.types',
        name: 'Ценовник горива',
        component: GasExpenseTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/fee-types',
        key: 'dashboard.fee.types',
        name: 'Врсте трошкова',
        component: FeeTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/rejection-reasons',
        key: 'dashboard.rejection.reasons',
        name: 'Разлози одбацивања',
        component: RejectionReasonsListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/account',
        key: 'dashboard.account',
        name: 'Мој налог',
        component: Account,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/proposal-types',
        key: 'dashboard.proposal.types',
        name: 'Врсте предлога',
        component: ProposalTypesListPage,
        // permissions: [
        //   permission.dashboardStatisticsSignUp,
        //   permission.dashboardStatisticsCountry
        // ].flat(),
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/litigation-types',
        key: 'dashboard.litigation.types',
        name: 'Врсте радних спорова',
        component: LitigationTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/strike-types',
        key: 'dashboard.strike.types',
        name: 'Врсте штрајка',
        component: StrikeTypesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/strike-places',
        key: 'dashboard.strike.places',
        name: 'Место одржавања штрајка',
        component: StrikePlacesListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/strike-methods',
        key: 'dashboard.strike.methods',
        name: 'Начин одржавања штрајка',
        component: StrikeMethodsListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/strike-cessations',
        key: 'dashboard.strike.cessations',
        name: 'Начин прекидања штрајка',
        component: StrikeCessationsListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/strike-endings',
        key: 'dashboard.strike.endings',
        name: 'Начин окончања штрајка',
        component: StrikeEndingsListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/consents',
        key: 'dashboard.consents',
        name: 'Врсте сагласности',
        component: ConsentsListPage,
        skipPermissionCheck: true,
        showOnMenu: false
      },
      {
        path: '/dashboard/conclusions',
        key: 'dashboard.conclusions',
        name: 'Врсте закључака',
        component: ConclusionsListingPage,
        skipPermissionCheck: true,
        showOnMenu: false
      }
    ]
  }
];

export const flatListOfRoutes = (
  data: Route[] = appRoutes,
  res: Route[] = []
): Route[] => {
  // eslint-disable-next-line consistent-return
  data.forEach((current) => {
    if (current.routes) {
      const { routes, ...route } = current;
      res.push(route);
      return flatListOfRoutes(routes, res);
    }
    res.push(current);
  });
  return res;
};

export const flatRoutes = flatListOfRoutes();
export default appRoutes;
