import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import ProcedureTypesRepo from '../repositories/ProcedureTypesRepo';

class ProcedureTypesStore extends TableStore<any> {
  @observable procedureTypes: any;

  @action
  setProcedureTypes = (data: any) => {
    this.procedureTypes = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(ProcedureTypesRepo.getAllProcedureTypes({}));
    if (res) {
      this.setProcedureTypes(res.data);
    }
  };
}

export const procedureTypesStore = new ProcedureTypesStore(ProcedureTypesRepo);
export default procedureTypesStore;
