import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { strikeTypesStore } from '../../data/stores/StrikeTypesStore';
import StrikeTypesRepo from '../../data/repositories/StrikeTypesRepo';

class StrikeTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitStrikeType = async (strikeType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStrikeType(strikeType);
    }

    return this.createStrikeType(strikeType);
  };

  createStrikeType = async (strikeType: any) => {
    const { create } = StrikeTypesRepo;

    const [response] = await to(create(strikeType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту штрајка.'
        });
      }, 1000);
      strikeTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateStrikeType = async (strikeType: any) => {
    const { update } = StrikeTypesRepo;

    const [response] = await to(update(strikeType.id, strikeType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту штрајка.'
        });
      }, 1000);
      strikeTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const strikeTypesListingStore = new StrikeTypesListingStore(
  StrikeTypesRepo
);
export default strikeTypesListingStore;
