import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import { strikeEndingsStore } from '../../data/stores/StrikeEndingsStore';
import StrikeEndingsRepo from '../../data/repositories/StrikeEndingsRepo';

class StrikeEndingsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitStrikeEnding = async (strikeEnding: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStrikeEnding(strikeEnding);
    }

    return this.createStrikeEnding(strikeEnding);
  };

  createStrikeEnding = async (strikeEnding: any) => {
    const { create } = StrikeEndingsRepo;

    const [response] = await to(create(strikeEnding));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали начин окончања штрајка.'
        });
      }, 1000);
      strikeEndingsStore.fetch();
      this.closeModalForm();
    }
  };

  updateStrikeEnding = async (strikeEnding: any) => {
    const { update } = StrikeEndingsRepo;

    const [response] = await to(update(strikeEnding.id, strikeEnding));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали начин окончања штрајка.'
        });
      }, 1000);
      strikeEndingsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const strikeEndingsListingStore = new StrikeEndingsListingStore(
  StrikeEndingsRepo
);
export default strikeEndingsListingStore;
