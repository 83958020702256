import React from 'react';
import { CellInfo } from 'react-table';

const LitigationNumberCell = ({ value }: CellInfo) => {
  if (!value) return null;
  return (
    <span>
      <strong>{value.substring(8, 17)}</strong>
    </span>
  );
};

export default LitigationNumberCell;
