import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import to from '../../lib/awaitTo';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SearchIcon from '@mui/icons-material/Search';
import { authStore } from 'data/stores/AuthStore';
import { childLitigationStore } from 'data/stores/ChildLitigationStore';
import { searchStore } from 'data/stores/SearchStore';
import { pageStore } from 'data/stores/PageStore';
import litigationDetailStore from 'pages/LitigationDetailPage/LitigationDetailStore';
import LitigationsRepo from 'data/repositories/LitigationsRepo';

const settings = ['МОЈ НАЛОГ', 'ОДЈАВА ИЗ АПЛИКАЦИЈЕ'];

const CssTextField = withStyles({
  label: {
    color: 'white'
  },
  placeholder: {
    color: 'white'
  },
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      }
    }
  }
})(TextField);

const Navbar = (props: any) => {
  const { authUser } = authStore;
  const { openItem } = litigationDetailStore;
  const pagePathName = window.location.pathname;
  let isArchive: boolean;
  const [currentLitigation, setCurrentLitigation] = React.useState<any>(null);

  React.useEffect(() => {
    const pagePathName = window.location.pathname;
    const getArchiveStatus = async () => {
      if (pagePathName.includes('/dashboard/litigations/')) {
        let litigationId = pagePathName.substring(23);
        return await to(LitigationsRepo.one(litigationId));
      }
    };

    if (pagePathName.includes('/dashboard/litigations/')) {
      getArchiveStatus().then((data: any) => {
        setCurrentLitigation(data[0].data.data);
        //console.log('Litigation: ', data[0].data.data);
      });
    }
    return () => console.log('unmoount');
  }, []);

  // pretraga zavisi od toga da li je korisnik na stranici arhiviranih ili aktivnih predmeta
  if (pagePathName.includes('archive')) {
    isArchive = true;
  } else {
    isArchive = false;
  }

  if (pagePathName.includes('/dashboard/litigations/')) {
    if (currentLitigation !== null) {
      if (currentLitigation!.status) {
        isArchive = false;
      } else {
        isArchive = true;
      }
    }
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  if (!authUser) {
    return null;
  }

  return (
    <AppBar
      style={{
        backgroundColor: '#3f51b5'
      }}
      position="static"
    >
      <div
        style={{
          paddingLeft: '20px',
          paddingRight: '20px'
        }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' }
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center'
              }}
            >
              <img
                style={{ height: '40px' }}
                src="/logo.png"
                alt="logo"
              />
              <img
                style={{ height: '26px', marginLeft: '15px' }}
                src="/logo-typography.png"
                alt="logo-typography"
              />
            </div>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton> */}
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <img
              style={{ height: '40px' }}
              src="/logo.png"
              alt="logo"
            />
          </Typography>

          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
            }}
          >
            <Tooltip title="Прикажи опције">
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingBottom: '20px',
                    marginRight: '20px'
                  }}
                >
                  <SearchIcon
                    sx={{ color: 'white', fontSize: 20, mr: 1, my: 0.5 }}
                  />
                  <CssTextField
                    onKeyUp={(e: any) => {
                      searchStore.setSearchKeyword(e.target.value);

                      if (e.keyCode === 13) {
                        searchStore.setSearchType(isArchive);
                        if (pagePathName.includes('other')) {
                          searchStore.setSearchEntity('other');
                        }
                        if (e.target.value === '') {
                          // revert all previous results
                          searchStore.setSearchKeyword('');
                          searchStore.searchByKeyword();
                        } else {
                          // searchStore.setSearchKeyword(e.target.value);
                          searchStore.searchByKeyword();
                        }
                      }

                      // if (searchStore.searchKeyword === '') {
                      //   searchStore.setSearchKeyword('');
                      //   searchStore.searchByKeyword();
                      // }
                    }}
                    inputProps={{
                      style: {
                        fontSize: 16,
                        color: 'white',
                        borderColor: 'white'
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                        color: 'white',
                        borderColor: 'white'
                      }
                    }}
                    id="input-with-sx"
                    label="Претрага ..."
                    variant="standard"
                  />
                </Box>
                <IconButton
                  style={{
                    color: 'white',
                    fontFamily: 'Roboto',
                    fontWeight: 400
                  }}
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  {authUser.image_link !== null ? (
                    <Avatar
                      alt={authUser.name}
                      style={{ marginRight: 10 }}
                      src={authUser.image_link}
                    />
                  ) : (
                    <Avatar
                      alt={authUser.name}
                      style={{ marginRight: 10 }}
                      src="/static/images/avatar/2.jpg"
                    />
                  )}
                  <span style={{ textTransform: 'uppercase' }}>
                    {authUser.firstname} {authUser.lastname}
                  </span>
                </IconButton>
              </>
            </Tooltip>
            <Menu
              style={{ zIndex: 999999 }}
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting: any, index: any) => (
                <div key={index}>
                  {setting === 'МОЈ НАЛОГ' ? (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        handleCloseUserMenu();
                        window.location = '/dashboard/account' as any;
                      }}
                    >
                      <Typography
                        textAlign="center"
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <PersonOutlinedIcon style={{ marginRight: '10px' }} />{' '}
                        {setting}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        authStore.logout();
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        textAlign="center"
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <PowerSettingsNewIcon style={{ marginRight: '10px' }} />{' '}
                        {setting}
                      </Typography>
                    </MenuItem>
                  )}
                </div>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </div>
    </AppBar>
  );
};
export default Navbar;
