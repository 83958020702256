import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import ForbiddenPage from '../../pages/Forbidden';
import ErrorPage from '../../pages/ErrorPage';
import { authStore } from '../../data/stores/AuthStore';
import { checkPermissions } from '../../lib/checkPermissions';
import PageTitle from '../PageTitle';
import ErrorBoundary from '../ErrorBoundary';

function RouteWithSubRoutes(route: any) {
  if (!route.permissions && !route.skipPermissionCheck) {
    return <Route exact {...route} component={ForbiddenPage} />;
  }

  if (route.permissions) {
    const showRoute = checkPermissions(
      authStore.authUserPermissions,
      route.permissions
    );

    if (!showRoute) {
      return <Route exact {...route} component={ForbiddenPage} />;
    }
  }

  return (
    <Route
      exact={route.path.includes('dashboard/')}
      path={route.path}
      render={props => {
        if (route.component) {
          return (
            <>
              <PageTitle />
              <ErrorBoundary ErrorComponent={ErrorPage}>
                <route.component {...props} routes={route.routes} />
              </ErrorBoundary>
            </>
          );
        }
        return null;
      }}
    />
  );
}

export default observer(RouteWithSubRoutes);
