import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import PhasesRepo from '../repositories/PhasesRepo';

class PhasesStore extends TableStore<any> {
  @observable phases: any;

  @action
  setPhases = (data: any) => {
    this.phases = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(PhasesRepo.getAllPhases({}));
    if (res) {
      this.setPhases(res.data);
    }
  };
}

export const phasesStore = new PhasesStore(PhasesRepo);
export default phasesStore;
