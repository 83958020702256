import { observable, action, computed } from 'mobx';
import { Filter, SortingRule } from 'react-table';

const initialValue = {
  page: 1,
  limit: 100
};

// interface DatesRule {
//   from: string;
//   to: string;
// }

interface Defaults {
  sorted?: SortingRule[];
  from?: string;
  to?: string;
}

class DataTableStore {
  @observable map = {};

  @computed
  get key() {
    const path = window.location.href.split('/');
    return path
      .slice(1)
      .slice(-2)
      .join('.');
  }

  @computed
  get query() {
    return this.map[this.key];
  }

  @action
  initTableStore(enableCaching: boolean, defaults?: Defaults, type?: string) {
    if (!this.map[this.key]) {
      this.map[this.key] = { initialValue, ...defaults };
    }

    if (this.map[this.key] && this.map[this.key].caching === false) {
      this.map[this.key] = { initialValue, ...defaults };
    }

    if (type && type !== undefined) {
      this.map['type'] = type;
    }

    this.setCaching(enableCaching);

    this.cleanUp();
  }

  // We want to keep cache maximum for one table.
  @action
  cleanUp() {
    if (Object.keys(this.map).length <= 1) return;

    this.map = {
      [this.key]: this.map[this.key]
    };
  }

  @action
  setPage(page: number) {
    this.map[this.key].page = page;
  }

  @action
  setLimit(limit: number) {
    this.map[this.key].limit = limit;
  }

  @action
  setFilters(filtered: Filter[]) {
    this.map[this.key].filtered = filtered;
  }

  @action
  setSorting(sorted: SortingRule[]) {
    this.map[this.key].sorted = sorted;
  }

  @action
  setFrom(from: any) {
    this.map[this.key].from = from;
  }

  @action
  setTo(to: any) {
    this.map[this.key].to = to;
  }

  @action
  setCaching(enableCaching: boolean) {
    this.map[this.key].caching = enableCaching;
  }

  @action
  clearCache() {
    this.map[this.key] = initialValue;
  }
}

export const dataTableStore = new DataTableStore();
export default DataTableStore;
