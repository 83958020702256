import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import FeeTypesRepo from '../../data/repositories/FeeTypesRepo';
import { feeTypesStore } from '../../data/stores/FeeTypesStore';

class FeeTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createFeeType = async (feeType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateFeeType(feeType);
    }

    return this.saveFeeType(feeType);
  };

  saveFeeType = async (feeType: any) => {
    const { create } = FeeTypesRepo;

    const [response] = await to(create(feeType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту трошка.'
        });
      }, 1000);
      feeTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateFeeType = async (feeType: any) => {
    const { update } = FeeTypesRepo;

    const [response] = await to(update(feeType.id, feeType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту трошка.'
        });
      }, 1000);
      feeTypesStore.fetch();
      this.closeModalForm();
    }
  };

  deleteFeeType = async (feeTypeId: any) => {
    const [response] = await to(FeeTypesRepo.delete(feeTypeId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали врсту трошка.'
        });
      }, 1000);
      feeTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const feeTypesListingStore = new FeeTypesListingStore(FeeTypesRepo);
export default FeeTypesListingStore;
