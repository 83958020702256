import { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Button, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import { litigationsArchiveStore } from '../LitigationsArchiveStore';
import { DropzoneArea } from 'material-ui-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import './style.css';
import { Box, Grid } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import to from '../../../lib/awaitTo';
import ServerSelect from 'components/ServerSelect';
import { LitigatorType } from '../../../data/types';
import LitigatorTypesRepo from '../../../data/repositories/LitigatorTypesRepo';
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import SelectField from 'components/SelectField';
import { observable, action } from 'mobx';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Thumb from 'components/Thumb';
import { litigatorAvatarsStore } from '../../../data/stores/LitigatorAvatarsStore';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextFIELD from '@mui/material/TextField';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import WorkersRepo from 'data/repositories/WorkersRepo';
import ServerSelectAutocomplete from 'components/ServerSelectAutocomplete';
import PhasesRepo from 'data/repositories/PhasesRepo';
import ProcedureTypesRepo from 'data/repositories/ProcedureTypesRepo';
import ProposalTypesRepo from 'data/repositories/ProposalTypesRepo';
import LegalFormTypesRepo from 'data/repositories/LegalFormTypesRepo';
import LitigationsRepo from 'data/repositories/LitigationsRepo';
import LitigatorStatusesRepo from 'data/repositories/LitigatorStatusesRepo';
import LitigatorsRepo from 'data/repositories/LitigatorsRepo';
import DocumentTypesRepo from 'data/repositories/DocumentTypesRepo';
import ConsentsRepo from 'data/repositories/ConsentsRepo';
import StatusesRepo from 'data/repositories/StatusesRepo';
import RejectionReasonsRepo from 'data/repositories/RejectionReasonsRepo';
import ConclusionsRepo from 'data/repositories/ConclusionsRepo';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import Menu from '@mui/material/Menu';
import { officeDocumentsListingStore } from '../../OfficeDocumentsListingPage/OfficeDocumentsListingStore';
import DropZone from '../../../components/DropZone';
import DataTable from '../../../components/DataTable';
import { officeDocumentFileUploadsStore } from '../../../data/stores/OfficeDocumentFileUploadsStore';
import LitigationTypesRepo from 'data/repositories/LitigationTypesRepo';
import authStore from 'data/stores/AuthStore';
import { RouteComponentProps } from 'react-router';

interface AddLitigationModalProps {
  className?: string;
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
  backLink?: boolean;
}

const DEFAULT_VALUES = {
  id: '',
  payment_status: '',
  status: '',
  document_type_id: null,
  document_date_of_input: '',
  litigation_number: null,
  working_on_user_id: null,
  worker: null,
  hasParent: 0,
  procedure_type_id: null,
  procedure_type: null,
  procedure_phase_id: null,
  parent: null,
  parent_id: null,
  parent_id_litigation_number: null,
  phase: null,
  decision_making_date: null,
  additional_info: '',
  conciliator_choosing_way_id: null,
  conciliator_id: null,
  litigator: null,
  litigator_id: null,
  consent_id: null,
  consent: null,
  finalizing_procedure_way: null,
  finalizing_procedure_way_id: null,
  rejection_reason: null,
  rejection_reason_id: null,
  conclusion: null,
  conclusion_id: null,
  proposal_type_id: null,
  proposal_type: null,
  litigation_type_id: null,
  litigation_type: null,
  legal_form_first_party_id: null,
  legal_form_first_party: null,
  legal_form_second_party_id: null,
  legal_form_second_party: null,
  municipality_first_party: '',
  municipality_second_party: '',
  name_first_party: '',
  name_second_party: '',
  street_first_party: '',
  street_second_party: '',
  phone_first_party: '',
  phone_second_party: '',
  street_number_first_party: '',
  street_number_second_party: '',
  city_first_party_id: null,
  city_first_party: null,
  city_second_party_id: null,
  city_second_party: null,
  post_number_first_party: null,
  post_number_second_party: null,
  litigator_status: null,
  litigator_status_id: null,
  other: false
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600]
  }
}));

const columns = [
  {
    Header: '#',
    id: 'id',
    accessor: 'id',
    filterable: false,
    sortable: false,
    width: 50
  },
  {
    Header: 'Врста документа',
    id: 'name',
    accessor: 'name',
    filterable: false,
    sortable: false
  },
  {
    Header: 'Датум',
    id: 'created_at',
    accessor: 'created_at',
    sortable: false,
    filterable: false,
    width: 80
  },
  {
    Header: 'Време',
    id: 'time',
    accessor: 'time',
    sortable: false,
    filterable: false,
    width: 80
  },
  {
    Header: 'Тип',
    id: 'type',
    accessor: 'type',
    filterable: false,
    sortable: false,
    width: 80
  },
  {
    Header: 'Акције',
    accessor: 'actions',
    sortable: false,
    filterable: false,
    width: 260
  }
];

@observer
class AddLitigationModal extends Component<any> {
  @observable switchValue: any = 1;

  @observable hasParent: any = 0;

  @observable fillFirstSide: any = 0;

  @observable fillSecondSide: any = 0;

  @observable parent_id: any = null;

  @observable parent_id_litigation_number: any = null;

  @observable paymentStatus: any = 0;

  @observable partiesInfo: any = {
    legal_form_first_party_id: null,
    legal_form_second_party_id: null,
    name_first_party: null,
    name_second_party: null,
    municipality_first_party: null,
    municipality_second_party: null,
    phone_number_first_party: null,
    phone_number_second_party: null,
    street_first_party: null,
    street_second_party: null,
    street_number_first_paty: null,
    street_number_second_paty: null,
    city_first_party: null,
    city_second_party: null,
    post_number_first_party: null,
    post_number_second_party: null
  };

  @observable anchorEl: any = false;

  @observable litigationsArray: any[] = [];

  @observable open: boolean = Boolean(this.anchorEl);

  @observable documentTypes: any = [];

  @observable documentButtonSwitch: boolean = false;

  @observable allSelectOptions: any = null;

  @action
  setAllSelectOptions = (data: any) => {
    this.allSelectOptions = data;
  };

  fetchDocumentTypesByPhaseId = async () => {
    const { openItem } = this.props;

    let queryParams = openItem ? openItem.procedure_phase_id : '';
    const [res] = await to(
      DocumentTypesRepo.getAll({
        params: { search: `phase_id:${queryParams}` }
      })
    );
    if (res) {
      this.setDocumentTypes(res.data);
    }
  };

  async componentDidMount() {
    const allSelectOptions: any = JSON.parse(
      localStorage.getItem('allSelectOptions') || ''
    );

    this.setAllSelectOptions(allSelectOptions);

    const [response] = await to(LitigationsRepo.getAll({}));

    if (response) {
      this.setLitigationsArray(response.data);
    }
  }

  componentWillUnmount() {
    litigatorAvatarsStore.clearLitigatorAvatar();
  }

  @action
  setDocumentButtonSwitch = (bool: boolean) => {
    this.documentButtonSwitch = bool;
  };

  @action
  setParentId = (parentId: number | null) => {
    this.parent_id = parentId;
  };

  @action
  setLitigationsArray = (litigations: any) => {
    this.litigationsArray = litigations;
  };

  @action
  toggleDocumentButtonSwitch = () => {
    this.documentButtonSwitch = !this.documentButtonSwitch;
  };

  @action
  setFillFirstSide = (bool: any) => {
    this.fillFirstSide = bool;
  };

  @action
  setFillSecondSide = (bool: any) => {
    this.fillSecondSide = bool;
  };

  @action
  setSwitchValue = (value: any) => {
    this.switchValue = value;
  };

  @action
  setPaymentStatus = (status: any) => {
    this.paymentStatus = status;
  };

  @action
  setHasParent = (value: any) => {
    this.hasParent = value;
  };

  @action
  setDocumentTypes = (documentTypes: string) => {
    this.documentTypes = documentTypes;
  };

  @action
  setAnchorEl = (anchorEl: null | HTMLElement) => {
    this.anchorEl = anchorEl;
  };

  @action
  setOpen = (bool: any) => {
    this.open = bool;
  };

  @action
  setParentIdLitigationNumber = (parentIdLitigationNumber: any) => {
    this.parent_id_litigation_number = parentIdLitigationNumber;
  };

  onSubmit = (data: any) => {
    if (this.fillFirstSide) {
      data.legal_form_first_party_id = data.parent.legal_form_first_party_id;
      data.name_first_party = data.parent.name_first_party;
      data.municipality_first_party = data.parent.municipality_first_party;
      data.phone_first_party = data.parent.phone_first_party;
      data.street_first_party = data.parent.street_first_party;
      data.street_number_first_party = data.parent.street_number_first_party;
      data.city_first_party = data.parent.city_first_party;
      data.post_number_first_party = data.parent.post_number_first_party;
    }

    if (this.fillSecondSide) {
      data.legal_form_second_party_id = data.parent.legal_form_second_party_id;
      data.name_second_party = data.parent.name_second_party;
      data.municipality_second_party = data.parent.municipality_second_party;
      data.phone_second_party = data.parent.phone_second_party;
      data.street_second_party = data.parent.street_second_party;
      data.street_number_second_party = data.parent.street_number_second_party;
      data.city_second_party = data.parent.city_second_party;
      data.post_number_second_party = data.parent.post_number_second_party;
    }

    if (this.hasParent) {
      if (data.parent_id_litigation_number) {
        let parent_litigation_number = data.parent_id_litigation_number.split(
          ' - '
        )[0];
        if (parent_litigation_number !== '') {
          data.parent_litigation_number = parent_litigation_number;
        }
      } else {
        let parent_litigation_number = data.parent_id.split(' - ')[0];
        if (parent_litigation_number !== '') {
          data.parent_litigation_number = parent_litigation_number;
        }
      }
    }

    litigationsArchiveStore.updateLitigation(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;
    let hasParent = 0;

    if (openItem && openItem.id) {
      if (openItem.status) {
        this.setSwitchValue(1);
      } else {
        this.setSwitchValue(0);
      }

      if (openItem.payment_status) {
        this.setPaymentStatus(1);
      } else {
        this.setPaymentStatus(0);
      }

      if (openItem.parent_id) {
        hasParent = 1;
        this.setHasParent(1);
      } else {
        hasParent = 0;
        this.setHasParent(0);
      }

      officeDocumentFileUploadsStore.setCurrentLitigationId(openItem.id);

      // if (openItem.image_link && openItem.image_link !== '') {
      //   litigatorAvatarsStore.setImageLink(openItem.image_link);
      // }

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      return {
        ...pickedValues,
        document_time_of_input: moment(openItem.document_date_of_input).format(
          'HH:mm:ss'
        ),
        hasParent: hasParent
      };
    }

    return DEFAULT_VALUES;
  };

  handleChangeSwitchLabel = () => {
    if (this.switchValue) {
      this.setSwitchValue(0);
    } else {
      this.setSwitchValue(1);
    }
  };

  handleChangePaymentStatus = () => {
    if (this.paymentStatus) {
      this.setPaymentStatus(0);
    } else {
      this.setPaymentStatus(1);
    }
  };

  handleChangeHasParent = () => {
    if (this.hasParent) {
      // this.setParentIdLitigationNumber(null);
      // this.setParentId(null);
      this.setHasParent(0);
      this.setFillFirstSide(0);
      this.setFillSecondSide(0);
    } else {
      this.setHasParent(1);
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.fetchDocumentTypesByPhaseId();
    this.setAnchorEl(event.currentTarget);
    this.setOpen(true);
  };
  handleClose = () => {
    this.setAnchorEl(null);
    this.setOpen(false);
  };

  itemAction = (officeDocumentFileUpload: any) => (
    <div style={{ display: 'block' }}>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 9999 }}
      >
        <Button
          onClick={() => {
            officeDocumentsListingStore.deleteOfficeDocumentFile(
              officeDocumentFileUpload.id
            );
          }}
        >
          <DeleteIcon style={{ fontSize: 24, marginRight: 3 }} />
          Обриши документ
        </Button>
      </div>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 9999 }}
      >
        <Button
          onClick={() => {
            window.open(officeDocumentFileUpload.link, '_blank');
          }}
        >
          <PrintIcon style={{ fontSize: 24, marginRight: 3 }} />
          Погледај документ
        </Button>
      </div>
    </div>
  );

  render() {
    const { showModal, closeModalForm, className, backLink } = this.props;
    const { history } = this.props;
    // if (imageLink) {
    //   setImageLink(imageLink);
    // }

    return (
      <div style={{ position: 'relative' }}>
        <Modal className={className} show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Измена постојећег предмета
            <div>
              <Button
                onClick={() => this.toggleDocumentButtonSwitch()}
                variant="contained"
                style={{
                  backgroundColor: '#4051B5',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px',
                  marginRight: 15
                }}
              >
                {this.documentButtonSwitch ? (
                  <InsertDriveFileIcon
                    style={{ fontSize: 16, color: 'white' }}
                  />
                ) : (
                  <InsertDriveFileIcon
                    style={{ fontSize: 16, color: 'white' }}
                  />
                )}

                {this.documentButtonSwitch ? 'ДЕТАЉИ' : 'ДОКУМЕНТА'}
              </Button>
              <Button
                onClick={this.handleClick}
                variant="contained"
                style={{
                  backgroundColor: '#E30022',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px'
                }}
              >
                <AddIcon style={{ fontSize: 16, color: 'white' }} /> КРЕИРАЈ
              </Button>
              {/* CREATE MENU */}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={this.anchorEl}
                open={this.open}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {this.documentTypes.map((item: any, index: any) => (
                  <MenuItem onClick={this.handleClose}>{item.name}</MenuItem>
                ))}
              </Menu>
              <Button
                onClick={() => {
                  if (backLink === undefined) {
                    litigationsArchiveStore.showAddButton();
                    litigatorAvatarsStore.clearLitigatorAvatar();
                    if (this.documentButtonSwitch) {
                      this.toggleDocumentButtonSwitch();
                    }
                    closeModalForm();
                  } else {
                    window.history.back();
                  }
                }}
                variant="contained"
                style={{
                  backgroundColor: '#fb8c00',
                  fontSize: 14,
                  fontWeight: 400,
                  color: 'white',
                  width: '205px',
                  height: '36px',
                  borderRadius: '4px',
                  marginLeft: '15px'
                }}
              >
                <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                НАЗАД
              </Button>
            </div>
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body className="add-litigation-div">
                  {/* Document files */}

                  {/* Litigation data */}
                  <div
                    style={{
                      display: this.documentButtonSwitch ? 'none' : 'block'
                    }}
                  >
                    <Col md={12}>
                      <Row>
                        <Col md={3}>
                          <Row>
                            <Col md={12}>
                              <GreenSwitch
                                onChange={() => {
                                  setFieldValue(
                                    'status',
                                    this.switchValue ? 0 : 1
                                  );
                                  this.handleChangeSwitchLabel();
                                }}
                                defaultChecked={this.switchValue ? true : false}
                              />

                              {this.switchValue
                                ? 'Поступак у току'
                                : 'Предмет је архивиран'}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={9}>
                          <Row>
                            <Col md={3}>
                              <GreenSwitch
                                disabled={!this.switchValue}
                                onChange={() => {
                                  setFieldValue(
                                    'hasParent',
                                    this.hasParent ? 0 : 1
                                  );
                                  this.handleChangeHasParent();
                                }}
                                defaultChecked={this.hasParent ? true : false}
                              />
                              {this.hasParent
                                ? 'Здружени предмет'
                                : 'Здружи предмет'}
                            </Col>
                            <Col md={3}>
                              <GreenSwitch
                                disabled={!this.switchValue}
                                onChange={() => {
                                  setFieldValue(
                                    'payment_status',
                                    this.paymentStatus ? 0 : 1
                                  );
                                  this.handleChangePaymentStatus();
                                }}
                                defaultChecked={
                                  this.paymentStatus ? true : false
                                }
                              />

                              {this.paymentStatus ? 'Плаћено' : 'Није плаћено'}
                            </Col>
                            <Col
                              md={3}
                              style={{
                                visibility: this.hasParent
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <GreenSwitch
                                disabled={!this.switchValue}
                                onChange={() => {
                                  if (this.fillFirstSide) {
                                    this.setFillFirstSide(0);
                                  } else {
                                    this.setFillFirstSide(1);
                                  }
                                }}
                                defaultChecked={
                                  this.fillFirstSide ? true : false
                                }
                              />
                              Попуни прву страну
                            </Col>
                            <Col
                              md={3}
                              style={{
                                visibility: this.hasParent
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <GreenSwitch
                                disabled={!this.switchValue}
                                onChange={() => {
                                  if (this.fillSecondSide) {
                                    this.setFillSecondSide(0);
                                  } else {
                                    this.setFillSecondSide(1);
                                  }
                                }}
                                defaultChecked={
                                  this.fillSecondSide ? true : false
                                }
                              />
                              Попуни другу страну
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              InputProps={{
                                style: { fontSize: 16, width: '100%' }
                              }}
                              label="Датум уписа*"
                              value={values.document_date_of_input}
                              disabled
                              inputFormat="dd-MM-yyyy"
                              onChange={newValue => {
                                setFieldValue(
                                  'document_date_of_input',
                                  newValue
                                );
                              }}
                              renderInput={params => (
                                <TextFIELD
                                  variant="standard"
                                  name="document_date_of_input"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            disabled={true}
                            component={TextField}
                            type="text"
                            label="Време уписа*"
                            variant="standard"
                            name="document_time_of_input"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="litigation_number"
                            type="text"
                            label="Број предмета*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.working_on_user_id}
                            fullValue={values.worker}
                            showFields={['firstname', 'lastname']}
                            // repo={WorkersRepo}
                            data={this.allSelectOptions.workers}
                            display="name"
                            name="working_on_user_id"
                            fullValueName="worker"
                            valueAttr="id"
                            label="Обрађивач*"
                            queryParams={{ search: 'role_id:4' }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.procedure_phase_id}
                            fullValue={values.phase}
                            display="name"
                            // repo={PhasesRepo}
                            data={this.allSelectOptions.phases}
                            name="procedure_phase_id"
                            fullValueName="phase"
                            valueAttr="id"
                            label="Фаза поступка*"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              InputProps={{
                                style: { fontSize: 16, width: '100%' }
                              }}
                              label="Датум доношења решења/препоруке миритеља*"
                              value={values.decision_making_date}
                              inputFormat="dd.MM.yyyy"
                              onChange={newValue => {
                                setFieldValue(
                                  'decision_making_date',
                                  moment(newValue).format('YYYY-MM-DD')
                                );
                              }}
                              renderInput={params => (
                                <TextFIELD
                                  variant="standard"
                                  name="decision_making_date"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="additional_info"
                            type="text"
                            label="Напомене"
                            multiline
                            rows={5}
                            variant="standard"
                            InputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      <Row>
                        <Col md={6}>
                          <ServerSelectAutocomplete
                            style={{
                              display: this.hasParent ? 'block' : 'none'
                            }}
                            label={'Носећи предмет'}
                            id={'nesto8'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            properties={[
                              'litigation_number',
                              'name_second_party'
                            ]}
                            fieldValue={'parent_id'}
                            setFieldValue={setFieldValue}
                            value={
                              values.parent_id_litigation_number
                                ? values.parent_id_litigation_number
                                : values.parent_id
                            }
                            fullValueName="parent_id"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.procedure_type_id}
                            fullValue={values.procedure_type}
                            display="name"
                            // repo={ProcedureTypesRepo}
                            data={this.allSelectOptions.procedure_types}
                            name="procedure_type_id"
                            fullValueName="procedure_type"
                            valueAttr="id"
                            label="Врста поступка*"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.proposal_type_id}
                            fullValue={values.proposal_type}
                            display="name"
                            // repo={ProposalTypesRepo}
                            data={this.allSelectOptions.proposal_types}
                            name="proposal_type_id"
                            fullValueName="proposal_type"
                            valueAttr="id"
                            label="Врста предлога*"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.litigation_type_id}
                            fullValue={values.litigation_type}
                            display="litigation_name"
                            // repo={LitigationTypesRepo}
                            data={this.allSelectOptions.litigation_types}
                            name="litigation_type_id"
                            fullValueName="litigation_type"
                            valueAttr="id"
                            label="Предмет спора*"
                            queryParams={{
                              search: `procedure_type_id:${values.procedure_type_id}`
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.legal_form_first_party_id}
                            fullValue={values.legal_form_first_party}
                            // repo={LegalFormTypesRepo}
                            data={this.allSelectOptions.legal_form_types}
                            display="name"
                            name="legal_form_first_party_id"
                            fullValueName="legal_form_first_party"
                            valueAttr="id"
                            label="Правна форма прве стране"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.legal_form_second_party_id}
                            fullValue={values.legal_form_second_party}
                            // repo={LegalFormTypesRepo}
                            data={this.allSelectOptions.legal_form_types}
                            display="name"
                            name="legal_form_second_party_id"
                            fullValueName="legal_form_second_party"
                            valueAttr="id"
                            label="Правна форма друге стране"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Име и презиме/назив прве стране*'}
                            id={'nesto'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'name_first_party'}
                            fieldValue={'name_first_party'}
                            setFieldValue={setFieldValue}
                            value={values.name_first_party}
                          />
                        </Col>
                        <Col md={6}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Име и презиме/назив друге стране*'}
                            id={'nesto1'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'name_second_party'}
                            fieldValue={'name_second_party'}
                            setFieldValue={setFieldValue}
                            value={values.name_second_party}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="municipality_first_party"
                            type="text"
                            label="Општина прве стране"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="phone_first_party"
                            type="text"
                            label="Контакт број"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="municipality_second_party"
                            type="text"
                            label="Општина друге стране"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="phone_second_party"
                            type="text"
                            label="Контакт број"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Улица прве стране*'}
                            id={'nesto2'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'street_first_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'street_first_party'}
                            value={values.street_first_party}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="street_number_first_party"
                            type="text"
                            label="Број прве стране*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Улица друге стране*'}
                            id={'nesto3'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'street_second_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'street_second_party'}
                            value={values.street_second_party}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            disabled={!this.switchValue}
                            component={TextField}
                            name="street_number_second_party"
                            type="text"
                            label="Број друге стране*"
                            variant="standard"
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            style={{
                              width: '100%',
                              fontFamily: 'Roboto'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Место прве стране*'}
                            id={'nesto4'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'city_first_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'city_first_party'}
                            value={values.city_first_party}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Поштански број прве стране*'}
                            id={'nesto5'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'post_number_first_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'post_number_first_party'}
                            value={values.post_number_first_party}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Место друге стране*'}
                            id={'nesto6'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'city_second_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'city_second_party'}
                            value={values.city_second_party}
                          />
                        </Col>
                        <Col md={3}>
                          <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Поштански број друге стране*'}
                            id={'nesto7'}
                            repo={LitigationsRepo}
                            data={this.litigationsArray}
                            property={'post_number_second_party'}
                            setFieldValue={setFieldValue}
                            fieldValue={'post_number_second_party'}
                            value={values.post_number_second_party}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.conciliator_choosing_way_id}
                            fullValue={values.litigator_status}
                            // repo={LitigatorStatusesRepo}
                            data={this.allSelectOptions.litigator_statuses}
                            display="name"
                            name="conciliator_choosing_way_id"
                            fullValueName="litigator_status"
                            valueAttr="id"
                            label="Начин избора миритеља односно арбитра"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.conciliator_id}
                            fullValue={values.litigator}
                            showFields={['firstname', 'lastname']}
                            display="name"
                            // repo={LitigatorsRepo}
                            data={this.allSelectOptions.litigators}
                            name="conciliator_id"
                            fullValueName="litigator"
                            valueAttr="id"
                            label="Миритељ односно арбитар"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.consent_id}
                            fullValue={values.consent}
                            // repo={ConsentsRepo}
                            data={this.allSelectOptions.consents}
                            display="name"
                            name="consent_id"
                            fullValueName="consent"
                            valueAttr="id"
                            label="Сагласност"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.finalizing_procedure_way_id}
                            fullValue={values.finalizing_procedure_way}
                            // repo={StatusesRepo}
                            data={this.allSelectOptions.statuses}
                            display="name"
                            name="finalizing_procedure_way_id"
                            fullValueName="finalizing_procedure_way"
                            valueAttr="id"
                            label="Начин окончања поступка"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.rejection_reason_id}
                            fullValue={values.rejection_reason}
                            // repo={RejectionReasonsRepo}
                            data={this.allSelectOptions.rejection_reasons}
                            display="name"
                            name="rejection_reason_id"
                            fullValueName="rejection_reason"
                            valueAttr="id"
                            label="Одбацивање"
                          />
                        </Col>
                        <Col md={6}>
                          <CustomMaterialUiSelect
                            disabled={!this.switchValue}
                            setFieldValue={setFieldValue}
                            value={values.conclusion_id}
                            fullValue={values.conclusion}
                            fullValueName="conclusion"
                            display="name"
                            // repo={ConclusionsRepo}
                            data={this.allSelectOptions.conclusions}
                            name="conclusion_id"
                            valueAttr="id"
                            label="Обустава"
                          />
                        </Col>
                      </Row>
                      {/* Add more fields */}
                    </Col>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="text"
                    style={{
                      fontSize: 14,
                      color: 'black',
                      display: this.documentButtonSwitch ? 'none' : 'block'
                    }}
                  >
                    <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                    ОБРИШИ
                  </Button>
                  <Button
                    onClick={() => {
                      litigationsArchiveStore.showAddButton();
                    }}
                    type="submit"
                    variant="text"
                    style={{
                      fontSize: 14,
                      color: 'black',
                      display: this.documentButtonSwitch ? 'none' : 'block'
                    }}
                  >
                    <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                    САЧУВАЈ
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          />
          <div
            style={{
              display: this.documentButtonSwitch ? 'block' : 'none',
              padding: '0 20px',
              minHeight: '570px'
            }}
          >
            <DropZone
              modelType="Litigation"
              id={officeDocumentFileUploadsStore.currentLitigationId}
              css={{ marginBottom: '10px' }}
              title={`Превуците документ овде, или кликните како бисте га одабрали`}
              subtitle={``}
              multiple={false}
              method={officeDocumentsListingStore.uploadOfficeDocumentFile}
            />

            <DataTable
              enableCaching={false}
              // getTdProps={this.getTdProps}
              minRows={5}
              columns={columns}
              fetchData={officeDocumentsListingStore.fetchByLitigationId}
              data={officeDocumentsListingStore.attachCustomColumn(
                'actions',
                this.itemAction
              )}
              loading={officeDocumentsListingStore.loading}
              defaultSort={[{ id: 'createdAt', desc: true }]}
            />

            {/* <OfficeDocumentsFileUploadsDataTable
                      litigationId={values.id}
                    /> */}
          </div>
        </Modal>
        {/* <Dialog
          open={this.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Да ли сте сигурни да желите да трајно обришете ову шему
              докумената? Трајно обрисане податке је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Не, одустани</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default AddLitigationModal;
