import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { Toast, genericError, WarningDialog } from '../../../lib/dialogs';
import * as authRepo from 'data/repositories/AuthRepo';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import { Switch } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { officeDocumentsListingStore } from '../OfficeDocumentsListingStore';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import TimePicker from '@material-ui/lab/TimePicker';

import moment from 'moment';
import './style.css';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { TextField as TextFIELD } from '@material-ui/core';
import { OfficeDocumentType } from 'data/types';
import ServerSelect from 'components/ServerSelect';
import ServerSelectAutocomplete from 'components/ServerSelectAutocomplete';
import DocumentTypesRepo from 'data/repositories/DocumentTypesRepo';
import DropZone from 'components/DropZone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import OfficeDocumentsFileUploadsDataTable from './OfficeDocumentFileUploadsDataTable';
import WorkersRepo from 'data/repositories/WorkersRepo';
import ProposalTypesRepo from 'data/repositories/ProposalTypesRepo';
import LitigationsRepo from 'data/repositories/LitigationsRepo';
import RejectionReasonsRepo from 'data/repositories/RejectionReasonsRepo';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import litigationsStore from 'data/stores/LitigationsStore';
import officeDocumentFileUploadsStore from 'data/stores/OfficeDocumentFileUploadsStore';
import authStore from 'data/stores/AuthStore';

interface AddOfficeDocumentModalProps {
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
}

const DEFAULT_VALUES = {
  id: null,
  status: true,
  document_date_of_input: new Date(),
  document_type_id: '',
  document_type: '',
  working_on_user_id: null,
  litigation_number: null,
  time: '',
  notes: '',
  worker: null
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600]
  }
}));

@observer
class AddOfficeDocumentModal extends Component<AddOfficeDocumentModalProps> {
  @observable switchValue: boolean = true;

  @observable open: boolean = false;

  @observable currentFileUpload: any;

  @action
  setOpen = (bool: boolean) => {
    this.open = bool;
  };

  @action
  setSwitchValue = (value: boolean) => {
    this.switchValue = value;
  };

  submitButton = React.createRef() as any;
  deleteButton = React.createRef() as any;

  onSubmit = (data: any) => {
    if (officeDocumentFileUploadsStore.data.length === 0) {
      Toast.fire({
        type: 'warning',
        text: 'Није могуће сачувати предмет без приложених докумената'
      });
      return false;
    }
    if (data.id !== null && data.id !== undefined) {
      // edit litigation (add document to it)
      officeDocumentsListingStore.updateOfficeDocument(
        data,
        officeDocumentFileUploadsStore.currentOfficeDocumentId
      );
    } else {
      // create new litigation
      officeDocumentsListingStore.createOfficeDocument(
        data,
        data.document_type_id
      );
    }
  };

  getInitialValues = () => {
    const { openItem } = this.props;

    if (openItem && openItem.id) {
      officeDocumentFileUploadsStore.setCurrentOfficeDocumentId(openItem.id);
      officeDocumentFileUploadsStore.setCurrentOfficeDocumentFileId(
        openItem.id
      );
      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      return {
        ...pickedValues
      };
    }

    return DEFAULT_VALUES;
  };

  handleChangeSwitchLabel = () => {
    if (this.switchValue === true) {
      this.setSwitchValue(false);
    } else {
      this.setSwitchValue(true);
    }
  };

  @action
  handleClose = () => {
    this.setOpen(false);
  };

  @action
  handleOpen = () => {
    this.setOpen(true);
  };

  handleDelete = (officeDocumentFileId: any) => {
    officeDocumentsListingStore.deleteOfficeDocumentFile(officeDocumentFileId);
    this.handleClose();
  };

  @action
  checkIfExists = (litigationNumber: string) => {
    litigationsStore.fetchLitigationIdByLitigationNumber(litigationNumber);
  };

  render() {
    const { showModal, closeModalForm, openItem } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <Modal show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Додавање новог предмета
            <Button
              onClick={() => {
                officeDocumentsListingStore.showAddButton();
                closeModalForm();
              }}
              variant="contained"
              style={{
                backgroundColor: '#fb8c00',
                fontSize: 14,
                fontWeight: 400,
                color: 'white',
                width: '205px',
                height: '36px',
                borderRadius: '4px'
              }}
            >
              <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
              НАЗАД
            </Button>
          </Modal.Header>
          <Modal.Body style={{ minHeight: 600 }}>
            <Col md={3}>
              <Formik
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                render={({
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  setFieldTouched
                }) => (
                  <Form noValidate className="form-horizontal">
                    <Col md={12}>
                      <Row>
                        <GreenSwitch
                          onChange={() => {
                            setFieldValue(
                              'status',
                              this.switchValue === true ? false : true
                            );
                            this.handleChangeSwitchLabel();
                          }}
                          defaultChecked={
                            this.switchValue === true ? true : false
                          }
                        />
                        {this.switchValue === true
                          ? 'Документ је актуелан'
                          : 'Документ је архивиран'}
                      </Row>
                      <Row>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            InputProps={{
                              style: { fontSize: 16, width: '100%' }
                            }}
                            label="Датум уписа*"
                            value={values.document_date_of_input}
                            disabled
                            inputFormat="dd.MM.yyyy"
                            onChange={newValue => {
                              setFieldValue('document_date_of_input', newValue);
                            }}
                            renderInput={params => (
                              <TextFIELD
                                variant="standard"
                                name="document_date_of_input"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Row>
                      <Row>
                        {/* <Field
                          disabled={true}
                          component={TextField}
                          type="text"
                          label="Време уписа*"
                          variant="inline"
                          // name="time_date"
                          value={'2022-10-08'}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        /> */}

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            InputProps={{ style: { fontSize: 16 } }}
                            ampm={false}
                            variant="inline"
                            inputVariant="standard"
                            disabled
                            label="Време уписа*"
                            value={new Date()}
                            onChange={newValue =>
                              console.log(moment(newValue).format('HH:mm:ss'))
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Row>
                      <Row>
                        <CustomMaterialUiSelect
                          style={{ maxHeight: 300 }}
                          setFieldValue={setFieldValue}
                          value={values.document_type_id}
                          fullValue={values.document_type}
                          repo={DocumentTypesRepo}
                          display="name"
                          name="document_type_id"
                          fullValueName="document_type"
                          valueAttr="id"
                          label="Врста документа*"
                        />
                      </Row>
                      <Row>
                        <Field
                          component={TextField}
                          name="litigation_number"
                          type="text"
                          label="Број*"
                          variant="standard"
                          value={values.litigation_number}
                          onChange={(e: any) => {
                            // if (e.currentTarget.value.length > 18) {
                            this.checkIfExists(e.currentTarget.value);

                            setTimeout(() => {
                              setFieldValue(
                                'id',
                                officeDocumentFileUploadsStore.currentOfficeDocumentId
                              );
                            }, 800);

                            setFieldValue(
                              'litigation_number',
                              e.currentTarget.value
                            );

                            officeDocumentFileUploadsStore.setCurrentLitigationNumber(
                              e.currentTarget.value
                            );

                            // }
                          }}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Row>
                      <Row>
                        <CustomMaterialUiSelect
                          setFieldValue={setFieldValue}
                          value={values.working_on_user_id}
                          fullValue={values.worker}
                          showFields={['firstname', 'lastname']}
                          repo={WorkersRepo}
                          display="name"
                          name="working_on_user_id"
                          fullValueName="worker"
                          valueAttr="id"
                          label="Обрађивач*"
                          // queryParams={{ search: 'role_id:4' }}
                        />
                      </Row>
                      <Row>
                        <Field
                          component={TextField}
                          name="notes"
                          type="text"
                          multiline
                          rows={5}
                          maxRows={8}
                          label="Напомене"
                          variant="standard"
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          style={{
                            width: '100%',
                            fontFamily: 'Roboto'
                          }}
                        />
                      </Row>
                      <Row>
                      {litigationsStore.message ? 
                        <h5 style={{ color: 'red', fontSize: 20 }}><strong>{litigationsStore.message}</strong></h5>
                      : null }
                      </Row>
                    </Col>
                    <div style={{ display: 'none' }}>
                      <Button
                        variant="text"
                        style={{ fontSize: 14, color: 'black' }}
                        ref={this.deleteButton}
                      >
                        <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                        ОБРИШИ
                      </Button>
                      <Button
                        onClick={() =>
                          officeDocumentsListingStore.showAddButton()
                        }
                        ref={this.submitButton}
                        type="submit"
                        variant="text"
                        style={{ fontSize: 14, color: 'black' }}
                      >
                        <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                        САЧУВАЈ
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </Col>
            <Col md={9}>
              <div style={{ padding: '20px' }}>
                <div>
                  <DropZone
                    modelType="OfficeDocument"
                    id={officeDocumentFileUploadsStore.currentOfficeDocumentId}
                    css={{ marginBottom: '10px' }}
                    title={`Превуците шему документа овде, или кликните како бисте је одабрали`}
                    multiple={true}
                    accept={
                      'image/jpg,image/png,image/jpeg,application/pdf,application/doc,application/txt,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,.doc, .docx'
                    }
                    method={
                      // officeDocumentsListingStore.uploadOfficeDocumentFile
                      officeDocumentsListingStore.prepareOfficeDocumentFileForUpload
                    }
                  />

                  {/* Delete Dialog */}
                  <Dialog
                    open={this.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'Молимо вас да потврдите'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Да ли сте сигурни да желите да трајно обришете '
                        {this.currentFileUpload && this.currentFileUpload.name}
                        '? Трајно обрисане податке је немогуће повратити!
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose}>Не, одустани</Button>
                      <Button
                        onClick={() =>
                          this.handleDelete(this.currentFileUpload.id)
                        }
                        autoFocus
                      >
                        Да, обриши
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>

              <div style={{ padding: '0 20px', marginBottom: 30 }}>
                {officeDocumentFileUploadsStore.data.length === 0 ? (
                  <p
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                  >
                    Није могуће сачувати предмет без приложеног документа.
                  </p>
                ) : null}
                <OfficeDocumentsFileUploadsDataTable
                  officeDocumentId={
                    officeDocumentFileUploadsStore.currentOfficeDocumentId
                  }
                />
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="text"
              style={{ fontSize: 14, color: 'black' }}
              onClick={() => this.deleteButton.current.click()}
            >
              <CloseIcon style={{ fontSize: 20, color: 'black' }} />
              ОБРИШИ
            </Button>

            <Button
              disabled={
                !officeDocumentFileUploadsStore.data.length ? true : false
              }
              onClick={() => this.submitButton.current.click()}
              type="submit"
              variant="text"
              style={{
                fontSize: 14,
                color: 'black',
                opacity: !officeDocumentFileUploadsStore.data.length ? 0.5 : 1
              }}
            >
              <CheckIcon style={{ fontSize: 20, color: 'black' }} />
              САЧУВАЈ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddOfficeDocumentModal;
