import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import StrikesRepo from '../../data/repositories/StrikesRepo';
import { strikesStore } from '../../data/stores/StrikesStore';

class StrikesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createStrike = async (strike: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateStrike(strike);
    }

    return this.saveStrike(strike);
  };

  saveStrike = async (strike: any) => {
    const { create } = StrikesRepo;

    const [response] = await to(create(strike));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали штрајк.'
        });
      }, 1000);
      strikesStore.fetch();
      this.closeModalForm();
    }
  };

  updateStrike = async (strike: any) => {
    const [response] = await to(StrikesRepo.update(strike.id, strike));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали штрајк.'
        });
      }, 1000);
      strikesStore.fetchActiveLitigationsWithoutOther();
      this.closeModalForm();
    }
  };
}

export const strikesListingStore = new StrikesListingStore(StrikesRepo);
export default strikesListingStore;
