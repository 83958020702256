import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import StrikeCessationsRepo from '../repositories/StrikeCessationsRepo';

class StrikeCessationsStore extends TableStore<any> {
  @observable strikeCessations: any;

  @action
  setStrikeCessations = (data: any) => {
    this.strikeCessations = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StrikeCessationsRepo.getAllStrikeCessations({}));
    if (res) {
      this.setStrikeCessations(res.data);
    }
  };
}

export const strikeCessationsStore = new StrikeCessationsStore(
  StrikeCessationsRepo
);
export default strikeCessationsStore;
