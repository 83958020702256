import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import TemplatesRepo from '../repositories/TemplatesRepo';

class TemplatesStore extends TableStore<any> {
  @observable templates: any;

  @action
  setTemplates = (data: any) => {
    this.templates = data;
  };

  @action
  fetchAll = async () => {
    const [res] = await to(TemplatesRepo.getAllTemplates({}));
    if (res) {
      this.setTemplates(res.data);
    }
  };
}

export const templatesStore = new TemplatesStore(TemplatesRepo);
export default templatesStore;
