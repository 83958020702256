import React from 'react';
import { CellInfo } from 'react-table';

const AttachmentNameCell = ({ value }: CellInfo) => {
  if (!value) return null;
  if (value.real_name) {
    return value.real_name;
  } else {
    return value.name;
  }
};

export default AttachmentNameCell;
