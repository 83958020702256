import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import DataTable from '../../components/DataTable';
import { navigate } from '../../lib/routerHelper';
import { Button } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import AddLitigationModal from './components/AddLitigationModal';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import { litigationsListingStore } from './LitigationsListingStore';
import LitigatorFullNameCellWithAvatar from 'components/TableCells/LitigatorFullNameCellWithAvatar';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import { RowInfo } from 'react-table';
import './style.css';
import DateCell from 'components/TableCells/DateCell';
import SearchIcon from '@mui/icons-material/Search';
import LitigationNumberCell from 'components/TableCells/LitigationNumberCell';
import axios from 'axios';
import { pageStore } from '../../data/stores/PageStore';
import { searchStore } from '../../data/stores/SearchStore';
import SearchResultsComponent from '../../components/SearchResultsComponent';

@observer
class LitigationsListingPage extends Component<RouteComponentProps> {
  componentDidMount() {
    litigationsStore.fetchAll();
  }

  showAddLitigationModal = () => {
    litigationsListingStore.hideAddButton();
    litigationsListingStore.openModalForm();
  };

  hideAddLitigationModal = () => {
    litigationsListingStore.showAddButton();
    litigationsListingStore.closeModalForm();
    pageStore.setNavTitle('Преглед предмета');
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    const colorizeRow = (status: string) => {
      if (status === 'Сагласност добијена') return '#CAF2C2';
      if (status === 'Није добијена сагласност') return '#FEFFD6';
      if (status === 'Истекао рок за добијање сагласности') return '#FFD6C9';

      return '';
    };

    return {
      style: {
        cursor: 'pointer',
        background:
          row &&
          row.original &&
          row.original.consent &&
          row.original.consent.name
            ? colorizeRow(row.original.consent.name)
            : ''
      },
      onClick: () => {
        const { history } = this.props;
        if (row && row.original) {
          // page detail
          window.location.href = '/dashboard/litigations/' + row.original.id;
          // modal detail
          pageStore.setNavTitle(
            pageStore.navTitle + ' ' + row.original.litigation_number
          );

          // navigate(
          //   'dashboard.litigations.details',
          //   { history },
          //   { id: row.original.id }
          // );
          // litigationsListingStore.openModalForm(row.original);
        }
      }
    };
  };

  itemAction = (litigation: any) => {
    return (
      <div className="td-actions text-center">
        {/* <PermissionGuard permissionsWithAccess={permission.accidentDetails}> */}
        <Button
          style={{ color: 'black' }}
          onClick={() => {
            // litigationsListingStore.openModalForm(litigation);
          }}
          startIcon={<SearchIcon />}
        >
          ПРИКАЗ
        </Button>
        {/* </PermissionGuard> */}
      </div>
    );
  };

  render() {
    const { addButtonVisible } = litigationsListingStore;
    const { searchResults } = searchStore;

    const columns = [
      // {
      //   Header: '#',
      //   id: 'id',
      //   accessor: 'id',
      //   sortable: false,
      //   width: 50
      // },
      {
        Header: 'Датум',
        id: 'document_date_of_input',
        accessor: 'document_date_of_input',
        Cell: DateCell(),
        sortable: true,
        filterable: false,
        width: 100
      },
      {
        Header: 'Бр. предмета',
        accessor: 'litigation_number',
        Cell: LitigationNumberCell,
        sortable: false,
        filterable: true,
        width: 120
      },
      {
        Header: 'Прва страна',
        id: 'name_first_party',
        accessor: 'name_first_party',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Друга страна',
        accessor: 'name_second_party',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Предмет спора',
        id: 'litigation_type.litigation_name',
        accessor: 'litigation_type.litigation_name',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Фаза',
        accessor: 'phase.name',
        sortable: false,
        filterable: false
      },
      {
        Header: 'Миритељ и арбитар',
        id: 'litigator.litigator_firstname',
        accessor: 'litigator',
        Cell: LitigatorFullNameCellWithAvatar,
        sortable: false,
        filterable: true
      },
      {
        Header: 'Обрађивач',
        id: 'worker.firstname',
        accessor: 'worker',
        Cell: WorkerFullNameCellWithAvatar,
        sortable: false,
        filterable: true
      },
      {
        Header: 'Опције',
        accessor: 'actions',
        filterable: false,
        sortable: false
      }
    ];

    return (
      <SearchResultsComponent type="active">
        <div style={{ padding: '20px' }}>
          {/* <Button
          onClick={() => console.log('data')}
          variant="contained"
          style={{
            visibility: 'hidden',
            padding: '5px 80px',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            float: 'right',
            marginBottom: '15px',
            width: '205px',
            marginRight: '15px',
            borderRadius: '4px',
            backgroundColor: '#1976d2',
            color: 'white'
          }}
        >
          <AddIcon style={{ fontSize: '14px', color: 'white' }} />
          Додај
        </Button> */}

          <div>
            <DataTable
              enableCaching={false}
              columns={columns}
              fetchData={
                searchStore.dataFetched === true
                  ? searchStore.failedSearch
                  : litigationsStore.fetchActiveLitigationsWithoutOther
              }
              meta={litigationsStore.meta}
              data={
                searchStore.dataFetched === true
                  ? searchStore.attachCustomColumn('actions', this.itemAction)
                  : litigationsStore.attachCustomColumn(
                      'actions',
                      this.itemAction
                    )
              }
              getTdProps={this.getTdProps}
              loading={litigationsStore.loading}
              // defaultSort={[]}
            />
            <AddLitigationModal
              className="add-litigation-modal"
              showModal={litigationsListingStore.showModal}
              closeModalForm={this.hideAddLitigationModal}
              openItem={litigationsListingStore.openItem}
            />
          </div>
        </div>
      </SearchResultsComponent>
    );
  }
}

export default LitigationsListingPage;
