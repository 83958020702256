import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import DocumentTypesRepo from '../../data/repositories/DocumentTypesRepo';
import { documentTypesStore } from '../../data/stores/DocumentTypesStore';

class DocumentTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createDocumentType = async (documentType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateDocumentType(documentType);
    }

    return this.saveDocumentType(documentType);
  };

  saveDocumentType = async (documentType: any) => {
    const { create } = DocumentTypesRepo;

    const [response] = await to(create(documentType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту документа.'
        });
      }, 1000);
      documentTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateDocumentType = async (documentType: any) => {
    const { update } = DocumentTypesRepo;

    const [response] = await to(update(documentType.id, documentType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту документа.'
        });
      }, 1000);
      documentTypesStore.fetch();
      this.closeModalForm();
    }
  };

  deleteDocumentType = async (expenseTypeId: any) => {
    const [response] = await to(DocumentTypesRepo.delete(expenseTypeId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали врсту документа.'
        });
      }, 1000);
      documentTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const documentTypesListingStore = new DocumentTypesListingStore(
  DocumentTypesRepo
);
export default DocumentTypesListingStore;
