import React, { Component } from 'react';
import { searchStore } from '../../data/stores/SearchStore';
import DataTable from '../DataTable';
import DateCell from 'components/TableCells/DateCell';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import LitigatorFullNameCellWithAvatar from 'components/TableCells/LitigatorFullNameCellWithAvatar';
import LitigationNumberCell from 'components/TableCells/LitigationNumberCell';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import { litigationsListingStore } from '../../pages/LitigationsListingPage/LitigationsListingStore';
import { pageStore } from '../../data/stores/PageStore';
import { Button } from '@mui/material';
import { RowInfo } from 'react-table';
import AddLitigationModal from '../../pages/LitigationsListingPage/components/AddLitigationModal';
import SearchIcon from '@mui/icons-material/Search';
import { observer } from 'mobx-react';
import otherLitigationsStore from 'data/stores/OtherLitigationsStore';
import DateFromTimestampCell from 'components/TableCells/DateFromTimestampCell';
import AddOtherLitigationModal from '../../pages/OtherLitigationsListingPage/components/AddOtherLitigationModal';
import { otherLitigationsListingStore } from '../../pages/OtherLitigationsListingPage/OtherLitigationsListingStore';

const columns_litigations = [
  // {
  //   Header: '#',
  //   id: 'id',
  //   accessor: 'id',
  //   sortable: false,
  //   width: 50
  // },
  {
    Header: 'Датум',
    id: 'document_date_of_input',
    accessor: 'document_date_of_input',
    Cell: DateCell(),
    sortable: false,
    filterable: false,
    width: 100
  },
  {
    Header: 'Бр. предмета',
    accessor: 'litigation_number',
    Cell: LitigationNumberCell,
    sortable: false,
    filterable: false,
    width: 120
  },
  {
    Header: 'Прва страна',
    id: 'name_first_party',
    accessor: 'name_first_party',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Друга страна',
    accessor: 'name_second_party',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Предмет спора',
    id: 'litigation_type.litigation_name',
    accessor: 'litigation_type.litigation_name',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Фаза',
    accessor: 'phase.name',
    sortable: false,
    filterable: false
  },
  {
    Header: 'Миритељ и арбитар',
    id: 'litigator.firstname',
    accessor: 'litigator',
    Cell: LitigatorFullNameCellWithAvatar,
    sortable: false,
    filterable: false
  },
  {
    Header: 'Обрађивач',
    id: 'worker.firstname',
    accessor: 'worker',
    Cell: WorkerFullNameCellWithAvatar,
    sortable: false,
    filterable: false
  },
  {
    Header: 'Опције',
    accessor: 'actions',
    filterable: false,
    sortable: false
  }
];

const columns_other = [
  // {
  //   Header: '#',
  //   id: 'id',
  //   accessor: 'id',
  //   sortable: false,
  //   width: 50
  // },
  {
    Header: 'Датум',
    id: 'document_date_of_input',
    accessor: 'entry_date',
    Cell: DateFromTimestampCell(),
    sortable: true,
    filterable: false,
    width: 100
  },
  {
    Header: 'Бр. предмета',
    accessor: 'number',
    sortable: false,
    filterable: false,
    width: 200
  },
  {
    Header: 'Обрађивач',
    id: 'worker.firstname',
    accessor: 'worker',
    Cell: WorkerFullNameCellWithAvatar,
    sortable: false,
    filterable: false,
    width: 280
  },
  {
    Header: 'Напомене',
    id: 'notes',
    accessor: 'notes',
    sortable: false,
    filterable: false
  }
];

@observer
class SearchResultsComponent extends Component<any> {
  componentDidMount() {
    if (this.props.entity === undefined) {
      if (this.props.type === 'active') {
        litigationsStore.fetchAll();
      } else {
        litigationsStore.fetchArchivedLitigations();
      }
    } else if (this.props.entity === 'other-litigations') {
      otherLitigationsStore.fetchActiveOtherLitigationsWithoutOther();
    }
  }

  showAddLitigationModal = () => {
    litigationsListingStore.hideAddButton();
    litigationsListingStore.openModalForm();
  };

  hideAddLitigationModal = () => {
    litigationsListingStore.showAddButton();
    litigationsListingStore.closeModalForm();
    pageStore.setNavTitle('Преглед предмета');
  };

  showAddOtherLitigationModal = () => {
    otherLitigationsListingStore.hideAddButton();
    otherLitigationsListingStore.openModalForm();
  };

  hideAddOtherLitigationModal = () => {
    otherLitigationsListingStore.showAddButton();
    otherLitigationsListingStore.closeModalForm();
  };

  getTdProps = (_state: any, row?: RowInfo): object => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        if (row && row.original) {
          // console.log('Row ', row.original);
          // page detail
          // window.location.href = '/dashboard/litigations/' + row.original.id
          if (
            this.props.entity === 'litigations' ||
            this.props.entity === undefined
          ) {
            window.location.href =
              '/dashboard/litigations/' + row.original.litigation_id;
            // modal detail
            pageStore.setNavTitle(
              pageStore.navTitle + ' ' + row.original.litigation_number
            );
          } else {
            otherLitigationsListingStore.openModalForm(row.original);
            // console.log(row.original);
            // modal detail
            pageStore.setNavTitle(
              pageStore.navTitle + ' ' + row.original.number
            );
          }
          // litigationsListingStore.openModalForm(row.original);
        }
      }
    };
  };

  itemAction = (litigation: any) => {
    return (
      <div className="td-actions text-center">
        {/* <PermissionGuard permissionsWithAccess={permission.accidentDetails}> */}
        <Button
          style={{ color: 'black' }}
          onClick={() => {
            // litigationsListingStore.openModalForm(litigation);
          }}
          startIcon={<SearchIcon />}
        >
          ПРИКАЗ
        </Button>
        {/* </PermissionGuard> */}
      </div>
    );
  };

  render() {
    if (searchStore.data.length > 0) {
      if (this.props.type === 'active') {
        if (this.props.entity === undefined) {
          return (
            <div style={{ padding: '20px' }}>
              <DataTable
                enableCaching={false}
                columns={columns_litigations}
                showPaginationBottom={true}
                // paginationLimit={0}
                fetchData={
                  searchStore.data.length > 0
                    ? searchStore.searchByKeyword
                    : searchStore.failedSearch
                }
                meta={
                  searchStore.data.length > 0 ? searchStore.meta : { total: 0 }
                }
                data={
                  searchStore.data.length > 0
                    ? searchStore.attachCustomColumn('actions', this.itemAction)
                    : litigationsStore.attachCustomColumn(
                        'actions',
                        this.itemAction
                      )
                }
                getTdProps={this.getTdProps}
                loading={litigationsStore.loading}
                defaultSort={[{ id: 'litigationNumber', desc: true }]}
              />
              <AddLitigationModal
                className="add-litigation-modal"
                showModal={litigationsListingStore.showModal}
                closeModalForm={this.hideAddLitigationModal}
                openItem={litigationsListingStore.openItem}
              />
            </div>
          );
        } else {
          return (
            <div style={{ padding: '20px' }}>
              <DataTable
                enableCaching={false}
                columns={columns_other}
                showPaginationBottom={true}
                // paginationLimit={0}
                fetchData={
                  searchStore.data.length > 0
                    ? searchStore.searchByKeyword
                    : searchStore.failedSearch
                }
                meta={searchStore.data.length > 0 ? searchStore.meta : null}
                data={
                  searchStore.data.length > 0
                    ? searchStore.attachCustomColumn('actions', this.itemAction)
                    : otherLitigationsStore.attachCustomColumn(
                        'actions',
                        this.itemAction
                      )
                }
                getTdProps={this.getTdProps}
                loading={litigationsStore.loading}
                defaultSort={[{ id: 'litigationNumber', desc: true }]}
              />
              <AddOtherLitigationModal
                className="add-other-litigation-modal"
                showModal={otherLitigationsListingStore.showModal}
                closeModalForm={this.hideAddOtherLitigationModal}
                openItem={otherLitigationsListingStore.openItem}
              />
            </div>
          );
        }
      }
      if (this.props.type === 'archived') {
        return (
          <div style={{ padding: '20px' }}>
            <DataTable
              enableCaching={false}
              columns={columns_litigations}
              showPaginationBottom={true}
              // paginationLimit={0}
              fetchData={
                searchStore.data.length > 0
                  ? searchStore.searchByKeyword
                  : searchStore.failedSearch
              }
              meta={searchStore.meta}
              data={
                searchStore.data.length > 0
                  ? searchStore.attachCustomColumn('actions', this.itemAction)
                  : litigationsStore.attachCustomColumn(
                      'actions',
                      this.itemAction
                    )
              }
              getTdProps={this.getTdProps}
              loading={litigationsStore.loading}
              defaultSort={[{ id: 'litigationNumber', desc: true }]}
            />
            <AddLitigationModal
              className="add-litigation-modal"
              showModal={litigationsListingStore.showModal}
              closeModalForm={this.hideAddLitigationModal}
              openItem={litigationsListingStore.openItem}
            />
          </div>
        );
      }
    } else {
      if (searchStore.dataFetched === true && searchStore.data.length === 0) {
        return (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              minHeight: '60vh',
              alignItems: 'center'
            }}
          >
            <div>
              <h2 style={{ fontSize: 28 }}>Нема резултата за ову претрагу.</h2>
              <p style={{ fontSize: 16 }}>Молимо покушајте поново...</p>
            </div>
          </div>
        );
      } else {
        return this.props.children;
      }
    }
  }
}

export default SearchResultsComponent;
