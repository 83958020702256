import React, { Component } from 'react';
// import { Menu, MenuItem, Button } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import InboxIcon from '@mui/icons-material/Inbox';
import TimelineIcon from '@mui/icons-material/Timeline';
// import * as permission from '../../permission-consts';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Menu } from 'antd';

// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';
import { observer } from 'mobx-react';
import PermissionGuard from '../../hoc/PermissionGuard';
const { SubMenu } = Menu;

const menuItems = [
  {
    icon: <AssignmentIcon />,
    key: 'sub1',
    name: 'Предмети',
    permission: ['litigations-view'],
    menuItems: [
      {
        name: 'Преглед предмета',
        key: '1',
        link: '/dashboard/litigations',
        permission: ['litigations-view']
      },
      {
        name: 'Архива предмета',
        key: '2',
        link: '/dashboard/litigations-archive',
        permission: ['litigations-archived-view']
      },
      {
        name: 'Филтрирање предмета',
        key: '3',
        link: '/dashboard/filter-litigations',
        permission: ['litigations-view']
      }
    ]
  },
  {
    icon: <AssignmentTurnedInIcon />,
    key: 'sub2',
    name: 'Остали предмети',
    permission: ['other-litigations-view'],
    menuItems: [
      {
        name: 'Преглед осталих предмета',
        key: '3',
        link: '/dashboard/other-litigations',
        permission: ['other-litigations-view']
      },
      {
        name: 'Архива осталих предмета',
        key: '4',
        link: '/dashboard/other-litigations-archive',
        permission: ['other-litigations-archived-view']
      }
    ]
  },
  {
    icon: <EventBusyIcon />,
    key: 'sub3',
    name: 'Штрајкови',
    permission: ['strikes-view'],
    menuItems: [
      {
        name: 'Преглед штрајкова',
        key: '5',
        link: '/dashboard/strikes',
        permission: ['strikes-view']
      },
      {
        name: 'Архива штрајкова',
        key: '6',
        link: '/dashboard/strikes-archive',
        permission: ['strikes-archived-view']
      }
    ]
  },
  {
    icon: <EventBusyIcon />,
    key: 'sub12',
    name: 'Оцене',
    permission: ['reviews-view'],
    menuItems: [
      {
        name: 'Оцене Агенције',
        key: '40',
        link: '/dashboard/reviews-agency',
        permission: ['reviews-agency-view']
      },
      {
        name: 'Оцене Миритеља',
        key: '41',
        link: '/dashboard/reviews-litigator',
        permission: ['reviews-litigator-view']
      }
    ]
  },
  {
    icon: <InboxIcon />,
    key: 'sub4',
    name: 'Писарница',
    permission: ['office-view'],
    menuItems: [
      {
        name: 'Преглед докумената',
        key: '7',
        link: '/dashboard/office-documents',
        permission: ['office-view']
      },
      {
        name: 'Архива докумената',
        key: '8',
        link: '',
        permission: ['office-view']
      }
    ]
  },
  {
    icon: <TimelineIcon />,
    key: 'key9',
    name: 'Извештаји',
    permission: ['reports-view'],
    menuItems: [
      {
        name: 'Преглед извештаја',
        key: '9',
        link: '/dashboard/reports',
        permission: ['reports-view']
      },
      {
        name: 'Филтрирање извештаја',
        key: '10',
        link: '/dashboard/filter-reports',
        permission: ['reports-view']
      }
    ]
  },
  {
    icon: <SupervisorAccountOutlinedIcon />,
    key: 'sub5',
    name: 'Именик миритеља и арбитара',
    permission: ['litigators-view'],
    menuItems: [
      {
        name: 'Преглед миритеља и арбитара',
        key: '11',
        link: '/dashboard/litigators',
        permission: ['litigators-view']
      }
    ]
  },
  {
    icon: <PersonOutlinedIcon />,
    key: 'sub6',
    name: 'Корисници',
    permission: ['users-view'],
    menuItems: [
      {
        name: 'Преглед корисника',
        key: '12',
        link: '/dashboard/workers',
        permission: ['users-view']
      }
    ]
  },
  {
    icon: <SettingsIcon />,
    key: 'sub7',
    name: 'Шифарници',
    permission: ['code-books-view'],
    menuItems: [
      {
        name: 'Шеме докумената',
        key: '13',
        link: '/dashboard/templates',
        permission: ['code-books-view']
      },
      {
        name: 'Врсте елемената предмета',
        key: 'sub8',
        link: '',
        permission: ['code-books-view'],
        menuItems: [
          {
            name: 'Врсте предлога',
            key: '14',
            link: '/dashboard/proposal-types',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте сагласности',
            key: '15',
            link: '/dashboard/consents',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте закључака',
            key: '16',
            link: '/dashboard/conclusions',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте радних спорова',
            key: '17',
            link: '/dashboard/litigation-types',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте поступка',
            key: '18',
            link: '/dashboard/procedure-types',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте правних форми',
            key: '19',
            link: '/dashboard/legal-form-types',
            permission: ['code-books-view']
          }
        ]
      },
      {
        name: 'Врсте статуса и фаза поступка',
        key: 'sub9',
        link: '',
        permission: ['code-books-view'],
        menuItems: [
          {
            name: 'Врсте начина окончања поступка',
            key: '20',
            link: '/dashboard/statuses',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте фаза',
            key: '21',
            link: '/dashboard/phases',
            permission: ['code-books-view']
          },
          {
            name: 'Врсте начина избора миритеља односно арбитара',
            key: '22',
            link: '/dashboard/litigator-statuses',
            permission: ['code-books-view']
          },
          {
            name: 'Разлози одбацивања',
            key: '23',
            link: '/dashboard/rejection-reasons',
            permission: ['code-books-view']
          }
        ]
      },
      {
        name: 'Штрајкови',
        key: 'sub10',
        link: '',
        permission: ['code-books-view'],
        menuItems: [
          {
            name: 'Врсте штрајка',
            key: '24',
            link: '/dashboard/strike-types',
            permission: ['code-books-view']
          },
          {
            name: 'Место штрајка',
            key: '25',
            link: '/dashboard/strike-places',
            permission: ['code-books-view']
          },
          {
            name: 'Начини одржавања штрајка',
            key: '26',
            link: '/dashboard/strike-methods',
            permission: ['code-books-view']
          },
          {
            name: 'Начини престанка штрајка',
            key: '27',
            link: '/dashboard/strike-cessations',
            permission: ['code-books-view']
          },
          {
            name: 'Начини окончања штрајка',
            key: '28',
            link: '/dashboard/strike-endings',
            permission: ['code-books-view']
          }
        ]
      },
      {
        divider: true,
        name: 'Врсте докумената',
        key: '29',
        link: '/dashboard/office-documents-types',
        permission: ['code-books-view']
      },
      {
        divider: true,
        name: 'Врсте миритеља и арбитара',
        key: '30',
        link: '/dashboard/litigator-types',
        permission: ['code-books-view']
      },
      {
        divider: true,
        name: 'Врсте трошкова',
        key: '31',
        link: '/dashboard/fee-types',
        permission: ['code-books-view']
      },
      {
        divider: true,
        name: 'Ценовник услуга',
        key: '32',
        link: '/dashboard/expense-types',
        permission: ['code-books-view']
      },
      {
        divider: true,
        name: 'Ценовник горива',
        key: '33',
        link: '/dashboard/gas-expense-types',
        permission: ['code-books-view']
      }
    ]
  },
  {
    icon: <AssignmentIcon />,
    key: 'sub11',
    name: 'Питања корисника',
    permission: ['questions-view'],
    menuItems: [
      {
        name: 'Преглед питања',
        key: '34',
        link: '/dashboard/questions',
        permission: ['questions-view']
      }
    ]
  }
];

// const MenuItem = (item: any) => {
//   return (
//     <Menu.Item key={item.key}>
//       <a href={item.link}>{item.name}</a>
//     </Menu.Item>
//   );
// };

@observer
class MainMenu extends Component {
  handleClickMenu = (e: any) => {
    return null;
  };

  render() {
    return (
      <div
        style={{
          display: 'block',
          textAlign: 'left',
          paddingLeft: '20px',
          position: 'relative',
          zIndex: 99999
        }}
      >
        <Menu
          onClick={this.handleClickMenu}
          style={{}}
          mode="horizontal"
          triggerSubMenuAction="click"
        >
          {menuItems.map((item: any, index: number) => (
            <PermissionGuard permissionsWithAccess={item.permission}>
              <SubMenu eventKey={item.key} icon={item.icon} title={item.name}>
                {item.menuItems.map((subItem: any, index: number) => (
                  <>
                    {subItem.menuItems ? (
                      <>
                        {subItem.divider ? <Menu.Divider /> : null}
                        <SubMenu
                          eventKey={subItem.key}
                          icon={subItem.icon}
                          title={subItem.name}
                        >
                          {subItem.menuItems.map((subSubItem: any) => (
                            <>
                              <PermissionGuard
                                permissionsWithAccess={subSubItem.permission}
                              >
                                {subSubItem.divider ? <Menu.Divider /> : null}
                                <Menu.Item eventKey={subSubItem.key}>
                                  <a href={subSubItem.link}>
                                    {subSubItem.name}
                                  </a>
                                </Menu.Item>
                              </PermissionGuard>
                            </>
                          ))}
                        </SubMenu>
                      </>
                    ) : (
                      <PermissionGuard
                        permissionsWithAccess={subItem.permission}
                      >
                        <Menu.Item eventKey={subItem.key}>
                          <a href={subItem.link}>{subItem.name}</a>
                        </Menu.Item>
                      </PermissionGuard>
                    )}
                  </>
                ))}
              </SubMenu>
            </PermissionGuard>
          ))}

          {/* <PermissionGuard permissionsWithAccess={permission.litigationsView}>
            
          
          
          
          <SubMenu
            key="sub11"
            icon={<AssignmentIcon />}
            title="Питања корисника"
          >
            <Menu.Item key="34">
              <a href="/dashboard/questions">Преглед питања</a>
            </Menu.Item>
          </SubMenu> */}
        </Menu>
      </div>
    );
  }
}

export default MainMenu;
