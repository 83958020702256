import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LitigatorsRepo from '../../data/repositories/LitigatorsRepo';
import { litigatorsStore } from '../../data/stores/LitigatorsStore';

class LitigatorsListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  createLitigator = async (litigator: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLitigator(litigator);
    }

    return this.saveLitigator(litigator);
  };

  saveLitigator = async (litigator: any) => {
    const { create } = LitigatorsRepo;

    const [response] = await to(create(litigator));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали миритеља односно арбитра.'
        });
      }, 1000);
      litigatorsStore.fetch();
      this.closeModalForm();
    }
  };

  updateLitigator = async (litigator: any) => {
    const { update } = LitigatorsRepo;

    const [response] = await to(update(litigator.id, litigator));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали миритеља односно арбитра.'
        });
      }, 1000);
      litigatorsStore.fetch();
      this.closeModalForm();
    }
  };
}

export const litigatorsListingStore = new LitigatorsListingStore(
  LitigatorsRepo
);
export default LitigatorsListingStore;
