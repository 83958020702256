import React from 'react';

export default function ForbiddenPage() {
  return (
    <div
      style={{
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {/* <h1>403</h1> */}
      <h1>Приступ онемогућен...</h1>
      <p>
        Немате привилегије да видите ову страницу. Проверите са администратором.
      </p>
    </div>
  );
}
