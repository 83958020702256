import React from 'react';

import style from './ErrorPage.module.scss';

const ErrorPage = () => (
  <div className={style.errorPage}>
    <h1>Упс! Дошло је до грешке.</h1>
    <p>Молимо освежите страницу.</p>
  </div>
);

export default ErrorPage;
