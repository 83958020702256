import React from 'react';

import RenderIf from '../RenderIf';
import { FormikErrors } from 'formik';

interface ErrorMessageProps {
  show: boolean;
  message?: string | FormikErrors<any>;
}

const showError = (
  touched: boolean,
  message?: string | FormikErrors<any>
): boolean => {
  return touched && !!message;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ show, message }) => (
  <RenderIf condition={showError(show, message)}>
    <small className="text-danger">{message}</small>
  </RenderIf>
);

ErrorMessage.defaultProps = {
  show: false,
  message: ''
};

export default ErrorMessage;
