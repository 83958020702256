import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import LitigatorTypesRepo from '../../data/repositories/LitigatorTypesRepo';
import { litigatorTypesStore } from '../../data/stores/LitigatorTypesStore';

class LitigatorTypesListingStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  submitLitigatorType = async (litigatorType: any) => {
    const isEdit = this.openItem && this.openItem.id;

    if (isEdit) {
      return this.updateLitigatorType(litigatorType);
    }

    return this.createLitigatorType(litigatorType);
  };

  createLitigatorType = async (litigatorType: any) => {
    const { create } = LitigatorTypesRepo;

    const [response] = await to(create(litigatorType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали врсту миритеља односно арбитра.'
        });
      }, 1000);
      litigatorTypesStore.fetch();
      this.closeModalForm();
    }
  };

  updateLitigatorType = async (litigatorType: any) => {
    const { update } = LitigatorTypesRepo;

    const [response] = await to(update(litigatorType.id, litigatorType));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте ажурирали врсту миритеља односно арбитра.'
        });
      }, 1000);
      litigatorTypesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const litigatorTypesListingStore = new LitigatorTypesListingStore(
  LitigatorTypesRepo
);
export default LitigatorTypesListingStore;
