import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'strike_places';

const getAllStrikePlaces = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const StrikePlacesRepo = new BaseRepo(type);

export default {
  ...StrikePlacesRepo,
  getAllStrikePlaces
};
