import TableStore from './TableStore';
import { observable, action } from 'mobx';
import to from '../../lib/awaitTo';
import { Query } from '../../lib/queryBuilder';
import StrikesRepo from 'data/repositories/StrikesRepo';
import axios from 'axios';

class StrikesStore extends TableStore<any> {
  @observable strikes: any;
  @observable openItem: any;
  @observable currentStrikeNumber: any = null;

  @action
  setStrikes = (data: any) => {
    this.strikes = data;
  };

  @action
  setOpenItem = (strike: any) => {
    this.openItem = strike;
  };

  @action
  setCurrentStrikeNumber = (strikeNumber: any) => {
    if (strikeNumber === '' || strikeNumber === null) {
      this.currentStrikeNumber = null;
    } else {
      this.currentStrikeNumber = strikeNumber;
    }
  };

  @action
  fetchAll = async () => {
    const [res] = await to(StrikesRepo.getAllStrikes({}));

    if (res) {
      this.setStrikes(res.data);
    }
  };

  @action
  fetchOne = async (id: any) => {
    const [res] = await to(StrikesRepo.one(id));

    if (res) {
      this.setOpenItem(res.data);
    }
  };

  @action
  fetchActiveLitigationsWithoutOther = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params,
      include: this.includes,
      ...this.customQueryParams
    };

    // let response: any = await to(
    //   this.repo.getAll({ params: this.queryParams })
    // );

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };

  //   @action
  //   fetchLitigationIdByLitigationNumber = async (litigationNumber: string) => {
  //     const response = await StrikesRepo.fetchLitigationIdByLitigationNumber(
  //       litigationNumber
  //     );
  //     if (response) {
  //       this.setCurrentLitigationNumber(response.data);
  //     }
  //   };

  @action
  fetchArchivedLitigations = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    const customQueryParams = {
      isArchive: true
    };
    this.queryParams = {
      ...params,
      include: this.includes,
      ...customQueryParams
    };

    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );

    if (response && response.data) {
      this.onFetch(response.data.message.data, response.data.message);
    }
    if (error) {
      this.onError();
    }
  };
}

export const strikesStore = new StrikesStore(StrikesRepo);
export default strikesStore;
