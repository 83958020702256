import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'conclusions';

const getAllConclusions = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const ConclusionsRepo = new BaseRepo(type);

export default {
  ...ConclusionsRepo,
  getAllConclusions
};
