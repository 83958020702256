import { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { pickDefaultValues } from '../../../lib/form';
import { Formik, Form, Field } from 'formik';
import { observable, action } from 'mobx';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TextField } from 'formik-mui';
import { expenseTypesListingStore } from '../ExpenseTypesListingStore';
import './style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { expenseTypesStore } from '../../../data/stores/ExpenseTypesStore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AddExpenseTypeModalProps {
  showModal: boolean;
  closeModalForm: () => void;
  openItem: any;
}

const DEFAULT_VALUES = {
  id: '',
  name: '',
  price: ''
};

@observer
class AddExpenseTypeModal extends Component<AddExpenseTypeModalProps> {
  @observable open: boolean = false;

  @action
  setOpen = (bool: boolean) => {
    this.open = bool;
  };

  @action
  handleClose = () => {
    this.setOpen(false);
  };

  @action
  handleOpen = () => {
    this.setOpen(true);
  };

  handleDelete = () => {
    const { openItem } = this.props;

    if (openItem) {
      expenseTypesListingStore.deleteExpenseType(openItem.id);
      this.handleClose();
    }
  };

  onSubmit = (data: any) => {
    expenseTypesListingStore.createExpenseType(data);
  };

  getInitialValues = () => {
    const { openItem } = this.props;

    if (openItem && openItem.id) {
      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      return {
        ...pickedValues
      };
    }

    return DEFAULT_VALUES;
  };

  render() {
    const { showModal, closeModalForm, openItem } = this.props;
    const { expenseTypes } = expenseTypesStore;

    return (
      <div style={{ position: 'relative' }}>
        <Modal show={showModal} onHide={closeModalForm}>
          <Modal.Header
            style={{
              backgroundColor: '#EEEEEE',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Додавање нове цене услуга
            <Button
              onClick={() => {
                expenseTypesListingStore.showAddButton();
                closeModalForm();
              }}
              variant="contained"
              style={{
                backgroundColor: '#fb8c00',
                fontSize: 14,
                fontWeight: 400,
                color: 'white',
                width: '205px',
                height: '36px',
                borderRadius: '4px'
              }}
            >
              <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
              НАЗАД
            </Button>
          </Modal.Header>
          <Formik
            onSubmit={this.onSubmit}
            initialValues={this.getInitialValues()}
            render={({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form noValidate className="form-horizontal">
                <Modal.Body>
                  <Col md={9}>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label="Назив услуге*"
                      variant="standard"
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                      style={{
                        width: '100%',
                        fontFamily: 'Roboto'
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Field
                      component={TextField}
                      name="price"
                      type="number"
                      label="Цена*"
                      variant="standard"
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                      style={{
                        width: '100%',
                        fontFamily: 'Roboto'
                      }}
                    />
                  </Col>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="text"
                    style={{
                      fontSize: 14,
                      color: 'black',
                      visibility: openItem ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleOpen()}
                  >
                    <CloseIcon style={{ fontSize: 20, color: 'black' }} />
                    ОБРИШИ
                  </Button>
                  <Button
                    onClick={() => {
                      expenseTypesListingStore.showAddButton();
                    }}
                    type="submit"
                    variant="text"
                    style={{ fontSize: 14, color: 'black' }}
                  >
                    <CheckIcon style={{ fontSize: 20, color: 'black' }} />
                    САЧУВАЈ
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          />
        </Modal>
        {/* Delete Dialog */}
        <Dialog
          open={this.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Да ли сте сигурни да желите да трајно обришете '
              {openItem && openItem.name}'? Трајно обрисане податке је немогуће
              повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Не, одустани</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddExpenseTypeModal;
