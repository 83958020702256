import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Formik, Form, Field } from 'formik';
import * as permission from '../../permission-consts';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Col, Modal, Row } from 'react-bootstrap';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import VirtualizedAutocomplete from '../../components/VirtualizedAutocomplete/VirtualizedAutocomplete';
import './style.css';
import DataTable from '../../components/DataTable';
import DropZone from '../../components/DropZone';
import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import DatePicker from '@material-ui/lab/DatePicker';
import { TextField as TextFIELD } from '@material-ui/core';
import { RouteComponentProps, StaticContext } from 'react-router';
import { pickDefaultValues } from '../../lib/form';
import ServerSelectAutocomplete from 'components/ServerSelectAutocomplete';
import Creatable from 'react-select/creatable';
import { SelectFetch, withSelectFetch } from "react-select-fetch";
import {
  DialogContent,
  DialogContentText,
  MenuItem,
  Paper
} from '@mui/material';
import moment from 'moment';
import { litigationDetailStore } from './LitigationDetailStore';
// import AddLitigationModal from 'pages/LitigationsListingPage/components';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress
} from '@material-ui/core';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from '@mui/styles';
import AttachmentNameCell from '../../components/TableCells/AttachmentNameCell';
import DateCell from '../../components/TableCells/DateCell';
import axios from 'axios';
import to from '../../lib/awaitTo';
import Menu from '@mui/material/Menu';
import { Box, Switch } from '@material-ui/core';
import { MdDelete } from 'react-icons/md';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { litigationsListingStore } from '../../pages/LitigationsListingPage/LitigationsListingStore';
import { officeDocumentFileUploadsStore } from '../../data/stores/OfficeDocumentFileUploadsStore';
import TemplatesRepo from '../../data/repositories/TemplatesRepo';
import LitigationsRepo from '../../data/repositories/LitigationsRepo';
import DocumentTypesRepo from '../../data/repositories/DocumentTypesRepo';
import { officeDocumentsListingStore } from '../../pages/OfficeDocumentsListingPage/OfficeDocumentsListingStore';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { TextField } from 'formik-mui';
import { litigationsStore } from '../../data/stores/LitigationsStore';
import SearchResultsComponent from 'components/SearchResultsComponent';
import { pageStore } from '../../data/stores/PageStore';
// import RenderIf from 'components/RenderIf';
import LitigationTypesRepo from 'data/repositories/LitigationTypesRepo';
import PermissionGuard from 'hoc/PermissionGuard';
import authStore from 'data/stores/AuthStore';
import WorkerFullNameCellWithAvatar from 'components/TableCells/WorkerFullNameCellWithAvatar';
import { values } from 'lodash';
import { Toast } from 'lib/dialogs';


// type TParams = { id: string };

const DEFAULT_VALUES = {
  id: '',
  payment_status: '',
  status: '',
  document_type_id: '',
  document_date_of_input: '',
  litigation_number: '',
  working_on_user_id: '',
  worker: null,
  hasParent: 0,
  procedure_type_id: '',
  procedure_type: null,
  procedure_phase_id: '',
  isParent: false,
  parent: null,
  parent_id: '',
  parent_id_litigation_number: '',
  phase: null,
  decision_making_date: '',
  additional_info: '',
  conciliator_choosing_way_id: '',
  conciliator_id: '',
  litigator: null,
  litigator_id: '',
  consent_id: '',
  children: null,
  consent: null,
  finalizing_procedure_way: null,
  finalizing_procedure_way_id: '',
  rejection_reason: null,
  rejection_reason_id: '',
  conclusion: null,
  conclusion_id: '',
  proposal_type_id: '',
  affected_number: '',
  sector: '',
  proposal_type: null,
  litigation_type_id: '',
  litigation_type: null,
  legal_form_first_party_id: '',
  legal_form_first_party: null,
  legal_form_second_party_id: '',
  legal_form_second_party: null,
  municipality_first_party: '',
  municipality_second_party: '',
  name_first_party: '',
  name_second_party: '',
  email_first_party: '',
  email_second_party: '',
  street_first_party: '',
  street_second_party: '',
  phone_first_party: '',
  phone_second_party: '',
  street_number_first_party: '',
  street_number_second_party: '',
  city_first_party_id: '',
  city_first_party: null,
  city_second_party_id: '',
  city_second_party: null,
  post_number_first_party: '',
  post_number_second_party: '',
  litigator_status: null,
  litigator_status_id: '',
  other: false
};

const SelectFetchCreatable = withSelectFetch(Creatable) as any;

const GreenSwitch = withStyles({
  colorSecondary: {
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'purple'
    }
  },
  track: {
    backgroundColor: 'blue'
  }
})(Switch);

const columns = [
  // {
  //   Header: '#',
  //   id: 'id',
  //   accessor: 'id',
  //   sortable: false,
  //   filterable: false,
  //   width: 50
  // },
  {
    Header: 'Врста документа',
    id: 'name',
    accessor: (officeDocument: any) => officeDocument,
    Cell: AttachmentNameCell,
    sortable: false,
    filterable: false,
    width: 450
  },
  {
    Header: 'Снимио/ла',
    accessor: 'uploadedByUser',
    Cell: WorkerFullNameCellWithAvatar,
    sortable: false,
    filterable: false,
    width: 280
  },
  {
    Header: 'Датум',
    id: 'created_at',
    accessor: 'created_at',
    Cell: DateCell(),
    sortable: false,
    filterable: false,
    width: 100
  },
  {
    Header: 'Време',
    id: 'time',
    accessor: 'time',
    sortable: false,
    filterable: false,
    width: 100
  },
  {
    Header: 'Тип',
    id: 'type',
    accessor: 'type',
    sortable: false,
    filterable: false,
    width: 100
  },
  {
    Header: 'Акције',
    accessor: 'actions',
    sortable: false,
    filterable: false,
    width: 'auto'
  }
];

@observer
class LitigationDetailPage extends Component<RouteComponentProps<any>> {
  constructor(props: any) {
    super(props);

    this.state = {
      procedure_type_id: null,
      procedure_type: null,
      proposal_type_id: null,
      litigation_type_id: null,
      legal_form_first_party_id: null,
      name_first_party: null,
      email_first_party: null,
      phone_first_party: null,
      municipality_first_party: null,
      street_first_party: null,
      street_number_first_party: null,
      city_first_party: null,
      post_number_first_party: null,

      legal_form_second_party_id: null,
      name_second_party: null,
      email_second_party: null,
      phone_second_party: null,
      municipality_second_party: null,
      street_second_party: null,
      street_number_second_party: null,
      city_second_party: null,
      post_number_second_party: null
    } as any;
  }

  @observable updateLitigationData: any = null;

  @observable openDeleteModal: boolean = false;

  @observable switchValue: any = 1;

  @observable isParent: any = 0;

  @observable hasParent: any = 0;

  @observable reviews: boolean = false;

  @observable reviewFirstPartyCompleted: boolean = false;

  @observable reviewSecondPartyCompleted: boolean = false;

  @observable surveyFirstPartySent: boolean = false;

  @observable surveySecondPartySent: boolean = false;

  @observable fillFirstSide: any = 0;

  @observable fillSecondSide: any = 0;

  @observable parent_id: any = null;

  @observable parent_id_litigation_number: any = null;

  @observable paymentStatus: any = 0;

  @observable partiesInfo: any = {
    legal_form_first_party_id: null,
    legal_form_second_party_id: null,
    name_first_party: null,
    email_first_party: null,
    name_second_party: null,
    email_second_party: null,
    municipality_first_party: null,
    municipality_second_party: null,
    phone_number_first_party: null,
    phone_number_second_party: null,
    street_first_party: null,
    street_second_party: null,
    street_number_first_paty: null,
    street_number_second_paty: null,
    city_first_party: null,
    city_second_party: null,
    post_number_first_party: null,
    post_number_second_party: null
  };

  @observable anchorEl: any = false;

  @observable alreadyExistModalOpen: boolean = false;

  @observable open: boolean = Boolean(this.anchorEl);

  @observable dialogOpen: any = {};

  @observable documentTypes: any = [];

  @observable templates: any = [];

  @observable documentButtonSwitch: boolean = false;

  @observable allSelectOptions: any = null;

  @observable cityOptions: any = [];

  @observable inputCityFirstPartyValue: any = {};

  @observable inputCitySecondPartyValue: any = {};

  @observable inputMunicipalityFirstPartyValue: any = {};

  @observable inputMunicipalitySecondPartyValue: any = {};

  @observable inputStreetFirstPartyValue: any = {};

  @observable inputStreetSecondPartyValue: any = {};

  @observable inputNameFirstPartyValue: any = {};

  @observable inputNameSecondPartyValue: any = {};

  @observable inputPostNumberFirstPartyValue: any = {};

  @observable inputPostNumberSecondPartyValue: any = {};

  @action
  setAllSelectOptions = (data: any) => {
    this.allSelectOptions = data;
  };

  @action
  setUpdateLitigationData = (data: any) => {
    this.updateLitigationData = data;
  }

  @action
  setCityOptions = (options: any) => {
    this.cityOptions = options;
  }

  @action
  setReviews = (reviews: boolean) => {
    this.reviews = reviews;
  }

  @action
  setSurveyFirstPartySent = (bool: boolean) => {
    this.surveyFirstPartySent = bool;
  }

  @action
  setSurveySecondPartySent = (bool: boolean) => {
    this.surveySecondPartySent = bool;
  }

  @action
  setReviewFirstPartyCompleted = (completed: boolean) => {
    this.reviewFirstPartyCompleted = completed;
  }

  @action
  setReviewSecondPartyCompleted = (completed: boolean) => {
    this.reviewSecondPartyCompleted = completed;
  }

  @action
  setInputCityFirstPartyValue = (input: any, setFieldValue: any) => {
    // console.log('test', input);
    if (input === null) {
      this.inputCityFirstPartyValue = { id: 0, label: '' };
      setFieldValue('city_first_party', '');
    } else {
      this.inputCityFirstPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('city_first_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputCitySecondPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputCitySecondPartyValue = { id: 0, label: '' };
      setFieldValue('city_second_party', '');
    } else {
      this.inputCitySecondPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('city_second_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputMunicipalityFirstPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputMunicipalityFirstPartyValue = { id: 0, label: '' };
      setFieldValue('municipality_first_party', '');
    } else {
      this.inputMunicipalityFirstPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('municipality_first_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputMunicipalitySecondPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputMunicipalitySecondPartyValue = { id: 0, label: '' };
      setFieldValue('municipality_second_party', '');
    } else {
      this.inputMunicipalitySecondPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('municipality_second_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputStreetFirstPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputStreetFirstPartyValue = { id: 0, label: '' };
      setFieldValue('street_first_party', '');
    } else {
      this.inputStreetFirstPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('street_first_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputStreetSecondPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputStreetSecondPartyValue = { id: 0, label: '' };
      setFieldValue('street_second_party', '');
    } else {
      this.inputStreetSecondPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('street_second_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputNameFirstPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputNameFirstPartyValue = { id: 0, label: '' };
      setFieldValue('name_first_party', '');
    } else {
      this.inputNameFirstPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('name_first_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputNameSecondPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputNameSecondPartyValue = { id: 0, label: '' };
      setFieldValue('name_second_party', '');
    } else {
      this.inputNameSecondPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('name_second_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputPostNumberFirstPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputPostNumberFirstPartyValue = { id: 0, label: '' };
      setFieldValue('post_number_first_party', '');
    } else {
      this.inputPostNumberFirstPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('post_number_first_party', input.label !== undefined ? input.label : input);
    }
  }

  @action
  setInputPostNumberSecondPartyValue = (input: any, setFieldValue: any) => {
    if (input === null) {
      this.inputPostNumberSecondPartyValue = { id: 0, label: '' };
      setFieldValue('post_number_second_party', '');
    } else {
      this.inputPostNumberSecondPartyValue = { id: undefined, label: input.label !== undefined ? input.label : input };
      setFieldValue('post_number_second_party', input.label !== undefined ? input.label : input);
    }
  }

  handleSendSurveyFirstParty = async () => {
    const { openItem } = litigationDetailStore;
    const response = await axios.post("send-survey-to-first-party", {
      "litigation_id": openItem.id
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response) {
      this.setSurveyFirstPartySent(true);
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте послали анкету првој страни.'
        });
      }, 1000);
      console.log("Resp ", response);
    }
  }

  handleSendSurveySecondParty = async () => {
    const { openItem } = litigationDetailStore;
    const response = await axios.post("send-survey-to-second-party", {
      "litigation_id": openItem.id
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response) {
      this.setSurveySecondPartySent(true);
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте послали анкету другој страни.'
        });
      }, 1000);
      console.log("Resp ", response);
    }
  }


  fetchDocumentTypesByPhaseId = async () => {
    const { openItem } = litigationDetailStore;

    let queryParams = openItem ? openItem.procedure_phase_id : '';
    const [res] = await to(
      DocumentTypesRepo.getAll({
        params: { search: `phase_id:${queryParams}` }
      })
    );
    if (res) {
      this.setDocumentTypes(res.data);
    }
  };

  fetchAllTemplates = async () => {
    const [res] = await to(TemplatesRepo.getAll({}));
    if (res) {
      this.setTemplates(res.data);
    }
  };

  async componentWillMount() {
    const { match } = this.props;
    const { params } = match;

    // fetch reviews for litigation id
    axios.get(`check-review/${params.id}`).then((response) => {
      if (response.data) {
        this.setSurveyFirstPartySent(response.data.data.survey_first_party_sent);
        this.setSurveySecondPartySent(response.data.data.survey_second_party_sent);
        this.setReviewFirstPartyCompleted(response.data.data.review_first_party_completed);
        this.setReviewSecondPartyCompleted(response.data.data.review_second_party_completed);
        this.setReviews(true);
      }
    })

    litigationDetailStore.fetchOne(params.id);
    const allSelectOptions: any = JSON.parse(
      localStorage.getItem('allSelectOptions') || ''
    );

    this.setAllSelectOptions(allSelectOptions);

    // const [response] = await to(LitigationsRepo.getAll({}));

    // if (response) {
    //   console.log('res ', response);
    // }

    const [responseAllLitigations] = await to(
      LitigationsRepo.getAllLitigationsWithoutPagination()
    );

    // console.log('TEST ', responseAllLitigations)

    // axios.get('get-all-litigations-without-pagination').then(data => {
    //   litigationsListingStore.setLitigationsArray(data.data);
    // });

    if (responseAllLitigations) {
      litigationsListingStore.setLitigationsArray(responseAllLitigations.data);
    }
    pageStore.setNavTitle(
      pageStore.navTitle +
      ' ' +
      litigationDetailStore.openItem.litigation_number
    );
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;

    // fetch reviews for litigation id
    axios.get(`check-review/${params.id}`).then((response) => {
      if (response.data) {
        this.setSurveyFirstPartySent(response.data.data.survey_first_party_sent);
        this.setSurveySecondPartySent(response.data.data.survey_second_party_sent);
        this.setReviewFirstPartyCompleted(response.data.data.review_first_party_completed);
        this.setReviewSecondPartyCompleted(response.data.data.review_second_party_completed);
        this.setReviews(true);
      }
    })
  }

  @action
  setDocumentButtonSwitch = (bool: boolean) => {
    this.documentButtonSwitch = bool;
  };

  @action
  setParentId = (parentId: number | null) => {
    this.parent_id = parentId;
  };

  @action
  setTemplates = (templates: any) => {
    this.templates = templates;
  };

  @action
  toggleDocumentButtonSwitch = () => {
    this.documentButtonSwitch = !this.documentButtonSwitch;
  };

  @action
  setFillFirstSide = (bool: any) => {
    this.fillFirstSide = bool;
  };

  @action
  setIsParent = (bool: any) => {
    this.isParent = bool;
  };

  @action
  setFillSecondSide = (bool: any) => {
    this.fillSecondSide = bool;
  };

  @action
  setSwitchValue = (value: any) => {
    this.switchValue = value;
  };

  @action
  setPaymentStatus = (status: any) => {
    this.paymentStatus = status;
  };

  @action
  setHasParent = (value: any) => {
    this.hasParent = value;
  };

  @action
  setDocumentTypes = (documentTypes: string) => {
    this.documentTypes = documentTypes;
  };

  @action
  setAnchorEl = (anchorEl: null | HTMLElement) => {
    this.anchorEl = anchorEl;
  };

  @action
  setOpen = (bool: any) => {
    this.open = bool;
  };

  @action
  setDialogOpen = (dialogId: any) => {
    this.dialogOpen[dialogId] = true;
  };

  @action
  setDialogClose = (dialogId: any) => {
    this.dialogOpen[dialogId] = false;
  };

  @action
  setParentIdLitigationNumber = (parentIdLitigationNumber: any) => {
    this.parent_id_litigation_number = parentIdLitigationNumber;
  };

  @action
  setOpenDeleteModal = (bool: boolean) => {
    this.openDeleteModal = bool;
  };

  @action
  setAlreadyExistModalOpen = (bool: boolean) => {
    this.alreadyExistModalOpen = bool;
  }

  @action
  handleCloseDeleteModal = () => {
    this.setOpenDeleteModal(false);
  };

  @action
  handleOpenDeleteModal = () => {
    this.setOpenDeleteModal(true);
  };

  @action
  handleOpenAlreadyExistModal = () => {
    this.setAlreadyExistModalOpen(true);
  }

  @action
  handleCloseAlreadyExistModal = () => {
    this.setUpdateLitigationData(null);
    this.setAlreadyExistModalOpen(false);
  }

  handleUpdateLitigation = () => {
    litigationsListingStore.updateLitigation(this.updateLitigationData);
    this.handleCloseAlreadyExistModal();
  }

  handleDeleteLitigation = async () => {
    const { match } = this.props;
    const { params } = match;
    const { openItem } = litigationDetailStore;

    const [response, error] = await to(
      LitigationsRepo.deleteLitigationById(params.id)
    );

    if (response) {
      if (openItem.status === 1) {
        window.location.href = '/dashboard/litigations';
      } else {
        window.location.href = '/dashboard/litigations-archive';
      }
    }

    if (error) {
      console.log('Error ', error);
    }
  };

  onSubmit = async (data: any) => {
    if (this.fillFirstSide) {
      data.procedure_type_id = data.parent.procedure_type_id;
      data.proposal_type_id = data.parent.proposal_type_id;
      data.litigation_type_id = data.parent.litigation_type_id;
      data.legal_form_first_party_id = data.parent.legal_form_first_party_id;
      data.name_first_party = data.parent.name_first_party;
      data.email_first_party = data.parent.email_first_party;
      data.municipality_first_party = data.parent.municipality_first_party;
      data.phone_first_party = data.parent.phone_first_party;
      data.street_first_party = data.parent.street_first_party;
      data.street_number_first_party = data.parent.street_number_first_party;
      data.city_first_party = data.parent.city_first_party;
      data.post_number_first_party = data.parent.post_number_first_party;
    }

    if (this.fillSecondSide) {
      data.legal_form_second_party_id = data.parent.legal_form_second_party_id;
      data.name_second_party = data.parent.name_second_party;
      data.email_second_party = data.parent.email_second_party;
      data.municipality_second_party = data.parent.municipality_second_party;
      data.phone_second_party = data.parent.phone_second_party;
      data.street_second_party = data.parent.street_second_party;
      data.street_number_second_party = data.parent.street_number_second_party;
      data.city_second_party = data.parent.city_second_party;
      data.post_number_second_party = data.parent.post_number_second_party;
    }

    if (this.hasParent) {
      if (data.parent_id && typeof data.parent_id === 'string') {
        let parent_litigation_number = data.parent_id.split(' - ')[0];
        data.parent_litigation_number = parent_litigation_number;
        // let parent_litigation_number = data.parent.litigation_number;
        // if (parent_litigation_number !== '') {
        //   data.parent_litigation_number = parent_litigation_number;
        // }
      }
      // else {
      //   let parent_litigation_number = data.parent_id.split(' - ')[0];
      //   if (parent_litigation_number !== '') {
      //     data.parent_litigation_number = parent_litigation_number;
      //   }
      // }
    }

    // litigationsListingStore.updateLitigation(data);
    let response = await litigationsListingStore.checkForExistingLitigation(data);

    if (response) {
      // console.log('RESPONSE ', response);
      if (response.data) {
        this.setUpdateLitigationData(data);
        this.setAlreadyExistModalOpen(true);
      } else {
        litigationsListingStore.updateLitigation(data);
      }
    }
    // console.log('SAVE ');
  };

  loadCityOptions = async (url: any, params: any) => {
    // Fetch options only when the user has entered at least 3 characters
    // let inputValue = e.target.value;

    // if (params.inputValue.length < 3) {
    //   // callback([]);
    //   this.setCityOptions([]);
    //   this.setInputCityValue('');
    //   return;
    // }

    try {
      // Your asynchronous data fetching logic here...
      const response = await fetch(`${url}?query=${params.search}`);
      const data = await response.json();

      // Transform the data into the format React Select expects
      const formattedOptions = data.data.map((item: any) => ({
        id: item.id,
        label: item.label
      }));

      // Update the options state
      // this.setCityOptions(formattedOptions);
      return {
        options: formattedOptions
      }
      // this.setInputCityValue('');

      // Call the callback function provided by React Select
      // callback(formattedOptions);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  handleCreateCityOption = async (inputValue: any) => {
    // Your logic for handling the creation of a new option
    console.log('Creating option:', inputValue);
  }

  getInitialValues = () => {
    const { openItem } = litigationDetailStore;

    let hasParent = 0;

    if (openItem && openItem.id) {
      if (Number(openItem.status)) {
        this.setSwitchValue(1);
      } else {
        this.setSwitchValue(0);
      }

      if (openItem.children.length > 0) {
        this.setIsParent(1);
      } else {
        this.setIsParent(0);
      }

      if (Number(openItem.payment_status)) {
        this.setPaymentStatus(1);
      } else {
        this.setPaymentStatus(0);
      }

      if (openItem.parent_id) {
        hasParent = 1;
        this.setHasParent(1);
      } else {
        hasParent = 0;
        this.setHasParent(0);
      }

      officeDocumentFileUploadsStore.setCurrentLitigationId(openItem.id);
      officeDocumentFileUploadsStore.setCurrentLitigationNumber(
        openItem.litigation_number
      );

      const pickedValues = pickDefaultValues(DEFAULT_VALUES, openItem);

      return {
        ...pickedValues,
        document_time_of_input: moment(openItem.document_date_of_input).format(
          'HH:mm:ss'
        ),
        children: openItem.children,
        parent: openItem.parent,
        hasParent: hasParent,
        isParent: this.isParent
      };
    }

    return DEFAULT_VALUES;
  };

  handleChangeSwitchLabel = () => {
    if (this.switchValue) {
      this.setSwitchValue(0);
    } else {
      this.setSwitchValue(1);
    }
  };

  handleChangePaymentStatus = () => {
    if (this.paymentStatus) {
      this.setPaymentStatus(0);
    } else {
      this.setPaymentStatus(1);
    }
  };

  handleChangeHasParent = () => {
    if (this.hasParent) {
      // this.setParentIdLitigationNumber(null);
      // this.setParentId(null);
      this.setHasParent(0);
      this.setFillFirstSide(0);
      this.setFillSecondSide(0);
    } else {
      this.setHasParent(1);
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.fetchAllTemplates();
    // this.fetchDocumentTypesByPhaseId();
    this.setAnchorEl(event.currentTarget);
    this.setOpen(true);
  };
  handleClose = (officeDocumentFileUploadId: any) => {
    this.setAnchorEl(null);
    this.setOpen(false);
    this.setDialogClose(officeDocumentFileUploadId);
  };

  handleDelete = (officeDocumentFileUploadId: any) => {
    const { createdSwitch } = litigationDetailStore;
    createdSwitch
      ? officeDocumentsListingStore.deleteOfficeDocumentFileScanned(
        officeDocumentFileUploadId
      )
      : officeDocumentsListingStore.deleteOfficeDocumentFileCreated(
        officeDocumentFileUploadId
      );
    this.setDialogClose(officeDocumentFileUploadId);
  };

  itemActionWithoutDelete = (officeDocumentFileUpload: any) => (
    <div style={{ display: 'flex' }}>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 2 }}
      >
        <Dialog
          open={this.dialogOpen[4343434343]}
          onClose={() => this.handleClose(4343434343)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id={`alert-dialog-title-${4343434343}`}>
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={`alert-dialog-description-${4343434343}`}>
              Да ли сте сигурни да желите да трајно обришете предмет? Трајно
              обрисане податке је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(4343434343)}>
              Не, одустани
            </Button>
            <Button onClick={this.handleDeleteLitigation} autoFocus>
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 2, margin: '10px 0' }}
      >
        <PermissionGuard permissionsWithAccess={permission.documentsView}>
          <Button
            style={{
              color: 'inherit',
              fontWeight: 400
            }}
            onClick={() => {
              window.open(officeDocumentFileUpload.link, '_blank');
            }}
          >
            <PrintIcon style={{ fontSize: 24, marginRight: 3 }} />
            Погледај документ
          </Button>
        </PermissionGuard>
      </div>
      {litigationDetailStore.openItem &&
        litigationDetailStore.openItem.children.length > 0 ? (
        <div
          className="td-actions text-right"
          style={{ display: 'flex', zIndex: 2 }}
        >
          <Button
            style={{
              color: 'inherit',
              fontWeight: 400
            }}
            onClick={() => {
              //window.open(officeDocumentFileUpload.link, '_blank');
              //console.log('OI ', litigationDetailStore.openItem);
              let phaseId = null;
              if (litigationDetailStore.openItem.phase) {
                phaseId = litigationDetailStore.openItem.phase.id;
              }

              let children = litigationDetailStore.openItem.children;
              children = children.map((item: any) => {
                return item.id;
              });

              litigationsStore.fillChildLitigationsPhase(phaseId, children);

              // console.log('OFFICE UPLOAD: ', officeDocumentFileUpload);
              officeDocumentFileUploadsStore.shareDocument(
                officeDocumentFileUpload.id,
                children
              );
              // console.log('Podeli', children);
            }}
          >
            <ShareIcon style={{ fontSize: 24, marginRight: 3 }} />
            Подели документ
          </Button>
        </div>
      ) : null}
    </div>
  );

  itemAction = (officeDocumentFileUpload: any) => (
    <div style={{ display: 'flex' }}>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 2 }}
      >
        <PermissionGuard permissionsWithAccess={permission.documentsDelete}>
          <Button
            style={{
              color: 'inherit',
              fontWeight: 400
            }}
            onClick={() => {
              this.setDialogOpen(officeDocumentFileUpload.id);
            }}
          >
            <MdDelete style={{ fontSize: 24, marginRight: 3 }} />
            Обриши документ
          </Button>

          {/* Delete Dialog */}
          <Dialog
            open={this.dialogOpen[officeDocumentFileUpload.id]}
            onClose={() => this.handleClose(officeDocumentFileUpload.id)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id={`alert-dialog-title-${officeDocumentFileUpload.id}`}
            >
              {'Молимо вас да потврдите'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Да ли сте сигурни да желите да трајно обришете фајл? Трајно
                обрисане фајлове је немогуће повратити!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleClose(officeDocumentFileUpload.id)}
              >
                Не, одустани
              </Button>
              <Button
                onClick={() => {
                  // console.log('fajl ', officeDocumentFileUpload);
                  // console.log('neee ', officeDocumentFileUpload.id);
                  this.handleDelete(officeDocumentFileUpload.id);
                }}
                autoFocus
              >
                Да, обриши
              </Button>
            </DialogActions>
          </Dialog>
        </PermissionGuard>
        <Dialog
          open={this.dialogOpen[4343434343]}
          onClose={() => this.handleClose(4343434343)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id={`alert-dialog-title-${4343434343}`}>
            {'Молимо вас да потврдите'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={`alert-dialog-description-${4343434343}`}>
              Да ли сте сигурни да желите да трајно обришете предмет? Трајно
              обрисане податке је немогуће повратити!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(4343434343)}>
              Не, одустани
            </Button>
            <Button onClick={this.handleDeleteLitigation} autoFocus>
              Да, обриши
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        className="td-actions text-right"
        style={{ display: 'flex', zIndex: 2, margin: '10px 0' }}
      >
        <PermissionGuard permissionsWithAccess={permission.documentsView}>
          <Button
            style={{
              color: 'inherit',
              fontWeight: 400
            }}
            onClick={() => {
              window.open(officeDocumentFileUpload.link, '_blank');
            }}
          >
            <PrintIcon style={{ fontSize: 24, marginRight: 3 }} />
            Погледај документ
          </Button>
        </PermissionGuard>
      </div>
      {litigationDetailStore.openItem &&
        litigationDetailStore.openItem.children.length > 0 ? (
        <div
          className="td-actions text-right"
          style={{ display: 'flex', zIndex: 2 }}
        >
          <Button
            style={{
              color: 'inherit',
              fontWeight: 400
            }}
            onClick={() => {
              //window.open(officeDocumentFileUpload.link, '_blank');
              //console.log('OI ', litigationDetailStore.openItem);
              let phaseId = null;
              if (litigationDetailStore.openItem.phase) {
                phaseId = litigationDetailStore.openItem.phase.id;
              }

              let children = litigationDetailStore.openItem.children;
              children = children.map((item: any) => {
                return item.id;
              });

              litigationsStore.fillChildLitigationsPhase(phaseId, children);

              // console.log('OFFICE UPLOAD: ', officeDocumentFileUpload);
              officeDocumentFileUploadsStore.shareDocument(
                officeDocumentFileUpload.id,
                children
              );
              // console.log('Podeli', children);
            }}
          >
            <ShareIcon style={{ fontSize: 24, marginRight: 3 }} />
            Подели документ
          </Button>
        </div>
      ) : null}
    </div>
  );


  render() {
    const { openItem, createdSwitch, setCreatedSwitch } = litigationDetailStore;
    const { litigationsArray } = litigationsListingStore;
    const { authUser } = authStore;

    if (!openItem || !litigationsArray) {
      return <LinearProgress color="secondary" />;
    }
    return (
      <SearchResultsComponent type={openItem.status ? 'active' : 'archived'}>
        <Box className="" style={{ padding: '10px' }}>
          <Paper>
            <Box
              style={{
                height: 'auto',
                minHeight: '60px',
                width: '100%',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px'
              }}
            >
              Измена постојећег предмета
              <div>
                <PermissionGuard
                  permissionsWithAccess={permission.documentsView}
                >
                  {openItem.email_first_party ?
                    <>
                      {this.reviews ?

                        <Button disabled={this.surveyFirstPartySent || this.reviewFirstPartyCompleted} onClick={this.handleSendSurveyFirstParty} variant="contained" style={{
                          fontSize: 14,
                          fontWeight: 400,
                          marginRight: 15
                        }}>
                          {this.surveyFirstPartySent ? this.reviewFirstPartyCompleted ? "Прва страна попунила анкету." : "Анкета послата. Чека се одговор" : "Пошаљи анкету првој страни"}
                        </Button>


                        : null}
                    </>
                    : null}

                  {openItem.email_second_party ?
                    <>
                      {this.reviews ?
                        <Button disabled={this.surveySecondPartySent || this.reviewSecondPartyCompleted} onClick={this.handleSendSurveySecondParty} variant="contained" style={{
                          fontSize: 14,
                          fontWeight: 400,
                          marginRight: 15
                        }}>
                          {this.surveySecondPartySent ? this.reviewSecondPartyCompleted ? "Друга страна попунила анкету." : "Анкета послата. Чека се одговор" : "Пошаљи анкету другој страни"}
                        </Button>
                        : null}
                    </>
                    : null}

                  < Button
                    onClick={() => this.toggleDocumentButtonSwitch()}
                    variant="contained"
                    style={{
                      backgroundColor: '#4051B5',
                      fontSize: 14,
                      fontWeight: 400,
                      color: 'white',
                      width: '205px',
                      height: '36px',
                      borderRadius: '4px',
                      marginRight: 15
                    }}
                  >
                    {this.documentButtonSwitch ? (
                      <InsertDriveFileIcon
                        style={{ fontSize: 16, color: 'white' }}
                      />
                    ) : (
                      <InsertDriveFileIcon
                        style={{ fontSize: 16, color: 'white' }}
                      />
                    )}

                    {this.documentButtonSwitch ? 'ДЕТАЉИ' : 'ДОКУМЕНТА'}
                  </Button>
                </PermissionGuard>
                <PermissionGuard
                  permissionsWithAccess={permission.createOfficeDocuments}
                >
                  <Button
                    onClick={this.handleClick}
                    variant="contained"
                    style={{
                      backgroundColor: '#E30022',
                      fontSize: 14,
                      fontWeight: 400,
                      color: 'white',
                      width: '205px',
                      height: '36px',
                      borderRadius: '4px'
                    }}
                  >
                    <AddIcon style={{ fontSize: 16, color: 'white' }} /> КРЕИРАЈ
                  </Button>
                </PermissionGuard>
                {/* CREATE MENU */}
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={this.anchorEl}
                  open={this.open}
                  onClose={this.handleClose}
                  style={{ maxHeight: '60%' }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  {this.templates && authUser.roles[0].name !== 'Миритељ/Арбитар' ? this.templates.map((item: any, index: any) => (
                    <MenuItem
                      onClick={(e: any) => {
                        // let data = openItem;
                        //console.log('OPEN ITEM ', openItem);
                        let object = {} as any;
                        if (item.name !== null) {
                          object.name = item.name;
                        } else {
                          object.name = '';
                        }

                        if (
                          openItem.children !== undefined &&
                          openItem.children.length > 0
                        ) {
                          object.children = openItem.children;
                        } else {
                          object.children = null;
                        }

                        if (openItem.id !== null) {
                          object.litigation_id = openItem.id;
                        } else {
                          object.litigation_id = '';
                        }

                        if (openItem.isParent) {
                          object.isParent = true;
                        } else {
                          object.isParent = false;
                        }

                        if (openItem.decision_making_date !== null) {
                          object.decision_date = openItem.decision_making_date;
                        } else {
                          object.decision_date = '';
                        }

                        if (openItem.litigation_number !== null) {
                          object.litigation_number = openItem.litigation_number;
                        } else {
                          object.name = '';
                        }

                        if (openItem.proposal_type !== null) {
                          object.proposal_type = openItem.proposal_type.name;
                        } else {
                          object.proposal_type = '';
                        }

                        if (openItem.parent !== null) {
                          object.parent_litigation_number =
                            openItem.parent.litigation_number;
                        } else {
                          object.parent_litigation_number = '';
                        }

                        if (openItem.procedure_type_id !== null) {
                          object.procedure_type = openItem.procedure_type;
                        } else {
                          object.procedure_type = '';
                        }

                        if (openItem.name_first_party !== null) {
                          object.name_first_party = openItem.name_first_party;
                        } else {
                          object.name_first_party = '';
                        }

                        if (openItem.email_first_party !== null) {
                          object.email_first_party = openItem.email_first_party;
                        } else {
                          object.email_first_party = '';
                        }

                        if (openItem.name_second_party !== null) {
                          object.name_second_party = openItem.name_second_party;
                        } else {
                          object.name_second_party = '';
                        }

                        if (openItem.email_second_party !== null) {
                          object.email_second_party =
                            openItem.email_second_party;
                        } else {
                          object.email_second_party = '';
                        }

                        if (openItem.litigation_type !== null) {
                          object.litigation_type =
                            openItem.litigation_type.litigation_name;
                        } else {
                          object.litigation_type = '';
                        }

                        if (openItem.litigator !== null) {
                          object.litigator = openItem.litigator;
                        } else {
                          object.litigator = '';
                        }

                        if (openItem.conclusion !== null) {
                          object.conclusion = openItem.conclusion;
                        } else {
                          object.conclusion = '';
                        }

                        if (openItem.litigator_status !== null) {
                          object.litigator_status =
                            openItem.litigator_status.name;
                        } else {
                          object.litigator_status = '';
                        }

                        object.document_date_of_input =
                          openItem.document_date_of_input;

                        object.address_first_party_line_1 =
                          openItem.street_first_party +
                          ' ' +
                          openItem.street_number_first_party;

                        object.address_second_party_line_1 =
                          openItem.street_second_party +
                          ' ' +
                          openItem.street_number_second_party;

                        if (openItem.municipality_first_party !== null) {
                          object.address_first_party_line_2 =
                            openItem.post_number_first_party +
                            ' ' +
                            openItem.city_first_party +
                            ', ' +
                            openItem.municipality_first_party;
                        } else {
                          object.address_first_party_line_2 =
                            openItem.post_number_first_party +
                            ' ' +
                            openItem.city_first_party;
                        }

                        if (openItem.municipality_second_party !== null) {
                          object.address_second_party_line_2 =
                            openItem.post_number_second_party +
                            ' ' +
                            openItem.city_second_party +
                            ', ' +
                            openItem.municipality_second_party;
                        } else {
                          object.address_second_party_line_2 =
                            openItem.post_number_second_party +
                            ' ' +
                            openItem.city_second_party;
                        }

                        axios({
                          url: 'getDownload', //your url
                          method: 'POST',
                          responseType: 'blob', // important
                          data: object
                        }).then((response) => {
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', `${item.name}.docx`); //or any other extension
                          document.body.appendChild(link);
                          link.click();
                          officeDocumentsListingStore.fetchCreatedDocumentsByLitigationId();
                          setCreatedSwitch(false);
                        });
                        this.handleClose();
                      }}
                      style={{ fontSize: 14 }}
                    >
                      {item.order}. {item.name}
                    </MenuItem>
                  )) : <MenuItem>За Миритеља/Арбитра</MenuItem>}
                </Menu>
                <Button
                  onClick={() => {
                    // if (backLink === undefined) {
                    //   litigationsListingStore.showAddButton();
                    //   litigatorAvatarsStore.clearLitigatorAvatar();
                    //   if (this.documentButtonSwitch) {
                    //     this.toggleDocumentButtonSwitch();
                    //   }
                    //   // uraditi : Kad se vratim da se vrati na litigations listing page
                    //   // closeModalForm();
                    // } else {
                    //   //window.history.back();
                    //   window.location.href = '/dashboard/litigations';
                    // }
                    window.location.href = '/dashboard/litigations';
                  }}
                  variant="contained"
                  style={{
                    backgroundColor: '#fb8c00',
                    fontSize: 14,
                    fontWeight: 400,
                    color: 'white',
                    width: '205px',
                    height: '36px',
                    borderRadius: '4px',
                    marginLeft: '15px'
                  }}
                >
                  <ArrowBackIosIcon style={{ fontSize: 12, color: 'white' }} />{' '}
                  НАЗАД
                </Button>
              </div>
            </Box>
            <Box style={{ padding: 0 }}>
              <Formik
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                render={({
                  values,
                  dirty,
                  setFieldValue,
                  errors,
                  touched,
                  setFieldTouched
                }) => (
                  <Form noValidate className="form-horizontal">
                    <Modal.Body className="add-litigation-div">
                      {/* Document files */}

                      {/* Litigation data */}
                      <div
                        style={{
                          display: this.documentButtonSwitch ? 'none' : 'block',
                          maxHeight: '650px',
                          overflow: 'scroll'
                        }}
                      >
                        <Col md={12}>
                          <Row>
                            <Col md={3}>
                              <Row>
                                <Col md={12}>
                                  <GreenSwitch
                                    onChange={() => {
                                      // provera da li je miritelj odabran, ukoliko nije predmet se moze arhivirati bez placanja
                                      // ukoliko je izabran miritelj, mora se cekirati da su obaveze placene da bi se arhivirao predmet.
                                      if (
                                        (values.conciliator_id !== null &&
                                          values.payment_status) ||
                                        (values.conciliator_id === null &&
                                          !values.payment_status)
                                      ) {
                                        setFieldValue(
                                          'status',
                                          this.switchValue ? 0 : 1
                                        );
                                        this.handleChangeSwitchLabel();
                                      }
                                    }}
                                    disabled={
                                      (values.conciliator_id !== null &&
                                        !values.payment_status) ||
                                      authUser.roles[0].name ===
                                      'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                    }
                                    checked={this.switchValue ? true : false}
                                  />

                                  {this.switchValue
                                    ? 'Поступак у току'
                                    : 'Предмет је архивиран'}

                                  {values.conciliator_id !== null &&
                                    !values.payment_status ? (
                                    <span
                                      style={{
                                        color: 'red',
                                        fontSize: 12,
                                        display: 'block'
                                      }}
                                    >
                                      Предмет се не може архивирати јер нису
                                      измирене финансијске обавезе
                                    </span>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                            <Col md={9}>
                              <Row>
                                <Col md={3}>
                                  {values.isParent ? (
                                    <>
                                      {/* ako je noseci predmet */}
                                      <GreenSwitch
                                        disabled={
                                          authUser.roles[0].name ===
                                          'Финансијска служба' || true
                                        }
                                        onChange={() => {
                                          console.log('cant set nothing');
                                        }}
                                        checked={true}
                                        inputProps={{
                                          'aria-label': 'secondary checkbox'
                                        }}
                                      />
                                      Носећи Здружени предмет
                                    </>
                                  ) : (
                                    <>
                                      {/* ako nije noseci */}
                                      <GreenSwitch
                                        disabled={
                                          !this.switchValue ||
                                          authUser.roles[0].name ===
                                          'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            'hasParent',
                                            this.hasParent ? 0 : 1
                                          );
                                          setFieldValue(
                                            'parent_id',
                                            !values.parent_id
                                          );
                                          this.handleChangeHasParent();
                                        }}
                                        checked={
                                          this.hasParent || values.parent_id
                                            ? true
                                            : false
                                        }
                                      />
                                      {this.hasParent || values.parent_id
                                        ? 'Здружени предмет'
                                        : 'Здружи предмет'}
                                    </>
                                  )}
                                </Col>
                                <Col md={3}>
                                  <GreenSwitch
                                    disabled={!this.switchValue || authUser.roles[0].name === 'Миритељ/Арбитар'}
                                    onChange={() => {
                                      setFieldValue(
                                        'payment_status',
                                        this.paymentStatus ? 0 : 1
                                      );
                                      this.handleChangePaymentStatus();
                                    }}
                                    checked={
                                      Number(this.paymentStatus) ? true : false
                                    }
                                  />

                                  {this.paymentStatus
                                    ? 'Плаћено'
                                    : 'Није плаћено'}
                                </Col>
                                <Col
                                  md={3}
                                  style={{
                                    visibility:
                                      this.hasParent || values.parent_id
                                        ? 'visible'
                                        : 'hidden'
                                  }}
                                >
                                  <GreenSwitch
                                    disabled={
                                      !this.switchValue ||
                                      authUser.roles[0].name ===
                                      'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                    }
                                    onChange={() => {
                                      if (this.fillFirstSide) {
                                        this.setState({
                                          procedure_type_id: null,
                                          proposal_type_id: null,
                                          litigation_type_id: null,
                                          legal_form_first_party_id: null,
                                          name_first_party: '',
                                          email_first_party: '',
                                          street_first_party: '',
                                          street_number_first_party: '',
                                          phone_first_party: '',
                                          city_first_party: '',
                                          municipality_first_party: '',
                                          post_number_first_party: ''
                                        });

                                        this.setFillFirstSide(0);
                                      } else {
                                        // lokalno menjanje tj popunjavanje predmeta
                                        console.log('v ', values.parent)
                                        this.setState({
                                          name_first_party:
                                            values.parent.name_first_party,
                                          email_first_party:
                                            values.parent.email_first_party,
                                          street_first_party:
                                            values.parent.street_first_party,
                                          street_number_first_party:
                                            values.parent
                                              .street_number_first_party,
                                          phone_first_party:
                                            values.parent.phone_first_party,
                                          city_first_party:
                                            values.parent.city_first_party,
                                          municipality_first_party:
                                            values.parent
                                              .municipality_first_party,
                                          post_number_first_party:
                                            values.parent
                                              .post_number_first_party,
                                          procedure_type_id:
                                            values.parent.procedure_type_id,
                                          proposal_type_id:
                                            values.parent.proposal_type_id,
                                          litigation_type_id:
                                            values.parent.litigation_type_id,
                                          procedure_type:
                                            values.parent.procedure_type,
                                          legal_form_first_party_id:
                                            values.parent
                                              .legal_form_first_party_id
                                        });

                                        this.setFillFirstSide(1);
                                      }
                                    }}
                                    checked={this.fillFirstSide ? true : false}
                                  />
                                  Попуни прву страну
                                </Col>
                                <Col
                                  md={3}
                                  style={{
                                    visibility:
                                      this.hasParent || values.parent_id
                                        ? 'visible'
                                        : 'hidden'
                                  }}
                                >
                                  <GreenSwitch
                                    disabled={
                                      !this.switchValue ||
                                      authUser.roles[0].name ===
                                      'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                    }
                                    onChange={() => {
                                      if (this.fillSecondSide) {
                                        this.setState({
                                          legal_form_second_party_id: null,
                                          name_second_party: '',
                                          email_second_party: '',
                                          street_second_party: '',
                                          street_number_second_party: '',
                                          phone_second_party: '',
                                          city_second_party: '',
                                          municipality_second_party: '',
                                          post_number_second_party: ''
                                        });

                                        this.setFillSecondSide(0);
                                      } else {
                                        this.setState({
                                          name_second_party:
                                            values.parent.name_second_party,
                                          email_second_party:
                                            values.parent.email_second_party,
                                          street_second_party:
                                            values.parent.street_second_party,
                                          street_number_second_party:
                                            values.parent
                                              .street_number_second_party,
                                          phone_second_party:
                                            values.parent.phone_second_party,
                                          city_second_party:
                                            values.parent.city_second_party,
                                          municipality_second_party:
                                            values.parent
                                              .municipality_second_party,
                                          post_number_second_party:
                                            values.parent
                                              .post_number_second_party,
                                          legal_form_second_party_id:
                                            values.parent
                                              .legal_form_second_party_id
                                        });

                                        this.setFillSecondSide(1);
                                      }
                                    }}
                                    checked={this.fillSecondSide ? true : false}
                                  />
                                  Попуни другу страну
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={3}>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  InputProps={{
                                    style: { fontSize: 16, width: '100%' }
                                  }}
                                  label="Датум уписа*"
                                  value={values.document_date_of_input}
                                  disabled
                                  inputFormat="dd.MM.yyyy"
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      'document_date_of_input',
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextFIELD
                                      variant="outlined"
                                      name="document_date_of_input"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <Field
                                disabled={true}
                                component={TextField}
                                type="text"
                                label="Време уписа*"
                                variant="outlined"
                                name="document_time_of_input"
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                style={{
                                  width: '100%',
                                  fontFamily: 'Roboto'
                                }}
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <Field
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                component={TextField}
                                name="litigation_number"
                                type="text"
                                label="Број предмета*"
                                variant="outlined"
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                style={{
                                  width: '100%',
                                  fontFamily: 'Roboto'
                                }}
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.working_on_user_id}
                                fullValue={values.worker}
                                showFields={['firstname', 'lastname']}
                                // repo={WorkersRepo}
                                data={this.allSelectOptions.workers}
                                display="name"
                                name="working_on_user_id"
                                fullValueName="worker"
                                valueAttr="id"
                                label="Обрађивач*"
                                queryParams={{ search: 'role_id:4' }}
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.procedure_phase_id}
                                fullValue={values.phase}
                                display="name"
                                // repo={PhasesRepo}
                                data={this.allSelectOptions.phases}
                                name="procedure_phase_id"
                                fullValueName="phase"
                                valueAttr="id"
                                label="Фаза поступка*"
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  InputProps={{
                                    style: { fontSize: 16, width: '100%' }
                                  }}
                                  label="Датум доношења решења/препоруке миритеља*"
                                  value={values.decision_making_date}
                                  inputFormat="dd.MM.yyyy"
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      'decision_making_date',
                                      moment(newValue).format('YYYY-MM-DD')
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextFIELD
                                      variant="outlined"
                                      name="decision_making_date"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={12}>
                              <Field
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                component={TextField}
                                name="additional_info"
                                type="text"
                                label="Напомене"
                                multiline
                                value={values.additional_info || ''}
                                rows={5}
                                variant="outlined"
                                InputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                style={{
                                  width: '100%',
                                  fontFamily: 'Roboto'
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={9}>
                          <Row>
                            {openItem && litigationsArray.length ? (
                              <Col md={6}>
                                <VirtualizedAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  data={litigationsArray}
                                  style={{
                                    display:
                                      this.hasParent || values.parent_id
                                        ? 'block'
                                        : 'none'
                                  }}
                                  label={'Носећи предмет'}
                                  idVal={values.parent_id}
                                  fieldValue={'parent_id'}
                                  setFieldValue={setFieldValue}
                                  fullValue={values.parent}
                                  fullValueKey="parent"
                                />
                              </Col>
                            ) : null}
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            {values.procedure_type_id == '2' ? (
                              // if type name is "Индивидуални"
                              <Col md={6}>
                                <CustomMaterialUiSelect
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  setFieldValue={setFieldValue}
                                  value={values.procedure_type_id || ''}
                                  fullValue={values.procedure_type}
                                  display="name"
                                  fromState={true}
                                  stateValue={this.state.procedure_type_id}
                                  // repo={ProcedureTypesRepo}
                                  data={this.allSelectOptions.procedure_types}
                                  name="procedure_type_id"
                                  InputLabelProps={
                                    !!this.state.proposal_type_id
                                  }
                                  fullValueName="procedure_type"
                                  valueAttr="id"
                                  label="Врста поступка*"
                                />
                              </Col>
                            ) : (
                              // if type name is "Колективни"
                              <>
                                <Col md={3}>
                                  <CustomMaterialUiSelect
                                    disabled={
                                      !this.switchValue ||
                                      authUser.roles[0].name ===
                                      'Финансијска служба'
                                      || authUser.roles[0].name === 'Миритељ/Арбитар'
                                    }
                                    setFieldValue={setFieldValue}
                                    value={values.procedure_type_id || ''}
                                    fullValue={values.procedure_type}
                                    display="name"
                                    fromState={true}
                                    stateValue={this.state.procedure_type_id}
                                    // repo={ProcedureTypesRepo}
                                    data={this.allSelectOptions.procedure_types}
                                    name="procedure_type_id"
                                    InputLabelProps={
                                      !!this.state.proposal_type_id
                                    }
                                    fullValueName="procedure_type"
                                    valueAttr="id"
                                    label="Врста поступка*"
                                  />
                                </Col>
                                <Col md={3}>
                                  <Field
                                    disabled={
                                      !this.switchValue ||
                                      authUser.roles[0].name ===
                                      'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                    }
                                    component={TextField}
                                    value={values.affected_number || ''}
                                    name="affected_number"
                                    type="number"
                                    label="На колико људи утиче"
                                    variant="outlined"
                                    inputProps={{ style: { fontSize: 16 } }}
                                    InputLabelProps={{
                                      style: { fontSize: 16 }
                                    }}
                                    style={{
                                      width: '100%',
                                      fontFamily: 'Roboto'
                                    }}
                                  />
                                </Col>
                              </>
                            )}

                            {/* <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба'
                                }
                                setFieldValue={setFieldValue}
                                value={values.procedure_type_id || ''}
                                fullValue={values.procedure_type}
                                display="name"
                                fromState={true}
                                stateValue={this.state.procedure_type_id}
                                // repo={ProcedureTypesRepo}
                                data={this.allSelectOptions.procedure_types}
                                name="procedure_type_id"
                                InputLabelProps={!!this.state.proposal_type_id}
                                fullValueName="procedure_type"
                                valueAttr="id"
                                label="Врста поступка*"
                              />
                            </Col> */}
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.proposal_type_id || ''}
                                fullValue={values.proposal_type}
                                display="name"
                                fromState={true}
                                stateValue={this.state.proposal_type_id}
                                // repo={ProposalTypesRepo}
                                data={this.allSelectOptions.proposal_types}
                                name="proposal_type_id"
                                InputLabelProps={!!this.state.proposal_type_id}
                                fullValueName="proposal_type"
                                valueAttr="id"
                                label="Врста предлога*"
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "40px 0px" }}>
                            <Col md={6}>
                              {values.procedure_type_id ? (
                                <CustomMaterialUiSelect
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  passedProp={values.procedure_type_id}
                                  setFieldValue={setFieldValue}
                                  value={values.litigation_type_id || ''}
                                  fullValue={values.litigation_type}
                                  display="litigation_name"
                                  fromState={true}
                                  stateValue={this.state.litigation_type_id}
                                  repo={LitigationTypesRepo}
                                  data={this.allSelectOptions.litigation_types}
                                  name="litigation_type_id"
                                  InputLabelProps={
                                    !!this.state.proposal_type_id
                                  }
                                  fullValueName="litigation_type"
                                  valueAttr="id"
                                  label="Предмет спора*"
                                  queryParams={{
                                    search: `procedure_type_id:${values.procedure_type_id}`
                                  }}
                                />
                              ) : null}
                            </Col>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={() => { }}
                                customOnChange={(e: any) => {
                                  setFieldValue('sector', e);
                                }}
                                value={values.sector || ''}
                                display="name"
                                data={[
                                  { name: 'Државни', value: 'state' },
                                  { name: 'Приватни', value: 'private' }
                                ]}
                                valueAttr="value"
                                label="Сектор"
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "20px 0px" }}>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.legal_form_first_party_id || ''}
                                fullValue={values.legal_form_first_party}
                                fromState={true}
                                stateValue={
                                  this.state.legal_form_first_party_id
                                }
                                // repo={LegalFormTypesRepo}
                                data={this.allSelectOptions.legal_form_types}
                                display="name"
                                name="legal_form_first_party_id"
                                InputLabelProps={
                                  !!this.state.legal_form_first_party_id
                                }
                                fullValueName="legal_form_first_party"
                                valueAttr="id"
                                label="Правна форма прве стране"
                              />
                            </Col>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.legal_form_second_party_id || ''}
                                fullValue={values.legal_form_second_party}
                                // repo={LegalFormTypesRepo}
                                data={this.allSelectOptions.legal_form_types}
                                display="name"
                                name="legal_form_second_party_id"
                                fullValueName="legal_form_second_party"
                                valueAttr="id"
                                label="Правна форма друге стране"
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "50px 0px" }}>
                            <Col md={6} style={{ marginTop: 10 }}>
                              {values.name_first_party !== "" ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Име и презиме/назив прве стране</label>
                                : null}
                              {/* <ServerSelectAutocomplete
                            disabled={!this.switchValue}
                            label={'Име и презиме/назив прве стране*'}
                            id={'nesto'}
                            repo={LitigationsRepo}
                            data={litigationsListingStore.litigationsArray}
                            property={'name_first_party'}
                            fieldValue="name_first_party"
                            setFieldValue={setFieldValue}
                            value={values.name_first_party}
                          /> */}
                              {/* {this.state.name_first_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Име и презиме/назив прве стране*'}
                                  id={'nesto'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  property={'name_first_party'}
                                  fieldValue="name_first_party"
                                  setFieldValue={setFieldValue}
                                  value={values.name_first_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="name_first_party"
                                  type="text"
                                  label="Име и презиме/назив прве стране"
                                  value={this.state.name_first_party || ''}
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                placeholder="Име и презиме/назив прве стране"
                                onCreateOption={(option: string) => {
                                  this.setInputNameFirstPartyValue(option, setFieldValue);
                                }}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}names`}
                                // value={{ id: undefined, label: values.name_first_party ? values.name_first_party : "Име и презиме/назив прве стране" }}
                                value={{ label: this.state.name_first_party || values.name_first_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputNameFirstPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />

                              {this.state.email_first_party === null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  value={values.email_first_party || ''}
                                  name="email_first_party"
                                  type="text"
                                  label="Емаил прве стране"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: 20
                                  }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="email_first_party"
                                  type="text"
                                  label="Емаил прве стране"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: 20
                                  }}
                                  value={this.state.email_first_party || ''}
                                />
                              )}
                            </Col>
                            <Col md={6} style={{ marginTop: 10 }}>
                              {values.name_second_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Име и презиме/назив друге стране</label>
                                : null}
                              {/* {this.state.name_second_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Име и презиме/назив друге стране*'}
                                  id={'nesto1'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  property={'name_second_party'}
                                  fieldValue={'name_second_party'}
                                  setFieldValue={setFieldValue}
                                  value={values.name_second_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="name_second_party"
                                  type="text"
                                  label="Име и презиме/назив друге стране"
                                  value={this.state.name_second_party || ''}
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                placeholder="Име и презиме/назив друге стране"
                                onCreateOption={(option: string) => this.setInputNameSecondPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}names`}
                                // value={{ id: undefined, label: values.name_second_party ? values.name_second_party : "Име и презиме/назив друге стране" }}
                                value={{ label: this.state.name_second_party || values.name_second_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputNameSecondPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />

                              {this.state.email_second_party === null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  value={values.email_second_party || ''}
                                  name="email_second_party"
                                  type="text"
                                  label="Емаил друге стране"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: 20
                                  }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="email_second_party"
                                  type="text"
                                  label="Емаил друге стране"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: 20
                                  }}
                                  value={this.state.email_second_party || ''}
                                />
                              )}
                            </Col>

                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={3} style={{ marginTop: 13 }}>
                              {values.municipality_first_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Општина прве стране</label>
                                : null}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputMunicipalityFirstPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}municipalities`}
                                // value={{ id: undefined, label: values.municipality_first_party ? values.municipality_first_party : "Општина прве стране" }}
                                value={{ label: this.state.municipality_first_party || values.municipality_first_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputMunicipalityFirstPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3}>
                              {this.state.phone_first_party === null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="phone_first_party"
                                  type="text"
                                  label="Контакт број"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                    shrink: !!values.phone_first_party
                                  }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                  }}
                                  value={values.phone_first_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="phone_first_party"
                                  type="text"
                                  label="Контакт број"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                    shrink: !!this.state.phone_first_party
                                  }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                  value={this.state.phone_first_party || ''}
                                />
                              )}
                            </Col>
                            <Col md={3} style={{ marginTop: 13 }}>
                              {values.municipality_second_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Општина друге стране</label>
                                : null}
                              {/* {this.state.municipality_second_party === null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  value={values.municipality_second_party || ''}
                                  name="municipality_second_party"
                                  type="text"
                                  label="Општина друге стране"
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="municipality_second_party"
                                  type="text"
                                  label="Општина друге стране"
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                  value={
                                    this.state.municipality_second_party || ''
                                  }
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputMunicipalitySecondPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}municipalities`}
                                // value={{ id: undefined, label: values.municipality_second_party ? values.municipality_second_party : "Општина друге стране" }}
                                value={{ label: this.state.municipality_second_party || values.municipality_second_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputMunicipalitySecondPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3}>
                              {this.state.phone_second_party === null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  value={values.phone_second_party || ''}
                                  name="phone_second_party"
                                  type="text"
                                  label="Контакт број"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="phone_second_party"
                                  type="text"
                                  label="Контакт број"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                    shrink: !!this.state.phone_second_party
                                  }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                  value={this.state.phone_second_party || ''}
                                />
                              )}
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={3} style={{ marginTop: 10 }}>
                              {values.street_first_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Улица прве стране</label>
                                : null}
                              {/* {this.state.street_first_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Улица прве стране*'}
                                  id={'nesto2'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  property={'street_first_party'}
                                  setFieldValue={setFieldValue}
                                  fieldValue={'street_first_party'}
                                  value={values.street_first_party || ''}
                                  style={{ marginTop: 10 }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="street_first_party"
                                  type="text"
                                  label="Улица прве стране*"
                                  value={this.state.street_first_party || ''}
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: 10
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputStreetFirstPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}streets`}
                                // value={{ id: undefined, label: values.street_first_party ? values.street_first_party : "Улица прве стране" }}
                                value={{ label: this.state.street_first_party || values.street_first_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputStreetFirstPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3}>
                              {this.state.street_number_first_party === null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="street_number_first_party"
                                  type="text"
                                  label="Број прве стране*"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                    shrink: !!values.street_number_first_party
                                  }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: -4
                                  }}
                                  value={values.street_number_first_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="street_number_first_party"
                                  type="text"
                                  label="Број прве стране*"
                                  value={
                                    this.state.street_number_first_party || ''
                                  }
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                    shrink: !!this.state
                                      .street_number_first_party
                                  }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )}
                            </Col>
                            <Col md={3} style={{ marginTop: 10 }}>
                              {values.street_second_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Улица друге стране</label>
                                : null}
                              {/* {this.state.street_second_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Улица друге стране*'}
                                  id={'nesto3'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  property={'street_second_party'}
                                  setFieldValue={setFieldValue}
                                  fieldValue={'street_second_party'}
                                  value={values.street_second_party || ''}
                                  style={{ marginTop: 10 }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="street_second_party"
                                  type="text"
                                  label="Улица друге стране*"
                                  value={this.state.street_second_party || ''}
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: 10
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputStreetSecondPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}streets`}
                                // value={{ id: undefined, label: values.street_second_party ? values.street_second_party : "Улица друге стране" }}
                                value={{ label: this.state.street_second_party || values.street_second_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputStreetSecondPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3}>
                              {this.state.street_number_second_party ===
                                null ? (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  value={
                                    values.street_number_second_party || ''
                                  }
                                  name="street_number_second_party"
                                  type="text"
                                  label="Број друге стране*"
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto',
                                    marginTop: -4
                                  }}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                  }
                                  component={TextField}
                                  name="street_number_second_party"
                                  type="text"
                                  label="Број друге стране*"
                                  value={
                                    this.state.street_number_second_party || ''
                                  }
                                  variant="outlined"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                    shrink: !!this.state
                                      .street_number_second_party
                                  }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={3} style={{ marginTop: 20 }}>
                              {values.city_first_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Место прве стране</label>
                                : null}
                              {/* {this.state.city_first_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Место прве стране*'}
                                  id={'nesto4'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsArray.slice(0, 100)
                                  }
                                  property={'city_first_party'}
                                  setFieldValue={setFieldValue}
                                  fieldValue={'city_first_party'}
                                  value={values.city_first_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="city_first_party"
                                  type="text"
                                  label="Место прве стране*"
                                  value={this.state.city_first_party || ''}
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputCityFirstPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}cities`}
                                // value={{ id: undefined, label: values.city_first_party ? values.city_first_party : "Место прве стране" }}
                                value={{ label: this.state.city_first_party || values.city_first_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputCityFirstPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3} style={{ marginTop: 20 }}>
                              {values.post_number_first_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Поштански број прве стране</label>
                                : null}
                              {/* {this.state.post_number_first_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Поштански број прве стране*'}
                                  id={'nesto5'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  style={{ marginTop: -10 }}
                                  property={'post_number_first_party'}
                                  setFieldValue={setFieldValue}
                                  fieldValue={'post_number_first_party'}
                                  value={values.post_number_first_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="post_number_first_party"
                                  type="text"
                                  label="Поштански број прве стране*"
                                  value={
                                    this.state.post_number_first_party || ''
                                  }
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputPostNumberFirstPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}post_numbers`}
                                // value={{ id: undefined, label: values.post_number_first_party ? values.post_number_first_party : "Поштански број прве стране" }}
                                value={{ label: this.state.post_number_first_party || values.post_number_first_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputPostNumberFirstPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3} style={{ marginTop: 20 }}>
                              {values.city_second_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Место друге стране</label>
                                : null}
                              {/* {this.state.city_second_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Место друге стране*'}
                                  id={'nesto6'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  property={'city_second_party'}
                                  setFieldValue={setFieldValue}
                                  fieldValue={'city_second_party'}
                                  value={values.city_second_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="city_second_party"
                                  type="text"
                                  label="Место друге стране*"
                                  value={this.state.city_second_party || ''}
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                placeholder="test"
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputCitySecondPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}cities`}
                                // value={{ id: undefined, label: values.city_second_party ? values.city_second_party : "Место друге стране" }}
                                value={{ label: this.state.city_second_party || values.city_second_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputCitySecondPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                            <Col md={3} style={{ marginTop: 20 }}>
                              {values.post_number_second_party !== '' ?
                                <label style={{ position: 'absolute', top: -30, color: "gray", fontWeight: 500, fontSize: 10 }}>Поштански број друге стране</label>
                                : null}
                              {/* {this.state.post_number_second_party === null ? (
                                <ServerSelectAutocomplete
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  label={'Поштански број друге стране*'}
                                  id={'nesto7'}
                                  repo={LitigationsRepo}
                                  data={
                                    litigationsListingStore.litigationsArray
                                  }
                                  style={{ marginTop: -10 }}
                                  property={'post_number_second_party'}
                                  setFieldValue={setFieldValue}
                                  fieldValue={'post_number_second_party'}
                                  value={values.post_number_second_party || ''}
                                />
                              ) : (
                                <Field
                                  disabled={
                                    !this.switchValue ||
                                    authUser.roles[0].name ===
                                    'Финансијска служба'
                                  }
                                  component={TextField}
                                  name="post_number_second_party"
                                  type="text"
                                  label="Поштански број друге стране*"
                                  value={
                                    this.state.post_number_second_party || ''
                                  }
                                  variant="standard"
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  style={{
                                    width: '100%',
                                    fontFamily: 'Roboto'
                                  }}
                                />
                              )} */}
                              <SelectFetchCreatable
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // Adjust the border style, width, and color as needed
                                    paddingLeft: "15px",
                                    height: "56px"
                                  }),
                                }}
                                isDisabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                onCreateOption={(option: string) => this.setInputPostNumberSecondPartyValue(option, setFieldValue)}
                                // defaultOptions={[{ label: 'Test 1', value: 1 }]}
                                url={`${process.env.REACT_APP_API_URL}post_numbers`}
                                // value={{ id: undefined, label: values.post_number_second_party ? values.post_number_second_party : "Поштански број друге стране" }}
                                value={{ label: this.state.post_number_second_party || values.post_number_second_party }}
                                isClearable
                                isSearchable
                                onChange={(e: any) => this.setInputPostNumberSecondPartyValue(e, setFieldValue)}
                                get={this.loadCityOptions}
                                queryParams={{
                                  limit: 10,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.conciliator_choosing_way_id || ''}
                                fullValue={values.litigator_status}
                                // repo={LitigatorStatusesRepo}
                                data={this.allSelectOptions.litigator_statuses}
                                display="name"
                                name="conciliator_choosing_way_id"
                                fullValueName="litigator_status"
                                valueAttr="id"
                                label="Начин избора миритеља односно арбитра"
                              />
                            </Col>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.conciliator_id || ''}
                                fullValue={values.litigator}
                                showFields={[
                                  'litigator_firstname',
                                  'litigator_lastname'
                                ]}
                                display="name"
                                // repo={LitigatorsRepo}
                                data={this.allSelectOptions.litigators}
                                name="conciliator_id"
                                fullValueName="litigator"
                                valueAttr="id"
                                label="Миритељ односно арбитар"
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "20px 0px" }}>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.consent_id || ''}
                                fullValue={values.consent}
                                // repo={ConsentsRepo}
                                data={this.allSelectOptions.consents}
                                display="name"
                                name="consent_id"
                                fullValueName="consent"
                                valueAttr="id"
                                label="Сагласност"
                              />
                            </Col>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.finalizing_procedure_way_id || ''}
                                fullValue={values.finalizing_procedure_way}
                                // repo={StatusesRepo}
                                data={this.allSelectOptions.statuses}
                                display="name"
                                name="finalizing_procedure_way_id"
                                fullValueName="finalizing_procedure_way"
                                valueAttr="id"
                                label="Начин окончања поступка"
                              />
                            </Col>
                          </Row>
                          <Row style={{ margin: "30px 0px" }}>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.rejection_reason_id || ''}
                                fullValue={values.rejection_reason}
                                // repo={RejectionReasonsRepo}
                                data={this.allSelectOptions.rejection_reasons}
                                display="name"
                                name="rejection_reason_id"
                                fullValueName="rejection_reason"
                                valueAttr="id"
                                label="Одбацивање"
                              />
                            </Col>
                            <Col md={6}>
                              <CustomMaterialUiSelect
                                disabled={
                                  !this.switchValue ||
                                  authUser.roles[0].name ===
                                  'Финансијска служба' || authUser.roles[0].name === 'Миритељ/Арбитар'
                                }
                                setFieldValue={setFieldValue}
                                value={values.conclusion_id || ''}
                                fullValue={values.conclusion}
                                fullValueName="conclusion"
                                display="name"
                                // repo={ConclusionsRepo}
                                data={this.allSelectOptions.conclusions}
                                name="conclusion_id"
                                valueAttr="id"
                                label="Обустава"
                              />
                            </Col>
                          </Row>
                          {/* Add more fields */}
                        </Col>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div>
                        <PermissionGuard
                          permissionsWithAccess={permission.litigationsDelete}
                        >
                          <Button
                            variant="text"
                            onClick={() => this.setDialogOpen(4343434343)}
                            style={{
                              padding: '0px 10px',
                              fontSize: 14,
                              color: 'black',
                              display: this.documentButtonSwitch
                                ? 'none'
                                : 'flex'
                            }}
                          >
                            <CloseIcon
                              style={{ fontSize: 20, color: 'black' }}
                            />
                            ОБРИШИ
                          </Button>
                          {/* Delete Dialog */}
                        </PermissionGuard>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        {values.isParent ? (
                          <Button
                            onClick={() => {
                              let children = values.children.map(
                                (item: any) => {
                                  return item.id;
                                }
                              );

                              litigationsStore.fillChildLitigations(
                                values.id,
                                children
                              );
                            }}
                            variant="text"
                            style={{
                              padding: '0px 10px',
                              fontSize: 14,
                              color: 'black',
                              display: this.documentButtonSwitch
                                ? 'none'
                                : 'flex'
                            }}
                          >
                            <CheckIcon
                              style={{ fontSize: 20, color: 'black' }}
                            />
                            ПОПУНИ ЗДРУЖЕНЕ ПРЕДМЕТЕ
                          </Button>
                        ) : null}
                        <PermissionGuard
                          permissionsWithAccess={permission.litigationsEdit}
                        >
                          <Button
                            onClick={() => {
                              litigationsListingStore.showAddButton();
                            }}
                            type="submit"
                            variant="text"
                            style={{
                              padding: '0px 10px',
                              fontSize: 14,
                              color: 'black',
                              display: this.documentButtonSwitch
                                ? 'none'
                                : 'flex'
                            }}
                          >
                            <CheckIcon
                              style={{ fontSize: 20, color: 'black' }}
                            />
                            САЧУВАЈ
                          </Button>
                        </PermissionGuard>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              />
              <div
                style={{
                  display: this.documentButtonSwitch ? 'block' : 'none',
                  padding: '0 20px',
                  minHeight: '570px'
                }}
              >
                {authUser.roles[0].name !== 'Електронска писарница' ? (
                  <>
                    <PermissionGuard
                      permissionsWithAccess={permission.documentsUpload}
                    >
                      <DropZone
                        modelType={
                          createdSwitch
                            ? authUser.roles[0].name === 'Правна служба'
                              ? 'Litigation'
                              : 'OfficeDocument'
                            : 'Litigation'
                        }
                        id={
                          createdSwitch
                            ? authUser.roles[0].name === 'Правна служба'
                              ? officeDocumentFileUploadsStore.currentLitigationId
                              : officeDocumentFileUploadsStore.currentOfficeDocumentId
                            : officeDocumentFileUploadsStore.currentLitigationId
                        }
                        css={{ marginBottom: '10px' }}
                        title={`Превуците документ овде, или кликните како бисте га одабрали`}
                        subtitle={``}
                        multiple={false}
                        method={
                          officeDocumentsListingStore.uploadOfficeDocumentFile
                        }
                      />
                    </PermissionGuard>
                    <>
                      {createdSwitch ? (
                        <>
                          <h4 style={{ textAlign: 'center' }}>
                            СКЕНИРАНИ ДОКУМЕНТИ
                          </h4>
                        </>
                      ) : (
                        <h4 style={{ textAlign: 'center' }}>
                          КРЕИРАНИ ДОКУМЕНТИ
                        </h4>
                      )}
                    </>
                    {createdSwitch ? (
                      <DataTable
                        enableCaching={false}
                        showPaginationBottom={false}
                        // getTdProps={this.getTdProps}
                        minRows={15}
                        columns={columns}
                        fetchData={
                          officeDocumentsListingStore.fetchScannedDocumentsByLitigationId
                        }
                        data={
                          authUser.roles[0].name === 'Правна служба'
                            ? officeDocumentsListingStore.attachCustomColumn(
                              'actions',
                              this.itemActionWithoutDelete
                            )
                            : officeDocumentsListingStore.attachCustomColumn(
                              'actions',
                              this.itemAction
                            )
                        }
                        loading={officeDocumentsListingStore.loading}
                        defaultSort={[{ id: 'createdAt', desc: true }]}
                      />
                    ) : (
                      <>
                        <DataTable
                          enableCaching={false}
                          showPaginationBottom={false}
                          // getTdProps={this.getTdProps}
                          minRows={15}
                          columns={columns}
                          fetchData={
                            officeDocumentsListingStore.fetchCreatedDocumentsByLitigationId
                          }
                          data={officeDocumentsListingStore.attachCustomColumn(
                            'actions',
                            this.itemAction
                          )}
                          loading={officeDocumentsListingStore.loading}
                          defaultSort={[{ id: 'createdAt', desc: true }]}
                        />
                      </>
                    )}

                    {authUser.roles[0].name !== 'Миритељ/Арбитар' ?
                      <>
                        {createdSwitch ? (
                          <Button
                            style={{
                              float: 'right',
                              height: 50,
                              padding: 15,
                              backgroundColor: '',
                              color: '',
                              marginTop: 10
                            }}
                            onClick={() => {
                              setCreatedSwitch(false);
                            }}
                          >
                            ПРИКАЗ КРЕИРАНИХ ДОКУМЕНАТА
                          </Button>
                        ) : (
                          <>
                            <Button
                              style={{
                                float: 'right',
                                height: 50,
                                padding: 15,
                                backgroundColor: '#4051B5',
                                color: 'white',
                                marginTop: 10
                              }}
                              onClick={() => {
                                setCreatedSwitch(true);
                              }}
                            >
                              ПРИКАЗ СКЕНИРАНИХ ДОКУМЕНАТА
                            </Button>
                          </>
                        )}
                      </>
                      : null}
                  </>
                ) : (
                  <>
                    <PermissionGuard
                      permissionsWithAccess={permission.documentsUpload}
                    >
                      <DropZone
                        modelType={
                          createdSwitch
                            ? authUser.roles[0].name === 'Правна служба'
                              ? 'Litigation'
                              : 'OfficeDocument'
                            : 'Litigation'
                        }
                        id={
                          createdSwitch
                            ? authUser.roles[0].name === 'Правна служба'
                              ? officeDocumentFileUploadsStore.currentLitigationId
                              : officeDocumentFileUploadsStore.currentOfficeDocumentId
                            : officeDocumentFileUploadsStore.currentLitigationId
                        }
                        css={{ marginBottom: '10px' }}
                        title={`Превуците документ овде, или кликните како бисте га одабрали`}
                        subtitle={``}
                        multiple={false}
                        method={
                          officeDocumentsListingStore.uploadOfficeDocumentFile
                        }
                      />
                    </PermissionGuard>
                    <DataTable
                      enableCaching={false}
                      showPaginationBottom={false}
                      // getTdProps={this.getTdProps}
                      minRows={15}
                      columns={columns}
                      fetchData={
                        officeDocumentsListingStore.fetchScannedDocumentsByLitigationId
                      }
                      data={
                        authUser.roles[0].name === 'Правна служба'
                          ? officeDocumentsListingStore.attachCustomColumn(
                            'actions',
                            this.itemActionWithoutDelete
                          )
                          : officeDocumentsListingStore.attachCustomColumn(
                            'actions',
                            this.itemAction
                          )
                      }
                      loading={officeDocumentsListingStore.loading}
                      defaultSort={[{ id: 'createdAt', desc: true }]}
                    />
                  </>
                )}
              </div>
            </Box>
          </Paper>
          {/* ALREADY EXIST */}
          <Dialog
            open={this.alreadyExistModalOpen}
            onClose={() => this.handleCloseAlreadyExistModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id={`alert-dialog-title-already-exist`}>
              {'Упозорење'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id={`alert-dialog-description-already-exist`}>
                Већ постоји предмет са овим Именом/Називом прве стране и предметом спора! Да ли сте сигурни да желите да сачувате ове измене?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleCloseAlreadyExistModal()}>
                Не, одустани
              </Button>
              <Button onClick={() => this.handleUpdateLitigation()} autoFocus>
                Да, Сачувај
              </Button>
            </DialogActions>
          </Dialog>
        </Box >
      </SearchResultsComponent >
    );
  }
}

export default LitigationDetailPage;
