import { PureComponent } from 'react';
import ReactTable, { TableProps, Filter, SortingRule } from 'react-table';
import debounce from 'lodash/debounce';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import moment from 'moment';

import queryBuilder, { Query } from '../../lib/queryBuilder';
import { DEBOUNCE_TIME, REQUEST_DATE_FORMAT } from '../../lib/constants';
import Card from '../../vendors/components/Card.jsx';
import { dataTableStore } from '../../data/stores/DataTableStore';

interface DataTableProps extends Partial<any> {
  fetchData: (query?: Query) => void;
  meta?: any;
  enableCaching: boolean;
  defaultSort?: SortingRule[];
  dateRangeFilter?: boolean;
  dateRangeFilterPlaceholder?: string;
  store?: any;
  showPaginationBottom?: boolean;
  minRows?: number;
  noPagination?: boolean;
  paginationLimit?: number;
  ifEmptyRenderMessage?: string;
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class DataTable extends PureComponent<DataTableProps> {
  static defaultProps = {
    showPageJump: true,
    manual: true,
    filterable: true,
    className: '-highlight',
    enableCaching: true,
    paginationLimit: 100,
    ifEmptyRenderMessage: ''
  };

  debounceFilter = debounce((filtered: Filter[]) => {
    dataTableStore.setFilters(filtered);
    const defaultPage = 1;
    this.onFetchData(defaultPage);
  }, DEBOUNCE_TIME);

  componentDidMount() {
    const { enableCaching, defaultSort, store } = this.props;
    if (store) {
      dataTableStore.initTableStore(
        enableCaching,
        {
          sorted: defaultSort,
          from: store ? store.from : undefined,
          to: store ? store.to : undefined
        },
        'active_litigations'
      );
    } else {
      dataTableStore.initTableStore(
        enableCaching,
        { sorted: defaultSort },
        'active_litigations'
      );
    }

    this.onFetchData();
  }

  onFetchData = async (pageDefault?: number) => {
    const { fetchData, defaultFiltered } = this.props;
    const {
      page,
      limit = 100,
      filtered,
      sorted,
      from,
      to,
      type
    } = dataTableStore.query;

    const query = queryBuilder({
      sorted,
      filtered: [...(filtered || []), ...(defaultFiltered || [])],
      from: from ? moment(from).format(REQUEST_DATE_FORMAT) : '',
      to: to ? moment(to).format(REQUEST_DATE_FORMAT) : '',
      page: pageDefault || page,
      limit,
      type
    });
    fetchData(query);
  };

  onSortChange = (sorted: SortingRule[]) => {
    dataTableStore.setSorting(sorted);
    this.onFetchData();
  };

  extractPaginationData = () => {
    // const { meta } = this.props;

    // if (meta) {
    //   const { pagination } = meta;
    //   return {
    //     page: pagination.current_page,
    //     pages: pagination.last_page
    //   };
    // }

    const { meta } = this.props;

    if (meta) {
      return {
        page: meta.current_page,
        pages: meta.last_page
      };
    }

    return { page: 0, pages: 0 };
  };

  onPageChange = (page: number) => {
    dataTableStore.setPage(page + 1);
    this.onFetchData();
    let myDiv: any = document.querySelector('.rt-table');
    myDiv.style.transition = 'ease .3s';
    myDiv.scrollTop = 0;
  };

  onPageSizeChange = (limit: number) => {
    dataTableStore.setLimit(limit);
    this.onFetchData();
  };

  setDate = (dateType: string, date: any) => {
    const { store } = this.props;

    if (store) {
      if (dateType === 'from') {
        dataTableStore.setFrom(date);
        store.setFrom(date);
      }

      if (dateType === 'to') {
        dataTableStore.setTo(date);
        store.setTo(date);
      }
    } else {
      if (dateType === 'from') {
        dataTableStore.setFrom(date);
      }

      if (dateType === 'to') {
        dataTableStore.setTo(date);
      }
    }

    this.onFetchData();
  };

  // printCustomMetaData = (custom: any) => {
  //   if (!custom || custom.length === 0) return null;

  //   const parseSumOfPossibleCosts = (possibleCosts: number) => {
  //     const formattedPossibleCosts = `${possibleCosts
  //       .toString()
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;

  //     return formattedPossibleCosts;
  //   };

  //   const keys = Object.keys(custom);
  //   const values = Object.values(custom) as any;

  //   return keys.map((key: string, index: number) => (
  //     <p key={key}>
  //       {startCase(key)}:{' '}
  //       <b>
  //         {key === 'sumOfPossibleCosts'
  //           ? parseSumOfPossibleCosts(values[index])
  //           : values[index]}
  //       </b>
  //     </p>
  //   ));
  // };

  render() {
    const { page, pages } = this.extractPaginationData();
    const {
      className,
      meta,
      // dateRangeFilter,
      // dateRangeFilterPlaceholder,
      // store,
      // minRows,
      paginationLimit,
      showPaginationBottom,
      ifEmptyRenderMessage,
      data,
      noPagination
    } = this.props;

    if (!dataTableStore.query) return null;

    const {
      limit = 100,
      filtered,
      sorted
      // from,
      // to
    } = dataTableStore.query;

    if (data!.length === 0 && ifEmptyRenderMessage)
      return (
        <div style={{ marginTop: '45px', marginBottom: '60px' }}>
          {ifEmptyRenderMessage}
        </div>
      );

    return (
      <>
        <Card
          content={
            <>
              {/* {dateRangeFilter && (
                <Row>
                  <Col sm={3}>
                    <DateTimeField
                      value={from}
                      defaultValue={store ? store.from : undefined}
                      onChange={(val: any) => this.setDate('from', val)}
                      // isValidDate={currentDate => currentDate.isBefore(to)}
                      inline={false}
                      inputProps={{
                        placeholder: `Date From ${dateRangeFilterPlaceholder ||
                          ''}`
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <DateTimeField
                      value={to}
                      defaultValue={store ? store.to : undefined}
                      onChange={(val: any) => this.setDate('to', val)}
                      // isValidDate={currentDate => currentDate.isAfter(from)}
                      inline={false}
                      inputProps={{
                        placeholder: `Date To ${dateRangeFilterPlaceholder ||
                          ''}`
                      }}
                    />
                  </Col>
                </Row>
              )} */}

              <ReactTableFixedColumns
                pageSizeOptions={[paginationLimit || limit]}
                defaultPageSize={1}
                defaultFiltered={filtered}
                defaultSorted={sorted}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                pages={pages}
                showPaginationBottom={showPaginationBottom}
                page={page - 1}
                {...this.props}
                onFilteredChange={this.debounceFilter}
                onSortedChange={this.onSortChange}
                previousText="Претходна страна"
                nextText="Следећа страна"
                rowsText="редова"
                pageText="Страница"
                loadingText="Учитавање..."
                noDataText="Нема резултата"
                className={`react-data-table -highlight ${className || ''}`}
              />
            </>
          }
        />

        {/* {this.printCustomMetaData(meta ? meta.custom : null)} */}

        {meta ? (
          <p>
            Укупно предмета: <b>{meta.total}</b>
          </p>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default DataTable;
