import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import { TextField as TextFIELD } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import moment from 'moment';
import { DATE_FORMAT } from 'lib/constants';
import * as ExcelJS from 'exceljs';

export const secondColumn = (
  filterName: string,
  values: any,
  setFieldValue: any,
  allSelectOptions: any
) => {
  let column;
  let consents = allSelectOptions.consents;
  consents.push({
    "id": 0,
    "name": "Чека на сагласност",
    "created_at": "2022-02-10T02:40:45.000000Z",
    "updated_at": "2022-02-10T02:40:45.000000Z"
  })

  switch (filterName) {
    case 'Датум уписа':
      column = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            InputProps={{
              style: { fontSize: 16, width: '100%' }
            }}
            label="Датум уписа*"
            value={values.document_date_of_input}
            inputFormat="dd.MM.yyyy"
            onChange={(newValue) => {
              setFieldValue('document_date_of_input', newValue);
            }}
            renderInput={(params) => (
              <TextFIELD
                variant="standard"
                name="document_date_of_input"
                {...params}
                error={false}
              />
            )}
          />
        </LocalizationProvider>
      );
      break;

    case 'Датум од':
      column = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            InputProps={{
              style: { fontSize: 16, width: '100%' }
            }}
            label="Датум од*"
            value={values.dateFrom}
            inputFormat="dd.MM.yyyy"
            onChange={(newValue) => {
              setFieldValue('dateFrom', newValue);
            }}
            renderInput={(params) => (
              <TextFIELD
                variant="standard"
                name="dateFrom"
                {...params}
                error={false}
              />
            )}
          />
        </LocalizationProvider>
      );
      break;

    case 'Датум до':
      column = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            InputProps={{
              style: { fontSize: 16, width: '100%' }
            }}
            label="Датум до*"
            value={values.dateTo}
            inputFormat="dd.MM.yyyy"
            onChange={(newValue) => {
              setFieldValue('dateTo', newValue);
            }}
            renderInput={(params) => (
              <TextFIELD
                variant="standard"
                name="dateTo"
                {...params}
                error={false}
              />
            )}
          />
        </LocalizationProvider>
      );
      break;

    case 'Број предмета':
      column = (
        <Field
          component={TextField}
          name="litigation_number"
          type="text"
          label="Број предмета*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('litigation_number', e.target.value);
          }}
        />
      );
      break;

    case 'Предмет спора':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.litigation_type_id}
          fullValue={values.litigation_type}
          display="litigation_name"
          data={allSelectOptions.litigation_types}
          name="litigation_type_id"
          fullValueName="litigation_type"
          valueAttr="id"
          label="Предмет спора*"
        />
      );
      break;

    case 'Обрађивач':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.working_on_user_id}
          fullValue={values.worker}
          showFields={['firstname', 'lastname']}
          data={allSelectOptions.workers}
          display="name"
          name="working_on_user_id"
          fullValueName="worker"
          valueAttr="id"
          label="Обрађивач*"
          queryParams={{ search: 'role_id:4' }}
        />
      );
      break;

    case 'Врста поступка':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.procedure_type_id}
          fullValue={values.procedure_type}
          display="name"
          data={allSelectOptions.procedure_types}
          name="procedure_type_id"
          fullValueName="procedure_type"
          valueAttr="id"
          label="Врста поступка*"
        />
      );
      break;

    case 'Правна форма прве стране':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.legal_form_first_party_id}
          fullValue={values.legal_form_first_party}
          data={allSelectOptions.legal_form_types}
          display="name"
          name="legal_form_first_party_id"
          fullValueName="legal_form_first_party"
          valueAttr="id"
          label="Правна форма прве стране"
        />
      );
      break;

    case 'Име и презиме/назив прве стране':
      column = (
        <Field
          component={TextField}
          name="name_first_party"
          type="text"
          label="Име и презиме/назив прве стране"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('name_first_party', e.target.value);
          }}
        />
      );
      break;

    case 'Општина прве стране':
      column = (
        <Field
          component={TextField}
          name="municipality_first_party"
          type="text"
          label="Општина прве стране"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('municipality_first_party', e.target.value);
            // if (e.target.value !== '') setDisableFilterSelect(true);
          }}
        />
      );
      break;

    case 'Место прве стране':
      column = (
        <Field
          component={TextField}
          name="city_first_party"
          type="text"
          label="Место прве стране*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('city_first_party', e.target.value);
          }}
        />
      );
      break;

    case 'Поштански број прве стране':
      column = (
        <Field
          component={TextField}
          name="post_number_first_party"
          type="text"
          label="Поштански број прве стране*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('post_number_first_party', e.target.value);
          }}
        />
      );
      break;

    case 'Врста предлога':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.proposal_type_id || ''}
          fullValue={values.proposal_type}
          display="name"
          fromState={true}
          data={allSelectOptions.proposal_types}
          name="proposal_type_id"
          fullValueName="proposal_type"
          valueAttr="id"
          label="Врста предлога*"
        />
      );
      break;

    case 'Правна форма друге стране':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.legal_form_second_party_id || ''}
          fullValue={values.legal_form_second_party}
          data={allSelectOptions.legal_form_types}
          display="name"
          name="legal_form_second_party_id"
          fullValueName="legal_form_second_party"
          valueAttr="id"
          label="Правна форма друге стране"
        />
      );
      break;

    case 'Име и презиме/назив друге стране':
      column = (
        <Field
          component={TextField}
          name="name_second_party"
          type="text"
          label="Име и презиме/назив друге стране"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('name_second_party', e.target.value);
          }}
        />
      );
      break;

    case 'Општина друге стране':
      column = (
        <Field
          component={TextField}
          name="municipality_second_party"
          type="text"
          label="Општина друге стране"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('municipality_second_party', e.target.value);
          }}
        />
      );
      break;

    case 'Место друге стране':
      column = (
        <Field
          component={TextField}
          name="city_second_party"
          type="text"
          label="Место друге стране*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('city_second_party', e.target.value);
          }}
        />
      );
      break;

    case 'Поштански број друге стране':
      column = (
        <Field
          component={TextField}
          name="post_number_second_party"
          type="text"
          label="Поштански број друге стране*"
          variant="standard"
          inputProps={{ style: { fontSize: 16 } }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          style={{
            width: '100%',
            fontFamily: 'Roboto'
          }}
          disabled={false}
          onChange={(e: any) => {
            setFieldValue('post_number_second_party', e.target.value);
          }}
        />
      );
      break;

    case 'Начин избора миритеља односно арбитра':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.conciliator_choosing_way_id || ''}
          fullValue={values.litigator_status}
          data={allSelectOptions.litigator_statuses}
          display="name"
          name="conciliator_choosing_way_id"
          fullValueName="litigator_status"
          valueAttr="id"
          label="Начин избора миритеља односно арбитра"
        />
      );
      break;

    case 'Сагласност':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.consent_id || ''}
          fullValue={values.consent}
          data={consents}
          display="name"
          name="consent_id"
          fullValueName="consent"
          valueAttr="id"
          label="Сагласност"
        />
      );
      break;

    case 'Одбацивање':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.rejection_reason_id || ''}
          fullValue={values.rejection_reason}
          data={allSelectOptions.rejection_reasons}
          display="name"
          name="rejection_reason_id"
          fullValueName="rejection_reason"
          valueAttr="id"
          label="Одбацивање"
        />
      );
      break;

    case 'Миритељ односно арбитар':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.conciliator_id || ''}
          fullValue={values.litigator}
          showFields={['litigator_firstname', 'litigator_lastname']}
          display="name"
          data={allSelectOptions.litigators}
          name="conciliator_id"
          fullValueName="litigator"
          valueAttr="id"
          label="Миритељ односно арбитар"
        />
      );
      break;

    case 'Начин окончања поступка':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.finalizing_procedure_way_id || ''}
          fullValue={values.finalizing_procedure_way}
          data={allSelectOptions.statuses}
          display="name"
          name="finalizing_procedure_way_id"
          fullValueName="finalizing_procedure_way"
          valueAttr="id"
          label="Начин окончања поступка"
        />
      );
      break;

    case 'Обустава':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={setFieldValue}
          value={values.conclusion_id || ''}
          fullValue={values.conclusion}
          fullValueName="conclusion"
          display="name"
          data={allSelectOptions.conclusions}
          name="conclusion_id"
          valueAttr="id"
          label="Обустава"
        />
      );
      break;

    case 'Поступак у току':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={() => { }}
          customOnChange={(e: any) => {
            setFieldValue('status', e === 'Да' ? 1 : 0);
          }}
          value={'id'}
          display="id"
          data={[{ id: 'Да' }, { id: 'Не' }]}
          valueAttr="id"
          label="Поступак у току"
        />
      );
      break;

    case 'Статус плаћања':
      column = (
        <CustomMaterialUiSelect
          setFieldValue={() => { }}
          customOnChange={(e: any) => {
            setFieldValue('payment_status', e === 'Плаћено' ? true : false);
          }}
          value={'id'}
          display="id"
          data={[{ id: 'Плаћено' }, { id: 'Није плаћено' }]}
          valueAttr="id"
          label="Статус плаћања"
        />
      );
      break;
  }

  return column;
};

export const exportToExcel = (data: any) => {
  interface ObjectDetails {
    id: number;
    name: string;
  }

  const allSelectOptions: any = JSON.parse(
    localStorage.getItem('allSelectOptions') || ''
  );

  const returnSector = (sector: string) => {
    if (sector === 'private') {
      return 'Приватни';
    }

    if (sector === 'state') {
      return 'Државни';
    }

    return '/';
  }

  const returnNameOfType = (id: string, array: ObjectDetails[]) => {
    const findObject: ObjectDetails | undefined = array.find(
      (type: any) => type.id == id
    );

    if (findObject && findObject.name) {
      return findObject.name;
    }

    return '';
  };

  const tableData = data.map((item: any) => {
    const {
      created_at,
      document_date_of_input,
      litigation_number,
      worker: { firstname, lastname },
      phase,
      decision_making_date,
      additional_info,
      procedure_type_id,
      litigation_type,
      legal_form_first_party_id,
      name_first_party,
      email_first_party,
      municipality_first_party,
      phone_first_party,
      street_first_party,
      street_number_first_party,
      city_first_party,
      post_number_first_party,
      conciliator_choosing_way_id,
      consent_id,
      rejection_reason_id,
      proposal_type_id,
      legal_form_second_party_id,
      name_second_party,
      email_second_party,
      municipality_second_party,
      phone_second_party,
      street_second_party,
      street_number_second_party,
      city_second_party,
      post_number_second_party,
      litigator,
      finalizing_procedure_way_id,
      conclusion_id,
      affected_number,
      sector,
      status,
      payment_status
    } = item;

    return {
      Датум_уписа: moment(created_at).format(DATE_FORMAT),
      Време_уписа: moment(document_date_of_input).format('HH:mm:ss'),
      Број_предмета: litigation_number.substring(8, 17),
      Обрађивач: `${firstname} ${lastname}`,
      Фаза: phase ? phase.name : '',
      Датум_доношења_решења_препоруке_миритеља:
        decision_making_date &&
        moment(decision_making_date).format(DATE_FORMAT),
      Напомене: additional_info,
      Врста_поступка: returnNameOfType(
        procedure_type_id,
        allSelectOptions.procedure_types
      ),
      Предмет_спора: litigation_type ? litigation_type.litigation_name : '',
      Правна_форма_прве_стране: returnNameOfType(
        legal_form_first_party_id,
        allSelectOptions.legal_form_types
      ),
      Прва_страна: name_first_party,
      Емаил_прве_стране: email_first_party,
      Општина_прве_стране: municipality_first_party,
      Контакт_број_прве_стране: phone_first_party,
      Улица_прве_стране: street_first_party,
      Број_прве_стране: street_number_first_party,
      Место_прве_стране: city_first_party,
      Поштански_број_прве_стране: post_number_first_party,
      Начин_избора_миритеља_односно_арбитра: returnNameOfType(
        conciliator_choosing_way_id,
        allSelectOptions.litigator_statuses
      ),
      Сагласност: returnNameOfType(consent_id, allSelectOptions.consents),
      Одбацивање: returnNameOfType(
        rejection_reason_id,
        allSelectOptions.rejection_reasons
      ),
      Врста_предлога: returnNameOfType(
        proposal_type_id,
        allSelectOptions.proposal_types
      ),
      Правна_форма_друге_стране: returnNameOfType(
        legal_form_second_party_id,
        allSelectOptions.legal_form_types
      ),
      Друга_страна: name_second_party,
      Емаил_друге_стране: email_second_party,
      Општина_друге_стране: municipality_second_party,
      Контакт_број_друге_стране: phone_second_party,
      Улица_друге_стране: street_second_party,
      Број_друге_стране: street_number_second_party,
      Место_друге_стране: city_second_party,
      Поштански_број_друге_стране: post_number_second_party,
      Миритељ_односно_арбитар: `${litigator ? litigator.litigator_firstname : ''
        } ${litigator ? litigator.litigator_lastname : ''}`,
      Начин_окончања_поступка: returnNameOfType(
        finalizing_procedure_way_id,
        allSelectOptions.statuses
      ),
      Обустава: returnNameOfType(conclusion_id, allSelectOptions.conclusions),
      Сектор: returnSector(sector),
      На_колико_људи_утиче: affected_number,
      Поступак_у_току: status == 1 ? 'Да' : 'Не',
      Плаћено: payment_status == 1 ? 'Да' : 'Не'
    };
  });

  const workbook = new ExcelJS.Workbook();

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 20000,
      height: 15000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible'
    }
  ];

  const firstSheet = workbook.addWorksheet('Подаци');

  const headers = [
    'Датум уписа',
    'Време уписа',
    'Број предмета',
    'Обрађивач',
    'Фаза',
    'Датум доношења решења препоруке миритеља',
    'Напомене',
    'Врста поступка',
    'Предмет спора',
    'Правна форма прве стране',
    'Прва страна',
    'Емаил прве стране',
    'Општина прве стране',
    'Контакт број прве стране',
    'Улица прве стране',
    'Број прве стране',
    'Место прве стране',
    'Поштански број прве стране',
    'Начин избора миритеља односно арбитра',
    'Сагласност',
    'Одбацивање',
    'Врста предлога',
    'Правна форма друге стране',
    'Друга страна',
    'Емаил друге стране',
    'Општина друге стране',
    'Контакт број друге стране',
    'Улица друге стране',
    'Број друге стране',
    'Место друге стране',
    'Поштански број друге стране',
    'Миритељ односно арбитар',
    'Начин окончања поступка',
    'Обустава',
    'Сектор',
    'На колико људи утиче',
    'Поступак у току',
    'Плаћено'
  ];

  const styleFirstRow = (sheet: any) => {
    sheet.addRow(headers).eachCell((cell: any) => {
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffd3d3d3' }
      };
    });

    sheet.columns.forEach((column: any, index: number) => {
      column.width = index === 0 ? 13 : 15;
    });
  };

  // Style and add data from the first table to the first sheet
  styleFirstRow(firstSheet);
  tableData.forEach((row: any) => {
    firstSheet.addRow(Object.values(row));
  });

  // Create a Blob from workbook instance
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Create a download link and trigger a click event to download the Excel file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Извештај.xlsx';
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  });
};
