import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'roles';

const getAllRoles = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const RolesRepo = new BaseRepo(type);

export default {
  ...RolesRepo,
  getAllRoles
};
