import moment from 'moment';
import BaseRepo from './BaseRepo';
import axios from 'axios';
import { REQUEST_DATE_FORMAT } from 'lib/constants';

const type = 'reports-new';

const getInitialReport = (
  dateFrom: string,
  dateTo: string,
  remainingData: object
) => {
  let filters: {}[] = [];

  Object.entries(remainingData).forEach((entry) => {
    const [key, value] = entry;

    filters.push({
      name: key,
      value
    });
  });

  const formatedDateFrom = moment(dateFrom).format(REQUEST_DATE_FORMAT);
  const formatedDateTo = moment(dateTo).format(REQUEST_DATE_FORMAT);

  return axios.post(`${type}`, {
      dateFrom: formatedDateFrom,
      dateTo: formatedDateTo,
      filters
    }
  );
};

const reportsFilterRepo = new BaseRepo(type);

export default {
  ...reportsFilterRepo,
  getInitialReport
};
