import { action, observable } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import { Toast } from '../../lib/dialogs';
import TemplatesRepo from '../../data/repositories/TemplatesRepo';
import { templatesStore } from '../../data/stores/TemplatesStore';

class TemplatesPageStore extends TableStore<any> {
  @observable addButtonVisible = true;

  @action
  showAddButton = () => {
    this.addButtonVisible = true;
  };

  @action
  hideAddButton = () => {
    this.addButtonVisible = false;
  };

  uploadTemplate = async (template: any) => {
    const [response] = await to(TemplatesRepo.uploadTemplate(template));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте креирали шему документа.'
        });
      }, 1000);
      templatesStore.fetch();
      this.closeModalForm();
    }
  };

  updateTemplate = async (template: any, templateId: any) => {
    const [response] = await to(
      TemplatesRepo.updateTemplate(template, templateId)
    );

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте земенили шему документа.'
        });
      }, 1000);
      templatesStore.fetch();
      this.closeModalForm();
    }
  };

  downloadTemplate = async (templateId: any) => {
    const [response] = await to(TemplatesRepo.downloadTemplate(templateId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте скинули шему документа.'
        });
      }, 1000);
      templatesStore.fetch();
      this.closeModalForm();
    }
  };

  deleteTemplate = async (templateId: any) => {
    const [response] = await to(TemplatesRepo.delete(templateId));

    if (response) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Успешно сте обрисали шему документа.'
        });
      }, 1000);
      templatesStore.fetch();
      this.closeModalForm();
    }
  };
}

export const templatesPageStore = new TemplatesPageStore(TemplatesRepo);
export default templatesPageStore;
