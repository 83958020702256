import { action, observable, runInAction } from 'mobx';

import TableStore from '../../data/stores/TableStore';
import to from '../../lib/awaitTo';
import FilterLitigationsRepo from 'data/repositories/FilterLitigationsRepo';
import { omit } from 'lodash';
import moment from 'moment';
import { REQUEST_DATE_FORMAT } from 'lib/constants';

class LitigationsFilterStore extends TableStore<any> {
  @observable selectedItems = [] as any;
  @observable allLitigationsForExport: any;
  @observable customParams: any;

  @observable remainingFilters = [
    { id: 'Датум уписа', visible: true },
    { id: 'Датум од', visible: true },
    { id: 'Датум до', visible: true },
    { id: 'Број предмета', visible: true },
    { id: 'Предмет спора', visible: true },
    { id: 'Обрађивач', visible: true },
    { id: 'Врста поступка', visible: true },
    { id: 'Правна форма прве стране', visible: true },
    { id: 'Име и презиме/назив прве стране', visible: true },
    { id: 'Општина прве стране', visible: true },
    { id: 'Место прве стране', visible: true },
    { id: 'Поштански број прве стране', visible: true },
    { id: 'Врста предлога', visible: true },
    { id: 'Правна форма друге стране', visible: true },
    { id: 'Име и презиме/назив друге стране', visible: true },
    { id: 'Општина друге стране', visible: true },
    { id: 'Место друге стране', visible: true },
    { id: 'Поштански број друге стране', visible: true },
    { id: 'Начин избора миритеља односно арбитра', visible: true },
    { id: 'Сагласност', visible: true },
    { id: 'Одбацивање', visible: true },
    { id: 'Миритељ односно арбитар', visible: true },
    { id: 'Начин окончања поступка', visible: true },
    { id: 'Обустава', visible: true },
    { id: 'Поступак у току', visible: true },
    { id: 'Статус плаћања', visible: true }
  ];

  @action
  addSelectedItem = (newItem: any) => {
    this.selectedItems = [...this.selectedItems, newItem];
  };

  @action
  removeSelectedItem = (selectedItem: any) => {
    this.selectedItems = this.selectedItems.filter(
      (everyItem: any) => everyItem.id !== selectedItem.id
    );
  };

  @action
  removeFilter = (filterName: string) => {
    runInAction(() => {
      this.remainingFilters = this.remainingFilters.map((item: any) =>
        item.id !== filterName ? item : { ...item, visible: false }
      );
    });
  };

  @action
  addFilter = (filterName: string) => {
    runInAction(() => {
      this.remainingFilters = this.remainingFilters.map((item: any) =>
        item.id !== filterName ? item : { ...item, visible: true }
      );
    });
  };

  @action
  fetchSubjects = async (data: any) => {
    // pagination fix
    if (data.page) {
      const [response, error] = await to(
        this.repo.getAll({
          params: { ...this.customParams, limit: 100, page: data.page }
        })
      );

      if (response && response.data) {
        this.onFetch(response.data.data.data, response.data.data);
      }
      if (error) {
        this.onError();
      }
    } else {
      runInAction(() => {
        this.customParams = {};
      });

      const removeEmptyKeys = (obj: any) => {
        let filteredObj = {};
        for (const key in obj) {
          if (obj[key] !== '') {
            filteredObj[key] = obj[key];
          }
        }

        if (data.document_date_of_input || data.dateFrom || data.dateTo) {
          const { document_date_of_input, dateFrom, dateTo } = data;

          filteredObj = {
            ...filteredObj,
            document_date_of_input: document_date_of_input
              ? moment(document_date_of_input).format(REQUEST_DATE_FORMAT)
              : undefined,
            dateFrom: dateFrom
              ? moment(dateFrom).format(REQUEST_DATE_FORMAT)
              : undefined,
            dateTo: dateTo
              ? moment(dateTo).format(REQUEST_DATE_FORMAT)
              : undefined
          };
        }

        // Set customParams for exportAllData
        runInAction(() => {
          this.customParams = filteredObj;
        });

        return filteredObj;
      };

      const customQueryParams = {
        ...removeEmptyKeys(data),
        // orderBy: 'createdAt',
        // sortedBy: 'desc',
        limit: 100
      };

      const [response, error] = await to(
        this.repo.getAll({ params: customQueryParams })
      );

      if (response && response.data) {
        this.onFetch(response.data.data.data, response.data.data);
      }
      if (error) {
        this.onError();
      }
    }
  };

  @action
  exportAllData = async (exporToExceFn: any) => {
    const params = omit(this.queryParams, ['page', 'orderBy', 'sortedBy']);
    const allParams = { ...this.customParams, ...params, limit: 8000 };

    const [response] = await to(this.repo.getAll({ params: allParams }));

    if (response) {
      exporToExceFn(response.data.data.data);
    }
  };
}

export const litigationsFilterStore = new LitigationsFilterStore(
  FilterLitigationsRepo
);
export default litigationsFilterStore;
