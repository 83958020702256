import React from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';

import FormFieldContainer from './FormFieldContainer';

type Component = 'input' | 'textarea';
interface FormFieldProps {
  errors: string | undefined | FormikErrors<any>;
  touched: boolean | undefined | FormikTouched<any>;
  type: string;
  name: string;
  placeholder?: string;
  label: string;
  autoFocus?: boolean;
  inline?: boolean;
  component: Component;
  disabled?: boolean;
  requiredClass?: boolean;
  values?: any;
  onBlur?: (e?: any) => void;
}

const FormField = ({
  values,
  errors,
  touched,
  type,
  name,
  placeholder,
  label,
  autoFocus,
  inline,
  component,
  disabled,
  requiredClass,
  onBlur
}: FormFieldProps) => (
  <FormFieldContainer
    errors={errors}
    touched={touched}
    label={label}
    inline={inline}
    requiredClass={requiredClass}
  >
    {/* <Box component="form" noValidate autoComplete="off">
      <TextField
        id={name}
        placeholder={placeholder}
        type={type}
        label={label}
        name={name}
        autoFocus={autoFocus}
        onBlur={onBlur}
        disabled={disabled}
        variant="standard"
      />
    </Box> */}
    <Field
      className="MuiInput-input MuiInputBase-input css-1x51dt5-MuiInputBase-input-MuiInput-input"
      style={{ border: 'none', boxShadow: 'none' }}
      placeholder={placeholder}
      type={type}
      onBlur={onBlur}
      name={name}
      autoFocus={autoFocus}
      component={component}
      rows={component === 'textarea' ? '5' : null}
      disabled={disabled}
    />
    {/* <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      // error={formik.touched.email && Boolean(formik.errors.email)}
      // helperText={formik.touched.email && formik.errors.email}
    /> */}
  </FormFieldContainer>
);

FormField.defaultProps = {
  type: 'text',
  inline: true,
  component: 'input'
};

export default FormField;
