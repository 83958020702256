import CustomMaterialUiSelect from 'components/CustomMaterialUiSelect';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import litigationsFilterStore from '../LitigationsFilterStore';
import { Button } from '@mui/material';
import { secondColumn } from './helper';

const formikKeys = {
  'Датум уписа': 'document_date_of_input',
  'Датум од': 'dateFrom',
  'Датум до': 'dateTo',
  'Број предмета': 'litigation_number',
  'Предмет спора': 'litigation_type_id',
  Обрађивач: 'working_on_user_id',
  'Врста поступка': 'procedure_type_id',
  'Правна форма прве стране': 'legal_form_first_party_id',
  'Име и презиме/назив прве стране': 'name_first_party',
  'Општина прве стране': 'municipality_first_party',
  'Место прве стране': 'city_first_party',
  'Поштански број прве стране': 'post_number_first_party',
  'Врста предлога': 'proposal_type_id',
  'Правна форма друге стране': 'legal_form_second_party_id',
  'Име и презиме/назив друге стране': 'name_second_party',
  'Општина друге стране': 'municipality_second_party',
  'Место друге стране': 'city_second_party',
  'Поштански број друге стране': 'post_number_second_party',
  'Начин избора миритеља односно арбитра': 'conciliator_choosing_way_id',
  Сагласност: 'consent',
  Одбацивање: 'rejection_reason_id',
  'Миритељ односно арбитар': 'litigator',
  'Начин окончања поступка': 'finalizing_procedure_way_id',
  Обустава: 'conclusion_id',
  'Поступак у току': 'status',
  'Статус плаћања': 'payment_status'
};

interface Props {
  values: any;
  setFieldValue: any;
  isLastRow: boolean;
  rowCount: number;
  valuesCount: number;
}

const NewRowFilter = ({
  values,
  setFieldValue,
  isLastRow,
  rowCount,
  valuesCount
}: Props) => {
  const { remainingFilters, removeFilter, addFilter } = litigationsFilterStore;
  const [filterName, setFilterName] = useState('');
  const [disableFilterSelect, setDisableFilterSelect] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [choseFilterVisible, setChoseFilterVisible] = useState(true);

  const rerenderChoseFilter = () => {
    if (!disableFilterSelect) {
      setFieldValue(filterName, '');
      setChoseFilterVisible(false);

      setTimeout(() => {
        setChoseFilterVisible(true);
      }, 500);
    }

    return;
  };

  useEffect(() => {
    if (isLastRow && rowCount > valuesCount && !filterName) {
      rerenderChoseFilter();
    }
  }, [isLastRow, rowCount, valuesCount]);

  const allSelectOptions: any = JSON.parse(
    localStorage.getItem('allSelectOptions') || ''
  );

  if (!isMounted) return null;

  return (
    <Row
      style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}
    >
      <Col md={4}>
        {choseFilterVisible && (
          <CustomMaterialUiSelect
            setFieldValue={() => { }}
            customOnChange={(e: string) => {
              setFilterName(e);
              removeFilter(e);
            }}
            value={'id'}
            display="id"
            data={remainingFilters.filter((filter: any) => filter.visible)}
            valueAttr="id"
            label="Изаберите филтер"
            disabled={filterName !== ''}
          />
        )}
      </Col>

      <Col md={4}>
        {secondColumn(filterName, values, setFieldValue, allSelectOptions)}
      </Col>

      {filterName && (
        <Button
          onClick={() => {
            addFilter(filterName);
            setFieldValue(formikKeys[filterName], '');
            setIsMounted(false);
          }}
          variant="contained"
          disabled={false}
          style={{
            backgroundColor: '#E30022',
            fontSize: 14,
            fontWeight: 400,
            color: 'white',
            width: '205px',
            height: '36px',
            borderRadius: '4px'
          }}
        >
          Обриши филтер
        </Button>
      )}
    </Row>
  );
};

export default observer(NewRowFilter);
