import BaseRepo from './BaseRepo';
import axios, { AxiosRequestConfig } from 'axios';

const type = 'litigator_types';

const getAllLitigatorTypes = (config: AxiosRequestConfig) => {
  return axios.get(`${type}`, config);
};

const LitigatorTypesRepo = new BaseRepo(type);

export default {
  ...LitigatorTypesRepo,
  getAllLitigatorTypes
};
